import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import axios from 'axios'
import { useDashboard } from './DashboardProvider'
export const useReminderForm = ({ candidateId, reminder, setSubmitted }) => {
  const { reminders, setReminders, toggleShowAddReminderDialog } =
    useDashboard()
  const schema = {
    due_date: z.union([z.date(), z.null(), z.string()]),
    content: z.string().min(2, {
      message: 'Please choose a content.',
    }),
    author_id: z.number(),
  }

  const defaultValues = {
    due_date: reminder?.due_date || null,
    content: reminder?.content || '',
    author_id: candidateId,
  }

  const form = useForm({
    resolver: zodResolver(z.object(schema)),
    defaultValues,
  })

  async function onSubmit(values) {
    setSubmitted(true)
    const token = document.querySelector('meta[name="csrf-token"]').content
    const baseUrl = `/candidates/${candidateId}/reminders`
    const url = reminder ? baseUrl + `/${reminder.id}` : baseUrl
    const data = {
      reminder: {
        due_date: values.due_date,
        content: values.content,
        author_id: values.author_id,
      },
    }

    const config = {
      headers: { 'content-type': 'application/json', 'X-CSRF-TOKEN': token },
      method: reminder ? 'PATCH' : 'POST',
    }
    const response = reminder
      ? await axios.patch(url, data, config)
      : await axios.post(url, data, config)
    if (response.status === 200 || response.status === 201) {
      if (reminder) {
        const updatedReminders = reminders.map((r) => {
          if (r.id === response.data.id) {
            return response.data
          } else {
            return r
          }
        })
        const sortedReminders = updatedReminders.sort((a, b) => {
          return new Date(a.due_date) - new Date(b.due_date)
        })
        setReminders(sortedReminders)
      } else {
        const newReminders = [...reminders, response.data]
        const sortedReminders = newReminders.sort((a, b) => {
          return new Date(a.due_date) - new Date(b.due_date)
        })
        setReminders(sortedReminders)
      }
      toggleShowAddReminderDialog()
      return response
    } else {
      console.log(response)
      console.log(response.status)
      console.log(response.error)
      throw new Error('res not ok')
    }
  }

  return { form, onSubmit }
}
