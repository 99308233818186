import React from 'react'
import { PrimaryQuizButton } from './Buttons'
import Avatar from './Avatar'
import Clock from './Icons/Clock'
import NotebookIcon from './Icons/NotebookIcon'
import { useTranslation } from '../../hooks/useTranslation'
import { useQuiz } from './QuizProvider'
import Outcomes from './Outcomes'
import Instructions from './Instructions'

const StartQuiz = ({ quizExists }) => {
  const { setStarted, saveQuiz, startPageAvatar, quizTime, formattedQuizName } =
    useQuiz()
  const avatar = startPageAvatar
  const buttonText = quizExists ? 'Continue Quiz' : 'Start Quiz'
  const { t } = useTranslation(formattedQuizName)
  const questionsInfo = t('questions')
  const questionsArr = Object.values(questionsInfo)
  const numQuestions = questionsArr.length
  const title = t('start_quiz.title')
  const description = t('start_quiz.description')
  const outcomes = t('start_quiz.outcomes')
  const instructions = t('start_quiz.instructions')

  const showOutcomes = typeof outcomes === 'object'
  const showInstructions = typeof instructions === 'object'

  const handleStartQuizClick = () => {
    if (quizExists) {
      setStarted(true)
    } else {
      saveQuiz()
    }
  }

  return (
    <div className="flex flex-col items-center sm:w-9/12">
      <h1
        style={{ fontFamily: 'gotham_boldregular' }}
        className="!mb-0 text-center"
      >
        {title}
      </h1>
      <div className="py-6">
        <div className="relative h-60 w-60">
          <Avatar src={avatar} className={'absolute'} />
          <div className="absolute left-2 top-28 flex h-20 w-40 flex-col justify-around bg-white text-[#08467C]">
            <div
              style={{ fontFamily: 'gotham_boldregular' }}
              className="flex items-center gap-1"
            >
              <Clock />
              {`${quizTime} minutes`}
            </div>
            <div
              style={{ fontFamily: 'gotham_boldregular' }}
              className="flex items-center gap-1"
            >
              <NotebookIcon />
              {`${numQuestions} questions`}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 text-center">
        {description.map((desc, idx) => (
          <div key={`description-p-${idx}`}>{desc}</div>
        ))}
      </div>
      {showInstructions && <Instructions instructions={instructions} />}
      <PrimaryQuizButton
        className="my-8 h-12 rounded-[20px] bg-primary hover:bg-primary-darker-75 sm:h-10"
        onClick={handleStartQuizClick}
      >
        {buttonText}
      </PrimaryQuizButton>
      {showOutcomes && <Outcomes outcomes={outcomes} />}
    </div>
  )
}
export default StartQuiz
