import React, { useState } from 'react'
import { Paragraph, H1 } from './styles'
import { cn } from '../../lib/utils'
import { useIsDesktop } from '../../hooks/useMediaQuery'
import { useMiniplatform } from './MiniplatformProvider'

const CoachBio = () => {
  const { coachBio, showOneVOneCoach, groupSessionCoachInfo } =
    useMiniplatform()
  const [collapsed, setCollapsed] = useState(true)
  const collapseHeight = 'max-h-16'
  const expandHeight = 'max-h-screen'
  const isDesktop = useIsDesktop()
  const [toggleText, setToggleText] = useState('View More')

  const startExpanded = isDesktop || coachBio.length < 150
  const startCollapsed = !startExpanded

  const [divHeight, setDivHeight] = useState(
    startExpanded ? expandHeight : collapseHeight,
  )

  const bio = showOneVOneCoach ? coachBio : groupSessionCoachInfo.coachBio

  const toggleBio = () => {
    if (collapsed) {
      setDivHeight(expandHeight)
      setToggleText('View Less')
    } else {
      setDivHeight(collapseHeight)
      setTimeout(() => {
        setToggleText('View More')
      }, 800)
      setTimeout(() => {
        setToggleText('View More')
      }, 800)
    }
    setCollapsed(!collapsed)
  }

  return (
    <div className={cn('col-span-6 flex flex-col gap-2')}>
      <H1>Meet Your Coach</H1>
      <Paragraph
        style={{
          transitionTimingFunction: 'ease-in-out',
          transitionDuration: '2s',
        }}
        className={
          isDesktop
            ? cn(expandHeight)
            : cn('duration-1500 overflow-hidden transition-all', divHeight)
        }
      >
        {bio}
      </Paragraph>
      {startCollapsed && (
        <Paragraph onClick={toggleBio} className={'underline'}>
          {toggleText}
        </Paragraph>
      )}
    </div>
  )
}

export default CoachBio
