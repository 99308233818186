import React from 'react'
import { RightArrow } from './Icons/Arrows'
import TaskIcons from './Icons/TaskIcons'
const Task = ({ task, icon, links, showArrow, ...props }) => {
  return (
    <div {...props}>
      <div className="text-center sm:w-2/5 sm:text-left">{task}</div>
      {showArrow && <RightArrow className="sm:w-1/5" />}
      <div className="flex flex-col items-center sm:w-2/5">
        {TaskIcons('compass')}
        {links.map((link, i) => {
          const [linkText, linkUrl] = link
          return (
            <a
              className="text-center sm:text-left"
              key={`task-link-${i}`}
              href={linkUrl}
            >
              {linkText}
            </a>
          )
        })}
      </div>
    </div>
  )
}
export default Task
