import * as React from 'react'

import { useSavedJobs } from './SavedJobsProvider'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuCheckboxItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from '../../../lib/ui/dropdown-menu'
import { useTranslation } from '../../../hooks/useTranslation'
import { ChevronDown } from 'lucide-react'
import { Button } from '../../../lib/ui/button'

export function SortByDropdown() {
  const {
    sortBy: {
      pinFollowupJobs,
      setPinFollowupJobs,
      sortByValue,
      setSortByValue,
    },
  } = useSavedJobs()
  const { t } = useTranslation('sort_by')

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="default"
          className="flex h-6 flex-row gap-2 rounded-full border border-[#B9B9B9] bg-white pl-3 pr-2 text-xs text-black hover:bg-white focus:bg-accent data-[status=applied]:bg-[#E0FFD8] data-[status=archive]:bg-white data-[status=closed]:bg-[#D8D8D8] data-[status=interview]:bg-[#FFF7DB] data-[status=offer]:bg-[#EDC7FF] data-[status=saved]:bg-[#D5EBFB] sm:h-8 sm:px-4 sm:text-base"
          data-status={sortByValue}
        >
          {t(sortByValue)}
          <ChevronDown className="h-3 w-3 sm:h-4 sm:w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        <DropdownMenuCheckboxItem
          checked={pinFollowupJobs}
          onCheckedChange={setPinFollowupJobs}
          className="text-base"
        >
          {t('pin_followup_jobs')}
        </DropdownMenuCheckboxItem>
        <DropdownMenuSeparator />
        <DropdownMenuRadioGroup
          value={sortByValue}
          onValueChange={setSortByValue}
        >
          <DropdownMenuLabel>Date</DropdownMenuLabel>
          <DropdownMenuRadioItem value="created_at" className="text-base">
            {t('created_at')}
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem value="updated_at" className="text-base">
            {t('updated_at')}
          </DropdownMenuRadioItem>
          <DropdownMenuSeparator />
          <DropdownMenuLabel>Status</DropdownMenuLabel>
          <DropdownMenuRadioItem
            value="saved"
            className="rounded-full text-base focus:bg-[#D5EBFB]"
          >
            {t('saved')}
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem
            value="applied"
            className="rounded-full text-base focus:bg-[#E0FFD8]"
          >
            {t('applied')}
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem
            value="interview"
            className="rounded-full text-base focus:bg-[#FFF7DB]"
          >
            {t('interview')}
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem
            value="offer"
            className="rounded-full text-base focus:bg-[#EDC7FF]"
          >
            {t('offer')}
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem
            value="closed"
            className="rounded-full text-base focus:bg-[#D8D8D8]"
          >
            {t('closed')}
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem
            value="archive"
            className="rounded-full border border-transparent text-base focus:border-alto-200 focus:bg-white"
          >
            {t('archive')}
          </DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
