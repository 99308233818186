import React from 'react'
import { FavoriteIcon } from './icons'
import { useResources } from './ResourcesProvider'

const AudioCard = ({ audioFile }) => {
  const { headerImageUrl, headerImageAlt, url, title, description } = audioFile
  const { checkResourceFavorite, toggleResourceFavorite } = useResources()
  const isResourceFavorite = checkResourceFavorite(audioFile)

  const favoriteIconClass = isResourceFavorite
    ? 'fill-primary stroke-primary'
    : 'stroke-primary'
  const favoriteTitle = isResourceFavorite
    ? 'Remove from favorites'
    : 'Add to favorites'

  return (
    <div className="relative mb-8 overflow-hidden rounded-3xl border border-solid border-[#B9B9B9]">
      <div className="absolute right-4 top-4">
        <button
          className="rounded-lg bg-white p-2 hover:bg-gray-100"
          title={favoriteTitle}
          onClick={() => toggleResourceFavorite(audioFile)}
        >
          <FavoriteIcon className={favoriteIconClass} height={24} />
        </button>
      </div>
      <img
        src={headerImageUrl}
        alt={headerImageAlt}
        className="aspect-[22/11] w-full object-cover object-center"
      />
      <div className="p-4">
        <h2
          role="heading"
          tabIndex="0"
          aria-level="2"
          className="!mb-2 !mt-0 truncate text-xl font-bold focus:overflow-visible"
          title={title}
        >
          {title}
        </h2>
        <p className="!my-0 line-clamp-3">{description}</p>
        <div className="mt-6 flex justify-between gap-6">
          <audio className="w-full" controls src={url} />
        </div>
      </div>
    </div>
  )
}

export default AudioCard
