import React from 'react'
import { Subheading, Paragraph, Link } from './styles'
import { Separator } from '../../lib/ui/separator'
import { cn } from '../../lib/utils'
import { useMiniplatform } from './MiniplatformProvider'

const CoachCard = () => {
  const {
    coachName,
    coachPhoto,
    coachingPath,
    isDesktop,
    page,
    className,
    maxWidth,
  } = useMiniplatform()
  return (
    page === 'coach_notes' && (
      <div className="bg-[#E5F6FF]">
        <div
          className={cn(
            'flex flex-col gap-4  md:flex-row md:gap-28',
            className,
            maxWidth,
          )}
        >
          <div className="w-full rounded-[20px] border border-solid border-black bg-white p-8">
            <div className="flex flex-col gap-4 md:h-full md:flex-row md:items-center">
              <div className="flex flex-col gap-4 md:w-9/12">
                <div className="flex items-center gap-2">
                  <img className="h-12 rounded-full" src={coachPhoto} alt="" />
                  <Paragraph className="font-bold">Coach {coachName}</Paragraph>
                </div>
                <Paragraph>
                  {`After each coaching session, your coach will post a note here summarizing the session and providing feedback for you.`}
                </Paragraph>
                <Paragraph>
                  {`Click the button ${
                    isDesktop ? 'to the right' : 'below'
                  } or go to the "Schedule Coaching" nav item on the left to view your coaching schedule.`}
                </Paragraph>
              </div>
              <Separator orientation="vertical" />
              <div className="flex flex-col items-center gap-4 md:w-3/12">
                <div className="flex flex-col md:items-center">
                  <Subheading className="text-base">Click here to</Subheading>
                  <Subheading className="text-base">
                    {' '}
                    view your Coaching Schedule
                  </Subheading>
                </div>
                <Link className="btn btn-primary" href={coachingPath}>
                  {' '}
                  View Coaching{' '}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
export default CoachCard
