import React from 'react'
import { useData } from './DataProvider'
import { SolarCase, Cycle, ContractLine, Building, Money } from './Icons'
import InterestCard from './InterestCard'
import useTranslation from '../shared/TranslationProvider'

const InterestSelect = () => {
  const { interest, interestKeys, setInterest } = useData()
  const { t } = useTranslation()

  const [newJob, careerChange, contractJob, workingForSelf, retirement] =
    interestKeys

  const handleInterestClick = (clickedInterest) => {
    if (interest == clickedInterest) {
      setInterest(null)
    } else {
      setInterest(clickedInterest)
    }
  }

  return (
    <>
      <div className="flex flex-row justify-center">
        <div className="mr-4 sm:mr-14">
          <InterestCard
            title={t('interests.new_job')}
            onClick={() => handleInterestClick(newJob)}
            active={interest == newJob}
          >
            <SolarCase active={interest == newJob} />
          </InterestCard>
        </div>
        <div className="mr-4 sm:mr-14">
          <InterestCard
            title={t('interests.career_change')}
            onClick={() => handleInterestClick(careerChange)}
            active={interest == careerChange}
          >
            <Cycle active={interest == careerChange} />
          </InterestCard>
        </div>
        <div className="">
          <InterestCard
            title={t('interests.contract_job')}
            onClick={() => handleInterestClick(contractJob)}
            active={interest == contractJob}
          >
            <ContractLine active={interest == contractJob} />
          </InterestCard>
        </div>
      </div>
      <div className="mt-4 flex flex-row justify-center sm:mt-14">
        <div className="mr-4 sm:mr-14">
          <InterestCard
            title={t('interests.working_for_self')}
            onClick={() => handleInterestClick(workingForSelf)}
            active={interest == workingForSelf}
          >
            <Building active={interest == workingForSelf} />
          </InterestCard>
        </div>
        <div className="">
          <InterestCard
            title={t('interests.retirement')}
            onClick={() => handleInterestClick(retirement)}
            active={interest == retirement}
          >
            <Money active={interest == retirement} />
          </InterestCard>
        </div>
      </div>
    </>
  )
}
export default InterestSelect
