import React from 'react'
import useTheme from '../../../shared/ThemeProvider'

const CalendarDay = ({ day }) => {
  const ordinal = () => {
    const intDay = parseInt(day)
    if (intDay === 1 || intDay === 21 || intDay === 31) {
      return 'st'
    } else if (intDay === 2 || intDay === 22) {
      return 'nd'
    } else if (intDay === 3 || intDay === 23) {
      return 'rd'
    } else {
      return 'th'
    }
  }
  const { secondaryLighter } = useTheme()
  const style = { color: secondaryLighter }
  const singleDigitDay = parseInt(day) < 10
  const singleDigitClass =
    'absolute left-[0.35rem] top-[1.17rem] h-[1.75rem] w-[1.55rem] text-right text-[1.55rem] font-medium'
  const doubleDigitClass =
    'absolute left-[0.35rem] top-[1.17rem] h-[1.75rem] w-[1.85rem] text-right text-[1.55rem] font-medium'
  const singleDigitOrdinalClass =
    'absolute h-[0.8rem] left-[1.9rem] top-[1.3rem] w-[0.75rem] text-[0.75rem] font-medium'
  const doubleDigitOrdinalClass =
    'absolute h-[0.8rem] left-[2.2rem] top-[1.3rem] w-[0.75rem] text-[0.75rem] font-medium'
  const digitClass = singleDigitDay ? singleDigitClass : doubleDigitClass
  const ordinalClass = singleDigitDay
    ? singleDigitOrdinalClass
    : doubleDigitOrdinalClass
  return (
    <div className="relative h-[3.5rem] w-[3.5rem]">
      <span className={digitClass} style={style}>
        {day}
      </span>
      <span className={ordinalClass} style={style}>
        {ordinal()}
      </span>
      <span className="absolute left-0 top-0">
        <CalIcon />
      </span>
    </div>
  )
}

const CalIcon = () => {
  const { secondaryLighter } = useTheme()
  return (
    <svg
      width="56"
      height="55"
      viewBox="0 0 56 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1847_8833)">
        <path
          d="M50.3438 55H5.65625C2.80313 55 0.5 52.6969 0.5 49.8438V8.59375C0.5 5.74063 2.80313 3.4375 5.65625 3.4375H50.3438C53.1969 3.4375 55.5 5.74063 55.5 8.59375V49.8438C55.5 52.6969 53.1969 55 50.3438 55ZM5.65625 6.875C4.69375 6.875 3.9375 7.63125 3.9375 8.59375V49.8438C3.9375 50.8063 4.69375 51.5625 5.65625 51.5625H50.3438C51.3063 51.5625 52.0625 50.8063 52.0625 49.8438V8.59375C52.0625 7.63125 51.3063 6.875 50.3438 6.875H5.65625Z"
          fill={secondaryLighter}
        />
        <path
          d="M15.9688 13.75C15.0063 13.75 14.25 12.9937 14.25 12.0312V1.71875C14.25 0.75625 15.0063 0 15.9688 0C16.9312 0 17.6875 0.75625 17.6875 1.71875V12.0312C17.6875 12.9937 16.9312 13.75 15.9688 13.75ZM40.0312 13.75C39.0687 13.75 38.3125 12.9937 38.3125 12.0312V1.71875C38.3125 0.75625 39.0687 0 40.0312 0C40.9938 0 41.75 0.75625 41.75 1.71875V12.0312C41.75 12.9937 40.9938 13.75 40.0312 13.75ZM53.7812 20.625H2.21875C1.25625 20.625 0.5 19.8687 0.5 18.9062C0.5 17.9438 1.25625 17.1875 2.21875 17.1875H53.7812C54.7438 17.1875 55.5 17.9438 55.5 18.9062C55.5 19.8687 54.7438 20.625 53.7812 20.625Z"
          fill={secondaryLighter}
        />
      </g>
      <defs>
        <clipPath id="clip0_1847_8833">
          <rect
            width="55"
            height="55"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

const Circle = ({ onClick, active }) => {
  return active ? <CircleActive /> : <CircleInactive onClick={onClick} />
}

const CircleInactive = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      style={{ display: 'inline-block' }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="white" />
    </svg>
  )
}

const CircleActive = () => {
  const { primaryColor } = useTheme()
  return (
    <svg
      style={{ display: 'inline-block' }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="white" />
      <circle cx="10" cy="10" r="6" fill={primaryColor} />
    </svg>
  )
}

const ArrowLeft = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      style={{ display: 'inline-block' }}
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9934 20C12.1521 20.0009 11.3361 19.7302 10.6826 19.2334L1.61714 12.2174C1.26164 11.9521 0.974547 11.615 0.777221 11.2313C0.579894 10.8475 0.477417 10.427 0.477417 10.001C0.477417 9.57496 0.579894 9.15443 0.777221 8.77069C0.974547 8.38694 1.26164 8.04986 1.61714 7.78452L10.6826 0.768538C11.2284 0.358568 11.886 0.10058 12.5806 0.0239725C13.2751 -0.0526348 13.9786 0.0552199 14.611 0.335247C15.1606 0.562357 15.6288 0.932977 15.9598 1.40284C16.2907 1.87271 16.4704 2.42195 16.4774 2.98499V17.017C16.4704 17.58 16.2907 18.1292 15.9598 18.5991C15.6288 19.069 15.1606 19.4396 14.611 19.6667C14.1029 19.8833 13.5516 19.9969 12.9934 20ZM4.35456 10.001L12.9223 16.667V3.48494L4.35456 10.001Z"
        fill="white"
      />
    </svg>
  )
}

const ArrowRight = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      style={{ display: 'inline-block' }}
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00657 1.93813e-06C4.8479 -0.000940786 5.66392 0.269764 6.31738 0.766592L15.3829 7.78258C15.7384 8.04791 16.0255 8.385 16.2228 8.76874C16.4201 9.15249 16.5226 9.57302 16.5226 9.99903C16.5226 10.425 16.4201 10.8456 16.2228 11.2293C16.0255 11.6131 15.7384 11.9501 15.3829 12.2155L6.31738 19.2315C5.77162 19.6414 5.11399 19.8994 4.41944 19.976C3.72489 20.0526 3.02135 19.9448 2.38901 19.6648C1.83944 19.4376 1.37121 19.067 1.04024 18.5972C0.709282 18.1273 0.529575 17.578 0.522583 17.015L0.522583 2.98305C0.529575 2.42001 0.709282 1.87076 1.04024 1.4009C1.37121 0.931031 1.83944 0.560415 2.38901 0.333304C2.89715 0.116662 3.44837 0.00308178 4.00657 1.93813e-06ZM12.6454 9.99903L4.07767 3.33301L4.07767 16.5151L12.6454 9.99903Z"
        fill="white"
      />
    </svg>
  )
}

const GreenBullet = () => {
  const { secondaryLighter } = useTheme()
  return (
    <svg
      width="1rem"
      height="1rem"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12.5"
        cy="13"
        r="10.5"
        fill={secondaryLighter}
        stroke={secondaryLighter}
        strokeWidth="4"
      />
    </svg>
  )
}

export { ArrowLeft, ArrowRight, Circle, GreenBullet, CalendarDay }
