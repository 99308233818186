import React from 'react'

const Instructions = ({ instructions }) => {
  return (
    <div className="sm:container">
      <div className="instructions flex min-h-28 flex-col rounded-2xl bg-white p-2 sm:gap-2 sm:pt-4">
        <div style={{ fontFamily: 'gotham_boldregular' }} className="text-base">
          {'Instructions for Getting Started:'}
        </div>
        <ul className="ml-6·list-disc">
          {instructions.map((instruction, idx) => (
            <li className="text-sm" key={`instruction-${idx}`}>
              {instruction}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Instructions
