import React, { useCallback, useEffect, useState } from 'react'
import { useData } from './DataProvider'
import { Button } from '@mui/joy'
import ContactUsLink from './ContactUsLink'
import { SpinnerWhite } from '../../shared/Spinner'

const AccountServices = () => {
  const { isLoading, setIsLoading, getApi } = useData()
  const [servicesText, setServicesText] = useState({})

  const navigateNext = () => {
    setIsLoading(true)
    window.location.href = servicesText.linkPath
  }

  const getServicesText = useCallback(async () => {
    try {
      const response = await getApi().get('/license_added/new')
      if (response.data) {
        setServicesText(response.data)
      }
    } catch (error) {
      console.error(error)
      window.location.href = '/'
    }
  }, [setServicesText, getApi])

  useEffect(() => {
    if (!Object.keys(servicesText).length) {
      getServicesText()
    }
  }, [servicesText, getServicesText])

  return (
    <>
      <h1>{servicesText.title}</h1>
      <p className="mt-10">{servicesText.greeting}</p>
      <p className="mt-8">{servicesText.p1}</p>
      <p className="my-8">{servicesText.p2}</p>
      <Button
        className="submit-button btn"
        size="lg"
        fullWidth
        onClick={navigateNext}
        disabled={isLoading}
      >
        {servicesText.linkText}
        <SpinnerWhite show={isLoading} className="pl-4" />
      </Button>
      <div className="mt-6">
        <ContactUsLink />
      </div>
    </>
  )
}

export default AccountServices
