import * as React from 'react'

export const useMediaQuery = (query) => {
  const [matches, setMatches] = React.useState(false)

  React.useEffect(() => {
    const media = window.matchMedia(query)
    if (media.matches !== matches) {
      setMatches(media.matches)
    }
    const listener = () => setMatches(media.matches)
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [matches, query])

  return matches
}

export const useMediaQueryScreen = (size) => {
  const sizes = {
    sm: '(min-width: 640px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 1024px)',
    xl: '(min-width: 1280px)',
  }

  return useMediaQuery(sizes[size])
}

/**
 * Often you want to know if the screen is desktop or mobile
 *
 * Example usage:
 *  const isDesktop = useIsDesktop()
 *
 * if (isDesktop) {
 *  <DesktopComponent />
 * } else {
 *  <MobileComponent />
 * }
 *
 */
export const useIsDesktop = () => useMediaQueryScreen('md')

export const useIsMobile = () => !useMediaQueryScreen('md')
