import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
} from 'react'

const ActionPlanContext = createContext()

export const useActionPlan = () => {
  return useContext(ActionPlanContext)
}

export const ActionPlanProvider = ({ s3_base_url, children }) => {
  const [trackerSectionInfo, setTrackerSectionInfo] = useState({
    complete: [],
    incomplete: [],
  })

  const [progressPercent, setProgressPercent] = useState(0)

  const completionAnimationUrl =
    s3_base_url + '/articles/images/article-complete.gif'

  const cacheCompletionAnimation = useCallback(async () => {
    if (!completionAnimationUrl) return
    const completionAnimation = new Image()
    completionAnimation.src = completionAnimationUrl
  }, [completionAnimationUrl])

  useEffect(() => {
    cacheCompletionAnimation()
  }, [cacheCompletionAnimation])

  useEffect(() => {
    if (
      trackerSectionInfo.complete.length === 0 &&
      trackerSectionInfo.incomplete.length === 0
    ) {
      setProgressPercent(0)
      return
    }

    const subsections = trackerSectionInfo.complete
      .concat(trackerSectionInfo.incomplete)
      .map((section) => Object.values(section)[0].subsections)
      .flat()

    const completed = subsections.filter(
      (section) => section.completed || section.skipped,
    ).length
    const total = subsections.length

    const percent = Math.round((completed / total) * 100)
    setProgressPercent(percent)
  }, [trackerSectionInfo])

  const value = {
    trackerSectionInfo,
    setTrackerSectionInfo,
    progressPercent,
    completionAnimationUrl,
  }

  return (
    <ActionPlanContext.Provider value={value}>
      {children}
    </ActionPlanContext.Provider>
  )
}
