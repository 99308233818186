import * as React from 'react'
import { Button } from '../../../lib/ui/button'
import { itemTypeMap } from '../../ActionPlan/TrackTileComponents/ExpandedCurrentTile'

export function RecommendedCard({ general_recommendations }) {
  return (
    <div className="flex flex-col gap-[32px] md:flex-row md:gap-16">
      {general_recommendations.map((recommendation, index) => (
        <div
          key={index}
          className="flex flex-col rounded-[20px] border p-0 shadow-md shadow-md"
        >
          <img
            src={recommendation.image}
            alt={recommendation.title}
            className="h-[170px] w-full rounded-tl-[20px] rounded-tr-[20px] object-cover"
          />
          <div className="flex flex-col gap-[16px] p-6">
            <h3 className="text-xl font-bold">{recommendation.title}</h3>
            <p className="text-gray-700">{recommendation.description}</p>
            <div className="flex flex-col items-center">
              <Button asChild>
                <a href={recommendation.url}>
                  {itemTypeMap[recommendation.type].text}
                  <span className="ml-[8px]">
                    {React.cloneElement(itemTypeMap[recommendation.type].icon, {
                      variant: 'dark',
                    })}
                  </span>
                </a>
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
