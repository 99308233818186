import * as React from 'react'
import AccessibleFormLabel from './AccessibleFormLabel'
import { Input, FormControl } from '@mui/joy'
import useTranslation from '../../shared/TranslationProvider'
import Errors from './Errors'

const EmailInput = ({ email, setEmail, errors, setErrors, ...props }) => {
  const { t } = useTranslation()
  return (
    <FormControl required error={!!errors.email?.length}>
      <AccessibleFormLabel>{t('email')}</AccessibleFormLabel>
      <Input
        name="email"
        autoComplete="email"
        size="lg"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        {...props}
      />
      <Errors errors={errors} field={'email'} setErrors={setErrors} />
    </FormControl>
  )
}

export default EmailInput
