import * as React from 'react'
import { Button } from '../../../lib/ui/button'
import { cn } from '../../../lib/utils'

export function RecommendedBlogsCard({ recommended_blogs, className }) {
  return (
    <div
      className={cn(
        'relative rounded-[20px] border border-[#B1B1B1] p-6 shadow-md',
        className,
      )}
    >
      <div className="flex h-full flex-col gap-[16px] text-cod-gray-800 md:gap-[12px]">
        <h2 className="mb-[12px] text-2xl font-bold text-primary">
          Recommended Coach Blogs For You
        </h2>
        <div className="flex h-[100%] flex-col gap-[16px] md:justify-around">
          {recommended_blogs.map((blog, index) => (
            <div key={index} className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <a href={blog['url']}>
                  <img
                    src={blog['image']}
                    alt={blog['title']}
                    className="h-[50px]"
                  />
                </a>
                <div className="flex flex-col">
                  <div className="font-bold">{blog['title']}</div>
                  <div>{blog['author']}</div>
                </div>
              </div>
              <div>
                <Button variant="outline" className="hidden md:block" asChild>
                  <a href={blog['url']} rel="noopener noreferrer">
                    Read
                  </a>
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
