import React, { useState } from 'react'
import { ThankYouImage } from './icons'
import { useProgramEvaluation } from './ProgramEvaluationProvider'
import { EvalButton } from './UiLib'

const ThankYouPage = () => {
  const {
    candidate,
    managerEval,
    managerPostEval,
    completedPath,
    inplatform,
    postProgramEvaluation,
    postSessionEval,
  } = useProgramEvaluation()

  const inputOrFeedback = postProgramEvaluation ? 'feedback' : 'input'

  const text =
    managerEval || managerPostEval
      ? `Thank you! Your ${inputOrFeedback} for ${candidate.first_name} has been received!`
      : `Thank you for your ${inputOrFeedback}, ${candidate.first_name}!`
  const [clicked, setClicked] = useState(false)

  const handleClick = () => {
    setClicked(true)
    window.location.href = completedPath.path
  }

  const showButton = !managerEval && !inplatform && !managerPostEval

  return (
    <>
      <div className="font-roboto text-xl">
        {postSessionEval ? <PostSessionEvalText /> : text}
      </div>
      <ThankYouImage />
      {showButton && (
        <EvalButton
          onClick={handleClick}
          className="ga-trackable"
          disabled={clicked}
        >
          {completedPath.text}
        </EvalButton>
      )}
    </>
  )
}

const PostSessionEvalText = () => {
  return (
    <>
      <p>
        Thank you for sharing your feedback. We are committed to developing
        coaching programs that work for you and your suggestions are critical to
        that mission so we appreciate you taking the time to share your
        thoughts.
      </p>

      <p>
        Don’t forget to log back into your platform account to see the session
        notes and resources from your coach and schedule your next meeting!
      </p>
    </>
  )
}

const EvalCompletedPage = () => {
  const { completedPath, managerEval } = useProgramEvaluation()
  const { path, text } = completedPath
  const [clicked, setClicked] = useState(false)
  const handleSetPasswordClick = () => {
    setClicked(true)
    window.location.href = path
  }
  return (
    <div className="flex flex-col items-center justify-center gap-4 text-center">
      <div>Your Program Evaluation has already been completed.</div>
      {!managerEval && (
        <EvalButton
          onClick={handleSetPasswordClick}
          className="ga-trackable"
          disabled={clicked}
        >
          {text}
        </EvalButton>
      )}
    </div>
  )
}

export { ThankYouPage, EvalCompletedPage }
