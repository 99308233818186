import React, { useState } from 'react'
import { Box, Button } from '@mui/joy'
import FeedbackOption from './FeedbackOption'
import { getApi } from '../../../../util/api'
import { SpinnerWhite } from '../../../shared/Spinner'
import useTranslation, {
  TranslationProvider,
} from '../../../shared/TranslationProvider'
import translations from '../../locales.json'

const UnsubscribeSuccessful = ({ locale, ...props }) => {
  return (
    <TranslationProvider
      scope="javascript.components.visitor.LoggedOutExperience.UnsubscribeSuccessful"
      locale={locale}
      translations={translations}
    >
      <UnsubscribeSuccessfulInner {...props} />
    </TranslationProvider>
  )
}

const UnsubscribeSuccessfulInner = ({
  email,
  feedbackKeys,
  feedbackSubmitPath,
}) => {
  const [selectedFeedbackItems, setSelectedFeedbackItems] = useState([])
  const [otherReasons, setOtherReasons] = useState('')
  const [otherReasonsChecked, setOtherReasonsChecked] = useState(false)
  const [disabledClass, setDisabledClass] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleOtherReasonsBlur = () => {
    if (!otherReasons.length) {
      setOtherReasonsChecked(false)
    }
  }

  const handleOtherReasonsFocus = () => {
    setOtherReasonsChecked(true)
    setDisabledClass('')
  }

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setSelectedFeedbackItems((prev) => [...prev, e.target.value])
    } else {
      setSelectedFeedbackItems((prev) =>
        prev.filter((item) => item !== e.target.value),
      )
    }
  }

  const handleOtherReasonsCheckboxChange = (e) => {
    setOtherReasonsChecked(e.target.checked)
    if (e.target.checked) {
      setDisabledClass('')
    } else {
      setDisabledClass('disabled')
    }
  }

  const submitFeedback = () => {
    const selectedTempVar = [...selectedFeedbackItems]
    if (otherReasons.length && otherReasonsChecked) {
      selectedTempVar.push(otherReasons)
    }
    handleSubmitResponse(selectedTempVar)
  }

  const handleSubmitResponse = async (selectedItemsTemp) => {
    setIsLoading(true)

    const feedbackParams = {
      email: email,
      resource: { unsubscribe_feedbacks: selectedItemsTemp },
    }

    try {
      const response = await getApi().patch(feedbackSubmitPath, feedbackParams)
      if (response.data.notice) {
        localStorage.setItem('unsubscribed', true)
        window.location.href = response.data.location
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const { t } = useTranslation()

  return (
    <div className="all-forms-container">
      <>
        <Box>
          <h1>{t('title')}</h1>
        </Box>
        <Box className="subtext-container">{t('you_have_been_removed')}</Box>
        <Box className="feedback-form">
          <Box className="feedback-header">{t('reason_for_leaving')}</Box>
          <Box className="feedback-options">
            {feedbackKeys.map((feedbackKey, idx) => {
              return (
                <FeedbackOption
                  key={`${feedbackKey}_${idx}`}
                  label={t(`key_${idx}`)}
                  value={feedbackKey}
                  onChange={handleCheckboxChange}
                  name={feedbackKey}
                />
              )
            })}

            <FeedbackOption
              label={t('key_5') + ':'}
              value="other"
              name={'other'}
              onChange={handleOtherReasonsCheckboxChange}
              checked={otherReasonsChecked}
            >
              <Box
                className="other-reasons"
                sx={{
                  textarea: {
                    width: '405px',
                    height: '118px',
                    borderRadius: '5px',
                    border: '1px solid #CBD5E0',
                    backgroundColor: '#FFF',
                    paddingBottom: '0',
                  },
                }}
              >
                <textarea
                  value={otherReasons}
                  onChange={(e) => setOtherReasons(e.target.value)}
                  onFocus={handleOtherReasonsFocus}
                  onBlur={handleOtherReasonsBlur}
                  className={disabledClass}
                />
              </Box>
            </FeedbackOption>
          </Box>
          <Box className="button-container">
            <Button
              className="submit-button btn"
              size="lg"
              fullWidth
              onClick={submitFeedback}
            >
              {t('submit_feedback')}
              <SpinnerWhite show={isLoading} className="pl-4" />
            </Button>
          </Box>
        </Box>
      </>
    </div>
  )
}
export default UnsubscribeSuccessful
