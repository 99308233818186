import * as React from 'react'

import { cn } from '../../../lib/utils'

export function CoachCard({ icon, title, description, children }) {
  const childrenArray = React.Children.toArray(children)
  const justifyClass =
    childrenArray.length > 1 ? 'justify-between' : 'justify-center'

  const Icon = icon

  return (
    <div className="pt-7">
      <div className="relative min-h-[195px] w-[400px] rounded-[20px] border border-intoo-green-coachcta p-4 pt-9 text-center">
        <div className="absolute left-[167px] top-[-30px] flex h-16 w-16 items-center justify-center rounded-full border border-intoo-green-coachcta bg-white">
          <Icon className="h-7 w-7 text-intoo-green-coachcta" />
        </div>
        <div className="flex h-full flex-col gap-4">
          <h2 className="text-2xl font-medium">{title}</h2>
          <div className="space-y-4">
            <p className="">{description}</p>
            <div className={cn('flex', justifyClass)}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
