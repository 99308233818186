import React, { useState } from 'react'
import { Box, Button, Link } from '@mui/joy'
import PasswordInput from './PasswordInput'
import ContactUsLink from './ContactUsLink'
import { SpinnerWhite } from '../../shared/Spinner'
import { useData } from './DataProvider'
import { useAlert } from './AlertModal'
import useTranslation from '../../shared/TranslationProvider'
import SsoButtons from './Sso/SsoButtons'

const AddLicense = () => {
  const { existingUsername, existingUserApple, existingUserGoogle } = useData()
  const { t } = useTranslation()
  const showPasswordAuth = !existingUserApple && !existingUserGoogle

  return (
    <Box className="add-license">
      <Box>
        <h1>{t('AddLicense.title')}</h1>
      </Box>
      <Box
        className="subtext-container"
        sx={{ color: '#171923', fontWeight: 500 }}
      >
        <h4>
          {t('AddLicense.username')}: {existingUsername}
        </h4>
      </Box>
      <Box className="subtext-container">{t('AddLicense.subtext')}</Box>
      <Box className="subtext-container">{t('AddLicense.subtext2')}</Box>
      <Box className="subtext-container last">{t('AddLicense.subtext3')}</Box>
      {showPasswordAuth && <PasswordAuth />}
      {!showPasswordAuth && (
        <SsoButtons
          showGoogle={existingUserGoogle}
          showApple={existingUserApple}
          showOr={false}
          goBackFormState="RegistrationForm"
        />
      )}
      <ContactUsLink />
    </Box>
  )
}

const PasswordAuth = () => {
  const { t } = useTranslation()
  const {
    existingUsername,
    isLoading,
    invitation,
    handleFormResponse,
    setFormState,
    setIsLoading,
    getApi,
  } = useData()

  const { setAlertState, setAlertData } = useAlert()
  const [password, setPassword] = useState('')

  const handleAddLicense = async () => {
    setIsLoading(true)
    const user = {
      add_license: true,
      invitation: invitation.id,
      locale: invitation.locale,
      password: password,
      email: existingUsername,
    }
    try {
      const response = await getApi().post('/login', { user })
      if (response.data.alert) {
        setIsLoading(false)
        setAlertState('alert')
        setAlertData(response.data.alert)
      } else {
        handleFormResponse(response)
      }
    } catch (error) {
      setIsLoading(false)
      setAlertState('alert')
      setAlertData(error.response.data.error)
      console.log('error', error)
    }
  }

  return (
    <>
      <Box className="control-container">
        <PasswordInput
          password={password}
          setPassword={setPassword}
          labelValue={t('password')}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleAddLicense()
          }}
        />
      </Box>
      <Box
        className="link-container"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <Link onClick={() => setFormState('ForgotPassword')}>
          {t('AddLicense.forgot_password')}
        </Link>
      </Box>
      <Box className="button-container sso-container">
        <Button
          className="submit-button btn"
          size="lg"
          fullWidth
          onClick={handleAddLicense}
          disabled={isLoading}
        >
          {t('continue')}
          <SpinnerWhite show={isLoading} className="pl-4" />
        </Button>
        <Button
          className="back-button btn"
          size="lg"
          fullWidth
          onClick={() => setFormState('RegistrationForm')}
        >
          {t('go_back')}
        </Button>
      </Box>
    </>
  )
}

export default AddLicense
