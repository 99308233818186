import React from 'react'
import { GreenBullet, CalendarDay } from './icons'
import { useData } from '../DataProvider'
import useTranslation from '../../../shared/TranslationProvider'

export {
  BulletList,
  WebinarTile,
  Testimonial,
  PanelHeading,
  PanelContent,
  BulletListItem,
  BenefitsBulletList,
}

const PanelHeading = ({ children }) => {
  return (
    <h2 className="my-4 max-w-md text-4xl font-bold text-white">{children}</h2>
  )
}

const PanelContent = ({ children }) => {
  return (
    <div className="flex min-h-[65vh] max-w-md flex-col sm:mx-4">
      {children}
    </div>
  )
}

const BenefitsBulletList = ({ items }) => {
  const { t } = useTranslation()
  const title = t('RightPanel.benefits')
  return <BulletList items={items} title={title} />
}

const BulletList = ({ subtitle, items, title }) => {
  const { t } = useTranslation()
  return (
    <PanelContent>
      <PanelHeading>{title}</PanelHeading>
      {subtitle && <h2 className="mt-4 text-white">{subtitle}</h2>}
      <ul className="mt-8 inline-flex flex-col gap-6 text-white">
        {items.map((item, key) => (
          <BulletListItem key={key}>{t(item)}</BulletListItem>
        ))}
      </ul>
    </PanelContent>
  )
}

const BulletListItem = ({ children }) => {
  return (
    <li className="inline-flex flex-row place-content-start">
      <span className="mr-2 min-w-7 pt-[0.35rem]">
        <GreenBullet />
      </span>
      <span className="flex-shrink">{children}</span>
    </li>
  )
}

const WebinarTile = ({ title, detail, day }) => {
  return (
    <div className="my-4 inline-flex max-w-md rounded-3xl bg-white px-8 py-6 shadow-md">
      <div className="flex w-full flex-col items-center">
        <CalendarDay day={day} />
        <div className="mt-4 h-px w-full bg-[#75B21D]"></div>
        <div className="mt-4 font-bold">{title}</div>
        <div className="mt-2 max-w-sm text-center text-xs leading-5 leading-5">
          {detail}
        </div>
      </div>
    </div>
  )
}

const Testimonial = () => {
  const { programLevel } = useData()
  const { t } = useTranslation()
  const quotes = {
    SOLO: t('RightPanel.testimonial.solo'),
    SOLO_PLUS: t('RightPanel.testimonial.solo_plus'),
    FLEX: t('RightPanel.testimonial.flex'),
    PREMIUM: t('RightPanel.testimonial.premium'),
    SELECT: t('RightPanel.testimonial.select'),
  }
  return (
    <div className="mb-6 mt-20 inline-flex max-w-md flex-shrink items-center rounded-3xl bg-white px-8 py-6 shadow-md xl:max-w-lg">
      <img
        className="h-24 w-24 sm:h-32 sm:w-32"
        src="https://www-yns-com.s3.us-east-2.amazonaws.com/images/outplacement/testimonial-raymond.png"
      />
      <div className="ml-6 flex flex-col">
        <span className="mb-2 font-semibold leading-5 text-[#1A1A1A]">
          {t('RightPanel.testimonial_title')}
        </span>
        <span className="mb-2 text-xs font-bold leading-5 text-[#123F1C]">
          Raymond Fields
        </span>
        <span className="text-xs leading-5 text-[#464646]">
          &ldquo;{quotes[programLevel]}&rdquo;
        </span>
      </div>
    </div>
  )
}
