import React from 'react'
import { useData } from '../DataProvider'
import { Label } from '../Label'
import { Input } from '../../../lib/ui/input'
import DesiredLocation from './DesiredLocation'
import useTranslation from '../../shared/TranslationProvider'

const Step3WorkingForMyself = () => {
  const {
    desiredIndustry,
    desiredOpportunities,
    setDesiredIndustry,
    setDesiredOpportunities,
  } = useData()
  const { t } = useTranslation()
  return (
    <>
      <div className="flex flex-col gap-8">
        <div>
          <Label className="text-base" htmlFor="desired-opportunities">
            {t('step3.desired_opportunities')}:
          </Label>
          <Input
            id="desired-opportunities"
            type="text"
            className="mt-2"
            value={desiredOpportunities}
            onChange={(e) => setDesiredOpportunities(e.target.value)}
          />
        </div>
        <DesiredLocation />
        <div>
          <Label htmlFor="desired-industry">
            {t('step3.desired_industry')}:
          </Label>
          <Input
            id="desired-industry"
            type="text"
            className="mt-2"
            name="desired-industry"
            value={desiredIndustry}
            onChange={(e) => setDesiredIndustry(e.target.value)}
          />
        </div>
      </div>
    </>
  )
}

export default Step3WorkingForMyself
