import React, { useState } from 'react'
import { Form } from '../../lib/ui/form'
import { useReminderForm } from './useReminderForm'
import { DatePickerField } from '../shared/DatePickerField'
import { TextareaField } from '../surveys/TextareaField'
import { Button } from '../../lib/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from '../../lib/ui/dialog'
import { useDashboard } from './DashboardProvider'

const SetReminderDialog = ({ children }) => {
  const {
    showAddReminderDialog,
    toggleShowAddReminderDialog,
    editingReminder,
  } = useDashboard()

  return (
    <Dialog
      open={showAddReminderDialog}
      onOpenChange={toggleShowAddReminderDialog}
    >
      <DialogTrigger asChild className="cursor-pointer">
        {children}
      </DialogTrigger>
      <DialogContent className="site-info-dialog roboto max-w-xl">
        <DialogHeader>
          <DialogTitle>Set Your Reminder</DialogTitle>
          {!editingReminder && <AddReminderForm />}
          {editingReminder && <EditReminderForm />}
        </DialogHeader>
        <DialogClose />
      </DialogContent>
    </Dialog>
  )
}
export default SetReminderDialog

const ReminderForm = ({ form, onSubmit, submitted }) => {
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <DatePickerField
          name="due_date"
          id="reminder_due_date"
          label="Due Date for this reminder:"
          disabled={(date) =>
            date < new Date() || date > new Date('2100-01-01')
          }
          control={form.control}
        />
        <TextareaField
          name="content"
          id="reminder_content"
          className="w-full"
          label="Share your thoughts:"
          control={form.control}
          placeholder="Remember to..."
        />
        <Button disabled={submitted} type="submit">
          Save
        </Button>
      </form>
    </Form>
  )
}

const AddReminderForm = () => {
  const [submitted, setSubmitted] = useState(false)
  const { candidateId } = useDashboard()
  const { form, onSubmit } = useReminderForm({
    candidateId: candidateId,
    setSubmitted,
  })
  return <ReminderForm form={form} onSubmit={onSubmit} submitted={submitted} />
}

const EditReminderForm = () => {
  const [submitted, setSubmitted] = useState(false)
  const { candidateId, editingReminder } = useDashboard()
  const { form, onSubmit } = useReminderForm({
    candidateId: candidateId,
    reminder: editingReminder,
    setSubmitted,
  })
  return <ReminderForm form={form} onSubmit={onSubmit} submitted={submitted} />
}
