import React from 'react'
import PanelNav, { PanelNavProvider, PanelEnabled } from './PanelNav'
import { BenefitsBulletList, Testimonial } from './components'
import { AylaPanel, WebinarPanel } from './commonPanels'
import useTranslation from '../../../shared/TranslationProvider'

const PremiumBenefitsPanel = () => {
  const items = [...Array(6).keys()].map(
    (key) => `RightPanel.premium.item${key}`,
  )
  return (
    <>
      <PanelEnabled thisPanelNumber={0}>
        <PanelNav />
        <BenefitsBulletList items={items} />
        <Testimonial />
      </PanelEnabled>
    </>
  )
}

const RightPanelPremium = () => {
  const { locale } = useTranslation()
  if (locale.slice(0, 2) === 'en') {
    return (
      <PanelNavProvider panelCount={3}>
        <PremiumBenefitsPanel />
        <WebinarPanel />
        <AylaPanel />
      </PanelNavProvider>
    )
  } else {
    return (
      <PanelNavProvider panelCount={2}>
        <PremiumBenefitsPanel />
        <AylaPanel thisPanelNumber={1} />
      </PanelNavProvider>
    )
  }
}

export default RightPanelPremium
