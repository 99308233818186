import React, { useState } from 'react'
import { Box, Button } from '@mui/joy'
import EmailInput from '../EmailInput'
import ContactUsLink from '../ContactUsLink'

const EmailPreferences = ({ candidate }) => {
  const [email, setEmail] = useState(candidate.email)
  const [errors, setErrors] = useState({})

  return (
    <>
      <Box>
        <h1>Bye {candidate.email}!</h1>
      </Box>
      <Box className="subtext-container">
        {`Please confirm your email address below and we'll remove you from our
        email list.`}
      </Box>

      <Box className="control-container">
        <EmailInput
          email={email}
          setEmail={setEmail}
          errors={errors}
          setErrors={setErrors}
        />
      </Box>
      <Box className="button-container">
        <Button className="submit-button btn" size="lg" fullWidth>
          Unsubscribe
        </Button>
      </Box>
      <ContactUsLink />
    </>
  )
}

export default EmailPreferences
