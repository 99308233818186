import React, { useState } from 'react'
import { PreviouslyWorkedWithCoach, IsCoachHelpful } from '../QuestionBank'
import {
  SelectField,
  TextareaField,
  TextField,
  HoverRatingField,
} from '../FormFields'
import { cn } from '../../../../../lib/utils'
import { DatePickerField } from '../../../../../components/shared/DatePickerField'

const ParentalLeave = ({ form }) => {
  const prioritiesChoices = [
    { value: '1', label: 'Not Important' },
    { value: '2', label: 'Rarely Important' },
    { value: '3', label: 'Somewhat Important' },
    { value: '4', label: 'Very Imporant' },
    { value: '5', label: 'Extremely Important' },
  ]

  const ratingClasses = 'col-span-6 2xl:col-span-2'

  return (
    <>
      <PreviouslyWorkedWithCoach form={form} />
      <IsCoachHelpful form={form} />
      <ParentalLeaveSection section="About Your Leave">
        <SelectField
          name="primary_role"
          label="What is your primary role regarding the upcoming leave?"
          control={form.control}
          className={'col-span-6 md:col-span-3'}
          choices={[
            { value: 'Birth Parent', label: 'Birth Parent' },
            { value: 'Partner/Co-Parent', label: 'Partner/Co-Parent' },
            { value: 'Adoptive Parent', label: 'Adoptive Parent' },
            { value: 'Other', label: 'Other (please specify)' },
          ]}
        />

        <TextField
          name="primary_role_other"
          label="Other"
          control={form.control}
          className={cn('col-span-6 md:col-span-1')}
        />
        <DatePickerField
          name={'approximate_start_date'}
          label="Approximate start date of your leave"
          control={form.control}
          className="col-span-6 md:col-span-3"
          buttonWidth="w-full"
          notRounded={true}
        />
        <SelectField
          name="length_of_leave"
          label="Approximate length of leave"
          control={form.control}
          className={'col-span-6 md:col-span-3'}
          choices={[
            { value: '1-3 months', label: '1-3 months' },
            { value: '4-6 months', label: '4-6 months' },
            { value: '7-12 months', label: '7-12 months' },
            { value: '12+ months', label: '12+ months' },
          ]}
        />
      </ParentalLeaveSection>
      <ParentalLeaveSection
        section="Your Priorities"
        description={
          'Please rate the importance of the following during your leave and upon return.'
        }
      >
        <HoverRatingField
          name="maintaining_visibility"
          label="Maintaining visibility within my workplace"
          control={form.control}
          choices={prioritiesChoices}
          className={ratingClasses}
        />
        <div className="col-span-1"></div>
        <HoverRatingField
          name="reentry_plan"
          label="Developing a smooth plan for re-entry"
          control={form.control}
          choices={prioritiesChoices}
          className={ratingClasses}
        />
        <HoverRatingField
          name="flexible_work"
          label="Exploring flexible work options (if applicable)"
          control={form.control}
          choices={prioritiesChoices}
          className={ratingClasses}
        />
        <div className="col-span-1"></div>
        <HoverRatingField
          name="work_life_balance"
          label="Finding work-life balance strategies"
          control={form.control}
          choices={prioritiesChoices}
          className={ratingClasses}
        />
      </ParentalLeaveSection>
      <ParentalLeaveSection section="Your Focus">
        <SelectField
          name="focus_area"
          label="Which one of these areas would you most like to focus on in your early coaching sessions?"
          control={form.control}
          className={'col-span-6'}
          choices={[
            {
              value:
                'Preparing for your exit: Delegation, handoff plans, and setting expectations.',
              label:
                'Preparing for your exit: Delegation, handoff plans, and setting expectations.',
            },
            {
              value:
                'Maintaining momentum: Staying connected and exploring professional development during leave.',
              label:
                'Maintaining momentum: Staying connected and exploring professional development during leave.',
            },
            {
              value:
                'Planning your return: Re-entry strategies, workload management, and communication.',
              label:
                'Planning your return: Re-entry strategies, workload management, and communication.',
            },
            {
              value:
                'The big picture: Reflecting on career goals in light of parenthood.',
              label:
                'The big picture: Reflecting on career goals in light of parenthood.',
            },
          ]}
        />
      </ParentalLeaveSection>
      <ParentalLeaveSection section="Open Feedback">
        <TextareaField
          name="specific_concerns"
          label="Is there a specific question or concern you'd like addressed in the program to make your experience more valuable?"
          control={form.control}
          className={'col-span-6'}
        />
      </ParentalLeaveSection>
    </>
  )
}
export default ParentalLeave

const ParentalLeaveSection = ({ section, description, children }) => {
  return (
    <>
      <div className="col-span-6">
        <div className="font-bold">{section}</div>
        {description && (
          <div className="text-sm text-muted-foreground">{description}</div>
        )}
      </div>
      {children}
    </>
  )
}
