import * as React from 'react'

import { api } from '../../../util/api'
import { useSortBy } from './useSortBy'
import { format, parse } from 'date-fns'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

const SavedJobsContext = React.createContext()

export function useSavedJobs() {
  return React.useContext(SavedJobsContext)
}

export function SavedJobsProvider({ saved_jobs, applied_jobs, children }) {
  const rerender = React.useReducer(() => ({}), {})[1]
  const queryClient = useQueryClient()
  const [jobArchiveNotification, setJobArchiveNotification] =
    React.useState(false)

  const { data: jobs } = useQuery({
    queryKey: ['get-saved-jobs'],
    queryFn: getSavedJobs,
    initialData: saved_jobs,
    select: (jobs) => jobs.map(transformJob),
  })

  const postSavedJobMutation = useMutation({
    mutationFn: postSavedJob,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['get-saved-jobs'] })
    },
    mutationKey: ['add-saved-job'],
  })

  const sortBy = useSortBy()

  const appliedWithIntoo = (savedJob) => {
    const job_posting_id = savedJob.job_posting_id
    const filteredAppliedJobs = applied_jobs.filter((id) => id !== null)
    return filteredAppliedJobs.includes(job_posting_id)
  }

  const value = {
    rerender,
    jobs,
    transformJob,
    patchSavedJob,
    postSavedJobMutation,
    sortBy,
    jobArchiveNotification,
    setJobArchiveNotification,
    appliedWithIntoo,
  }

  return (
    <SavedJobsContext.Provider value={value}>
      {children}
    </SavedJobsContext.Provider>
  )
}

const getSavedJobs = async () => {
  const response = await api.get('/job-search/saved_jobs')

  return response.data
}

const postSavedJob = async (data) => {
  const response = await api.post('/job-search/saved_jobs', {
    saved_job: data,
  })
  return response.data
}

const patchSavedJob = async (savedJob, updates) => {
  const { id } = savedJob
  const response = await api.patch(`/job-search/saved_jobs/${id}`, {
    saved_job: updates,
  })

  return response.data
}

const transformJob = (job) => {
  // @tanstack/react-table requires a Date or undefined (but not null)
  const dateOrUndefined = (dateTime) =>
    dateTime ? new Date(dateTime) : undefined

  const parseDateWithoutTime = (date) => {
    if (typeof date === 'string') {
      return parse(date, 'yyyy-MM-dd', new Date())
    } else if (date instanceof Date) {
      return date
    } else {
      return undefined
    }
  }

  const location = job.location
    ? job.location
    : [job.city, job.state].filter(Boolean).join(', ')

  return {
    ...job,
    location,
    job_posting_url: job.job_posting_url || job.url,
    created_at: dateOrUndefined(job.created_at),
    updated_at: dateOrUndefined(job.updated_at),
    followup_on: parseDateWithoutTime(job.followup_on),
    posted_on_month_day: job.published_at
      ? format(new Date(job.published_at), 'MMM d')
      : undefined,
  }
}
