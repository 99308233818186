import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTrigger,
  DialogClose,
} from '../../lib/ui/dialog'
import { useDashboard } from './DashboardProvider'
import Accolade from '../coach_services/MeetYourCoach/Accolade'
import HeadShot from '../coach_services/MeetYourCoach/HeadShot'
import { Button } from '../../lib/ui/button'
import { useTranslation } from '../../hooks/useTranslation'

const CoachInfoDialog = ({ children, coach }) => {
  const { showCoachInfoDialog, toggleShowCoachInfoDialog, accolades } =
    useDashboard()

  const { yearsExperience, numClientsSupported } = accolades

  const bio = coach.bio?.bio ? coach.bio.bio : coach.bio

  const { t } = useTranslation()

  return (
    <Dialog open={showCoachInfoDialog} onOpenChange={toggleShowCoachInfoDialog}>
      <DialogTrigger asChild className="cursor-pointer">
        {children}
      </DialogTrigger>
      <DialogContent
        className="h-[95%] max-w-sm overflow-y-scroll text-left md:mb-8 md:mt-4 md:h-auto md:max-w-5xl"
        variant="noHeader"
      >
        <div className="flex flex-col p-6 font-roboto text-cod-gray-800">
          <div className="flex flex-col gap-[16px]">
            <div className="flex flex-col md:mb-6 md:grid md:grid-cols-12 md:flex-row">
              <div className="flex flex-row items-center md:col-span-4 md:flex-col md:pr-6">
                <HeadShot
                  className="h-[150px] w-[150px]"
                  coachImage={coach.image}
                />
                <h2 className="block shrink-[5] bg-gradient-to-r from-intoo-green-coachcta to-intoo-green-bright bg-clip-text font-ibm-plex text-3xl font-bold uppercase text-transparent md:text-center">
                  {coach.name}
                </h2>
              </div>
              <div className="flex flex-col text-left md:col-span-8 md:flex-row">
                <Accolade
                  rating={`${yearsExperience}+`}
                  title={t('accolades.yearsExperience.title')}
                  description={t('accolades.yearsExperience.description', {
                    first_name: coach.name.split(' ')[0],
                    years: yearsExperience,
                  })}
                />
                <Accolade
                  rating={`${numClientsSupported}+`}
                  title={t('accolades.clientsSupported.title')}
                  description={t('accolades.clientsSupported.description', {
                    first_name: coach.name.split(' ')[0],
                  })}
                />
              </div>
            </div>

            <div className="flex flex-col gap-[16px] border-t border-cod-gray-200 pt-6 text-left">
              <h2 className="text-2xl font-bold text-intoo-green-coachcta">
                About {coach.name.split(' ')[0]}:
              </h2>
              <p>{bio}</p>
            </div>
          </div>
        </div>
        <DialogFooter>
          <DialogTrigger>
            <Button>Close</Button>
          </DialogTrigger>
        </DialogFooter>
        <DialogClose />
      </DialogContent>
    </Dialog>
  )
}
export default CoachInfoDialog
