import React from 'react'
import { Box, Link } from '@mui/joy'
import useTranslation from '../../shared/TranslationProvider'

const LegalAgreement = ({ actionText = null, htmlText = null }) => {
  const { t, locale } = useTranslation()

  const termsLink = `/${locale}/terms`
  const cookieLink = `/${locale}/cookie_policy`
  const privacyLink = `/${locale}/privacy`

  if (htmlText) {
    const legalNoticeWithAction = htmlText.replace(
      '~~BUTTON_TEXT~~',
      actionText,
    )
    return (
      <Box
        className="subtext-bottom-container link-container"
        sx={{
          '--textColor': '#000',
        }}
        dangerouslySetInnerHTML={{ __html: legalNoticeWithAction }}
      />
    )
  }

  return (
    <Box
      className="subtext-bottom-container link-container"
      sx={{
        '--textColor': '#000',
      }}
    >
      {t('by_clicking')}
      {' "'}
      {actionText}
      {'" '}
      {t('you_agree')}{' '}
      <Link href={termsLink} target="_blank">
        INTOO {t('terms_of_services')}
      </Link>{' '}
      {t('and_acknowledge')}{' '}
      <Link href={cookieLink} target="_blank">
        {t('cookie_policy')}
      </Link>{' '}
      {t('and')}{' '}
      <Link href={privacyLink} target="_blank">
        {t('privacy_policy')}
      </Link>
    </Box>
  )
}

export default LegalAgreement
