import * as React from 'react'

import { DataTable } from './DataTable'
import { SortByDropdown } from './SortByDropdown'
import { ArrowDownWideNarrow, Plus } from 'lucide-react'
import { useTranslation } from '../../../hooks/useTranslation'
import { Button } from '../../../lib/ui/button'
import { OutsideJobFormModal } from './OutsideJobFormModal'
import { useIsDesktop } from '../../../hooks/useMediaQuery'

export default function Page() {
  const { t } = useTranslation('Page')

  return (
    <div className="flex min-h-[67vh] flex-col gap-4 px-2 ">
      <h1 className="py-2 font-roboto-slab text-3.5xl font-bold leading-[2.64rem] text-[#1A2B4C]">
        {t('my_jobs')}
      </h1>
      <p>{t('explanation')}</p>

      <div className="flex flex-row justify-between gap-4">
        <SortByDropdownAndLabel />
        <OutsideJobFormModalButton />
      </div>

      <DataTable />
    </div>
  )
}

function SortByDropdownAndLabel() {
  const { t } = useTranslation('Page')
  const isDesktop = useIsDesktop()
  const size = isDesktop ? 20 : 14

  return (
    <div className="flex flex-row items-center gap-2 whitespace-nowrap">
      <ArrowDownWideNarrow size={size} />
      <div className="text-sm font-medium sm:text-base">{t('sort_by')}:</div>
      <SortByDropdown />
    </div>
  )
}

function OutsideJobFormModalButton() {
  const { t } = useTranslation('Page')
  const isDesktop = useIsDesktop()
  const size = isDesktop ? 20 : 14

  return (
    <OutsideJobFormModal>
      <Button variant="link" className="gap-2 text-sm sm:text-base">
        <Plus size={size} />
        {t('add_a_new_saved_job')}
      </Button>
    </OutsideJobFormModal>
  )
}
