import * as React from 'react'
import { DoorIcon } from '../Icons'
import { cn } from '../../../lib/utils'
import { Button } from '../../../lib/ui/button'

export function AylaCard({ className }) {
  return (
    <div
      className={cn(
        'relative rounded-[20px] border border-[#B1B1B1] p-6 shadow-md',
        className,
      )}
    >
      <div className="flex h-full flex-col gap-[12px] text-cod-gray-800">
        <div className="flex">
          <div className="flex-grow">
            <h2 className="mb-[16px] text-2xl font-bold text-primary">
              Meet Ayla, Your AI-Based Assistant
            </h2>
            <div className="mb-[16px]">
              Ayla Cover Letter enables you to create a customized cover letter
              as and Ayla Interview helps you create customized practice video
              interviews.
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="absolute bottom-[16px] right-[24px]">
            <Button variant="link" asChild className="h-0 px-0 py-0">
              <a href="story/cover_letters/ai" className="ga-trackable">
                Go to Ayla
                <DoorIcon className="ml-2 inline-flex" />
              </a>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
