import React from 'react'
import {
  Tabs as PossibleTabs,
  TabsContent as PossibleTabsContent,
  TabsList as PossibleTabsList,
  TabsTrigger as PossibleTabsTrigger,
} from '../../coach_services/CoachFeedback/CoachTabs'
import { useIsDesktop } from '../../../hooks/useMediaQuery'

function ConversationStarters() {
  const isDesktop = useIsDesktop()
  const PassThrough = ({ children }) => <>{children}</>
  const Tabs = !isDesktop ? PassThrough : PossibleTabs
  const TabsContent = !isDesktop ? PassThrough : PossibleTabsContent
  const TabsList = !isDesktop ? PassThrough : PossibleTabsList
  const TabsTrigger = !isDesktop ? PassThrough : PossibleTabsTrigger
  const marginClass = isDesktop ? 'mb-[24px]' : 'mb-[12px]'

  const CoachingImage = () => (
    <div>
      <img
        width="300"
        height="283"
        alt="man talking at laptop"
        src={imageElement.src}
        className={`rounded-xl ${isDesktop ? '' : 'mb-6'}`}
      ></img>
    </div>
  )

  const image =
    'https://www-yns-com.s3.us-east-2.amazonaws.com/images/career_development/deloitte_conversation_starters.jpg'
  const imageElement = new Image()
  imageElement.src = image

  const CtaComponent = () => (
    <div>
      <p className="mb-[16px] font-extrabold">
        Feeling ready? Get started on your chat now!
      </p>
      <a
        className="btn btn-cta btn-start-coach-chat"
        data-toggle="modal"
        data-target="#coach_chat_list"
        data="Start a coach chat"
      >
        Start a Chat
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ display: 'inline', verticalAlign: 'sub', marginLeft: '5px' }}
        >
          {' '}
          <path
            d="M15.982 16.725C15.2833 15.7999 14.3793 15.0496 13.3412 14.5334C12.3031 14.0171 11.1594 13.749 10 13.75C8.84066 13.749 7.6969 14.0171 6.65883 14.5334C5.62077 15.0496 4.71675 15.7999 4.01801 16.725M15.982 16.725C17.3455 15.5122 18.3071 13.9136 18.7412 12.1411C19.1753 10.3686 19.0603 8.5061 18.4115 6.80049C17.7627 5.09488 16.6107 3.62679 15.1084 2.59091C13.6061 1.55503 11.8244 1.00031 9.99951 1.00031C8.17467 1.00031 6.39295 1.55503 4.89062 2.59091C3.38829 3.62679 2.23634 5.09488 1.58755 6.80049C0.938753 8.5061 0.823765 10.3686 1.25783 12.1411C1.6919 13.9136 2.65451 15.5122 4.01801 16.725M15.982 16.725C14.336 18.1932 12.2056 19.0032 10 19C7.79404 19.0034 5.66425 18.1934 4.01801 16.725M13 7.75001C13 8.54566 12.6839 9.30872 12.1213 9.87133C11.5587 10.4339 10.7957 10.75 10 10.75C9.20436 10.75 8.4413 10.4339 7.87869 9.87133C7.31608 9.30872 7.00001 8.54566 7.00001 7.75001C7.00001 6.95436 7.31608 6.1913 7.87869 5.62869C8.4413 5.06608 9.20436 4.75001 10 4.75001C10.7957 4.75001 11.5587 5.06608 12.1213 5.62869C12.6839 6.1913 13 6.95436 13 7.75001Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </a>
    </div>
  )

  return (
    <div>
      {!isDesktop && <CoachingImage />}
      <Tabs className="TabsRoot" defaultValue="tab1">
        {isDesktop && (
          <TabsList className="TabsList" aria-label="Manage your account">
            <TabsTrigger className="TabsTrigger" value="tab1">
              Assessments/Career Planning
            </TabsTrigger>
            <TabsTrigger className="TabsTrigger" value="tab2">
              Opportunities
            </TabsTrigger>
            <TabsTrigger className="TabsTrigger" value="tab3">
              Marketing Toolkit
            </TabsTrigger>
            <TabsTrigger className="TabsTrigger" value="tab4">
              Networking
            </TabsTrigger>
            <TabsTrigger className="TabsTrigger" value="tab5">
              Interview
            </TabsTrigger>
          </TabsList>
        )}
        <TabsContent className="TabsContent py-4 px-0" value="tab1">
          {!isDesktop && (
            <div className="mb-[12px] font-extrabold">
              Assessments/Career Planning
            </div>
          )}
          <div
            className={`items-top flex justify-between gap-8 ${marginClass}`}
          >
            <div>
              <ul style={{ paddingInlineStart: 15 }}>
                <li className={marginClass}>
                  I’m contemplating a career pivot. What factors might I want to
                  consider?
                </li>
                <li className={marginClass}>
                  I have completed the assessments but I’m unsure how to use
                  this information for career planning. What do you suggest?
                </li>
                <li className={marginClass}>
                  I’d like to talk about my career aspirations with my Deloitte
                  coach/mentor/leader. Can you help me gain clarity and prepare
                  my thoughts in advance of that conversation?
                </li>
              </ul>
              {isDesktop && <CtaComponent />}
            </div>
            {isDesktop && <CoachingImage />}
          </div>
        </TabsContent>
        <TabsContent className="TabsContent py-4 px-0" value="tab2">
          {!isDesktop && (
            <div className="mb-[12px] font-extrabold">Opportunities</div>
          )}
          <div
            className={`items-top ${marginClass} flex justify-between gap-8`}
          >
            <div>
              <ul style={{ paddingInlineStart: 15 }}>
                <li className={marginClass}>
                  I’m considering a career change but unsure of how to search
                  for positions in a new industry/functional area. What
                  strategies to you recommend?
                </li>
                <li className={marginClass}>
                  How does my current resume align with X position of interest?
                </li>
              </ul>
              {isDesktop && <CtaComponent />}
            </div>
            {isDesktop && <CoachingImage />}
          </div>
        </TabsContent>
        <TabsContent className="TabsContent py-4 px-0" value="tab3">
          {!isDesktop && (
            <div className="mb-[12px] font-extrabold">Marketing Toolkit</div>
          )}
          <div
            className={`items-top ${marginClass} flex justify-between gap-8`}
          >
            <div>
              <ul style={{ paddingInlineStart: 15 }}>
                <li className={marginClass}>
                  I’m struggling with my &lt;Summary of Qualifications or this
                  resume bullet/accomplishment statement&gt;. Can you suggest
                  different wording?
                </li>
                <li className={marginClass}>
                  How should I modify my resume for different positions?
                </li>
                <li className={marginClass}>
                  What might I choose to highlight in value proposition/pitch?
                </li>
                <li className={marginClass}>
                  Can I practice delivering my pitch with you?
                </li>
              </ul>
              {isDesktop && <CtaComponent />}
            </div>
            {isDesktop && <CoachingImage />}
          </div>
        </TabsContent>
        <TabsContent className="TabsContent py-4 px-0" value="tab4">
          {!isDesktop && (
            <div className="mb-[12px] font-extrabold">Networking</div>
          )}
          <div
            className={`items-top ${marginClass} flex justify-between gap-8`}
          >
            <div>
              <ul style={{ paddingInlineStart: 15 }}>
                <li className={marginClass}>
                  Can you review my networking outreach email and provide
                  feedback?
                </li>
                <li className={marginClass}>
                  How might I reach out to contacts without looking “needy” or
                  “desperate?”
                </li>
                <li className={marginClass}>
                  Can you review my social media profile? How might I improve
                  it?
                </li>
              </ul>
              {isDesktop && <CtaComponent />}
            </div>
            {isDesktop && <CoachingImage />}
          </div>
        </TabsContent>
        <TabsContent className="TabsContent py-4 px-0" value="tab5">
          {!isDesktop && (
            <div className="mb-[12px] font-extrabold">Interview</div>
          )}
          <div
            className={`items-top ${marginClass} flex justify-between gap-8`}
          >
            <div>
              <ul style={{ paddingInlineStart: 15 }}>
                <li className={marginClass}>
                  I was just invited to interview for a position – how might I
                  prepare?
                </li>
                <li className={marginClass}>
                  I recorded a practice interview within the platform. Can you
                  provide feedback? (CN advisors can review the recorded
                  interviews and provide feedback.)
                </li>
                <li className={marginClass}>
                  I had an interview and struggled with this question. Can you
                  practice the best responses with me? (CN advisors can conduct
                  brief “mock” interviews limited ~15 minutes.)
                </li>
                <li className={marginClass}>
                  I have two offers and I’m not sure which one to accept. Can
                  you help me think it through?
                </li>
              </ul>
              <CtaComponent />
            </div>
            {isDesktop && <CoachingImage />}
          </div>
        </TabsContent>
      </Tabs>
    </div>
  )
}

export default ConversationStarters
