import React from 'react'
import { useProgramEvaluation } from '../ProgramEvaluationProvider'
import { ImprovementAreas } from '../UiLib'
import {
  Company,
  Employee,
  ProgramRating,
  ProgramRatingDetails,
  RecommendProgram,
  SuggestedImprovements,
  AdditionalComments,
  ManagerName,
} from '../QuestionBank'

const ManagerPostProgramEvaluation = ({ form }) => {
  const {
    postProgramManagerImprovementAreas,
    postProgramManagerImprovementObjectives,
  } = useProgramEvaluation()

  return (
    <>
      <ManagerName className={'required-text col-span-3'} form={form} />
      <Company className={'required-text col-span-3'} form={form} />
      <Employee
        label="Name of your employee who participated in INTOO’s coaching program"
        className={'required-text col-span-6'}
        form={form}
      />
      <div className="col-span-6">
        In what areas and to what extent do you feel your team has benefited as
        a result of INTOO’s coaching program? What value / return on investment
        do you feel you have received in each of these areas?
      </div>
      <ImprovementAreas
        form={form}
        improvementAreas={postProgramManagerImprovementObjectives}
        minDescription="No Impact"
        maxDescription="Very Helpful"
        keyName="manager-benefit"
        objectives={true}
      />
      <div className="col-span-6">
        Please indicate the areas in which your employee has demonstrated
        personal development and growth. Please rate the level of growth you
        have observed:
      </div>
      <ImprovementAreas
        form={form}
        improvementAreas={postProgramManagerImprovementAreas}
        minDescription="No Improvement"
        maxDescription="Huge Improvement"
        keyName="manager-development"
      />
      <ProgramRating form={form} />
      <ProgramRatingDetails form={form} />
      <RecommendProgram form={form} />
      <SuggestedImprovements form={form} />
      <AdditionalComments form={form} />
    </>
  )
}
export default ManagerPostProgramEvaluation
