import React from 'react'

const Check = () => {
  return (
    <svg
      className="inline-block"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.01314 10.5059L1.32065 6.91846C1.17557 6.72515 0.978797 6.61655 0.77362 6.61655C0.568443 6.61655 0.37167 6.72515 0.226588 6.91846C0.0815062 7.11176 0 7.37394 0 7.64731C0 7.78267 0.0200104 7.91671 0.0588885 8.04176C0.0977666 8.16682 0.154751 8.28045 0.226588 8.37617L3.46999 12.6976C3.7726 13.1008 4.26144 13.1008 4.56405 12.6976L12.7734 1.75961C12.9185 1.56631 13 1.30413 13 1.03076C13 0.757383 12.9185 0.495206 12.7734 0.301902C12.6283 0.108597 12.4316 0 12.2264 0C12.0212 0 11.8244 0.108597 11.6793 0.301902L4.01314 10.5059Z"
        fill="#148335"
      />
    </svg>
  )
}

const Cancel = () => {
  return (
    <svg
      className="inline-block"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1L1 13M1 1L13 13"
        stroke="#DA0000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export { Cancel, Check }
