import React, { useState, useMemo } from 'react'
import ProgressContainer from '../../ActionPlan/ProgressContainer'
import { DoorIcon } from '../Icons'
import { useTranslation } from '../../../hooks/useTranslation'
import { cn } from '../../../lib/utils'
import { getApi } from '../../../util/api'
import { Button } from '../../../lib/ui/button'
import parse from 'html-react-parser'

const useCoachChatSubstitution = (text, timePeriod) => {
  const { t } = useTranslation()
  const coachChatSplit = text.split('@COACH_CHAT_LINK@')

  if (coachChatSplit.length > 1) {
    const linkText = t(`action_plan_card.${timePeriod}.coach_chat_text`)
    return (
      coachChatSplit[0] +
      `<a class="open-chat-modal" data-toggle="modal" data-target="#coach_chat_list" data-action="Start a Coach Chat">${linkText}</a>` +
      coachChatSplit[1]
    )
  } else {
    return text
  }
}

const NextActionText = (timePeriod, nextActionText, url) => {
  const { t } = useTranslation()
  const nextActionLink = `<a href="${url}">${nextActionText}</a>`
  const translationKey = `action_plan_card.${timePeriod}.next_step`
  let translationString = t(translationKey, { next_action: nextActionLink })

  translationString = useCoachChatSubstitution(translationString, timePeriod)

  const feedbackSplit = translationString.split('@FEEDBACK_LINK@')
  if (timePeriod === '') {
    return ''
  }

  return feedbackSplit.length > 1 ? (
    <>
      {parse(feedbackSplit[0])}
      {FeedbackLink()}
      {parse(feedbackSplit[1])}
    </>
  ) : (
    parse(translationString)
  )
}

const FeedbackLink = () => {
  const popupFeedback = () => {
    window.candidateFeedback()
  }

  return (
    <a onClick={popupFeedback} className="cursor-pointer">
      Feedback
    </a>
  )
}

const TimePeriodText = (timePeriod) => {
  const { t } = useTranslation()

  let translationString = t(`action_plan_card.${timePeriod}.current_status`)
  translationString = useCoachChatSubstitution(translationString, timePeriod)

  const feedbackSplit = translationString.split('@FEEDBACK_LINK@')
  return feedbackSplit.length > 1 ? (
    <>
      {parse(feedbackSplit[0])}
      {FeedbackLink()}
      {parse(feedbackSplit[1])}
    </>
  ) : (
    parse(translationString)
  )
}

export function ActionPlanCard({ tracker_progress, className }) {
  const [timePeriod, setTimePeriod] = useState('')
  const [trackerStepName, setTrackerStepName] = useState('')
  const [stepUrl, setStepUrl] = useState('')

  useMemo(() => {
    getApi()
      .get('/tracker/current_step')
      .then((response) => {
        if (response.status == 200) {
          const {
            data: { time_period, name, url },
          } = response
          setTimePeriod(time_period)
          setTrackerStepName(name)
          setStepUrl(url)
          return
        } else {
          throw new Error('json request failed')
        }
      })
      .catch((error) => {
        console.log('json request failed', error)
      })
  }, [setTimePeriod, setTrackerStepName])

  return (
    <div
      className={cn(
        'relative rounded-[20px] border border-[#B1B1B1] p-6  shadow-md',
        className,
      )}
    >
      <div className="flex h-full flex-col text-cod-gray-800">
        <div className="mb-[24px] flex">
          <div className="flex-grow">
            <h2 className="mb-[16px] text-2xl font-bold text-intoo-blue-medium">
              Action Plan
            </h2>
            <div>{TimePeriodText(timePeriod)}</div>
          </div>
          <ProgressContainer
            progressPercent={tracker_progress}
            homeProgress={true}
          />
        </div>
        <div className="flex flex-col">
          <p className="mb-[24px]">
            {NextActionText(timePeriod, trackerStepName, stepUrl)}
          </p>
          <p></p>
          <div className="absolute bottom-[7%] right-[7%]">
            <Button variant="link" asChild className="h-0 px-0 py-0">
              <a href="orientation/action_plan" className="ga-trackable">
                View Your Weekly Actions
                <DoorIcon className="ml-2 inline-flex" />
              </a>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
