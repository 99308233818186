import React, { useState } from 'react'
import { useResources } from '../ResourcesProvider'
import { cn } from '../../../lib/utils'
import { ChevronDownIcon } from 'lucide-react'
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '../../../lib/ui/pagination'

const SkillsList = ({ className }) => {
  const {
    skillsArr,
    selectedSkill,
    setSelectedSkill,
    isMobile,
    setDisableSaveRankButton,
  } = useResources()
  const [currentPage, setCurrentPage] = useState(1)

  const numPerPage = 12

  const numPages = isMobile
    ? Math.ceil(skillsArr.length - 1 / numPerPage)
    : Math.ceil(skillsArr.length / numPerPage)

  const handlePrevClick = () => {
    setCurrentPage((prev) => prev - 1)
    setDisableSaveRankButton(false)
  }

  const handlePageClick = (page) => {
    setCurrentPage(page)
    setDisableSaveRankButton(false)
  }

  const handleNextClick = () => {
    setCurrentPage((prev) => prev + 1)
    setDisableSaveRankButton(false)
  }

  return (
    <div
      className={cn(
        'col-span-10 mt-8 flex flex-col justify-between divide-y divide-[#B9B9B9] md:col-span-2 md:mt-0',
        className,
      )}
    >
      <Page
        skills={skillsArr.slice(
          (currentPage - 1) * numPerPage,
          currentPage * numPerPage,
        )}
        selectedSkill={selectedSkill}
        setSelectedSkill={setSelectedSkill}
        currentPage={currentPage}
      />
      {!isMobile && numPages > 1 && (
        <Pagination className={'pt-4'}>
          <PaginationContent className="w-full content-center gap-4">
            <PaginationItem>
              <PaginationPrevious
                onClick={handlePrevClick}
                className={cn(
                  'h-[30px] w-[30px] rounded-full border border-black p-2 text-black',
                  currentPage === 1 && 'invisible',
                )}
              />
            </PaginationItem>

            {Array.from({ length: numPages }).map((_, idx) => {
              const page = idx + 1
              if (numPages > 3 && page > 3 && page < currentPage - 1) {
                return null
              }
              return (
                <PaginationItem key={`page-${page}`}>
                  <PaginationLink
                    onClick={() => handlePageClick(page)}
                    isActive={currentPage === page}
                    className={cn(
                      'rounded-[5px] border p-2',
                      currentPage === page
                        ? 'cursor-default border-transparent bg-intoo-blue-medium text-white hover:border-transparent hover:bg-intoo-blue-medium hover:text-white'
                        : ' cursor-pointer border-[#ACACAC]',
                    )}
                  >
                    {page}
                  </PaginationLink>
                </PaginationItem>
              )
            })}
            {numPages > 3 && numPages > currentPage && (
              <PaginationItem>
                <PaginationEllipsis />
              </PaginationItem>
            )}

            <PaginationItem>
              <PaginationNext
                onClick={handleNextClick}
                className={cn(
                  'h-[30px] w-[30px] rounded-full border border-black p-2 text-black',
                  numPages === currentPage && 'invisible',
                )}
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      )}
    </div>
  )
}
export default SkillsList

const Page = ({ skills }) => {
  const {
    selectedSkill,
    isMobile,
    setSelectedSkill,
    setDisableSaveRankButton,
  } = useResources()

  const handleSkillClick = (skill) => {
    setSelectedSkill(skill)
    setDisableSaveRankButton(false)
  }
  return (
    <div className="flex flex-col gap-4">
      {skills.map((skill, idx) => {
        const selectedClass =
          selectedSkill === skill ? 'text-[#fff] selected' : ''
        const showSkill = !isMobile || skill !== selectedSkill
        return (
          showSkill && (
            <div
              className={cn(
                `cursor-pointer rounded-[10px] px-5 py-[10px] text-[#333] checkbox-${skill[0]} hover:text-white`,
                selectedClass,
              )}
              key={`skills-list-${idx}`}
              onClick={() => handleSkillClick(skill)}
              onFocus={() => handleSkillClick(skill)}
              tabIndex={1}
            >
              <div className="grid grid-cols-5">
                <div className="col-span-4">{skill[1]}</div>
                {isMobile && <ChevronDownIcon className="h-4 w-4" />}
              </div>
            </div>
          )
        )
      })}
    </div>
  )
}
