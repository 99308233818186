import * as React from 'react'
import { useBenefits } from '../../BenefitsProvider'
import { CoachCard } from '../CoachCard'
import { LightBulbIcon } from '@heroicons/react/24/outline'
import CalendlyCallPopup, {
  CalendlyCallScheduled,
} from '../../CalendlyCallPopup'

export function CareerConsultationCallCard({ status }) {
  // TODO: Implement in_progress and completed (See ResumeReviewCard.js)

  const { careerConsultationEvent } = useBenefits()

  return (
    <CoachCard
      icon={LightBulbIcon}
      title="Career Consultation Call"
      description="Schedule an in-depth phone-based career consultation to help you build your career and job target strategy."
    >
      {!careerConsultationEvent && (
        <CalendlyCallPopup eventType="career consultation" />
      )}
      {careerConsultationEvent && (
        <CalendlyCallScheduled
          eventType="career consultation"
          calendlyEvent={careerConsultationEvent}
        />
      )}
    </CoachCard>
  )
}
