import React from 'react'
import { Paragraph, Link } from './styles'
import { Separator } from '../../lib/ui/separator'
import Moment from 'moment'
import { useMiniplatform } from './MiniplatformProvider'

const MeetingConfirmed = () => {
  const { individualEvent, coachName, coachPhoto } = useMiniplatform()
  const event = individualEvent
  const eventDetails = Moment(event.start_time).format(
    'h:mma - dddd, MMMM Do, YYYY',
  )
  return (
    <div className="flex justify-center rounded-[10px] bg-gradient-calendly md:m-auto md:h-[25.9rem] md:w-[63.4rem]">
      <div>
        <div className="mt-[.2rem] rounded-[10px] bg-white md:h-[25.5rem] md:w-[63rem]">
          <div className="flex flex-col gap-2 p-8">
            <div className="flex items-center justify-between">
              <div className="flex flex-col gap-2">
                <Paragraph className={'font-bold'}>Meeting Confirmed</Paragraph>
                <Paragraph>{`You've successfully scheduled a meeting with ${coachName}`}</Paragraph>
              </div>
              <img className="h-20 rounded-full" src={coachPhoto} alt="" />
            </div>
            <hr />
            <div>
              <Paragraph className={'font-bold'}>Event Date/Time:</Paragraph>
              <Paragraph>{eventDetails}</Paragraph>
            </div>
            <div>
              <Paragraph className={'font-bold'}>Event Type:</Paragraph>
              <Paragraph>90 min meeting</Paragraph>
            </div>
            <div className="flex flex-col justify-center gap-2 md:h-28 md:flex-row md:gap-28">
              <div className="flex flex-col items-center gap-4">
                <Link className={'btn btn-primary'} href={event.join_url}>
                  Click to Join Meeting
                </Link>
                <Paragraph>Or click link</Paragraph>
                <Link href={event.join_url}>{event.join_url}</Link>
              </div>
              <Separator orientation="vertical" />
              <div className="flex flex-col items-center gap-8">
                <Link
                  className={
                    'rounded-[10px] border border-solid border-cod-gray-800 p-2 text-cod-gray-900 hover:bg-cod-gray-800 hover:text-white'
                  }
                  href={`https://calendly.com/reschedulings/${event.calendly_user_id}`}
                >
                  Reschedule Event
                </Link>
                <Link
                  className={
                    'rounded-[10px] border border-solid border-cod-gray-800 p-2 text-cod-gray-900 hover:bg-cod-gray-800 hover:text-white'
                  }
                  href={`https://calendly.com/cancellations/${event.calendly_user_id}`}
                >
                  Cancel Event
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default MeetingConfirmed
