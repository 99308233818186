import React, { useState } from 'react'
import { Chart } from 'react-google-charts'
import { useResources } from '../ResourcesProvider'
import { cn } from '../../../lib/utils'

const GraphView = () => {
  const {
    skillsArr,
    setSelectedSkill,
    openCardViewTab,
    selectedSkill,
    isMobile,
    addSkillstoSkillsDialog,
  } = useResources()

  const [popUpText, setPopUpText] = useState()

  const [chartLoaded, setChartLoaded] = useState(false)

  const categoryColors = {
    relationship: '#527bad',
    communication: '#396479',
    leadership: '#95499c',
    analytical: '#9c4959',
    creative: '#499c65',
    physical: '#54a5ab',
    technical: '#a47841',
  }

  const chartColumns = [
    { type: 'number', id: 'Enjoyment of Skill' },
    { type: 'number', id: 'Skill Level' },
    { type: 'string', role: 'style' },
    { type: 'string', role: 'tooltip' },
    { type: 'string', role: 'annotation' },
  ]

  const skillsArrDuplicate = (skill) => {
    const dupSkills = []
    skillsArr.forEach((s) => {
      if (
        s[1] !== skill[1] &&
        parseInt(s[2]) === parseInt(skill[2]) &&
        parseInt(s[3]) === parseInt(skill[3])
      ) {
        dupSkills.push(s[1])
      }
    })
    if (dupSkills.length > 0) {
      dupSkills.push(skill[1])
    }

    return dupSkills
  }

  const chartRows = skillsArr.map((skill) => {
    const [category, skillName, ability, enjoyment] = skill
    const duplicateSkills = skillsArrDuplicate(skill)
    const duplicateSkillExists = duplicateSkills.length > 0
    const pointSize =
      skill === selectedSkill ? '25' : duplicateSkillExists ? '15' : '10'
    const fillColor = duplicateSkillExists
      ? '#000000'
      : categoryColors[category]

    const annotation = duplicateSkillExists
      ? duplicateSkills.join(', ')
      : skillName

    const color = duplicateSkillExists ? '#000000' : '#CCCCCC'

    return [
      ability - 50,
      enjoyment - 50,
      `point { size: ${pointSize}; fill-color: ${fillColor}; color: ${color}`,
      annotation,
      null,
    ]
  })

  const annotations = [
    [
      -35,
      49,
      'point { size: 0; fill-color: #000000; color: #000000',
      '',
      'Reskill Motivated',
    ],
  ]

  const chartData = [chartColumns, ...chartRows, ...annotations]

  const options = {
    hAxis: {
      title: 'Enjoyment of Skill',
      minValue: -50,
      maxValue: 50,
      textPosition: 'none',
      titleTextStyle: { fontSize: 16, bold: true, italic: true },
    },
    vAxis: {
      title: 'Skill Level',
      minValue: -50,
      maxValue: 50,
      textPosition: 'none',
      titleTextStyle: { fontSize: 16, bold: true, italic: true },
    },
    legend: 'none',
    scale: 10,
    sizeAxis: { minValue: 0, maxSize: 10 },
    colors: Object.values(categoryColors),
    pointSize: 20,
    chartArea: { width: '90%', height: '90%' },
    annotations: {
      stem: { length: 0 },
      textStyle: {
        color: '#000',
        bold: false,
        italic: false,
        enableInteractivity: false,
      },
    },
  }

  const handleSelect = (row) => {
    const skills = chartRows[row][3].split(', ')
    const skillsFromSkillsArr = skillsArr.filter((s) => skills.includes(s[1]))
    if (skillsFromSkillsArr.length === 1) {
      setSelectedSkill(skillsFromSkillsArr[0])
      openCardViewTab()
    } else addSkillstoSkillsDialog(skillsFromSkillsArr)
  }

  const chartEvents = [
    {
      eventName: 'select',
      callback({ chartWrapper }) {
        handleSelect(chartWrapper.getChart().getSelection()[0].row)
      },
    },
    {
      eventName: 'ready',
      callback() {
        setChartLoaded(true)
      },
    },
  ]

  return (
    <div className="relative col-span-10 h-[585px] py-4 md:col-span-7 md:py-0">
      {chartLoaded && (
        <>
          <AxisLabel
            variant={'vertical-up'}
            className="left-[37%] top-[19%] md:top-[15%]"
          >
            Expert Ability
            <span className="glyphicon glyphicon-arrow-right"></span>
          </AxisLabel>
          <AxisLabel
            variant={'vertical-down'}
            className={'bottom-[13%] left-[41%] md:bottom-[29%] md:left-[38%]'}
          >
            No Ability
            <span className="glyphicon glyphicon-arrow-right"></span>
          </AxisLabel>
          <AxisLabel className={' left-[5%] top-[53%] md:top-[42%]'}>
            <span className="glyphicon glyphicon-arrow-left"></span>
            {`Don't Care For It`}
          </AxisLabel>
          <AxisLabel
            className={'right-[6%] top-[53%] md:right-1/4 md:top-[42%]'}
          >
            Really Enjoy
            <span className="glyphicon glyphicon-arrow-right"></span>
          </AxisLabel>
          <SkillLabelText
            className="left-[6%] top-[1%] md:-top-2 md:left-[15%]"
            onMouseEnter={() => setPopUpText('reskill')}
            onMouseLeave={() => setPopUpText()}
          >
            Reskill Motivated
          </SkillLabelText>
          <SkillLabelText
            className="right-[4%] top-[1%] md:-top-2 md:right-[33%]"
            onMouseEnter={() => setPopUpText('promoter')}
            onMouseLeave={() => setPopUpText()}
          >
            Skill Promoter
          </SkillLabelText>
          <SkillLabelText
            className="-bottom-[3%] left-[5%] md:bottom-[6rem] md:left-[15%]"
            onMouseEnter={() => setPopUpText('detractor')}
            onMouseLeave={() => setPopUpText()}
          >
            Skill Detractor
          </SkillLabelText>
          <SkillLabelText
            className="-bottom-[3%] right-[3%] md:bottom-[6rem] md:right-[33%]"
            onMouseEnter={() => setPopUpText('upskill')}
            onMouseLeave={() => setPopUpText()}
          >
            Upskill Motivated
          </SkillLabelText>
        </>
      )}
      {popUpText === 'reskill' && <ReskillMotivatedText />}
      {popUpText === 'promoter' && <SkillPromoterText />}
      {popUpText === 'detractor' && <SkillDetractorText />}
      {popUpText === 'upskill' && <UpskillMotivatedText />}
      <Chart
        chartType="ScatterChart"
        data={chartData}
        options={options}
        height={isMobile ? '100%' : '90%'}
        width={isMobile ? '100%' : '90%'}
        chartEvents={chartEvents}
      />
    </div>
  )
}
export default GraphView

const ReskillMotivatedText = () => {
  return (
    <SkillText className="left-[5%] top-[7%] h-[50%] md:left-[4%] md:top-[4%] md:h-[40%]">
      <p>
        {`If you do not enjoy something, don't like it, aren't motivated by it, but you happen to be skilled at it, then you will avoid learning and under-perform.`}{' '}
      </p>
      <p>
        {`Perhaps it's time to seek out training and refresh these skills so that you can start to enjoy them more.`}
      </p>
    </SkillText>
  )
}

const SkillPromoterText = () => {
  return (
    <SkillText className="right-[5%] top-[7%] h-[50%] md:right-[23%] md:top-[4%] md:h-[40%]">
      <p>
        {`If you enjoy something, like it, are motivated by it, and you are fairly skilled at it, then you are prime to lead with that skill.`}{' '}
      </p>
      <p>
        {' '}
        {`Consider using these skills in your pitch statement, resume summary, and cover letters because these are your strongest skills.`}
      </p>
    </SkillText>
  )
}

const SkillDetractorText = () => {
  return (
    <SkillText className="bottom-[7%] left-[5%] h-[70%] md:bottom-32 md:left-[4%] md:h-[40%]">
      <p>
        {`If you do not enjoy something, don't like it, aren't motivated by it, and you're not highly skilled at it, then you will reject learning opportunities for this skill.`}
      </p>
      <p>
        {`If this skill isn't a requirement for your next job, great - move on. However, if it is, try to improve these skills so that you can help your next organization meet it's goals.`}
      </p>
    </SkillText>
  )
}

const UpskillMotivatedText = () => {
  return (
    <SkillText className="bottom-[7%] right-[5%] h-[58%] md:bottom-32 md:right-[23%] md:h-[40%]">
      <p>
        {`If you enjoy something, like it, are motivated by it, but you aren't
        highly skilled at it, then you are motivated to advance that skill.`}
      </p>
      <p>
        {`The skills in this quadrant are exciting and ripe for learning
        advancement. Focus on moving these skills into the quadrant above so
        that you can advance!`}
      </p>
    </SkillText>
  )
}

const SkillText = ({ className, children }) => {
  return (
    <div
      className={cn(
        'absolute z-50 flex h-[40%] w-[45%] flex-col items-center justify-center bg-[#666] px-[4%] py-[2%] text-sm text-[#fff] md:w-[37%]',
        className,
      )}
    >
      {children}
    </div>
  )
}

const SkillLabelText = ({ className, children, ...props }) => {
  return (
    <div
      {...props}
      className={cn(
        'absolute z-50 flex cursor-pointer items-center gap-2',
        className,
      )}
    >
      <div className="underline">{children}</div>
      <span className="glyphicon glyphicon-plus"></span>
    </div>
  )
}

const AxisLabel = ({ variant = 'horizontal', className, children }) => {
  let transform
  if (variant === 'vertical-up') {
    transform = '-rotate-90'
  } else if (variant === 'vertical-down') {
    transform = 'rotate-90'
  } else {
    transform = ''
  }
  return (
    <div
      className={cn(
        'absolute z-50 flex items-center gap-2',
        transform,
        className,
      )}
    >
      {children}
    </div>
  )
}
