import React from 'react'
import {
  Goals,
  DefinitionOfSuccess,
  CurrentChallenges,
  OtherTopics,
} from '../QuestionBank'

const AnnualBenefit = ({ form }) => {
  const formClasses = 'col-span-6'
  const formProps = { form, className: formClasses }
  return (
    <>
      <Goals {...formProps} />
      <DefinitionOfSuccess {...formProps} />
      <CurrentChallenges {...formProps} />
      <OtherTopics {...formProps} />
    </>
  )
}
export default AnnualBenefit
