import * as React from 'react'
import { api } from '../../../../util/api'
import { set } from 'date-fns'

export const useAiResponseStream = () => {
  const [aiResponse, setAiResponse] = React.useState('')
  const [coverLetterId, setCoverLetterId] = React.useState(null)
  const [aiStreamComplete, setAiStreamComplete] = React.useState(false)
  const [serverSentEvents, setServerSentEvents] = React.useState(false)

  React.useEffect(() => {
    if (!serverSentEvents) return
    if (!coverLetterId) return

    const eventURL = `/story/cover_letters/create_ai_sse/${coverLetterId}`
    const eventSource = new EventSource(eventURL)

    eventSource.addEventListener('message', (event) => {
      setAiResponse((r) => `${r}${event.data}`)
    })

    eventSource.addEventListener('error', (event) => {
      if (event.eventPhase === EventSource.CLOSED) {
        eventSource.close()
        setAiStreamComplete(true)
      }
    })

    return () => eventSource.close()
  }, [coverLetterId])

  React.useEffect(() => {
    if (serverSentEvents) return
    if (!coverLetterId) return

    const pollingURL = `/story/cover_letters/create_ai_polling/${coverLetterId}`

    const interval = setInterval(async () => {
      const {
        data: { content, completed },
      } = await api.get(pollingURL)
      setAiResponse(`${content}`)
      setAiStreamComplete(completed)
      if (completed) {
        document.getElementById('copy-button').style.display = 'flex'
        document.getElementById('download-button-container').style.display =
          'block'
        document.getElementById('ayla-cover-letter-text').style.display =
          'block'
        document.getElementById('cover-letter-buttons').style.display = 'flex'
        document.getElementById('continue-button').style.display = 'block'
        clearInterval(interval)
        setAiResponse('')
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [coverLetterId, aiResponse, aiStreamComplete])

  return {
    aiResponse,
    coverLetterId,
    setCoverLetterId,
    aiStreamComplete,
    setServerSentEvents,
  }
}
