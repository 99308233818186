import axios from 'axios'
import { csrfToken } from './csrf'

export const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-CSRF-Token': csrfToken(),
  },
})

export const apiPost = async (url, data) => {
  const response = await getApi().post(url, data)
  if (response?.data?.csrf_token) {
    updateCsrfToken(response.data.csrf_token)
  }

  return response
}

export const apiDelete = async (url) => {
  const response = await getApi().delete(url)
  if (response?.data?.csrf_token) {
    updateCsrfToken(response.data.csrf_token)
  }

  return response
}

export const apiPatch = async (url, data) => {
  const response = await getApi().patch(url, data)
  if (response?.data?.csrf_token) {
    updateCsrfToken(response.data.csrf_token)
  }

  return response
}

export const apiPut = async (url, data) => {
  const response = await getApi().put(url, data)
  if (response?.data?.csrf_token) {
    updateCsrfToken(response.data.csrf_token)
  }

  return response
}

export const getApi = () =>
  axios.create({
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRF-Token': csrfToken(),
    },
  })

export const updateCsrfToken = (token) => {
  if (token) {
    document
      .querySelector('meta[name=csrf-token]')
      .setAttribute('content', token)
  }
}

export const getUserConsent = async () => {
  const response = await api.get('/user_consents/new')

  return response.data
}

export const setUserConsent = async ({ consentGiven }) => {
  const CONSENT_TYPES = {
    ExternalLinks: 1,
  }
  const action = consentGiven ? '1' : '0'
  const consent_type_id = CONSENT_TYPES.ExternalLinks

  const response = await api.post('/user_consents', {
    user_consent: { action, consent_type_id },
  })

  return response.data
}
