import * as React from 'react'
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../lib/ui/form'
import { Input } from '../../../../lib/ui/input'
import { Textarea } from '../../../../lib/ui/textarea'
import { RadioGroup, RadioGroupItem } from '../../../../lib/ui/radio-group'
import { Checkbox } from '../../../../lib/ui/checkbox'
import { cn } from '../../../../lib/utils'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../../lib/ui/select'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '../../../../lib/ui/hover-card'
import { Slider } from '../../../../lib/ui/slider'

export function TextField({
  description,
  placeholder,
  name,
  label,
  control,
  className,
  ...props
}) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Input placeholder={placeholder} {...field} {...props} />
          </FormControl>
          <FormDescription>{description}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export function TextareaField({
  description,
  placeholder,
  name,
  label,
  control,
  className,
  innerClassName,
  ...props
}) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Textarea
              placeholder={placeholder}
              className={cn('resize-none', innerClassName)}
              {...field}
              {...props}
            />
          </FormControl>
          <FormDescription>{description}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export function CheckboxField({
  name,
  label,
  control,
  description,
  className,
}) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem
          className={cn(
            'flex flex-row items-center space-x-3 space-y-0 pl-8',
            className,
          )}
        >
          <FormControl>
            <Checkbox
              checked={field.value}
              onCheckedChange={field.onChange}
              className="data-[state=checked]:bg-intoo-blue-medium"
            />
          </FormControl>
          <div className="space-y-1 leading-none">
            <FormLabel>{label}</FormLabel>
            {description && <FormDescription>{description}</FormDescription>}
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export function Checkboxes({
  name,
  label,
  control,
  description,
  choices,
  className,
}) {
  return (
    <FormField
      control={control}
      name={name}
      render={() => (
        <FormItem className={className}>
          <div className="mb-4">
            <FormLabel className="text-base">{label}</FormLabel>
            {description && <FormDescription>{description}</FormDescription>}
          </div>
          {choices.map((choice, idx) => (
            <FormField
              key={`${name}-${choice}-checkbox-${idx}`}
              control={control}
              name={name}
              render={({ field }) => {
                return (
                  <FormItem
                    key={choice.id}
                    className="flex items-start space-x-3 space-y-0"
                  >
                    <FormLabel className="flex cursor-pointer items-center rounded-md border px-3 py-2 text-muted-foreground hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground has-[:checked]:border-primary has-[:checked]:bg-accent has-[:checked]:text-accent-foreground has-[:focus-visible]:outline-none has-[:focus-visible]:ring-2 has-[:focus-visible]:ring-ring has-[:focus-visible]:ring-offset-2 md:justify-center">
                      <FormControl>
                        <Checkbox
                          className="sr-only"
                          checked={field.value?.includes(choice.id)}
                          onCheckedChange={(checked) => {
                            return checked
                              ? field.onChange([...field.value, choice.id])
                              : field.onChange(
                                  field.value?.filter(
                                    (value) => value !== choice.id,
                                  ),
                                )
                          }}
                        />
                      </FormControl>
                      <p className="text-center text-sm font-medium">
                        {choice.label}
                      </p>
                    </FormLabel>
                  </FormItem>
                )
              }}
            />
          ))}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export function Radios({ name, label, choices, control, className }) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={cn('space-y-3', className)}>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <RadioGroup
              onValueChange={field.onChange}
              defaultValue={field.value}
              className="flex space-x-1 space-y-1"
            >
              {choices.map((choice, idx) => (
                <FormItem
                  key={`${name}-${choice.value}-${idx}`}
                  className="flex items-center space-x-3 space-y-0"
                >
                  <FormControl>
                    <RadioGroupItem value={choice.value} />
                  </FormControl>
                  <FormLabel>{choice.label}</FormLabel>
                </FormItem>
              ))}
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export function IntegerField({
  description,
  placeholder,
  name,
  label,
  control,
  className,
  ...props
}) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Input
              type="number"
              placeholder={placeholder}
              {...field}
              {...props}
            />
          </FormControl>
          <FormDescription>{description}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export function SelectField({
  name,
  label,
  control,
  className,
  choices,
  description,
  descriptionClassName,
  placeholder,
}) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={cn(className, description ? 'relative' : '')}>
          <FormLabel>{label}</FormLabel>
          {description && (
            <FormDescription
              className={cn('absolute top-2 text-xs', descriptionClassName)}
            >
              {description}
            </FormDescription>
          )}
          <Select onValueChange={field.onChange} defaultValue={field.value}>
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder={placeholder || 'Select one'} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {choices.map((choice, idx) => (
                <SelectItem
                  key={`${name}-${choice.value}-${idx}`}
                  value={choice.value}
                >
                  {choice.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export function HoverRatingField({ name, label, choices, control, className }) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={cn('', className)}>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <RadioGroup
              onValueChange={field.onChange}
              defaultValue={field.value}
              className="flex flex-col rounded-md ring-offset-background transition-colors md:flex-row"
            >
              {choices.map((choice) => (
                <FormItem
                  className="items-left flex-grow space-x-3 space-y-0"
                  key={choice.value}
                >
                  {choice.description ? (
                    <HoverCard>
                      <HoverCardTrigger>
                        <FormLabel className="flex cursor-pointer items-center rounded-md border px-3 py-2 text-muted-foreground hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground has-[:checked]:border-primary has-[:checked]:bg-accent has-[:checked]:text-accent-foreground has-[:focus-visible]:outline-none has-[:focus-visible]:ring-2 has-[:focus-visible]:ring-ring has-[:focus-visible]:ring-offset-2 md:justify-center">
                          <FormControl>
                            <RadioGroupItem
                              className="md:sr-only"
                              value={choice.value}
                            />
                          </FormControl>

                          <p className="text-center text-sm font-medium max-md:ml-3">
                            {choice.label}
                          </p>
                        </FormLabel>
                      </HoverCardTrigger>
                      <HoverCardContent className="text-sm">
                        {choice.description}
                      </HoverCardContent>
                    </HoverCard>
                  ) : (
                    <FormLabel className="flex cursor-pointer items-center rounded-md border px-3 py-2 text-muted-foreground hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground has-[:checked]:border-primary has-[:checked]:bg-accent has-[:checked]:text-accent-foreground has-[:focus-visible]:outline-none has-[:focus-visible]:ring-2 has-[:focus-visible]:ring-ring has-[:focus-visible]:ring-offset-2 md:justify-center">
                      <FormControl>
                        <RadioGroupItem
                          className="md:sr-only"
                          value={choice.value}
                        />
                      </FormControl>

                      <p className="text-center text-sm font-medium max-md:ml-3">
                        {choice.label}
                      </p>
                    </FormLabel>
                  )}
                </FormItem>
              ))}
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export function SliderField({
  name,
  label,
  control,
  className,
  step,
  minDescription,
  maxDescription,
  defaultValue,
  disabled,
  ...props
}) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Slider
              {...props}
              disabled={disabled}
              step={step || 1}
              defaultValue={[field.value]}
              value={disabled ? [1] : [field.value]}
              onValueChange={(vals) => {
                field.onChange(vals[0])
              }}
            />
          </FormControl>
          <div className="flex justify-between">
            <FormDescription>{minDescription}</FormDescription>
            <FormDescription>{maxDescription}</FormDescription>
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
