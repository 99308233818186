import React from 'react'
import { cn } from '../../lib/utils'
import { useNav } from './NavProvider'
import { Bar, SplitBar } from './icons'

const SubNav = ({ className, parentActive, theme, items }) => {
  const subNavClasses = cn('flex flex-col overflow-y-hidden', className)
  const { activeSubNav } = useNav()
  if (!items) {
    return null
  }
  var activeIndex = 0
  const lastIndex = items.length - 1
  items.forEach((child, childIndex) => {
    if (child.title === activeSubNav) {
      activeIndex = childIndex
    }
  })
  return (
    <div className={subNavClasses}>
      {items.map((child, childIndex) => (
        <SubNavLink
          key={childIndex}
          item={child}
          theme={theme}
          parentActive={parentActive}
          withDecoration
          isLast={childIndex === lastIndex}
          activeBefore={activeIndex <= childIndex}
        />
      ))}
    </div>
  )
}
export default SubNav

export const SubNavLink = ({
  withDecoration,
  theme,
  activeBefore,
  parentActive,
  isLast,
  item,
}) => {
  const {
    activeSubNav,
    setActiveSubNav,
    navigateTo,
    meetsMinimumProgramLevel,
  } = useNav()
  const { title, programLevel } = item
  const inactiveBaseClasses =
    'px-3 py-2 tracking-tight text-sm w-full focus:text-foreground focus:outline-none hover:bg-cod-gray-100 hover:rounded text-cod-gray-800 focus:bg-cod-gray-200 focus:text-cod-gray-800'
  let inactiveThemeClasses = 'hover:text-intoo-blue-medium'
  switch (theme) {
    case 'green':
      inactiveThemeClasses = 'hover:text-foreground'
      break
    case 'deloitte':
      inactiveThemeClasses =
        'hover:text-white hover:bg-deloitte focus:bg-deloitte focus:text-white'
  }
  const inactiveClasses = cn(inactiveBaseClasses, inactiveThemeClasses)
  let activeThemeClasses = 'bg-[#BFDCF5]'
  switch (theme) {
    case 'green':
      activeThemeClasses =
        'bg-[#c8e1b5] text-cod-gray-800 hover:text-foreground'
      break
    case 'deloitte':
      activeThemeClasses =
        'bg-black text-white hover:text-white hover:bg-deloitte focus:bg-deloitte'
  }
  const activeClasses = cn(
    inactiveClasses,
    activeThemeClasses,
    'rounded font-medium',
  )
  const active = title === activeSubNav
  const activate = () => {
    setActiveSubNav(title)
    navigateTo(item)
  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      activate()
    }
  }
  const defaultDecorationClasses =
    'relative -top-4 left-4 h-9 w-5 rounded-bl-lg border-b-2 border-l-2 border-solid border-cod-gray-200'
  let themeBorder = 'border-[#BFDCF5]'
  let themeBorderActive = ''
  switch (theme) {
    case 'green':
      themeBorder = 'border-intoo-green-coachcta'
      break
    case 'deloitte':
      themeBorder = 'border-black'
      themeBorderActive = 'border-deloitte'
  }
  const decorationClasses = active
    ? cn(defaultDecorationClasses, themeBorder)
    : cn(defaultDecorationClasses, themeBorderActive)
  const barClasses = cn(
    'relative -left-[2px]',
    isLast && 'hidden',
    active && 'top-[7px]',
  )
  const BarIcon = active ? SplitBar : Bar
  if (!meetsMinimumProgramLevel(programLevel)) {
    return null
  }
  return (
    <div className="relative flex cursor-pointer flex-row items-center">
      {withDecoration && (
        <div className="w-10">
          <div className={decorationClasses}>
            {!isLast && (
              <BarIcon
                theme={theme}
                isColored={!activeBefore}
                className={barClasses}
              />
            )}
          </div>
        </div>
      )}
      <a
        onClick={activate}
        onKeyDown={handleKeyDown}
        role="link"
        tabIndex={parentActive ? '0' : '-1'}
        className={active ? activeClasses : inactiveClasses}
      >
        {title}
      </a>
    </div>
  )
}
