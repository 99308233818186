import React from 'react'
import { useData } from '../DataProvider'
import { Box } from '@mui/joy'
import Step3Default from './Step3Default'
import Step3Retirement from './Step3Retirement'
import Step3WorkingForMyself from './Step3WorkingForMyself'
import { NavButton, ButtonContainer } from '../Buttons'
import useTranslation from '../../shared/TranslationProvider'

const Step3 = () => {
  const {
    setCurrentStep,
    interestIsRetirement,
    interestIsWorkingForSelf,
    interestIsJobSeeking,
  } = useData()
  const { t } = useTranslation()

  return (
    <>
      <Box className="pt-10">
        <h1 className="text-2xl font-bold md:text-4xl">{t('step3.title')}</h1>
        <p className="py-4 text-base md:text-xl">{t('step3.subtitle')}</p>
        <div className="flex grid grid-cols-1 flex-col gap-4 pt-2 md:grid-cols-2">
          {interestIsRetirement && <Step3Retirement />}
          {interestIsWorkingForSelf && <Step3WorkingForMyself />}
          {interestIsJobSeeking && <Step3Default />}
        </div>
      </Box>
      <ButtonContainer>
        <NavButton type="back" onClick={() => setCurrentStep(2)}>
          {t('back')}
        </NavButton>
        <NavButton type="submit" onClick={() => setCurrentStep(4)}>
          {t('next')}
        </NavButton>
      </ButtonContainer>
    </>
  )
}
export default Step3
