import React from 'react'
import { Paragraph } from './styles'
import CoachBio from './coachBio'
import { cn } from '../../lib/utils'
import { useMiniplatform } from './MiniplatformProvider'

const MeetYourCoach = () => {
  const {
    assessmentMissing,
    coachName,
    coachPhoto,
    maxWidth,
    showOneVOneCoach,
    groupSessionCoachInfo,
  } = useMiniplatform()
  const name = showOneVOneCoach ? coachName : groupSessionCoachInfo.coachName
  const photo = showOneVOneCoach ? coachPhoto : groupSessionCoachInfo.coachPhoto
  return (
    !assessmentMissing && (
      <div className={cn('md:p-12', maxWidth)}>
        <div
          className={cn(
            'flex flex-col gap-4 p-8 lg:grid lg:grid-cols-7 lg:gap-2 lg:p-0',
          )}
        >
          <div className="md:flex md:flex-col md:items-start">
            <div className="flex flex-col items-center gap-4">
              <img className="h-32 w-32 rounded-full" src={photo} alt="" />
              <Paragraph className="font-bold">Coach {name}</Paragraph>
            </div>
          </div>
          <CoachBio />
        </div>
      </div>
    )
  )
}

export default MeetYourCoach
