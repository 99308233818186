import {
  ArrowPathIcon,
  DocumentArrowDownIcon,
} from '@heroicons/react/24/outline'
import * as React from 'react'

export function Feedback({ title, feedback }) {
  const Content = feedback?.resume ? ResumeInProgress : NoFeedback

  return (
    <div className="mx-2 my-6">
      <div className="flex flex-col gap-4">
        <h1 className="text-2xl font-bold">{title}</h1>

        <Content feedback={feedback} />
      </div>
    </div>
  )
}

function NoFeedback() {
  return (
    <div className="text-center text-2xl">
      <p>
        There is currently no feedback available. Please head over to your
        Outplacement Benefits and get started.
      </p>
    </div>
  )
}

function ResumeInProgress({ feedback }) {
  const coach = feedback?.resume_review_request?.coach
  const email = coach ? coach.email : 'support@yournextstep.com'

  return (
    <div className="flex flex-col gap-6">
      <div className="">
        <p>
          Thank you for submitting your resume. Your coach will be in touch
          within 2-3 business days.
        </p>
        <p>
          If you have any questions, please contact{' '}
          <a href={`mailto:${email}`}>{email}</a>
        </p>
      </div>

      <div className="flex flex-col gap-4">
        <h2 className="text-2xl font-bold">The Resume Review Process</h2>

        <ul className="ml-6 flex list-disc flex-col gap-2">
          <li>
            Your coach will reach out to set up a call to go over your resume
            and goals for your next position.
          </li>
          <li>
            After that discussion you will receive your resume review from your
            coach with suggestions on things you can do to make your resume
            stronger and more attractive to potential employers.
          </li>
          <li>
            Next, make the modifications you wish and send your revised resume
            back to your coach for a final review.
          </li>
        </ul>
      </div>

      <div className="flex flex-col gap-4">
        <h2 className="text-2xl font-bold">Your Uploaded Resume</h2>

        <div className="flex list-disc flex-col gap-2 rounded-xl border border-cod-gray-200 p-6 text-cod-gray-500">
          <div>
            Original Resume:{' '}
            <a
              href={feedback.resume.document_url}
              target="_blank"
              rel="noreferrer"
              className="text-intoo-green-coachcta hover:text-intoo-green-coachcta-hover"
            >
              {feedback.resume.document_name}{' '}
              <DocumentArrowDownIcon className="inline size-4" />
            </a>{' '}
            -{' '}
            <a
              // href="/resume/review/refresh"
              className="modal-remote text-intoo-green-coachcta hover:cursor-pointer hover:text-intoo-green-coachcta-hover"
              data-url="/resume/review/refresh"
              data-key="submit-your-resume-key"
            >
              Upload a new resume <ArrowPathIcon className="inline size-4" />
            </a>
          </div>
          <div>Submitted: {feedback.resume.submitted_date}</div>
          <div>
            Remarks: <p className="pt-1 text-xs">{feedback.resume.remarks}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
