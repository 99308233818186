import React, { useState } from 'react'
import { Box, Button } from '@mui/joy'
import EmailInput from './EmailInput'
import ContactUsLink from './ContactUsLink'
import { SpinnerWhite } from '../../shared/Spinner'
import { useData } from './DataProvider'
import useTranslation from '../../shared/TranslationProvider'

const ForgotPassword = () => {
  const { handleFormResponse, getApi, isLoading, setIsLoading } = useData()
  const { t } = useTranslation()

  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState({})

  const handleResetPassword = async () => {
    setIsLoading(true)
    try {
      const response = await getApi().post('/users/password', {
        user: { email: email },
      })
      if (response.data.errors) {
        setErrors(response.data.errors)
      } else {
        handleFormResponse(response)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log('error', error)
    }
  }

  return (
    <>
      <Box>
        <h1>{t('ForgotPassword.title')}</h1>
      </Box>
      <Box className="subtext-container">{t('ForgotPassword.subtext')}</Box>
      <Box className="control-container">
        <EmailInput
          email={email}
          setEmail={setEmail}
          errors={errors}
          onKeyDown={(e) => e.key === 'Enter' && handleResetPassword()}
        />
      </Box>
      <Box className="button-container">
        <Button
          className="submit-button btn"
          size="lg"
          fullWidth
          onClick={handleResetPassword}
          disabled={isLoading}
        >
          {t('ForgotPassword.send_instructions')}
          <SpinnerWhite show={isLoading} className="pl-4" />
        </Button>
      </Box>
      <ContactUsLink />
    </>
  )
}
export default ForgotPassword
