import React from 'react'
import { useProgramEvaluation } from '../../ProgramEvaluations/ProgramEvaluationProvider'
import {
  FirstName,
  LastName,
  Title,
  YearsInRole,
  Manager,
  CoachingProgramName,
  Email,
  LinkedInUrl,
} from '../QuestionBank'
import { cn } from '../../../../../lib/utils'

const OutPlatform = ({ form }) => {
  const { managerInfoPresent, annualBenefit } = useProgramEvaluation()

  const classes = cn(
    `col-span-6 ${managerInfoPresent ? 'md:col-span-2' : 'md:col-span-3'}`,
  )

  return (
    <>
      <FirstName
        form={form}
        className={'required-text col-span-6 md:col-span-3'}
      />

      <LastName
        form={form}
        className={'required-text col-span-6 md:col-span-3'}
      />
      {annualBenefit && (
        <>
          <Email form={form} className={'col-span-6 md:col-span-3'} />
          <LinkedInUrl form={form} className={'col-span-6 md:col-span-3'} />
        </>
      )}
      {!annualBenefit && (
        <>
          <Title form={form} className={'required-text col-span-6'} />
          <YearsInRole form={form} className={classes} />
          {managerInfoPresent && (
            <Manager form={form} className={'col-span-6 md:col-span-2'} />
          )}
          <CoachingProgramName disabled form={form} className={classes} />
        </>
      )}
    </>
  )
}
export default OutPlatform
