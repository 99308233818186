import React, { useState } from 'react'
import { Button } from '../../lib/ui/button'
import SetGoalForm from './SetGoalForm'
import { apiPatch } from '../../util/api'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  DialogFooter,
} from '../../lib/ui/dialog'

const SetGoalDialog = ({
  showDialog,
  toggleDialog,
  goal,
  setGoal,
  getUserTrackerSections,
  children,
}) => {
  const [newGoal, setNewGoal] = useState(goal)

  const updateGoal = async () => {
    const res = await apiPatch('/trackers/set_goal', {
      candidate: { candidate_profile_attributes: { interest: newGoal } },
    })
    const data = res.data
    if (res.status === 200) {
      setGoal(data.trackerGoal)
      getUserTrackerSections()
      return res
    } else {
      throw new Error('res not ok')
    }
  }

  const handleSave = () => {
    toggleDialog()
    if (newGoal) {
      updateGoal()
    }
  }
  return (
    <Dialog open={showDialog} onOpenChange={toggleDialog}>
      <DialogTrigger asChild className="cursor-pointer">
        {children}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Set Your Goal</DialogTitle>
        </DialogHeader>
        <SetGoalForm newGoal={goal} setNewGoal={setNewGoal} />
        <DialogFooter>
          <Button onClick={handleSave}>Save</Button>
        </DialogFooter>
        <DialogClose />
      </DialogContent>
    </Dialog>
  )
}
export default SetGoalDialog
