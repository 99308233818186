import React, { useEffect, useState, useContext, createContext } from 'react'
import { ArrowLeft, ArrowRight, Circle } from './icons'
import { useData } from '../DataProvider'

const PanelNavContext = createContext({})

const useNavData = () => {
  return useContext(PanelNavContext)
}

const PanelNavProvider = ({ panelCount, children }) => {
  const [selectedPanel, setSelectedPanel] = useState(0)
  const { disableRotation, setDisableRotation } = useData()

  const nextPanel = () => {
    setDisableRotation(true)
    setSelectedPanel((index) => (index + 1) % panelCount)
  }
  const prevPanel = () => {
    setDisableRotation(true)
    setSelectedPanel((index) => (index - 1 + panelCount) % panelCount)
  }
  const clickPanel = (index) => {
    setDisableRotation(true)
    setSelectedPanel(index)
  }

  const data = {
    selectedPanel,
    clickPanel,
    panelCount,
    nextPanel,
    prevPanel,
  }

  useEffect(() => {
    if (disableRotation) return

    const interval = setInterval(() => {
      setSelectedPanel((index) => (index + 1) % panelCount)
    }, 20000)

    return () => clearInterval(interval)
  }, [selectedPanel, disableRotation, panelCount])

  return (
    <PanelNavContext.Provider value={data}>{children}</PanelNavContext.Provider>
  )
}

const PanelNav = () => {
  const { nextPanel, prevPanel, selectedPanel, clickPanel, panelCount } =
    useNavData()
  return (
    <div className="mt-16 pb-4">
      <ArrowLeft onClick={prevPanel} />
      <span className="mx-8 space-x-10">
        {[...Array(panelCount).keys()].map((key) => (
          <Circle
            onClick={() => clickPanel(key)}
            key={key}
            active={key === selectedPanel}
          />
        ))}
      </span>
      <ArrowRight onClick={nextPanel} />
    </div>
  )
}

const PanelEnabled = ({ thisPanelNumber, children }) => {
  const selected = useNavData().selectedPanel === thisPanelNumber
  return selected && children
}

export default PanelNav
export { useNavData, PanelNavProvider, PanelEnabled }
