import React from 'react'
import UploadAssessment from './uploadAssessment'
import AboutProgram from './aboutProgram'
import CoachResources from './coachResources'
import MeetYourCoach from './meetYourCoach'
import CoachCard from './coachCard'
import { MiniplatformProvider } from './MiniplatformProvider'

const Miniplatform = ({ coachingSessionsInfo, ...props }) => {
  return (
    <MiniplatformProvider {...props}>
      <UploadAssessment />
      <MeetYourCoach />
      <AboutProgram />
      <CoachResources />
      {!coachingSessionsInfo.length && <CoachCard />}
    </MiniplatformProvider>
  )
}
export default Miniplatform
