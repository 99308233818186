import React from 'react'
import { Box } from '@mui/joy'
import { NavButton, ButtonContainer } from './Buttons'
import { useData } from './DataProvider'
import useTranslation from '../shared/TranslationProvider'

const Step1 = () => {
  const { candidate, setCurrentStep } = useData()
  const { t } = useTranslation()

  return (
    <Box className="mx-auto flex max-w-3xl flex-col flex-wrap content-center pt-10 text-center">
      <h1 className="mx-auto text-2xl font-bold md:text-4xl">
        {t('welcome')}, {candidate.firstName}!
      </h1>
      <p className="pt-4 text-center text-base md:pt-10 md:text-xl">
        {t('welcome_message_1')}
      </p>
      <p className="pt-4 text-center text-base md:text-xl">
        {t('welcome_message_2')}
      </p>
      <p className="pt-4 text-center text-base md:text-xl">
        {t('welcome_message_3')}
      </p>
      <Box className="pt-10 text-center">
        <NavButton type={'continue'} onClick={() => setCurrentStep(2)}>
          {t('continue')}
        </NavButton>
      </Box>
    </Box>
  )
}

export default Step1
