import React from 'react'
import { LogoBackgroundRight } from './images'
import { PanelContent, BulletListItem } from './components'
import useTheme from '../../../shared/ThemeProvider'
import useTranslation from '../../../shared/TranslationProvider'

const UpdatedSitePanel = () => {
  const { t } = useTranslation()
  const { primaryColor, isCustomBranded, platformName } = useTheme()
  return (
    <div
      className="relative flex flex-grow flex-col items-center overflow-hidden p-2 sm:p-0"
      style={{ backgroundColor: primaryColor }}
    >
      {!isCustomBranded && <LogoBackgroundRight />}
      <PanelContent>
        <h1 className="z-10 mt-20 max-w-md text-[2rem] font-bold leading-9 text-white sm:mt-16">
          {t('UpdatedSitePanel.title')}
        </h1>
        <h2 className="text-medium z-10 mt-8 text-balance text-2xl text-white">
          {platformName} {t('UpdatedSitePanel.subtitle')}
        </h2>
        <h2 className="text-medium mt-10 text-2xl text-white">
          {t('UpdatedSitePanel.bullet_head1')}
        </h2>
        <ul className="mt-4 inline-flex flex-col gap-6 text-white">
          <BulletListItem>{t('UpdatedSitePanel.bullet1')}</BulletListItem>
        </ul>
        <h2 className="text-medium mt-8 text-2xl text-white">
          {t('UpdatedSitePanel.bullet_head2')}
        </h2>
        <ul className="mt-4 inline-flex flex-col gap-6 text-white">
          <BulletListItem>{t('UpdatedSitePanel.bullet2')}</BulletListItem>
        </ul>
        <h2 className="text-medium mt-8 text-2xl text-white">
          {t('UpdatedSitePanel.bullet_head3')}
        </h2>
        <ul className="my-4 inline-flex flex-col gap-6 text-white">
          <BulletListItem>{t('UpdatedSitePanel.bullet3')}</BulletListItem>
        </ul>
      </PanelContent>
    </div>
  )
}

export default UpdatedSitePanel
