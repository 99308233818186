import React from 'react'
import { Button } from '../../lib/ui/button'
import { cn } from '../../lib/utils'

const Option = ({ children, className, ...props }) => {
  return (
    <Button
      className={cn(
        'whitespace-pre-line rounded-[10px] border border-solid border-[#8E8E8E] bg-white text-[#2F2F2F] hover:bg-primary hover:text-white focus:bg-primary focus:text-white',
        className,
      )}
      {...props}
    >
      {children}
    </Button>
  )
}
export default Option
