import * as React from 'react'

import { Checkbox } from '../../../lib/ui/checkbox'
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../lib/ui/form'
import { useJobSearch } from './JobSearchProvider'

export default function FilterCheckboxGroup({
  name,
  label,
  description,
  items,
  showLabel = false,
}) {
  const {
    form: { control },
  } = useJobSearch()

  const className = showLabel ? 'text-sm sm:text-base !mb-0' : 'sr-only'

  return (
    <FormField
      control={control}
      name={name}
      render={() => (
        <FormItem className="mr-8 sm:mr-12">
          <div>
            <FormLabel className={className}>{label}</FormLabel>
            <FormDescription className="sr-only">{description}</FormDescription>
          </div>
          {items.map((item) => (
            <FormField
              key={item.id}
              control={control}
              name={name}
              render={({ field }) => {
                return (
                  <FormItem
                    key={item.id}
                    className="flex flex-row items-start space-x-3 space-y-0"
                  >
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(item.id)}
                        onCheckedChange={(checked) => {
                          return checked
                            ? field.onChange([...field.value, item.id])
                            : field.onChange(
                                field.value?.filter(
                                  (value) => value !== item.id,
                                ),
                              )
                        }}
                      />
                    </FormControl>
                    {/* TODO: Remove global label font-weight */}
                    <FormLabel className="!font-normal !leading-none *:text-sm sm:text-base">
                      {item.label}
                    </FormLabel>
                  </FormItem>
                )
              }}
            />
          ))}
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
