import * as React from 'react'

import { LinkedInButton, LearnMoreButton } from './buttons'
import { useTranslation } from '../../../hooks/useTranslation'
import LeaveSiteWarning from '../../shared/LeaveSiteWarning'
import { DebugInfo } from '../../shared/DebugInfo'
import { useJobSearch } from './JobSearchProvider'
import SavedJobButton from './SavedJobButton'

export default function JobCard({ job }) {
  const { t } = useTranslation('JobCard')
  const { selectedJob, handleSelectJob, isIntooItaly, isDemo } = useJobSearch()
  const selected = selectedJob && selectedJob?.id === job?.id

  const description = job.description
    .replace(/<[^>]*>?/gm, ' ')
    .split(' ')
    .filter((word) => word.length <= 40)
    .join(' ')
  return (
    <li
      className="relative flex cursor-pointer flex-row gap-4 rounded-2xl border-2 p-4 transition-colors hover:border-gray-400 hover:shadow-lg data-[selected=true]:border-intoo-blue-bright"
      data-selected={selected}
      onClick={handleSelectJob(job)}
    >
      <div className="relative flex min-w-full flex-col gap-4">
        <div className="flex flex-row justify-between">
          <div className="text-xs">
            {!!job.posted_on_month_day && (
              <>
                {t('posted')} {job.posted_on_month_day}
              </>
            )}
          </div>
          <div className="absolute right-0 top-0">
            <SavedJobButton job={job} variant="icon" />
          </div>
        </div>

        {job.job_bank_job && isIntooItaly && isDemo && (
          <div className="-mt-2 mb-2 flex">
            <div
              className="rounded-md p-1.5 px-2.5 text-xs font-medium text-white"
              style={{ background: 'var(--theme-primary)' }}
            >
              {t('intoo_job_bank')}
            </div>
          </div>
        )}

        <div className="flex flex-row justify-start gap-4">
          <CompanyImage job={job} />

          <div className="flex flex-col gap-1">
            <h4 className="pr-10 font-extrabold">{job.title}</h4>
            <div className="flex flex-row text-xs">
              <div className="text-right">
                <LeaveSiteWarning url={job.url}>
                  <a className="line-clamp-1">{job.company_name}</a>
                </LeaveSiteWarning>
              </div>
              <div className="px-2">•</div>
              <div className="whitespace-nowrap">{job.location}</div>
            </div>
          </div>
        </div>
        <p className="line-clamp-3 text-xs">{description}</p>
        <div className="my-2 flex flex-wrap justify-center gap-4">
          <LinkedInButton job={job} />
          <LearnMoreButton job={job} />
        </div>
      </div>
    </li>
  )
}

function CompanyImage({ job }) {
  const [imageLoaded, setImageLoaded] = React.useState(false)
  const { debug } = useJobSearch()

  const handleImageLoaded = () => {
    if (!job.logo_url) return

    setImageLoaded(true)
  }

  const showInitials = !imageLoaded || !job.logo_url

  return (
    <DebugInfo obj={job} debug={debug}>
      <div>
        {showInitials && (
          <div
            className="flex h-10 w-10 items-center justify-center rounded bg-gray-100 text-3xl font-bold text-white text-opacity-80 opacity-80"
            style={{ backgroundColor: job.company_color }}
          >
            {job.company_name[0]}
          </div>
        )}
        {
          <img
            src={job.logo_url}
            alt={job.company_name}
            className="h-10 w-10 rounded object-cover"
            style={{ display: showInitials ? 'none' : 'block' }}
            onLoad={() => handleImageLoaded(true)}
          />
        }
      </div>
    </DebugInfo>
  )
}
