import React, { createContext, useContext, useEffect, useState } from 'react'
import { apiPatch } from '../../util/api'
import { useIsMobile, useIsDesktop } from '../../hooks/useMediaQuery'
import { usePersistSkillsInventoryState } from '../../hooks/usePersistState'
import parse from 'html-react-parser'

const ResourcesContext = createContext()

export const useResources = () => {
  return useContext(ResourcesContext)
}

const ResourcesProvider = ({
  serialized_article_list,
  serialized_audio_list,
  serialized_quiz_list,
  serialized_workshop_list,
  serialized_exercise_list,
  serialized_video_list,
  serialized_assessment_list,
  favorite_article_ids,
  favorite_resources,
  initial_tab,
  module,
  skillsInventory,
  skills,
  skillsImagePath,
  keySkillsExercise,
  children,
}) => {
  const articles = serialized_article_list
  const audioFiles = serialized_audio_list
  const quizzes = serialized_quiz_list
  const workshops = serialized_workshop_list
  const exercises = serialized_exercise_list
  const videos = serialized_video_list
  const assessments = serialized_assessment_list
  const [favorites, setFavorites] = useState(favorite_article_ids || [])
  const userSkills = keySkillsExercise?.content?.key_skills
    ? JSON.parse(keySkillsExercise?.content?.key_skills)
    : {}
  const [skillsArr, setSkillsArr] = useState(skills || [])
  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()
  const [disableSaveRankButton, setDisableSaveRankButton] = useState(false)

  const [favoritedResources, setFavoritedResources] = useState(
    favorite_resources || {},
  )
  const initialTab = initial_tab

  useEffect(() => {
    const parseSkillName = () => {
      const newSkills = []
      skillsArr.forEach((skill) => {
        const skillName = parse(parse(skill[1]))
        newSkills.push([skill[0], skillName, skill[2], skill[3]])
      })
      setSkillsArr(newSkills)
    }
    if (skillsArr.length) parseSkillName()
  }, [])

  const skillsState = skillsArr.length ? 'skills_present' : 'empty'

  const [skillsInventoryView, setSkillsInventoryView] =
    usePersistSkillsInventoryState(initialTab, skillsState)

  const [activity, setActivity] = useState(
    module === 'skills_inventory' ? skillsInventoryView : initialTab,
  )

  const currentPath =
    window.location.pathname.slice(-1) === '/'
      ? window.location.pathname.slice(0, -1)
      : window.location.pathname

  const toggleFavorite = (article) => {
    const newIsFavorite = !checkFavorite(article)
    if (newIsFavorite) {
      setFavorites([...favorites, article.id])
    } else {
      setFavorites(favorites.filter((id) => id !== article.id))
    }
    patchFavorite(article, newIsFavorite)
  }

  const checkFavorite = (article) => {
    return favorites.includes(article.id)
  }

  const toggleResourceFavorite = (resource) => {
    const newIsFavorite = !checkResourceFavorite(resource)

    setFavoritedResources((prevFavoritedResources) => {
      const oldFavorites = {
        ...prevFavoritedResources,
      }

      const oldSlugs = oldFavorites[resource.type] || []

      const newSlugs = newIsFavorite
        ? [...oldSlugs, resource.slug]
        : oldSlugs.filter((slug) => slug !== resource.slug)
      const newFavorites = {
        ...oldFavorites,
        [resource.type]: newSlugs,
      }
      return newFavorites
    })

    patchFavoriteResource(resource, newIsFavorite)
  }

  const checkResourceFavorite = (resource) => {
    const { type, slug } = resource

    if (favoritedResources[type]) {
      return favoritedResources[type].includes(slug)
    }

    return false
  }

  const saveSkillRank = async (skill, ability, enjoyment) => {
    const skillCategory = skill[0]
    const skillName = skill[1]
    const documentId = keySkillsExercise?.document_id
    const documentPartId = keySkillsExercise?.id
    const url = `/documents/${documentId}/parts/${documentPartId}`
    const prevSkills = userSkills
    prevSkills[skillCategory][skillName]['ability'] = ability
    prevSkills[skillCategory][skillName]['like'] = enjoyment
    const newSkills = { ...prevSkills }
    const data = {
      document_part: {
        document_id: documentId,
        id: documentPartId,
        key_skills: JSON.stringify(newSkills),
      },
    }
    try {
      const response = await apiPatch(url, data)
      if (response.status === 200) {
        const updatedSkill = [skillCategory, skillName, ability, enjoyment]
        const idx = skillsArr.findIndex((s) => s[1] === skillName)
        const newSkills = [
          ...skillsArr.slice(0, idx),
          updatedSkill,
          ...skillsArr.slice(idx + 1),
        ]
        setSkillsArr(newSkills)
        setSelectedSkill(updatedSkill)
        return { success: true }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getArticlePath = (article) => {
    return `${currentPath}/article/${article.slug}`
  }

  const patchFavorite = async (article, isFavorite) => {
    const articlePath = getArticlePath(article)
    const data = { is_favorite: isFavorite }
    try {
      const response = await apiPatch(articlePath, {
        candidate_article: data,
      })
      if (response.status !== 200) {
        console.error(
          'Updating CandidateArticle failed with response',
          response,
        )
      }
    } catch (error) {
      console.error('Error updating CandidateArticle', error)
    }
  }

  const patchFavoriteResource = async (resource, isFavorite) => {
    const resourcePath = `${currentPath}/update_favorites`
    const data = {
      resource_slug: resource.slug,
      resource_type: resource.type,
      is_favorite: isFavorite,
    }
    try {
      const response = await apiPatch(resourcePath, data)
      if (response.status !== 200) {
        console.error(
          'Updating FavoritedResource failed with response',
          response,
        )
      }
    } catch (error) {
      console.error('Error updating FavoritedResource', error)
    }
  }

  const openArticlesTab = () => {
    setActivity('articles')
  }
  const articlesActive = activity === 'articles'

  const openAudioTab = () => {
    setActivity('audio')
  }
  const audioActive = activity === 'audio'

  const openFavoritesTab = () => {
    setActivity('favorites')
  }
  const favoritesActive = activity === 'favorites'

  const openExercisesTab = () => {
    setActivity('exercises')
  }
  const exercisesActive = activity === 'exercises'

  const openQuizzesTab = () => {
    setActivity('quizzes')
  }
  const quizzesActive = activity === 'quizzes'

  const openAssessmentsTab = () => {
    setActivity('assessments')
  }
  const assessmentsActive = activity === 'assessments'

  const openWebinarsTab = () => {
    setActivity('webinars')
  }
  const webinarsActive = activity === 'webinars'

  const openWorkshopsTab = () => {
    setActivity('workshops')
  }
  const workshopsActive = activity === 'workshops'

  const openGraphViewTab = () => {
    setSkillsInventoryView('graph_view')
    setActivity('graph_view')
    setDisableSaveRankButton(false)
  }

  const graphViewActive = activity === 'graph_view'

  const openCardViewTab = () => {
    setSkillsInventoryView('card_view')
    setActivity('card_view')
    setDisableSaveRankButton(false)
  }

  const cardViewActive = activity === 'card_view'

  const isRecentlyAdded = (article) => {
    const today = new Date()
    const articleDate = new Date(article.created_at)
    const timeDifference = Math.abs(today - articleDate)
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
    return dayDifference < 30
  }

  const hasFavorites = () => {
    const hasArticleFavorites = favorites.length > 0
    const hasResourceFavorites = Object.values(favoritedResources).some(
      (resourceList) => resourceList.length > 0,
    )
    return hasArticleFavorites || hasResourceFavorites
  }

  const hasResources = (resourceType) => {
    switch (resourceType) {
      case 'articles':
        return articles.length > 0
      case 'audio':
        return audioFiles.length > 0
      case 'quizzes':
        return quizzes.length > 0
      case 'workshops':
        return workshops.length > 0
      case 'exercises':
        return exercises.length > 0
      case 'videos':
        return videos.length > 0
      case 'favorites':
        return favorites.length > 0
      default:
        return false
    }
  }

  const [selectedSkill, setSelectedSkill] = useState(
    skillsArr.length ? skillsArr[0] : [],
  )

  const [skillsForSkillsDialog, setSkillsForSkillsDialog] = useState([])

  const [skillsDialogOpen, setSkillsDialogOpen] = useState(false)

  const addSkillstoSkillsDialog = (skills) => {
    setSkillsForSkillsDialog(skills)
    setSkillsDialogOpen(true)
  }

  const toggleSkillsDialog = () => setSkillsDialogOpen((prev) => !prev)

  const value = {
    articles,
    checkFavorite,
    toggleFavorite,
    openArticlesTab,
    openAudioTab,
    openFavoritesTab,
    openQuizzesTab,
    openAssessmentsTab,
    openWebinarsTab,
    openExercisesTab,
    articlesActive,
    audioActive,
    audioFiles,
    quizzes,
    favoritesActive,
    quizzesActive,
    assessmentsActive,
    webinarsActive,
    exercisesActive,
    getArticlePath,
    workshops,
    openWorkshopsTab,
    workshopsActive,
    exercises,
    videos,
    isRecentlyAdded,
    checkResourceFavorite,
    toggleResourceFavorite,
    hasFavorites,
    hasResources,
    module,
    skillsInventory,
    openGraphViewTab,
    graphViewActive,
    openCardViewTab,
    cardViewActive,
    skillsArr,
    skillsState,
    saveSkillRank,
    skillsImagePath,
    selectedSkill,
    setSelectedSkill,
    isMobile,
    isDesktop,
    assessments,
    disableSaveRankButton,
    setDisableSaveRankButton,
    addSkillstoSkillsDialog,
    skillsForSkillsDialog,
    skillsDialogOpen,
    toggleSkillsDialog,
  }

  return (
    <ResourcesContext.Provider value={value}>
      {children}
    </ResourcesContext.Provider>
  )
}

export default ResourcesProvider
