import React, { useRef, useState } from 'react'
import { cn } from '../../lib/utils'
import { useNav } from './NavProvider'
import { getIcon } from './icons'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import SubNav from './SubNav'
import PopupSubNav from './PopupSubNav'
import Link from './Link'

const NavLink = ({ item, theme }) => {
  const { activeNav, currentPageNavItem } = useNav()
  const { slug } = item
  let customInactiveClasses = ''
  switch (theme) {
    case 'green':
      customInactiveClasses =
        'hover:bg-intoo-green-coachcta-hover hover:text-white focus:bg-intoo-green-coachcta-hover focus:text-white'
      break
    case 'deloitte':
      customInactiveClasses =
        'text-black hover:bg-deloitte hover:text-white focus:text-white focus:bg-deloitte'
  }
  let customActiveClasses = ''
  switch (theme) {
    case 'green':
      customActiveClasses = 'bg-intoo-green-coachcta text-white'
      break
    case 'deloitte':
      customActiveClasses =
        'bg-black text-white hover:bg-deloitte focus:bg-deloitte'
  }

  return (
    <NavLinkInner
      active={slug === activeNav}
      item={item}
      className={customInactiveClasses}
      customActiveClasses={customActiveClasses}
      theme={theme}
    />
  )
}
export default NavLink

const defaultInactiveClasses =
  'text-normal flex cursor-pointer items-center px-3 py-2.5 font-medium leading-5 tracking-tight text-cod-gray-800 hover:bg-cod-gray-100 rounded-lg hover:border-none hover:text-cod-gray-800 focus:outline-none focus:bg-cod-gray-200 focus:text-cod-gray-800'
const defaultActiveClasses = cn(
  defaultInactiveClasses,
  'bg-intoo-blue-medium hover:border-intoo-blue-medium hover:bg-primary-button-darker-75 text-white hover:text-white focus:bg-primary-button-darker-75 focus:text-white',
)

const NavLinkInner = ({
  active,
  item,
  className,
  customActiveClasses,
  theme,
}) => {
  const { navigateTo, setActiveNav, isExpanded, currentPageNavItem } = useNav()
  const { title, children, slug } = item
  const popupRef = useRef(null)
  const Icon = getIcon(slug)

  const activeClasses = cn(defaultActiveClasses, className, customActiveClasses)
  const inactiveClasses = cn(defaultInactiveClasses, className)

  const [currentPageActive, setCurrentPageActive] = useState(
    slug === currentPageNavItem,
  )

  const handleClick = () => {
    active || currentPageActive ? deactivate() : activate()
    navigateTo(item)
  }
  const activate = () => {
    if (currentPageNavItem === slug) {
      setCurrentPageActive(true)
    }
    setActiveNav(slug)
  }
  const deactivate = () => {
    if (children) {
      if (currentPageNavItem === slug) {
        setCurrentPageActive(false)
      }
      setActiveNav(null)
    }
  }
  const collapsedChildren = children ? children : [item]
  const activeItem = active || currentPageActive
  if (isExpanded) {
    return (
      <>
        <Link
          className={
            active || currentPageNavItem === slug
              ? activeClasses
              : inactiveClasses
          }
          onClick={handleClick}
        >
          {Icon && <Icon className="mr-2 inline-block h-6 w-6" />}
          {title}
          {children && (
            <ChevronDownIcon
              className={cn(
                'ml-auto inline-block h-4 w-4 stroke-2 transition-all duration-500',
                activeItem && 'rotate-180',
              )}
            />
          )}
        </Link>

        <SubNav
          items={children}
          theme={theme}
          parentActive={activeItem}
          className={cn(
            'transition-all duration-500',
            activeItem ? 'visible max-h-screen' : 'invisible max-h-0',
          )}
        />
      </>
    )
  } else {
    return (
      <>
        <PopupSubNav
          popupRef={popupRef}
          slug={slug}
          items={collapsedChildren}
        />
        <Link
          className={active ? activeClasses : inactiveClasses}
          onClick={handleClick}
          title={title}
          popupRef={popupRef}
          slug={slug}
        >
          {Icon && <Icon className="inline-block h-6 w-6" />}
        </Link>
      </>
    )
  }
}
