import * as React from 'react'

export default function Accolade({ rating, title, description }) {
  return (
    <div className="flex flex-col gap-2 py-4">
      <div className="flex items-center gap-3">
        <div className="font-ibm bg-gradient-to-r from-intoo-green-coachcta to-intoo-green-bright bg-clip-text font-ibm-plex text-5xl font-bold text-transparent">
          {rating}
        </div>
        <h2 className="w-2 p-0 text-lg font-bold italic leading-tight">
          {title}...
        </h2>
      </div>
      <p className="line-clamp-4">{description}</p>
    </div>
  )
}
