import * as React from 'react'
import useTranslation from '../../shared/TranslationProvider'
import { FormLabel } from '@mui/joy'

export function AccessibleAsterisk({ className }) {
  const { t } = useTranslation()
  return <span className={className}> ({t('required')})</span>
}

export default function AccessibleFormLabel({ children }) {
  return (
    <FormLabel slots={{ asterisk: AccessibleAsterisk }}>{children}</FormLabel>
  )
}
