import React from 'react'
import { Button } from '../../lib/ui/button'

export const ButtonContainer = ({ children }) => {
  return <div className="flex justify-end pt-10">{children}</div>
}

export const NavButton = ({ children, type, ...props }) => {
  return (
    <>
      {type === 'back' && (
        <Button
          variant="outline"
          className={
            'ml-6 h-12 w-32 rounded-full border-cod-gray-700 px-12 text-base font-bold leading-7 text-cod-gray-700 hover:bg-cod-gray-75 sm:w-44'
          }
          {...props}
        >
          {children}
        </Button>
      )}

      {type === 'submit' && (
        <Button
          className={
            'ml-6 h-12 w-32 rounded-full bg-secondary px-12 text-base font-bold leading-7 text-primary-white hover:bg-secondary-darker-75 sm:w-44'
          }
          {...props}
        >
          {children}
        </Button>
      )}

      {type === 'continue' && (
        <Button
          className={
            'h-12 w-32 rounded-full bg-secondary px-6 text-base font-bold leading-7 text-primary-white hover:bg-secondary-darker-75 sm:w-full'
          }
          {...props}
        >
          {children}
        </Button>
      )}
    </>
  )
}
