import * as React from 'react'

import { CoachCard } from '../CoachCard'
import { CoachButton } from '../CoachButton'
import { LinkedInIcon } from '../../LinkedInIcon'

export function SocialMediaReviewCard({ status }) {
  // TODO: Implement in_progress and completed (See ResumeReviewCard.js)
  const disabled = status !== 'unsubmitted'

  return (
    <CoachCard
      icon={LinkedInIcon}
      title="Social Media Review"
      description="An effective social media strategy is important for you to secure a job. Your coach will provide a branding plan for LinkedIn, Facebook, and X."
    >
      <CoachButton
        className="modal-remote"
        data-toggle="modal"
        data-url="/network-and-research/social_media_review/new"
        data-key="social-media-review-modal-container"
        disabled={disabled}
        disabledText="Scheduled"
      >
        Get a Review
      </CoachButton>
    </CoachCard>
  )
}
