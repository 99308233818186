import React from 'react'
import Box from '@mui/material/Box'
import TextField from './TextField'

export default function NewCoverLetterForm({
  targetJobTitle,
  setTargetJobTitle,
  yearsExperience,
  setYearsExperience,
  exampleDescription,
  setExampleDescription,
  exampleLink,
  setExampleLink,
  skillsExperiences,
  setSkillsExperiences,
  handleSubmitButtonClick,
  submitButtonDisabled,
  tonePresent,
  coverLetterTone,
  setCoverLetterTone,
  t,
}) {
  return (
    <>
      <h3
        style={{
          textAlign: 'center',
          fontFamily: tonePresent ? 'gotham_boldregular' : 'gotham_bookregular',
        }}
      >
        {tonePresent ? t.regenerate_title : t.subtitle}
      </h3>

      <h4 style={{ textAlign: 'center' }}>
        {tonePresent ? t.regenerate_subtitle : ''}
      </h4>

      <Box marginY={2}>
        <TextField
          label={t.target_job_title_label}
          placeholder={t.target_job_title_placeholder}
          name="target_job_title"
          value={targetJobTitle}
          setValue={setTargetJobTitle}
          required
        />
        <TextField
          label={t.years_experience_label}
          placeholder={t.years_experience_placeholder}
          name="years_experience"
          value={yearsExperience}
          setValue={setYearsExperience}
          required
        />
        <TextField
          label={t.example_description_label}
          name="example_description"
          value={exampleDescription}
          setValue={setExampleDescription}
          multiline
        />
        <TextField
          label={t.example_link_label}
          name="example_link"
          value={exampleLink}
          setValue={setExampleLink}
          multiline
        />
        <TextField
          label={t.skills_experiences_label}
          name="skills_experiences"
          value={skillsExperiences}
          setValue={setSkillsExperiences}
          multiline
        />
        {tonePresent && (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0.5rem',
                paddingTop: '2rem',
                gap: '1rem',
                fontFamily: 'gotham_boldregular',
              }}
            >
              <label
                htmlFor="cover-letter-tone-slider"
                style={{ marginBottom: '2rem' }}
              >
                {t.select_tone}
              </label>
              <input
                type="range"
                min="0"
                max="99"
                value={coverLetterTone}
                onChange={(event) => setCoverLetterTone(event.target.value)}
                step={33}
                list="tickmarks"
                id="cover-letter-tone-slider"
                name="cover-letter-tone"
                className="w-full md:w-1/2"
              />
              <datalist id="tickmarks">
                <option value="0" label={t.tone_informal} />
                <option
                  value="33"
                  className="!md:block hidden"
                  label={t.tone_neutral}
                />
                <option
                  value="66"
                  className="!md:block hidden"
                  label={t.tone_business_formal}
                />
                <option value="99" label={t.tone_very_formal} />
              </datalist>

              <div>
                {coverLetterTone < 33 && t.tone_informal}
                {coverLetterTone >= 33 &&
                  coverLetterTone < 66 &&
                  t.tone_neutral}
                {coverLetterTone >= 66 &&
                  coverLetterTone < 99 &&
                  t.tone_business_formal}
                {coverLetterTone >= 99 && t.tone_very_formal}
              </div>
            </Box>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          padding: '0.5rem',
          gap: '1rem',
          marginTop: '1rem',
        }}
      >
        <button
          className="btn btn-primary"
          onClick={handleSubmitButtonClick}
          disabled={submitButtonDisabled}
        >
          {tonePresent ? t.create_new_button_text : t.submit_button_text}
        </button>
      </Box>
    </>
  )
}
