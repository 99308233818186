import * as React from 'react'

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../lib/ui/form'

import { Input } from '../../../lib/ui/input'

export default function SearchInput({
  name,
  control,
  label,
  icon: Icon,
  placeholder,
  description,
  ...props
}) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel className="sr-only">{label}</FormLabel>
          <FormControl>
            <div className="relative">
              <div className="absolute left-2 top-2 z-10 text-muted-foreground md:left-4 md:top-3">
                <Icon />
              </div>
              <Input
                placeholder={placeholder}
                className="autocomplete w-full rounded-xl border-alto-300 bg-intoo-haze pl-10 focus-visible:ring-intoo-blue-medium md:h-12 md:pl-14 md:text-lg"
                {...field}
                {...props}
              />
            </div>
          </FormControl>
          <FormDescription className="sr-only">{description}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
