import React, { useState } from 'react'
import ExpandedUpcomingtTile from './ExpandedUpcomingTile'
import CollapsedUpcomingTile from './CollapsedUpcomingTile'

const UpcomingTile = ({ ...props }) => {
  const { idx } = props
  const [expanded, setExpanded] = useState(idx == 0)

  return (
    <>
      <ExpandedUpcomingtTile
        expanded={expanded}
        setExpanded={setExpanded}
        {...props}
      />
      <CollapsedUpcomingTile
        expanded={expanded}
        setExpanded={setExpanded}
        {...props}
      />
    </>
  )
}
export default UpcomingTile
