import React from 'react'
import { cn } from '../../../lib/utils'
import { Button } from '../../../lib/ui/button'
import { DoorIcon } from '../Icons'
import { useDashboard } from '../DashboardProvider'

const RemindersCard = ({ className }) => {
  const { setOnlyReminders } = useDashboard()
  return (
    <div
      className={cn(
        'relative rounded-[20px] border border-[#B1B1B1] p-6 shadow-md',
        className,
      )}
    >
      <div className="mb-4 flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="text-2xl font-bold text-intoo-blue-medium">
            Reminders
          </div>
          <div>
            Job search can be a stressful experience. Let us help you get
            started on planning your day to day.
          </div>
        </div>
      </div>
      <div className="absolute bottom-[16px] right-[24px]">
        <Button variant="link" asChild className="h-0 px-0 py-0">
          <a onClick={() => setOnlyReminders(true)} className="ga-trackable">
            View and Edit Your Reminders
            <DoorIcon className="ml-2 inline-flex" />
          </a>
        </Button>
      </div>
    </div>
  )
}
export default RemindersCard
