import React from 'react'
import { useProgramEvalForm } from './useProgramEvalForm'
import { useProgramEvaluation } from './ProgramEvaluationProvider'
import Executive from './PreProgramEvaluations/Executive'
import OutPlatform from './PreProgramEvaluations/OutPlatform'
import Manager from './PreProgramEvaluations/Manager'
import { H1, MainBox, EvalForm, SubmitButton } from './UiLib'
import Professional from './PreProgramEvaluations/Professional'
import PeakPerformance from './PreProgramEvaluations/PeakPerformance'
import ParentalLeave from './PreProgramEvaluations/ParentalLeave'
import AnnualBenefit from './PreProgramEvaluations/AnnualBenefit'
import { cn } from '../../../../lib/utils'
import { ThankYouPage, EvalCompletedPage } from './ThankYouPage'

const EvalPage = ({ setShowButton }) => {
  const {
    inplatform,
    outplatform,
    executiveEval,
    professionalEval,
    peakPerformance,
    parentalLeave,
    managerEval,
    annualBenefit,
  } = useProgramEvaluation()
  const { form, onSubmit, submitted } = useProgramEvalForm(setShowButton)
  const showOutplatform = outplatform && !managerEval && !peakPerformance
  const formProps = { form, onSubmit }
  return (
    <>
      {inplatform && <EvalHeader />}
      <EvalForm {...formProps}>
        {showOutplatform && <OutPlatform form={form} />}
        {executiveEval && <Executive form={form} />}
        {professionalEval && <Professional form={form} />}
        {peakPerformance && <PeakPerformance form={form} />}
        {parentalLeave && <ParentalLeave form={form} />}
        {annualBenefit && <AnnualBenefit form={form} />}
        {managerEval && <Manager form={form} />}
        <SubmitButton disabled={submitted}>
          {managerEval ? 'Done' : 'Continue'}
        </SubmitButton>
      </EvalForm>
    </>
  )
}

const PreProgramEvaluation = ({ setShowButton }) => {
  const { thankYouPage, completed } = useProgramEvaluation()
  const showEval = !thankYouPage && !completed
  const boxClasses = thankYouPage ? 'flex flex-col items-center gap-12' : ''
  return (
    <MainBox className={cn('bg-white p-12', boxClasses)}>
      {thankYouPage && <ThankYouPage />}
      {showEval && <EvalPage setShowButton={setShowButton} />}
      {completed && <EvalCompletedPage />}
    </MainBox>
  )
}
export default PreProgramEvaluation

const EvalHeader = () => {
  const { executiveEval } = useProgramEvaluation()
  return (
    <>
      {executiveEval && <ExecutivePreProgramHeader />}
      {!executiveEval && (
        <>
          <H1>Complete this quick pre-session survey</H1>
          <p>
            Please complete this survey before scheduling your first coaching
            session. This survey will help our coaches prepare for your session.
          </p>
        </>
      )}
    </>
  )
}

const ExecutivePreProgramHeader = () => {
  return (
    <>
      <p>
        Executive coaching is a powerful tool that can help leaders reach their
        full potential, improve their decision-making skills, and enhance their
        overall performance. To ensure a successful executive coaching program,
        it is essential to start with a pre-program executive coaching survey.
      </p>
      <p>
        This survey serves as a foundational step in understanding your needs,
        goals, and challenges. The information shared here will be provided to
        the coaching team to ensure a good coach fit for you.{' '}
      </p>
    </>
  )
}
