import * as React from 'react'

import { CoachCard } from '../CoachCard'
import { CoachButton } from '../CoachButton'

export function TargetCompanyResearchCard({ status }) {
  // TODO: Implement in_progress and completed (See ResumeReviewCard.js)
  const disabled = status !== 'unsubmitted'

  return (
    <CoachCard
      icon={StreamlineTargetIcon}
      title="Target Company Research"
      description="Your coach will research and compile a list of best matched companies and contact information for you to target based on your preferences."
    >
      <CoachButton
        className="modal-remote"
        data-toggle="modal"
        data-url="/network-and-research/target_company_research/new"
        data-key="target-company-research-modal-container"
        disabled={disabled}
        disabledText="Scheduled"
      >
        Get a Review
      </CoachButton>
    </CoachCard>
  )
}

function StreamlineTargetIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M13.48 7.516a6.5 6.5 0 1 1-6.93-7"></path>
        <path d="M9.79 8.09A3 3 0 1 1 5.9 4.21M7 7l2.5-2.5m2 .5l-2-.5l-.5-2l2-2l.5 2l2 .5z"></path>
      </g>
    </svg>
  )
}
