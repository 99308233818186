import React from 'react'
import { useIsDesktop } from '../../../hooks/useMediaQuery'

const TileIntooIcon = ({ className }) => {
  const isDesktop = useIsDesktop()
  return (
    isDesktop && (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="216"
        height="125"
        viewBox="0 0 216 125"
        fill="none"
        className={className}
      >
        <path
          d="M133.763 103.307C140.709 108.492 145.899 114.095 149.286 119.912C152.68 125.669 154.321 131.638 154.248 137.66C154.219 144.66 151.801 151.746 147.092 158.629C144.866 161.849 141.881 163.713 138.091 161.834C134.182 159.846 135.13 155.553 137.544 151.707C139.664 148.342 141.223 145.097 142.232 141.89C142.313 141.746 142.339 141.57 142.391 141.379C144.141 135.579 143.973 130.028 141.63 124.87C139.689 120.467 136.225 116.307 131.083 112.422C112.408 98.292 93.3723 110.543 98.8034 122.901C92.4976 110.048 116.632 92.6312 133.763 103.307Z"
          fill="white"
          fillOpacity="0.25"
        />
        <path
          d="M153.057 52.5257C182.211 70.9086 194.562 102.223 166.37 129.439C162.972 132.729 158.83 135.468 154.24 137.66C154.326 131.636 152.687 125.661 149.287 119.919C150.55 118.98 151.762 117.934 152.925 116.86C153.351 116.492 153.758 116.104 154.132 115.709C172.972 97.2752 173.266 64.4336 148.906 50.0872C150.314 50.8607 151.69 51.6803 153.057 52.5257Z"
          fill="white"
          fillOpacity="0.25"
        />
        <path
          d="M99.3571 124.005C99.512 124.261 99.6326 124.51 99.816 124.754C108.161 138.833 126.043 144.207 142.398 141.368C142.349 141.565 142.32 141.752 142.225 141.89C120.33 147.305 94.4821 142.141 86.9985 124.178C79.0065 105.009 106.323 82.7734 133.76 103.305C116.64 92.6317 92.5044 110.046 98.8006 122.9C98.808 123 98.8821 123.094 98.9349 123.17C99.0691 123.441 99.2053 123.715 99.3571 124.005Z"
          fill="white"
          fillOpacity="0.25"
        />
        <path
          d="M99.8178 124.758C105.858 133.763 125.133 133.743 141.639 124.871C143.978 130.039 144.157 135.576 142.4 141.372C126.043 144.207 108.163 138.836 99.8178 124.758Z"
          fill="white"
          fillOpacity="0.25"
        />
        <path
          d="M63.6348 63.0827C61.0406 56.1065 67.6256 42.8347 84.9576 39.6475C101.211 36.6632 127.11 38.1159 148.891 50.0678L148.904 50.0897C173.268 64.4341 172.974 97.2758 154.13 115.712C169.182 100.671 168.455 78.4401 152.626 69.2159C127.725 54.682 87.3479 70.9519 87.3479 70.9519C78.0977 74.2103 67.136 72.5218 63.6348 63.0827Z"
          fill="white"
          fillOpacity="0.25"
        />
        <path
          d="M16.4073 60.5193C26.2612 52.4337 41.0342 52.2731 49.4089 60.1573C57.7778 68.0321 56.5752 80.9801 46.7213 89.0658C36.8654 97.1483 22.0905 97.3057 13.7196 89.4278C5.34868 81.5416 6.55327 68.5967 16.4073 60.5193Z"
          fill="white"
          fillOpacity="0.25"
        />
      </svg>
    )
  )
}
export default TileIntooIcon
