import React, { createContext, useCallback, useState, useContext } from 'react'
import { Sheet, Box, ModalClose, Typography, Button, Modal } from '@mui/joy'
import Spinner from '../../shared/Spinner'
import parse from 'html-react-parser'
import Icon from '@mdi/react'
import { mdiCheckCircleOutline } from '@mdi/js'
import useTranslation from '../../shared/TranslationProvider'

const AlertContext = createContext()

export const AlertProvider = ({ children }) => {
  const [alertData, setData] = useState('')
  const [alertState, setState] = useState('')

  const setAlertData = useCallback(
    (data) => {
      setData(data)
    },
    [setData],
  )

  const setAlertState = useCallback(
    (state) => {
      setState(state)
    },
    [setState],
  )

  const clearAlert = useCallback(() => {
    setAlertState('')
    setAlertData('')
  }, [setAlertState, setAlertData])

  const value = {
    alertData,
    setAlertData,
    alertState,
    setAlertState,
    clearAlert,
  }

  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
}

export const useAlert = () => useContext(AlertContext)

export default function AlertModal() {
  const { alertData, alertState, clearAlert } = useAlert()
  const { t } = useTranslation()

  return (
    <>
      <Modal
        open={alertState !== ''}
        onClose={clearAlert}
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: '686px',
            minHeight: '200px',
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
            '--Typography-fontSize': '16px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'space-around',
          }}
        >
          <ModalClose
            variant="plain"
            color="black"
            size="xl"
            onClick={clearAlert}
            sx={{ '--Icon-fontSize': '1.875rem', m: 1 }}
          />
          {alertState === 'alert' && (
            <>
              <ModalHeader>{t('AlertModal.alert')}</ModalHeader>
              <Typography id="modal-desc">
                {alertData && parse(alertData)}
              </Typography>
            </>
          )}
          {alertState === 'notice' && (
            <>
              <ModalHeader>{t('AlertModal.notice')}</ModalHeader>
              <Typography id="modal-desc">
                {alertData && parse(alertData)}
              </Typography>
            </>
          )}
          {alertState === 'loading' && (
            <Box
              sx={{
                width: '350px',
                height: '300px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-around',
              }}
            >
              <Spinner show />
              <ModalHeader>{t('AlertModal.loading')}</ModalHeader>
            </Box>
          )}
          {alertState === 'success' && (
            <Box
              sx={{
                height: '300px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-around',
              }}
            >
              <Icon path={mdiCheckCircleOutline} color={'#123F1C'} size={6} />
              <ModalHeader>{t('AlertModal.success')}</ModalHeader>
              <Typography id="modal-desc">{alertData}</Typography>
              <Button
                sx={{
                  fontFamily: 'Roboto',
                  backgroundColor: '#123F1C',
                  padding: '0 24px',
                  color: '#F7FAFC',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: '28px',
                  borderRadius: '20px',
                  height: '60px',
                  '&:hover': {
                    backgroundColor: '#08260C',
                  },
                }}
                size="lg"
                fullWidth
                onClick={clearAlert}
              >
                {t('AlertModal.continue')}
              </Button>
            </Box>
          )}
        </Sheet>
      </Modal>
    </>
  )
}

function ModalHeader({
  children,
  level = 'h3',
  component = 'h2',
  fontWeight = 'xl',
  ...props
}) {
  return (
    <Typography
      component={component}
      id="modal-title"
      level={level}
      textColor="inherit"
      fontWeight={fontWeight}
      mb={1}
      sx={{
        color: 'var(--Gray-10, #2B2B2B)',
        fontFamily: 'Roboto',
        fontSize: '24px',
        fontWeight: '400',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '-0.48px',
      }}
      {...props}
    >
      {children}
    </Typography>
  )
}
