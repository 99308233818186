import React from 'react'
import { BenefitsBulletList, Testimonial } from './components'
import PanelNav, { PanelNavProvider, PanelEnabled } from './PanelNav'
import { AylaPanel } from './commonPanels'

const SoloBenefitsPanel = () => {
  const items = [...Array(4).keys()].map((key) => `RightPanel.solo.item${key}`)
  return (
    <>
      <PanelEnabled thisPanelNumber={0}>
        <PanelNav />
        <BenefitsBulletList items={items} />
        <Testimonial />
      </PanelEnabled>
    </>
  )
}

const SoloPlusBenefitsPanel = () => {
  const items = [...Array(5).keys()].map(
    (key) => `RightPanel.solo_plus.item${key}`,
  )
  return (
    <>
      <PanelEnabled thisPanelNumber={0}>
        <PanelNav />
        <BenefitsBulletList items={items} />
        <Testimonial />
      </PanelEnabled>
    </>
  )
}

const RightPanelSolo = () => {
  return (
    <PanelNavProvider panelCount={2}>
      <SoloBenefitsPanel />
      <AylaPanel thisPanelNumber={1} />
    </PanelNavProvider>
  )
}

const RightPanelSoloPlus = () => {
  return (
    <PanelNavProvider panelCount={2}>
      <SoloPlusBenefitsPanel />
      <AylaPanel thisPanelNumber={1} />
    </PanelNavProvider>
  )
}

export { RightPanelSolo, RightPanelSoloPlus }
