import * as React from 'react'
import { Calendar } from '../../coach_services/OutplacementBenefits/Calendar'
import { ActionPlanCard } from '../cards/ActionPlanCard'
import { PremiumCoachCard } from '../cards/PremiumCoachCard'
import { QuoteCard } from '../cards/QuoteCard'
import { RecommendedJobsCard } from '../cards/RecommendedJobsCard'
import { RecommendedBlogsCard } from '../cards/RecommendedBlogsCard'

export function Premium({
  tracker_progress,
  tracker_time_period,
  coach,
  quote,
  recommended_jobs,
  recommended_blogs,
  calendar_events,
  orientation_call_completed,
  current_user,
}) {
  return (
    <>
      <div className="flex flex-col gap-[32px] xl:grid xl:grid-cols-12">
        <PremiumCoachCard
          coach={coach}
          orientation_call_completed={orientation_call_completed}
          className="md:col-span-7"
        />
        <ActionPlanCard
          tracker_progress={tracker_progress}
          tracker_time_period={tracker_time_period}
          className="md:col-span-5"
        />
      </div>
      <QuoteCard quote={quote} />
      <div className="flex flex-col gap-[32px] md:grid md:grid-cols-7">
        <RecommendedJobsCard
          recommended_jobs={recommended_jobs}
          className="md:col-span-3"
        />
        <RecommendedBlogsCard
          recommended_blogs={recommended_blogs}
          className="md:col-span-4"
        />
      </div>
      {!current_user.global_program_level && (
        <div className="relative flex flex-col gap-[16px]">
          <h2 className="text-2xl font-bold text-primary">
            Upcoming Live Webinars
          </h2>
          <div className="inline-block">
            Don't miss out! Check out our live webinar schedule now.
          </div>
          <Calendar calendar_events={calendar_events} homePageDisplay={true} />
        </div>
      )}
    </>
  )
}
