import React, { useState } from 'react'
import AccessibleFormLabel from './AccessibleFormLabel'
import { Box, Input, FormControl, Button, Link } from '@mui/joy'
import ContactUsLink from './ContactUsLink'
import LegalAgreement from './LegalAgreement'
import { useData } from './DataProvider'
import { SpinnerWhite } from '../../shared/Spinner'
import useTranslation from '../../shared/TranslationProvider'

export default function InviteCodeEntry() {
  const { setFormState, getInvitation, isLoading, setIsLoading, legalNotice } =
    useData()

  const [invitationCode, setInvitationCode] = useState('')

  const handleInviteCode = () => {
    setIsLoading(true)
    getInvitation(invitationCode, 'InviteCodeEntry')
  }

  const { t } = useTranslation()

  return (
    <Box>
      <Box>
        <h1 className="m-8">{t('InviteCodeEntry.title')}</h1>
      </Box>
      <Box className="control-container">
        <FormControl required>
          <AccessibleFormLabel>
            {t('InviteCodeEntry.invitation_code')}
          </AccessibleFormLabel>
          <Input
            name="invitation_code"
            size="lg"
            autoFocus
            value={invitationCode}
            onChange={(e) => setInvitationCode(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleInviteCode()
            }}
          />
        </FormControl>
      </Box>
      <Box className="button-container">
        <Button
          className="submit-button btn !rounded-full"
          size="lg"
          fullWidth
          onClick={handleInviteCode}
          disabled={isLoading}
        >
          {t('InviteCodeEntry.validate_code')}
          <SpinnerWhite show={isLoading} className="pl-4" />
        </Button>
      </Box>
      <LegalAgreement
        actionText={t('InviteCodeEntry.validate_code')}
        htmlText={legalNotice}
      />
      <ContactUsLink />
      <Box
        className="subtext-bottom-container link-container"
        sx={{
          '--linkMargin': '0',
        }}
      >
        <Link onClick={() => setFormState('LoginForm')}>
          {t('InviteCodeEntry.log_in_existing_account')}
        </Link>
      </Box>
    </Box>
  )
}
