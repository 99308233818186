import React from 'react'

const Outcomes = ({ outcomes }) => {
  return (
    <div className="rounded-[15px] bg-gradient-to-b from-[#0F80E2] to-[#00C2FF] sm:container">
      <div className="outcomes m-1 flex min-h-28 flex-col rounded-2xl bg-white p-2 sm:gap-2 sm:pt-4">
        <div
          style={{ fontFamily: 'gotham_boldregular' }}
          className="pl-7 text-base"
        >
          {"Unlock Insights: What You'll Learn With This Quiz"}
        </div>
        <ul>
          {outcomes.map((outcome, idx) => (
            <li className="text-sm" key={`outcome-${idx}`}>
              {outcome}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Outcomes
