import React, { useState } from 'react'
import ExpandedCurrentTile from './ExpandedCurrentTile'
import CollapsedCurrentTile from './CollapsedCurrentTile'

const CurrentTile = ({ ...props }) => {
  const { idx } = props
  const [expanded, setExpanded] = useState(idx == 0)

  return (
    <>
      <ExpandedCurrentTile
        expanded={expanded}
        setExpanded={setExpanded}
        {...props}
      />
      <CollapsedCurrentTile
        expanded={expanded}
        setExpanded={setExpanded}
        {...props}
      />
    </>
  )
}
export default CurrentTile
