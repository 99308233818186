import React from 'react'
import { cn } from '../../lib/utils'
import {
  BuildingStorefrontIcon,
  DocumentTextIcon,
  SparklesIcon,
  AcademicCapIcon,
  ChatBubbleLeftRightIcon,
  BriefcaseIcon,
  BookOpenIcon,
  GlobeAmericasIcon,
  RocketLaunchIcon,
  MapIcon,
  Cog6ToothIcon,
  Squares2X2Icon,
  ChatBubbleBottomCenterTextIcon,
  UserCircleIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'

export const getIcon = (name) => {
  const icons = {
    coachServices: UserCircleIcon,
    dashboard: Squares2X2Icon,
    home: Squares2X2Icon,
    orientation: MapIcon,
    careerAdvisement: UserCircleIcon,
    scheduleCoaching: MapIcon,
    jobTargeting: BriefcaseIcon,
    actionPlanMiniPlatform: BriefcaseIcon,
    skills: AcademicCapIcon,
    personalBrand: SparklesIcon,
    resume: DocumentTextIcon,
    coachNotes: ChatBubbleBottomCenterTextIcon,
    networking: GlobeAmericasIcon,
    research: BookOpenIcon,
    interview: ChatBubbleLeftRightIcon,
    interviewDeloitte: UsersIcon,
    onboardingSuccess: RocketLaunchIcon,
    guidesMiniPlatform: RocketLaunchIcon,
    entrepreneurship: BuildingStorefrontIcon,
    retirement: RetirementIcon,
    wellness: WellnessIcon,
    settings: Cog6ToothIcon,
  }
  return icons[name]
}

export const Bar = ({ isColored, theme = 'blue', className }) => {
  let notColoredFill = 'fill-cod-gray-200'
  let themeFill = 'fill-[#BFDCF5]'
  switch (theme) {
    case 'green':
      themeFill = 'fill-[#1E7129]'
      break
    case 'deloitte':
      themeFill = 'fill-black'
      notColoredFill = 'fill-deloitte'
  }
  const barClass = cn(
    'fill-[#BFDCF5]',
    className,
    !isColored && notColoredFill,
    isColored && themeFill,
  )
  return (
    <svg
      role="img"
      width="2"
      height="50"
      viewBox="0 0 2 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={barClass}
    >
      <title>subnav decoration bar</title>
      <path d="M0 0H2V125C0.895431 125 0 124.105 0 123V0Z" />
      <path d="M0 0H2V125C0.895431 125 0 124.105 0 123V0Z" />
    </svg>
  )
}

export const SplitBar = ({ theme = 'blue', className }) => {
  let topStroke = 'stroke-[#BFDCF5]'
  let bottomStroke = 'stroke-[#D1D1D1]'
  switch (theme) {
    case 'green':
      topStroke = 'stroke-[#1E7129]'
      break
    case 'deloitte':
      topStroke = 'stroke-black'
      bottomStroke = 'stroke-deloitte'
  }
  return (
    <svg
      role="img"
      width={2}
      height={50}
      viewBox="0 0 2 50"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>active subnav decoration bar</title>
      <g strokeWidth={2}>
        <path className={topStroke} d="M1 0v25" />
        <path className={bottomStroke} d="M1 25v25" />
      </g>
    </svg>
  )
}

export const WellnessIcon = ({ className }) => {
  return (
    <svg
      className={className}
      role="img"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <title>wellness</title>
      <path
        d="M18 20L21.8243 16.1757C21.9368 16.0632 22 15.9106 22 15.7515V10.5C22 9.67157 21.3284 9 20.5 9C19.6716 9 19 9.67157 19 10.5V15"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 16L18.8581 15.1419C18.949 15.051 19 14.9278 19 14.7994C19 14.6159 18.8963 14.4482 18.7322 14.3661L18.2893 14.1447C17.5194 13.7597 16.5894 13.9106 15.9807 14.5193L15.0858 15.4142C14.7107 15.7893 14.5 16.298 14.5 16.8284V20"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 20L2.17574 16.1757C2.06321 16.0632 2 15.9106 2 15.7515V10.5C2 9.67157 2.67157 9 3.5 9C4.32843 9 5 9.67157 5 10.5V15"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 16L5.14187 15.1419C5.05103 15.051 5 14.9278 5 14.7994C5 14.6159 5.10366 14.4482 5.26776 14.3661L5.71067 14.1447C6.48064 13.7597 7.41059 13.9106 8.01931 14.5193L8.91421 15.4142C9.28929 15.7893 9.5 16.298 9.5 16.8284V20"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6667 12H10.3333V9.66667H8V6.33333H10.3333V4H13.6667V6.33333H16V9.66667H13.6667V12Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const RetirementIcon = ({ className }) => {
  return (
    <svg
      className={className}
      role="img"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <title>retirement</title>
      <path
        d="M16 13C13.2386 13 11 11.8807 11 10.5C11 9.11929 13.2386 8 16 8C18.7614 8 21 9.11929 21 10.5C21 11.8807 18.7614 13 16 13Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 14.5C11 15.8807 13.2386 17 16 17C18.7614 17 21 15.8807 21 14.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 9.5C3 10.8807 5.23858 12 8 12C9.12583 12 10.1647 11.814 11.0005 11.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 13C3 14.3807 5.23858 15.5 8 15.5C9.12561 15.5 10.1643 15.314 11 15.0002"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 5.5V16.5C3 17.8807 5.23858 19 8 19C9.12563 19 10.1643 18.8139 11 18.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 8.5V5.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 10.5V18.5C11 19.8807 13.2386 21 16 21C18.7614 21 21 19.8807 21 18.5V10.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 8C5.23858 8 3 6.88071 3 5.5C3 4.11929 5.23858 3 8 3C10.7614 3 13 4.11929 13 5.5C13 6.88071 10.7614 8 8 8Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
