import React, { useState, useCallback } from 'react'
import { Button } from '../../../lib/ui/button'
import { useUploadJobApplicationForm } from './useUploadJobApplicationForm'
import { Form } from '../../../lib/ui/form'
import { FileField } from './FileField'
import { useJobSearch } from './JobSearchProvider'
import { useTranslation } from '../../../hooks/useTranslation'
import { SpinnerWhite } from '../../shared/Spinner'

const ACCEPT_LIST = ['.pdf', '.rtf', '.doc', '.docx', '.txt']

export default function JobApplicationModal({ onClose, job }) {
  const jobPostingId = job?.id
  const { form, onSubmit } = useUploadJobApplicationForm({
    onClose,
    jobPostingId,
  })
  const { availableResumes } = useJobSearch()
  const [selectedResumeId, setSelectedResumeId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [resumeError, setResumeError] = useState('')
  const { t } = useTranslation('ApplyWithIntooButton')

  const handleResumeChange = useCallback((event) => {
    setSelectedResumeId(event.target.value)
    setResumeError('')
  }, [])

  const handleSubmit = useCallback(
    async (data) => {
      if (!selectedResumeId && !data.uploadedResume) {
        setResumeError(t('noResume'))
        return
      }

      setIsLoading(true)
      setTimeout(async () => {
        await onSubmit({ ...data, documentId: selectedResumeId })
        setIsLoading(false)
      }, 2000)
    },
    [onSubmit, selectedResumeId, t],
  )

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-full max-w-lg rounded-lg bg-white p-6 shadow-lg">
        <button
          type="button"
          className="absolute right-2 top-2 flex h-8 w-8 items-center justify-center rounded-full border border-gray-300 text-2xl text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          &times;
        </button>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="space-y-8"
          >
            <h2 className="mb-4 text-center text-2xl font-bold">
              {t('job_application')}:
            </h2>
            <h3 className="text-center text-2xl font-bold underline">
              {' '}
              {job.title}{' '}
            </h3>
            <div className="well">
              <p className="mb-4">{t('select_existing_resume')}</p>
              <select
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-base shadow-sm focus:border-accent focus:ring-accent sm:text-sm"
                name="documentId_id"
                value={selectedResumeId}
                onChange={handleResumeChange}
              >
                <option value="">{t('select_resume')}</option>
                {availableResumes.map((resume) => (
                  <option key={resume.id} value={resume.id}>
                    {resume.name}
                  </option>
                ))}
              </select>
              <p className="!mb-0 pt-5">{t('or_upload_below')}</p>
              <FileField
                accept={ACCEPT_LIST.join(',')}
                name="uploadedResume"
                id="job_application_uploaded_resume"
                control={form.control}
              />
              <div className="hint mt-2">{t('hint')}</div>
            </div>
            {resumeError && (
              <p className="text-sm text-red-600">{resumeError}</p>
            )}
            <Button
              type="submit"
              disabled={isLoading}
              className="flex items-center"
            >
              {isLoading ? t('uploading') : t('upload_resume')}
              {isLoading && (
                <SpinnerWhite show={true} className="mt-2 h-8 w-8 pl-2" />
              )}
            </Button>
          </form>
        </Form>
      </div>
    </div>
  )
}
