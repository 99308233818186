import React, { useState } from 'react'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder'
import { TrashIcon } from './icons'
import { Button } from '../../../../lib/ui/button'

const Audio = ({
  name,
  audioSrc,
  setAudioSrc,
  showAudio,
  setShowAudio,
  deleteRecording,
  children,
  ...props
}) => {
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.log(err),
  )
  const handleDeleteClick = (e) => {
    e.preventDefault()
    window.confirm('Are you sure you want to delete this recording?') &&
      deleteRecording()
  }
  return (
    <div className="mt-[5px] flex min-h-12 items-center gap-4 rounded-[5px] border border-input bg-input-background">
      <AudioRecorder
        recorderControls={recorderControls}
        showVisualizer={true}
        {...props}
      />
      {showAudio && (
        <>
          <audio src={audioSrc} controls />
          <Button onClick={handleDeleteClick} variant="ghost">
            <TrashIcon className="cursor-pointer" />
          </Button>
        </>
      )}
    </div>
  )
}

export default Audio
