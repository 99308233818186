import React from 'react'
import { cn } from '../../lib/utils'

export const H1 = ({ className, children }) => {
  return (
    <h1
      className={cn(
        '!font-robotoSlab text-[26px] font-bold leading-8',
        className,
      )}
    >
      {children}
    </h1>
  )
}

export const Heading = ({ className, children }) => {
  return (
    <div
      className={cn(
        'font-robotoSlab text-[26px] font-bold leading-8',
        className,
      )}
    >
      {children}
    </div>
  )
}
export const Subheading = ({ className, children }) => {
  return (
    <div
      className={cn(
        'font-robotoSlab text-[26px] font-medium leading-8',
        className,
      )}
    >
      {children}
    </div>
  )
}

export const Paragraph = ({ className, children, ...props }) => {
  return (
    <div {...props} className={cn('font-roboto', className)}>
      {children}
    </div>
  )
}

export const Link = ({ className, children, href, target, onClick }) => {
  return (
    <a
      className={cn('cursor-pointer !font-roboto', className)}
      href={href}
      target={target}
      onClick={onClick}
    >
      {children}
    </a>
  )
}
