import React from 'react'
import { ArticlesIcon } from '../Resources/icons'
import { cn } from '../../lib/utils'

const DashboardArticleCard = ({ article, className }) => {
  const { headerImageUrl, headerImageAlt, title, description } = article
  const articlePath = `/coaching/resources/article/${article.slug}`

  return (
    <div
      className={cn(
        'relative col-span-2 mb-8 overflow-hidden rounded-xl border border-solid border-[#B9B9B9]',
        className,
      )}
    >
      <img
        src={headerImageUrl}
        alt={headerImageAlt}
        className="aspect-[20/11] w-full object-center"
      />
      <div className="p-4">
        <h2
          role="heading"
          tabIndex="0"
          aria-level="2"
          className="!mb-2 !mt-0 min-h-12 overflow-hidden text-xl font-bold"
        >
          {title}
        </h2>
        <p className="!my-0 line-clamp-3 min-h-16">{description}</p>
        <div className="mt-6">
          <a
            href={articlePath}
            className="flex w-full items-center justify-center rounded-3xl bg-primary px-4 py-3 text-base font-medium text-white hover:!bg-primary-lighter-30 hover:text-white focus:text-white active:text-white sm:py-2"
          >
            Read Article
            <ArticlesIcon className="ml-2 stroke-white" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default DashboardArticleCard
