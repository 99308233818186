import * as React from 'react'
import { Toggle } from '../../../lib/ui/toggle'

export default function FilterToggleButton({
  title,
  pressed,
  onPressedChange,
  show = true,
}) {
  if (!show) {
    return null
  }

  return (
    <Toggle
      className="flex h-8 items-center justify-center gap-0 whitespace-nowrap rounded-full border border-alto-300 border-input bg-background bg-intoo-haze pl-3 pr-2 text-xs font-medium italic text-muted-foreground ring-offset-background transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-intoo-blue-medium focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=on]:bg-intoo-blue-bright-100 data-[state=on]:text-black sm:gap-2 sm:pl-6 sm:pr-4 md:h-9 md:text-base"
      pressed={pressed}
      onPressedChange={onPressedChange}
    >
      {title}
    </Toggle>
  )
}
