import React from 'react'
import { useProgramEvaluation } from './ProgramEvaluationProvider'
import { H1, H2, H3 } from './UiLib'

const EvalHeader = ({ children }) => {
  return <div className="p-4 text-intoo-blue-medium md:p-0">{children}</div>
}

const ProgramEvalHeader = () => {
  const {
    peakPerformance,
    managerEval,
    managerPostEval,
    topicSelection,
    postProgramEvaluation,
    midProgramEval,
    postSessionEval,
  } = useProgramEvaluation()

  if (peakPerformance) return <PeakPerformanceHeader />
  if (managerPostEval) return <ManagerPostProgramHeader />
  if (managerEval) return <ManagerHeader />
  if (topicSelection) return <TopicSelectionHeader />
  if (postProgramEvaluation) return <PostProgramEvaluationHeader />
  if (midProgramEval) return <MidProgramEvaluationHeader />
  if (postSessionEval) return <PostSessionEvalHeader />
  return <DefaultEvalHeader />
}

const PostSessionEvalHeader = () => {
  const { thankYouPage } = useProgramEvaluation()
  return (
    <EvalHeader>
      <H1>Coaching Program Feedback{thankYouPage ? ' Received' : ''} </H1>
    </EvalHeader>
  )
}

const MidProgramEvaluationHeader = () => {
  return (
    <EvalHeader>
      <H1>Please provide input on your ongoing coaching program</H1>
      <H3>
        {`This evaluation should take just 5 minutes to complete and is meant to
        help gauge the impact you are seeing from your participation in INTOO’s
        coaching program. Your input will inform our coaching team on how well
        we are meeting your goals and expectations. Thank you in advance!`}
      </H3>
    </EvalHeader>
  )
}

const PostProgramEvaluationHeader = () => {
  const { companyName } = useProgramEvaluation()
  return (
    <EvalHeader>
      <H1>
        {companyName || 'Career Development Coaching Program'}: Post-Program
        Input for Program Participants
      </H1>
      <H2>Please provide feedback on your recent coaching program.</H2>
      <H3>
        This evaluation should take just 5 minutes to complete, and is meant to
        help gauge the impact of your recent participation in INTOO’s coaching
        program. Your input will inform our coaching team on how well we have
        met your goals and expectations. Thank you in advance!
      </H3>
    </EvalHeader>
  )
}

const ManagerPostProgramHeader = () => {
  const { candidate } = useProgramEvaluation()
  const candidateName = candidate?.first_name + ' ' + candidate?.last_name
  return (
    <EvalHeader>
      <H1>Post-Program Input for {candidateName}</H1>
      <H2>
        {`Please provide your feedback related to your employee's recent coaching
        program participation`}
      </H2>
      <H3>
        {`This evaluation should take just 5 minutes to complete, and is meant to
        help gauge the impact of your employee’s recent participation in INTOO’s
        coaching program.`}
        <br></br>
        {`Your input will inform our coaching team on how well we have met your
        goals and expectations. Thank you in advance!`}
      </H3>
    </EvalHeader>
  )
}

const DefaultEvalHeader = () => {
  const { company, completed, companyName } = useProgramEvaluation()

  return (
    <EvalHeader>
      <H1>Welcome to {companyName || 'your career development program'}!</H1>
      <H3>
        {!completed &&
          `Before beginning your coaching program for ${
            companyName || company.name
          }, we need a little information about your career so that we can customize a plan that benefits you the most.`}
      </H3>
    </EvalHeader>
  )
}
export default ProgramEvalHeader

const TopicSelectionHeader = () => {
  const { company } = useProgramEvaluation()
  const companyName =
    company.career_development_settings?.platform_name ||
    'Intoo Career Development'
  return (
    <EvalHeader>
      <H1>Welcome to {companyName}</H1>
    </EvalHeader>
  )
}

const PeakPerformanceHeader = () => {
  return (
    <EvalHeader>
      <H1>Peak Performance Coaching Program survey</H1>
      <H3>
        This short survey will help us baseline your current situation at work
        and home, identify the key things that you want to work on with your
        coach, and identify blockers that might be getting in the way of your
        success.
      </H3>
    </EvalHeader>
  )
}

const ManagerHeader = () => {
  const { candidate } = useProgramEvaluation()
  const candidateName = candidate?.first_name + ' ' + candidate?.last_name
  return (
    <EvalHeader>
      <H1 className="font-bold">Career Development Input from Leaders</H1>
      <H2 className="flex items-center gap-2">
        Program for: <div className="font-bold">{candidateName}</div>
      </H2>
      <H3 className="text-left">
        {`Please provide input related to your employee's coaching program
          participation. This evaluation should take just 2 minutes to complete,
          and is meant to help guide your employee's coaching program. With your
          input in mind, our coaching team will personalize the program
          experience to ensure we are meeting your goals and expectations. Thank
          you in advance!`}
      </H3>
    </EvalHeader>
  )
}
