import React from 'react'
import { Paragraph, Subheading, Link } from './styles'
import { ScheduleCoachingCard, MeetingDetailsCard } from './eventCards'
import { useMiniplatform } from './MiniplatformProvider'

const ScheduleCoachingSubheading = () => {
  return <Subheading>Schedule Coaching</Subheading>
}

const P1 = () => {
  const { programType, includesPrivateSession } = useMiniplatform()
  return (
    <>
      {programType === 'group' && !includesPrivateSession && (
        <Paragraph>{`You can join your CliftonStrenths group coaching session here. The meeting link along with the time and date of the group session will be updated by your coach.`}</Paragraph>
      )}
      {programType === 'group' && includesPrivateSession && (
        <Paragraph>{`Schedule your 1v1 meeting with a coach and join your group coaching session here.`}</Paragraph>
      )}
      {programType !== 'group' && (
        <Paragraph>{`Your CliftonStrenths Coaching session can be scheduled by clicking “Schedule Meeting”. Please allow your coach one week to review your assessment before meeting.`}</Paragraph>
      )}
    </>
  )
}

const P2 = () => {
  const { programType, includesPrivateSession, coachingProgressPath } =
    useMiniplatform()
  return (
    <>
      {programType === 'group' && !includesPrivateSession && (
        <Paragraph>
          After you complete your group coaching session, your coach will send
          you a session note. Check out{' '}
          <Link href={coachingProgressPath}>Coach Notes</Link> in the menu to
          view your session note.
        </Paragraph>
      )}
      {programType === 'group' && includesPrivateSession && (
        <Paragraph>
          Check out <Link href={coachingProgressPath}>Coach Notes</Link> in the
          menu to view your session notes.
        </Paragraph>
      )}
      {programType !== 'group' && (
        <Paragraph>
          After you complete your 1v1 coaching session, your coach will send you
          a post session note. Check out{' '}
          <Link href={coachingProgressPath}>Coach Notes</Link> in the menu to
          view your session note.
        </Paragraph>
      )}
    </>
  )
}

const P3 = () => {
  const { assessmentInfo } = useMiniplatform()
  const { assessmentName, downloadUrl } = assessmentInfo
  return (
    <>
      <Paragraph className={'font-bold'}>CliftonStrengths Assessment</Paragraph>
      <Link href={downloadUrl} className={'flex items-center gap-1'}>
        <img
          src="https://www-yns-com.s3.us-east-2.amazonaws.com/miniplatform/downloads+(1)+10.png"
          alt=""
        />
        {assessmentName}
      </Link>
    </>
  )
}

const ScheduleCoaching = () => {
  const {
    showCalendly,
    assessmentMissing,
    programType,
    includesPrivateSession,
  } = useMiniplatform()

  return (
    !showCalendly &&
    !assessmentMissing && (
      <>
        {!(programType === 'group' && includesPrivateSession) && (
          <OneEventScheduling />
        )}
        {programType === 'group' && includesPrivateSession && (
          <MultipleEventScheduling />
        )}
      </>
    )
  )
}

export default ScheduleCoaching

const MobileView = ({ children }) => {
  const { isLargeScreen } = useMiniplatform()
  return !isLargeScreen && children
}

const DesktopView = ({ children }) => {
  const { isLargeScreen } = useMiniplatform()
  return isLargeScreen && children
}

const MultipleEventScheduling = ({ download }) => {
  const { individualEvent, groupEvent } = useMiniplatform()

  const size = 'group'

  const event = groupEvent

  const groupEventInfo = { event, size }

  const individualEventInfo = {
    event: individualEvent,
    size: '1v1',
  }

  const eventExists =
    individualEvent?.type === 'completed' || individualEvent?.type === 'pending'

  const card = eventExists ? (
    <MeetingDetailsCard colSpan={2} eventInfo={individualEventInfo} />
  ) : (
    <ScheduleCoachingCard colSpan={2} />
  )

  return (
    <>
      <DesktopView>
        <div className="grid grid-cols-6 gap-20">
          <div className="col-span-2 flex flex-col gap-4">
            <ScheduleCoachingSubheading />
            <P1 />
            <P2 />
            <P3 />
            {download}
          </div>
          {card}
          <MeetingDetailsCard colSpan={2} eventInfo={groupEventInfo} />
        </div>
      </DesktopView>
      <MobileView>
        <div className="flex flex-col gap-4">
          <ScheduleCoachingSubheading />
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4">
              <P1 />
              <P2 />
              <P3 />
              {download}
            </div>
            {card}
            <MeetingDetailsCard eventInfo={groupEventInfo} />
          </div>
        </div>
      </MobileView>
    </>
  )
}

const OneEventScheduling = ({ cardClass }) => {
  const { individualEvent, groupEvent } = useMiniplatform()

  const size = groupEvent ? 'group' : '1v1'

  const eventInfo = groupEvent
    ? { event: groupEvent, size }
    : { event: individualEvent, size }

  const card =
    individualEvent || groupEvent ? (
      <MeetingDetailsCard className={cardClass} eventInfo={eventInfo} />
    ) : (
      <ScheduleCoachingCard className={cardClass} />
    )

  return (
    <div className="flex flex-col gap-4">
      <MobileView>
        <>
          <ScheduleCoachingSubheading />
          <div className="flex flex-col gap-4">
            <P1 />
            {card}
            <P2 />
            <P3 />
          </div>
        </>
      </MobileView>

      <DesktopView>
        <div className="grid grid-cols-6 gap-20">
          <div className="col-span-4 flex flex-col gap-4">
            <ScheduleCoachingSubheading />
            <P1 />
            <P2 />
            <P3 />
          </div>
          <div className="col-span-2 flex justify-center">{card}</div>
        </div>
      </DesktopView>
    </div>
  )
}
