import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
} from 'react'

const ThemeContext = createContext()

const useTheme = () => {
  return useContext(ThemeContext)
}
export default useTheme

export const ThemeProvider = ({ children }) => {
  // These are set in the layout through SiteThemeComponent
  const {
    is_custom_branded,
    platform_name,
    login_page_white_logo_url,
    login_page_background_url,
    theme_primary,
    theme_secondary,
    theme_primary_darker_75,
    theme_secondary_darker_75,
    theme_secondary_lighter_30,
  } = document.siteTheme

  const [isCustomBranded, setIsCustomBranded] = useState(is_custom_branded)
  const [platformName, setPlatformName] = useState(platform_name)
  const [loginPageWhiteLogoUrl, setLoginPageWhiteLogoUrl] = useState(
    login_page_white_logo_url,
  )
  const loginPageBackgroundImage = login_page_background_url
  const [primaryColor, setPrimaryColor] = useState(theme_primary)
  const [primaryDarker, setPrimaryDarker] = useState(theme_primary_darker_75)
  const [secondaryColor, setSecondaryColor] = useState(theme_secondary)
  const [secondaryDarker, setSecondaryDarker] = useState(
    theme_secondary_darker_75,
  )
  const [secondaryLighter, setSecondaryLighter] = useState(
    theme_secondary_lighter_30,
  )

  const cacheLogoImage = useCallback(async () => {
    if (!loginPageWhiteLogoUrl) return
    const logoImage = new Image()
    logoImage.src = loginPageWhiteLogoUrl
  }, [loginPageWhiteLogoUrl])

  const cacheBackgroundImage = useCallback(async () => {
    if (!loginPageBackgroundImage) return
    const backgroundImage = new Image()
    backgroundImage.src = loginPageBackgroundImage
  }, [loginPageBackgroundImage])

  useEffect(() => {
    cacheLogoImage()
    cacheBackgroundImage()
  }, [cacheLogoImage, cacheBackgroundImage])

  const value = {
    platformName,
    setPlatformName,
    loginPageWhiteLogoUrl,
    setLoginPageWhiteLogoUrl,
    primaryColor,
    setPrimaryColor,
    secondaryColor,
    setSecondaryColor,
    primaryDarker,
    setPrimaryDarker,
    secondaryDarker,
    setSecondaryDarker,
    secondaryLighter,
    setSecondaryLighter,
    isCustomBranded,
    setIsCustomBranded,
    loginPageBackgroundImage,
  }

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}
