import * as React from 'react'

import { CoachCard } from '../CoachCard'
import { CoachButton } from '../CoachButton'
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline'

export function InterviewReviewCard({ status }) {
  // TODO: Implement in_progress and completed (See ResumeReviewCard.js)
  const disabled = status !== 'unsubmitted'

  return (
    <CoachCard
      icon={ChatBubbleBottomCenterTextIcon}
      title="Interview Review"
      description="Your coach will work with you to review practice interviews and offer suggestions on how to answer relevant interview questions."
    >
      <CoachButton
        className="modal-remote"
        data-toggle="modal"
        data-url="/interview/interview_review/new"
        data-key="interview-review-modal-container"
        disabled={disabled}
        disabledText="Scheduled"
      >
        Submit Your Interview
      </CoachButton>
    </CoachCard>
  )
}
