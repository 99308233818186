import React from 'react'
import QuickStartPage from './QuickStartPage/edit'
import Tracker from '../tracker/Index'

export default function Orientation({ current_user }) {
  return (
    <>
      <QuickStartPage current_user={current_user} />
      <Tracker desiredTimeline={current_user.desired_timeline} />
    </>
  )
}
