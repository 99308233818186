import React from 'react'

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../lib/ui/../../../lib/ui/form'
import { Input } from '../../../lib/ui/input'

export function FileField({ name, label, accept, control, id }) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field: { value, onChange, ...fieldProps } }) => (
        <FormItem className="">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Input
              {...fieldProps}
              type="file"
              name={name}
              id={id}
              accept={accept}
              onChange={(e) => onChange(e.target.files && e.target.files[0])}
              className="!mt-0 block w-full rounded-md border border-gray-300 px-3 py-2 text-base shadow-sm focus:border-accent focus:ring-accent sm:text-sm"
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
