import * as React from 'react'
import { Box } from '@mui/joy'
import PasswordCritera from './PasswordCriteria'
import PasswordInput from '../PasswordInput'
import useTranslation from '../../../shared/TranslationProvider'

const SetPassword = ({
  password,
  setPassword,
  passwordConfirmation,
  setPasswordConfirmation,
  onKeyDown,
  errors,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Box className="control-container">
        <PasswordInput
          password={password}
          autoComplete="new-password"
          setPassword={setPassword}
          labelValue={t('password')}
          errors={errors}
        />
      </Box>
      <Box className="criteria-container">
        <PasswordCritera password={password} />
      </Box>

      <Box className="control-container">
        <PasswordInput
          password={passwordConfirmation}
          autoComplete="new-password"
          setPassword={setPasswordConfirmation}
          name="password_confirmation"
          labelValue={t('SetPassword.confirm_password')}
          onKeyDown={onKeyDown}
          errors={errors}
        />
      </Box>
    </>
  )
}

export default SetPassword
