import * as React from 'react'

import { Slot } from '@radix-ui/react-slot'
import { cva } from 'class-variance-authority'

import { cn } from '../utils'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-full text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-primary-button text-primary-foreground hover:bg-primary-button-darker-75',
        cd: 'bg-primary-button-cd text-primary-foreground hover:bg-primary-button-cd-darker-75',
        secondary:
          'bg-secondary-button text-secondary-foreground hover:bg-secondary-button-darker-75',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        ghost: 'rounded-md hover:bg-accent hover:text-accent-foreground',
        link: 'text-link underline-offset-4 hover:underline hover:text-link-hover',
      },
      size: {
        default: 'h-10 px-8 py-2',
        sm: 'h-9 px-3',
        lg: 'h-12 px-12 text-xl',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

const Button = React.forwardRef(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
;<Button className="h-10 w-7 rounded-md bg-transparent p-0 opacity-50 hover:opacity-100" />
