import React, { createContext, useContext, useState } from 'react'
import {
  useIsDesktop,
  useIsMobile,
  useMediaQueryScreen,
} from '../../hooks/useMediaQuery'

const MiniplatformContext = createContext()

export const useMiniplatform = () => {
  return useContext(MiniplatformContext)
}

export const MiniplatformProvider = ({
  coachingProgram,
  coachingPage,
  coachInfo,
  candidateInfo,
  calendlyEventsPath,
  children,
  groupSessionCoachInfo,
}) => {
  const page = coachingPage
  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()
  const isLargeScreen = useMediaQueryScreen('lg')
  const [showCalendly, setShowCalendly] = useState(false)
  const [showAssessmentDialog, setShowAssessmentDialog] = useState(false)
  const toggleAssessmentDialog = () => {
    setShowAssessmentDialog(!showAssessmentDialog)
  }
  const className = 'p-8 md:p-12'
  const {
    assessmentInfo,
    assessmentMissing,
    coachingProgramId,
    uploadUrl,
    imgUrl,
    aboutProgram,
    coachingResources,
    programType,
    pendingIndividualEvent,
    pendingGroupEvent,
    completedIndividualEvent,
    completedGroupEvent,
    individualSessionDescription,
    groupSessionDescription,
    includesPrivateSession,
    checkMarkUrl,
    showOneVOneCoach,
  } = coachingProgram

  const [individualEvent, setIndividualEvent] = useState(
    completedIndividualEvent
      ? { type: 'completed', ...completedIndividualEvent }
      : pendingIndividualEvent
        ? { type: 'pending', ...pendingIndividualEvent }
        : null,
  )

  const groupEvent = completedGroupEvent
    ? { type: 'completed', ...completedGroupEvent }
    : pendingGroupEvent
      ? { type: 'pending', ...pendingGroupEvent }
      : null

  const {
    coachBio,
    coachPhoto,
    coachCalendlyUrl,
    coachId,
    coachingProgressPath,
    coachingPath,
  } = coachInfo

  const coachName =
    programType === 'group' && !coachInfo.coachName
      ? 'Match in Progress'
      : coachInfo.coachName

  const maxWidth = 'md:max-w-[1450px]'

  const value = {
    maxWidth,
    assessmentInfo,
    candidateInfo,
    calendlyEventsPath,
    page,
    isMobile,
    isDesktop,
    assessmentMissing,
    coachingProgramId,
    uploadUrl,
    checkMarkUrl,
    coachBio,
    coachName,
    coachPhoto,
    coachingPath,
    className,
    coachCalendlyUrl,
    coachId,
    coachingProgressPath,
    imgUrl,
    aboutProgram,
    coachingResources,
    programType,
    individualSessionDescription,
    groupSessionDescription,
    individualEvent,
    setIndividualEvent,
    groupEvent,
    showCalendly,
    setShowCalendly,
    showAssessmentDialog,
    setShowAssessmentDialog,
    toggleAssessmentDialog,
    includesPrivateSession,
    groupSessionCoachInfo,
    showOneVOneCoach,
    isLargeScreen,
  }
  return (
    <MiniplatformContext.Provider value={value}>
      {children}
    </MiniplatformContext.Provider>
  )
}
