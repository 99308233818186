import * as React from 'react'

import { useJobSearch } from './JobSearchProvider'
import { ScrollArea } from '../../../lib/ui/scroll-area'
import JobDetails, { JobDetailsTop, JobDetailsBottom } from './JobDetails'
import JobCard from './JobCard'
import { Drawer, DrawerContent } from '../../../lib/ui/drawer'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { Button } from '../../../lib/ui/button'
import { Separator } from '../../../lib/ui/separator'
import ArrowLongLeftIcon from './ArrowLongLeftIcon'
import { useTranslation } from '../../../hooks/useTranslation'
import { ViewMoreButton } from './buttons'

export default function SearchResults() {
  const { t } = useTranslation('SearchResults')
  const {
    selectedJobDrawerOpen,
    setSelectedJobDrawerOpen,
    resultDetailScrollRef,
    noResultQuery,
    jobResults,
  } = useJobSearch()
  const isDesktop = useIsDesktop()
  const scrollAreaRef = React.useRef(null)

  React.useEffect(() => {
    const handleResize = () => {
      if (scrollAreaRef.current) {
        const rect = scrollAreaRef.current.getBoundingClientRect()
        const viewHeight = Math.max(
          document.documentElement.clientHeight,
          window.innerHeight,
        )
        const scrollHeight = document.documentElement.scrollHeight
        const scrollTop = document.documentElement.scrollTop
        const remainingScroll = scrollHeight - scrollTop - viewHeight

        let dynamicHeight = viewHeight - rect.top - 16
        const footerHeight = 160

        if (remainingScroll < footerHeight) {
          dynamicHeight -= footerHeight - remainingScroll
        }

        scrollAreaRef.current.style.height = `${dynamicHeight}px`
      }
    }

    window.addEventListener('scroll', handleResize)
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('scroll', handleResize)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const showResults = jobResults.length > 0

  if (noResultQuery) {
    return (
      <div className="mx-6 my-12 flex flex-col items-center justify-center ">
        <p className="text-balance text-center text-xl text-gray-500">
          {t('no_results')}
        </p>
        <p className="text-balance text-center text-xl text-gray-500">
          {t('no_results_suggestions')}
        </p>
      </div>
    )
  }

  if (!showResults) {
    return (
      <div className="m-12 flex flex-col items-center justify-center ">
        <p className="text-balance text-center text-xl text-gray-500">
          {t('get_started')}
        </p>
      </div>
    )
  }

  if (isDesktop) {
    return (
      <div className="flex w-full flex-row gap-2">
        <div className="min-h-[80vh] min-w-96 basis-2/5 pr-4">
          <JobCardList />
        </div>

        <div
          ref={scrollAreaRef}
          className="sticky top-32 flex basis-3/5 flex-col rounded-2xl border-2"
        >
          <div className="shadow-bottom relative shadow-md">
            <div className="flex flex-col gap-4 p-4">
              <JobDetailsTop />
            </div>
          </div>
          <ScrollArea
            className="mb-4 mr-1 mt-1 h-auto px-4"
            viewPortRef={resultDetailScrollRef}
          >
            <div className="py-4">
              <JobDetailsBottom />
            </div>
          </ScrollArea>
        </div>
      </div>
    )
  }

  return (
    <div className="flex w-full flex-col">
      <JobCardList />

      <Drawer
        direction="right"
        open={selectedJobDrawerOpen}
        onClose={setSelectedJobDrawerOpen}
      >
        <DrawerContent>
          <ScrollArea className="h-screen">
            <div className="mt-40 sm:mt-16">
              <Button
                type="button"
                variant="link"
                className="flex flex-row gap-2"
                onClick={() => setSelectedJobDrawerOpen(false)}
              >
                <ArrowLongLeftIcon />
                {t('return_to_list_of_jobs')}
              </Button>
            </div>
            <div className="fix-legacy-hack mb-16 p-4 font-roboto">
              <JobDetails />
            </div>
          </ScrollArea>
        </DrawerContent>
      </Drawer>
      <Separator className="mt-16" />
    </div>
  )
}

function JobCardList() {
  const { jobResults, showViewMoreButton, amountShown, amountTotal } =
    useJobSearch()

  return (
    <>
      <ul className="flex flex-col gap-4">
        {jobResults.map((job, i) => (
          <JobCard key={i} job={job} />
        ))}
      </ul>
      <div className="my-4 flex flex-col gap-4">
        <div className="text-center text-xs">
          Displaying {amountShown} of {amountTotal} jobs
        </div>
        {showViewMoreButton && <ViewMoreButton />}
      </div>
    </>
  )
}
