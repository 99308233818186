import * as React from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { Button } from '../../../lib/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../lib/ui/form'
import { Input } from '../../../lib/ui/input'
import { Textarea } from '../../../lib/ui/textarea'
import { Modal } from './Modal'
import { useTranslation } from '../../../hooks/useTranslation'
import { useSavedJobs } from './SavedJobsProvider'
import { useQueryClient } from '@tanstack/react-query'

export function OutsideJobFormModal({ children, savedJob = null }) {
  const { t } = useTranslation('OutsideJobFormModal')
  const [open, setOpen] = React.useState(false)

  const titleText = savedJob ? t('edit_job') : t('add_job')
  const title = <h2 className="text-2xl">{titleText}</h2>
  const content = <OutsideJobForm savedJob={savedJob} setOpen={setOpen} />
  const description = (
    <div className="mt-2 text-center">{t('all_fields_required')}</div>
  )

  return (
    <Modal
      title={title}
      description={description}
      content={content}
      open={open}
      setOpen={setOpen}
    >
      {children}
    </Modal>
  )
}

export function OutsideJobForm({ savedJob, setOpen }) {
  const { t } = useTranslation('OutsideJobForm')
  const { postSavedJobMutation, patchSavedJob, transformJob } = useSavedJobs()
  const queryClient = useQueryClient()

  const FormSchema = z.object({
    title: z.string().min(2, {
      message: t('title_validation'),
    }),
    company_name: z.string().min(2, {
      message: t('company_name_validation'),
    }),
    location: z.string().min(2, {
      message: t('location_validation'),
    }),
    url: z.string().min(2, {
      message: t('url_validation'),
    }),
    description: z.string().min(2, {
      message: t('description_validation'),
    }),
  })

  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      title: savedJob?.title || '',
      company_name: savedJob?.company_name || '',
      location: savedJob?.location || '',
      url: savedJob?.url || '',
      description: savedJob?.description || '',
    },
  })

  const placeholderJob = (data) => {
    return transformJob({
      ...data,
      id: Math.random(),
      created_at: new Date(),
      updated_at: new Date(),
      followup_on: null,
      status: 'saved',
    })
  }

  const createSavedJob = (data) => {
    queryClient.setQueryData(['get-saved-jobs'], (old) => [
      ...old,
      placeholderJob(data),
    ])
    postSavedJobMutation.mutate(data)
  }

  const updateSavedJob = (data) => {
    queryClient.setQueryData(['get-saved-jobs'], (old) =>
      old.map((job) => {
        if (job.id === savedJob.id) {
          return { ...job, ...data }
        }
        return job
      }),
    )
    patchSavedJob(savedJob, data)
  }

  const onSubmit = (data) => {
    if (savedJob) {
      updateSavedJob(data)
    } else {
      createSavedJob(data)
    }
    setOpen(false)
  }

  return (
    <div className="mx-2 justify-center lg:flex">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-6 lg:w-3/4"
        >
          <OutsideJobFormField
            form={form}
            name="title"
            initialValue={savedJob?.title}
          />
          <OutsideJobFormField form={form} name="company_name" />
          <OutsideJobFormField form={form} name="location" />
          <OutsideJobFormField form={form} name="url" />
          <OutsideJobFormField form={form} name="description" textarea />
          <div className="flex justify-center p-4">
            <Button type="submit">{t('submit')}</Button>
          </div>
        </form>
      </Form>
    </div>
  )
}

function OutsideJobFormField({ form, name, textarea = false }) {
  const { t } = useTranslation('OutsideJobForm')

  const TextComponent = textarea ? Textarea : Input

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{t(name)}</FormLabel>
          <FormControl>
            <TextComponent
              aria-required="true"
              placeholder={t(name)}
              {...field}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
