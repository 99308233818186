import React from 'react'
import Stepper from '@mui/joy/Stepper'
import { stepIndicatorClasses } from '@mui/joy/StepIndicator'
import CustomStep from './CustomStep'
import { stepClasses } from '@mui/joy/Step'

const Steps = ({ activeStep, numSteps }) => {
  const arr = Array.from({ length: numSteps }, (_, i) => i + 1)
  return (
    <Stepper
      size="lg"
      sx={{
        width: '31.25rem',
        margin: 'auto',
        '--StepIndicator-size': '2.5rem',
        '--Step-connectorInset': '0.9375rem',
        '--Step-horizontalGap': '0.3125rem',
        '--Step-connectorRadius': '0.625rem',
        '--Step-connectorThickness': '3px',
        [`& .${stepIndicatorClasses.root}`]: {
          borderWidth: 2,
        },
        [`& .${stepClasses.root}::after`]: {
          height: 4,
        },
        [`& .${stepClasses.completed}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: '#FFF',
            color: '#FFF',
          },
          '&::after': {
            bgcolor: '#08467C',
          },
        },
        [`& .${stepClasses.active}`]: {
          [`& .${stepIndicatorClasses.root}`]: {
            borderColor: '#08467C',
          },
          '&::after': {
            bgcolor: '#08467C',
          },
        },
        [`& .${stepClasses.disabled}`]: {
          '&::after': {
            bgcolor: '#6D6D6D',
          },
          color: '6D6D6D',
        },
      }}
    >
      {arr.map((step) => (
        <CustomStep
          orientation="vertical"
          activeStep={activeStep}
          stepNumber={step}
          key={step}
          className={`step${step}`}
        />
      ))}
    </Stepper>
  )
}
export default Steps
