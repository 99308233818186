import React from 'react'
import { useProgramEvaluation } from '../ProgramEvaluationProvider'
import { Checkboxes, TextareaField } from '../FormFields'
import {
  ManagerName,
  Company,
  Title,
  Employee,
  ImprovementAreasOther,
} from '../QuestionBank'

const Manager = ({ form }) => {
  const { managerImprovementAreas } = useProgramEvaluation()

  return (
    <>
      <ManagerName className={'required-text col-span-3'} form={form} />
      <Company className={'required-text col-span-3'} form={form} />
      <Title
        label="Your Current Job Title"
        className={'required-text col-span-3'}
        form={form}
      />
      <Employee
        label="Name of Employee"
        className={'required-text col-span-3'}
        form={form}
      />
      <Checkboxes
        name="improvement_areas"
        label="How do you believe this person will benefit from INTOO's coaching program?"
        choices={managerImprovementAreas}
        className={'col-span-6'}
        control={form.control}
      />
      <ImprovementAreasOther form={form} className={'col-span-6'} />
      <TextareaField
        name="program_notes"
        label="Please provide any other comments you may have related to INTOO’s coaching program."
        className={'col-span-6'}
        control={form.control}
      />
    </>
  )
}
export default Manager
