import React from 'react'

const Selected = ({ header, subtext, length, type, url }) => {
  return (
    <div>
      <div className="row tile-row">
        <div className="col-md-2 icon-container">
          <div className="track-item-icon">
            <i className={type}></i>
          </div>
        </div>
        <div style={{ paddingLeft: '0px' }} className="col-md-10 tile-content">
          <div className="tile-title">{header}</div>
          <div className="row">
            <div className="col-md-9 sub-column" style={{ padding: '0px' }}>
              <div className="tile-subtext">{subtext}</div>
            </div>
            <div
              className="col-md-3 sub-column"
              style={{
                padding: '20px',
                paddingLeft: '0px',
                textAlign: 'center',
                marginLeft: '-5px',
              }}
            >
              <a
                className="tile-btn"
                href={url ? url : ''}
                style={{
                  width: '10rem',
                  height: '3rem',
                  borderRadius: '5px',
                  padding: '5px 15px',
                  fontFamily: 'Roboto-Bold',
                  textDecoration: 'none',
                }}
              >
                {' '}
                Start{' '}
              </a>
              <div
                style={{
                  fontSize: '12px',
                  textAlign: 'center',
                  marginTop: '10px',
                }}
              >
                ({length})
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Selected
