import React from 'react'
import { useMiniplatform } from './MiniplatformProvider'
import { Paragraph, Link } from './styles'
import { Button } from '../../lib/ui/button'
import Moment from 'moment'
import { cn } from '../../lib/utils'

const EventCards = ({ className, children, type, colSpan }) => {
  const {
    coachName,
    coachPhoto,
    groupSessionDescription,
    individualSessionDescription,
    groupSessionCoachInfo,
  } = useMiniplatform()

  const name = type === 'Group' ? groupSessionCoachInfo.coachName : coachName
  const photo = type === 'Group' ? groupSessionCoachInfo.coachPhoto : coachPhoto

  const cardClass = 'border border-solid'

  const sessionDescription =
    type === 'Group' ? groupSessionDescription : individualSessionDescription

  const bgClass = type === 'Group' ? 'bg-[#B8EEFF]' : 'bg-[#CAFFA9]'

  return (
    <div
      className={cn(
        'relative min-h-[354px] max-w-[370px] rounded-[10px] bg-white shadow-miniplatform-event-tile',
        cardClass,
        colSpan && `col-span-${colSpan}`,
      )}
    >
      <div
        className={cn(
          'absolute right-2 top-2 flex w-[15%] justify-center rounded-[5px]',
          bgClass,
        )}
      >
        <Paragraph>{type}</Paragraph>
      </div>
      <div className={cn('flex flex-col gap-4 p-6', className)}>
        <CoachCardPhoto className={'h-12 w-12'} coachPhoto={photo} alt="">
          <div className="flex flex-col">
            <Paragraph className="font-bold">Coaching Session</Paragraph>
            <Paragraph>{name}</Paragraph>
          </div>
        </CoachCardPhoto>
        <div>
          <Paragraph className="font-bold">Meeting Topic</Paragraph>
          <Paragraph className="min-h-[100px]">{sessionDescription}</Paragraph>
        </div>
        {children}
      </div>
    </div>
  )
}

export const ScheduleCoachingCard = ({ colSpan }) => {
  const { setShowCalendly } = useMiniplatform()
  return (
    <EventCards className="h-full justify-between" type="1v1" colSpan={colSpan}>
      <div>
        <Paragraph className="font-bold">Meeting Length</Paragraph>
        <Paragraph>90 minute meeting</Paragraph>
      </div>
      <div className="mt-4 flex justify-center">
        <Button className="font-roboto" onClick={() => setShowCalendly(true)}>
          Schedule Meeting
        </Button>
      </div>
    </EventCards>
  )
}

export const MeetingDetailsCard = ({ eventInfo, colSpan }) => {
  const { coachingProgressPath, checkMarkUrl } = useMiniplatform()

  const { event, size } = eventInfo

  const eventDetails = event.start_time
    ? Moment(event.start_time).format('h:mma - dddd, MMMM Do, YYYY')
    : 'Meeting Pending'

  const completedEvent = event.type === 'completed'

  const type = size == 'group' ? 'Group' : '1v1'

  return (
    <EventCards type={type} colSpan={colSpan}>
      <div>
        <Paragraph className="font-bold">Event Date/Time</Paragraph>
        <Paragraph>{eventDetails}</Paragraph>
      </div>
      {completedEvent ? (
        <div className="flex items-center">
          <div className="flex w-[70%] flex-col gap-2">
            <Paragraph className="text-lg font-bold">
              Congrats, Session Completed!
            </Paragraph>
            <Link href={coachingProgressPath}>View your session notes</Link>
          </div>
          <img className="h-10" src={checkMarkUrl} alt="" />
        </div>
      ) : (
        <>
          <div>
            <Paragraph className="font-bold">Meeting Length</Paragraph>
            <Paragraph>90 minute meeting</Paragraph>
          </div>
          <div className="mt-4 flex justify-center">
            <a target="_blank" rel="noreferrer" href={event.join_url}>
              <Button className="font-roboto">Join Meeting</Button>
            </a>
          </div>
        </>
      )}
    </EventCards>
  )
}

const CoachCardPhoto = ({ className, coachPhoto, children, alt }) => {
  return (
    <div className="flex items-center gap-2">
      <img
        className={cn('h-20 rounded-full', className)}
        src={coachPhoto}
        alt={alt}
      />
      {children}
    </div>
  )
}
