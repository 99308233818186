import * as React from 'react'

import { useTranslation } from '../../../hooks/useTranslation'
import LeaveSiteWarning from '../../shared/LeaveSiteWarning'
import { LinkedInButton, ApplyNowButton } from '../JobSearchPage/buttons'
import IndeedLogo from '../JobSearchPage/IndeedLogo'
import Sanitize from '../Sanitize'
import { FollowupButton } from './FollowupButton'
import { Modal } from './Modal'
import { ScrollArea } from '../../../lib/ui/scroll-area'
import { Button } from '../../../lib/ui/button'
import { Edit } from 'lucide-react'
import { OutsideJobFormModal } from './OutsideJobFormModal'
import { Activity } from './Activity'

export function DetailsModal({ children, savedJob, rowIndex, updateData }) {
  const [open, setOpen] = React.useState(false)

  const expired = savedJob.job_posting_id === null && savedJob.is_job_posting

  return (
    <Modal
      content={
        <>
          <JobDetailsTop
            job={savedJob}
            rowIndex={rowIndex}
            updateData={updateData}
            active={!expired}
          />
          <JobDetailsBottom job={savedJob} />
        </>
      }
      open={open}
      setOpen={setOpen}
      variant="noHeader"
    >
      {children}
    </Modal>
  )
}

function JobDetailsTop({ job, rowIndex, updateData, active }) {
  const { t } = useTranslation('DetailsModal')

  return (
    <div className="flex flex-col gap-4 text-left">
      <div className="relative flex flex-row justify-between font-light">
        {!!job.posted_on_month_day && (
          <div className="text-xs">
            {t('posted')} {job.posted_on_month_day}
          </div>
        )}
      </div>

      <div className="flex flex-col gap-1">
        <h2 className="flex items-center gap-4 text-xl font-bold">
          <div>{job.title}</div>
          <EditButton job={job} />
        </h2>

        <div className="flex items-center text-sm">
          <LeaveSiteWarning url={job.url}>
            <a className="">{job.company_name}</a>
          </LeaveSiteWarning>
          <span className="mx-2">•</span>
          <div className="font-normal">{job.location}</div>
        </div>
      </div>

      <div className="flex flex-row justify-start gap-3 sm:gap-4">
        {active && <ApplyNowButton job={job} />}
        <LinkedInButton job={job} />
      </div>

      {active && (
        <>
          <div className="flex text-base font-bold">{t('follow_up')}:</div>

          <div className="flex">
            <FollowupButton
              rowIndex={rowIndex}
              savedJob={job}
              updateData={updateData}
              variant="outline"
            />
          </div>
        </>
      )}

      {!active && (
        <div className="mt-4 text-lg italic">{t('no_longer_active')}</div>
      )}
    </div>
  )
}

function JobDetailsBottom({ job }) {
  const isIndeed = job.job_publisher_name === 'Indeed'
  const { t } = useTranslation('DetailsModal')

  return (
    <div className="p-4 sm:px-0">
      <ScrollArea className="h-[calc(100vh-500px)]">
        <div className="flex flex-col gap-4 md:flex-row">
          <div className="text-base">
            <Sanitize html={job.description} addMarkup />
            {isIndeed && (
              <LeaveSiteWarning url={job.url}>
                <div className="mb-4 mt-16 flex flex-row gap-1 text-sm text-intoo-blue-medium">
                  {t('jobs_by')} <IndeedLogo className="-mt-1" />
                </div>
              </LeaveSiteWarning>
            )}
          </div>
          <div>
            <Activity job={job} />
          </div>
        </div>
      </ScrollArea>
    </div>
  )
}

function EditButton({ job }) {
  if (job.is_job_posting) return null

  return (
    <OutsideJobFormModal savedJob={job}>
      <Button variant="ghost" size="icon">
        <Edit size={16} />
      </Button>
    </OutsideJobFormModal>
  )
}
