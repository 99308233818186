import React, { useState, useEffect } from 'react'
import { Box, Button, Link } from '@mui/joy'
import PasswordInput from './PasswordInput'
import SsoButtons from './Sso/SsoButtons'
import EmailInput from './EmailInput'
import LegalAgreement from './LegalAgreement'
import { SpinnerWhite } from '../../shared/Spinner'
import { useData } from './DataProvider'
import { useAlert } from './AlertModal'
import useTranslation from '../../shared/TranslationProvider'
import useTheme from '../../shared/ThemeProvider'

export default function LoginForm() {
  const {
    handleFormResponse,
    setFormState,
    oauthEnabled,
    googleAuthEnabled,
    appleAuthEnabled,
    getApi,
    isLoading,
    setIsLoading,
    appleOauthAttachEmail,
    googleOauthAttachEmail,
    newLegalNotice,
  } = useData()
  const { setAlertState, setAlertData } = useAlert()
  const { t } = useTranslation()
  const { platformName } = useTheme()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})

  const oauthAttachEmail = !!(appleOauthAttachEmail || googleOauthAttachEmail)

  const handleLogin = () => {
    setIsLoading(true)
    asyncHandleLogin()
  }

  const cancelSso = () => {
    window.location.href = '/op_login?cancel_sso=true'
  }

  useEffect(() => {
    const log_in = t('LoginForm.log_in_button')
    const queryString = decodeURI(window.location.search)
    const urlParams = new URLSearchParams(queryString)
    if (appleOauthAttachEmail) {
      setEmail(appleOauthAttachEmail)
      setAlertData(t('LoginForm.apple_account_already_exists', { log_in }))
      setAlertState('notice')
    } else if (googleOauthAttachEmail) {
      setEmail(googleOauthAttachEmail)
      setAlertData(t('LoginForm.google_account_already_exists', { log_in }))
      setAlertState('notice')
    } else if (urlParams.has('user[email]')) {
      setEmail(urlParams.get('user[email]'))
    }
  }, [
    appleOauthAttachEmail,
    googleOauthAttachEmail,
    setAlertData,
    setAlertState,
    setEmail,
    t,
  ])

  const asyncHandleLogin = async () => {
    try {
      const response = await getApi().post('/login', {
        user: { email, password },
      })
      handleFormResponse(response)
    } catch (error) {
      setIsLoading(false)
      if (error?.response?.data?.message) {
        if (error.response.data.redirectUrl) {
          window.location = error.response.data.redirectUrl
        } else {
          setAlertData(error.response.data.message)
          setAlertState('alert')
        }
      } else {
        setAlertData(t('LoginForm.login_failure'))
        setAlertState('alert')
      }
    }
  }

  const h1Text = () => {
    if (appleOauthAttachEmail) {
      return t('LoginForm.attach_apple_id')
    } else if (googleOauthAttachEmail) {
      return t('LoginForm.attach_google_account')
    } else {
      return `${platformName} ${t('LoginForm.log_in_h1')}`
    }
  }

  return (
    <Box className="login-form">
      <Box>
        <h1 className="text-balance">{h1Text()}</h1>
      </Box>
      {!oauthAttachEmail && (
        <Box className="subtext-container link-container">
          {t('LoginForm.dont_have_account')}{' '}
          <Link onClick={() => setFormState('InviteCodeEntry')}>
            {t('LoginForm.register_link')}
          </Link>
        </Box>
      )}
      <Box className="control-container">
        <EmailInput
          email={email}
          setEmail={setEmail}
          errors={errors}
          setErrors={setErrors}
          disabled={!!oauthAttachEmail}
        />
      </Box>
      <Box className="control-container">
        <PasswordInput
          password={password}
          setPassword={setPassword}
          labelValue={t('password')}
          errors={errors}
          autoComplete="current-password"
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleLogin()
          }}
        />
        <Box
          className="link-container"
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
          }}
        >
          <Link onClick={() => setFormState('ForgotPassword')}>
            {t('forgot_password')}
          </Link>
        </Box>
      </Box>
      <Box className="button-container">
        <Button
          className="submit-button btn"
          size="lg"
          fullWidth
          onClick={handleLogin}
          disabled={isLoading}
        >
          {t('LoginForm.log_in_button')}
          <SpinnerWhite show={isLoading} className="pl-4" />
        </Button>
        {oauthAttachEmail && (
          <Button
            className="back-button btn"
            sx={{ marginTop: '1.5rem' }}
            fullWidth
            onClick={cancelSso}
          >
            {t('go_back')}
          </Button>
        )}
      </Box>
      {oauthEnabled && !oauthAttachEmail && (
        <SsoButtons
          showGoogle={googleAuthEnabled}
          showApple={appleAuthEnabled}
        />
      )}
      <LegalAgreement
        actionText={t('LoginForm.log_in_button')}
        htmlText={newLegalNotice}
      />
    </Box>
  )
}
