import React from 'react'
import ActionPlan from '../ActionPlan/index'
const Tracker = ({ ...props }) => {
  return (
    <div className="platform-home-container">
      <div className="my-track flex flex-col px-8 py-4">
        <ActionPlan {...props} />
      </div>
    </div>
  )
}
export default Tracker
