import React from 'react'
import {
  SelectField,
  TextareaField,
  Radios,
  TextField,
  IntegerField,
  SliderField,
} from './FormFields'
import { cn } from '../../../../lib/utils'

export const FirstName = ({ form, className }) => {
  return (
    <TextField
      name="first_name"
      label="First Name"
      control={form.control}
      className={cn('', className)}
    />
  )
}

export const LastName = ({ form, className }) => {
  return (
    <TextField
      name="last_name"
      label="Last Name"
      control={form.control}
      className={cn('', className)}
    />
  )
}

export const Email = ({ form, className }) => {
  return (
    <TextField
      name="email"
      label="Email"
      control={form.control}
      className={cn('', className)}
    />
  )
}

export const LinkedInUrl = ({ form, className }) => {
  return (
    <TextField
      name="linkedin_url"
      label="LinkedIn Profile URL"
      control={form.control}
      className={cn('', className)}
    />
  )
}

export const Title = ({ form, className, label }) => {
  return (
    <TextField
      name="title"
      label={label || 'Current Job Title'}
      className={cn('', className)}
      control={form.control}
    />
  )
}

export const YearsInRole = ({ form, className }) => {
  return (
    <IntegerField
      name="years_in_role"
      label="Years in current role"
      min="0"
      control={form.control}
      className={cn('', className)}
    />
  )
}

export const Manager = ({ form, className, managerEval }) => {
  return (
    <TextField
      name="manager"
      label={managerEval ? 'Your Name' : 'Name of Manager'}
      className={cn('', className)}
      control={form.control}
    />
  )
}

export const CoachingProgramName = ({ form, className, ...props }) => {
  return (
    <TextField
      name="coaching_program_name"
      label="Coaching Program"
      className={cn('', className)}
      control={form.control}
      {...props}
    />
  )
}

export const CoachingGoals = ({ form, label }) => {
  return (
    <TextareaField
      name="coaching_goals"
      label={label}
      control={form.control}
      className={'col-span-6'}
    />
  )
}

export const PreviouslyWorkedWithCoach = ({ form }) => {
  const choices = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ]
  return (
    <SelectField
      name="previously_worked_with_coach"
      label="Have you worked with a career coach before?"
      control={form.control}
      className={'col-span-6 md:col-span-3'}
      choices={choices}
    />
  )
}

export const IsCoachHelpful = ({ form }) => {
  const choices = [
    { value: '5', label: 'Very Helpful' },
    { value: '4', label: 'Somewhat Helpful' },
    { value: '3', label: 'Helpful Helpful' },
    { value: '2', label: 'Slightly Helpful' },
    { value: '1', label: 'Not Helpful' },
  ]
  return (
    <SelectField
      name="is_coach_helpful"
      label="How helpful do you feel it will be to work with a career coach?"
      control={form.control}
      className={'col-span-6 md:col-span-3'}
      choices={choices}
    />
  )
}

export const GrowthExpectation = ({ form }) => {
  return (
    <TextareaField
      name="growth_expectation"
      label="How would you like to grow within your current role at your company?"
      control={form.control}
      className={'col-span-6'}
    />
  )
}

export const CoachNotes = ({ form }) => {
  return (
    <TextareaField
      name="coach_notes"
      label="Please share any other notes you’d like your coach to know as part of your upcoming coaching program."
      control={form.control}
      className={'col-span-6'}
    />
  )
}
export const MeetingTimes = ({ form }) => {
  return (
    <TextareaField
      name="meeting_times"
      label="Tell us the days/times that are generally best for you to meet with your coach:"
      control={form.control}
      className={'col-span-6'}
      innerClassName={'h-12 min-h-12'}
    />
  )
}

export const LongTermGoals = ({ form }) => {
  return (
    <TextareaField
      name="long_term_goals"
      label="It is helpful to share some of your goals and aspirations with your coach. Share with us how you feel your executive coaching program can help you grow as a leader. Where do you hope to be in your leadership journey in the next year and how do you envision your leadership style developing?"
      control={form.control}
      className={'col-span-6'}
    />
  )
}

export const OrganizationalCulture = ({ form }) => {
  const choices = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ]
  return (
    <Radios
      name="organizational_culture"
      label="Are you interested in discussing organizational culture challenges that affect your leadership with your coach?"
      choices={choices}
      control={form.control}
      className={'col-span-6'}
    />
  )
}

export const StressAndStrategies = ({ form }) => {
  const choices = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ]
  return (
    <Radios
      name="stress_and_strategies"
      label="Are you interested in working with your coach to help with challenging and/or stressful situations that are affecting your leadership?"
      choices={choices}
      control={form.control}
      className={'col-span-6'}
    />
  )
}

export const ClearGoals = ({ form, className }) => {
  const choices = [
    { value: '0', label: 'Never' },
    { value: '1', label: 'Almost Never' },
    { value: '2', label: 'Rarely' },
    { value: '3', label: 'Sometimes' },
    { value: '4', label: 'Often' },
    { value: '5', label: 'Almost Always' },
    { value: '6', label: 'Always' },
  ]
  return (
    <SelectField
      name="clear_goals"
      label="I feel that I have clear goals"
      control={form.control}
      className={cn('', className)}
      choices={choices}
    />
  )
}

export const SelfConscious = ({ form, className }) => {
  const choices = [
    { value: '6', label: 'Always' },
    { value: '5', label: 'Almost Always' },
    { value: '4', label: 'Often' },
    { value: '3', label: 'Sometimes' },
    { value: '2', label: 'Rarely' },
    { value: '1', label: 'Almost Never' },
    { value: '0', label: 'Never' },
  ]
  return (
    <SelectField
      name="self_conscious"
      label="I feel self-conscious"
      description="Notice the options are reversed on this one"
      control={form.control}
      className={cn('', className)}
      choices={choices}
    />
  )
}

export const InControl = ({ form, className }) => {
  const choices = [
    { value: '0', label: 'Never' },
    { value: '1', label: 'Almost Never' },
    { value: '2', label: 'Rarely' },
    { value: '3', label: 'Sometimes' },
    { value: '4', label: 'Often' },
    { value: '5', label: 'Almost Always' },
    { value: '6', label: 'Always' },
  ]
  return (
    <SelectField
      name="in_control"
      label="I feel in control"
      control={form.control}
      className={cn('', className)}
      choices={choices}
    />
  )
}

export const TimeManagement = ({ form, className }) => {
  const choices = [
    { value: '0', label: 'Never' },
    { value: '1', label: 'Almost Never' },
    { value: '2', label: 'Rarely' },
    { value: '3', label: 'Sometimes' },
    { value: '4', label: 'Often' },
    { value: '5', label: 'Almost Always' },
    { value: '6', label: 'Always' },
  ]
  return (
    <SelectField
      name="time_management"
      label="I lose track of time"
      control={form.control}
      className={cn('', className)}
      choices={choices}
    />
  )
}

export const SelfAwareness = ({ form, className }) => {
  const choices = [
    { value: '0', label: 'Never' },
    { value: '1', label: 'Almost Never' },
    { value: '2', label: 'Rarely' },
    { value: '3', label: 'Sometimes' },
    { value: '4', label: 'Often' },
    { value: '5', label: 'Almost Always' },
    { value: '6', label: 'Always' },
  ]
  return (
    <SelectField
      name="self_awareness"
      label="I feel I know how well I am doing"
      control={form.control}
      className={cn('', className)}
      choices={choices}
    />
  )
}

export const Concentration = ({ form, className }) => {
  const choices = [
    { value: '0', label: 'Never' },
    { value: '1', label: 'Almost Never' },
    { value: '2', label: 'Rarely' },
    { value: '3', label: 'Sometimes' },
    { value: '4', label: 'Often' },
    { value: '5', label: 'Almost Always' },
    { value: '6', label: 'Always' },
  ]
  return (
    <SelectField
      name="concentration"
      label="I have a high level of concentration"
      control={form.control}
      className={cn('', className)}
      choices={choices}
    />
  )
}

export const WorkLifeBalance = ({ form, className }) => {
  const choices = [
    { value: '0', label: 'Never' },
    { value: '1', label: 'Almost Never' },
    { value: '2', label: 'Rarely' },
    { value: '3', label: 'Sometimes' },
    { value: '4', label: 'Often' },
    { value: '5', label: 'Almost Always' },
    { value: '6', label: 'Always' },
  ]
  return (
    <SelectField
      name="work_life_balance"
      label="I forget personal problems when I am working"
      control={form.control}
      className={cn('', className)}
      choices={choices}
    />
  )
}

export const Involved = ({ form, className }) => {
  const choices = [
    { value: '0', label: 'Never' },
    { value: '1', label: 'Almost Never' },
    { value: '2', label: 'Rarely' },
    { value: '3', label: 'Sometimes' },
    { value: '4', label: 'Often' },
    { value: '5', label: 'Almost Always' },
    { value: '6', label: 'Always' },
  ]
  return (
    <SelectField
      name="involved"
      label="I feel fully involved in my activities"
      control={form.control}
      className={cn('', className)}
      choices={choices}
    />
  )
}

export const Goals = ({ form, className }) => {
  return (
    <TextField
      name="goals"
      label="What are your goals for this coaching session?"
      control={form.control}
      className={cn('', className)}
    />
  )
}
export const DefinitionOfSuccess = ({ form, className }) => {
  return (
    <TextField
      name="definition_of_success"
      label="What is your definition of success for our time together?"
      control={form.control}
      className={cn('', className)}
    />
  )
}
export const CurrentChallenges = ({ form, className }) => {
  return (
    <TextField
      name="current_challenges"
      label="What are your current challenges?"
      control={form.control}
      className={cn('', className)}
    />
  )
}
export const OtherTopics = ({ form, className }) => {
  return (
    <TextField
      name="other_topics"
      label="Anything else you'd like us to know?"
      control={form.control}
      className={cn('', className)}
    />
  )
}

export const ManagerName = ({ form, className }) => {
  return (
    <TextField
      name="manager"
      label="Your Name"
      className={cn('', className)}
      control={form.control}
    />
  )
}

export const Employee = ({ form, name, label, className }) => {
  return (
    <TextField
      name={name || 'employee'}
      className={cn('', className)}
      label={label || 'Your Name'}
      control={form.control}
    />
  )
}

export const Company = ({ form, label, className }) => {
  return (
    <TextField
      name="company"
      className={cn('', className)}
      label={label || 'Company'}
      control={form.control}
    />
  )
}

export const CoachProgramExperience = ({ form, className, label }) => {
  return (
    <SliderField
      name="coach_program_experience"
      min={1}
      max={5}
      minDescription="Not Valuable"
      maxDescription={'Extremely Valuable'}
      label={
        label ||
        'Overall, how valuable was your coaching program and experience?'
      }
      className={cn('col-span-6', className)}
      control={form.control}
    />
  )
}

export const ImprovementAreasOther = ({ form, className }) => {
  return (
    <TextField
      name="improvement_areas_other"
      label="Other"
      className={cn('', className)}
      control={form.control}
    />
  )
}

export const ProgramRating = ({
  form,
  label,
  minDescription,
  maxDescription,
  className,
}) => {
  return (
    <SliderField
      name="program_rating"
      min={1}
      max={3}
      minDescription={minDescription || 'Did not meet my expectations'}
      maxDescription={maxDescription || 'Exceeded my expectations'}
      label={label || 'The coaching program:'}
      className={cn('col-span-6', className)}
      control={form.control}
    />
  )
}

export const ProgramRatingDetails = ({ form, className }) => {
  return (
    <TextareaField
      name="program_rating_details"
      label="Please let us know why you rated the program this way."
      className={cn('col-span-6', className)}
      control={form.control}
    />
  )
}

export const RecommendProgram = ({ form, className }) => {
  return (
    <SliderField
      name="recommend_program"
      min={1}
      max={5}
      minDescription="Very Unlikely"
      maxDescription="Definitely"
      label="Would you recommend this program to your colleagues?"
      className={cn('col-span-6', className)}
      control={form.control}
    />
  )
}

export const SuggestedImprovements = ({ form, className, label }) => {
  return (
    <TextareaField
      name="suggested_improvements"
      label={
        label ||
        'Do you have any suggestions for how we can improve our coaching program?'
      }
      className={cn('col-span-6', className)}
      control={form.control}
    />
  )
}

export const AdditionalComments = ({ form, label, className }) => {
  return (
    <TextareaField
      name="additional_comments"
      label={
        label ||
        'Please provide any other comments you may have related to INTOO’s coaching program.'
      }
      className={cn('col-span-6', className)}
      control={form.control}
    />
  )
}

export const PersonalGoals = ({ form, label, className }) => {
  return (
    <SliderField
      name="personal_goals_rating"
      label={
        label ||
        'In working with your career coach, to what degree do you feel you have achieved your personal goals?'
      }
      control={form.control}
      min={1}
      max={5}
      minDescription="Need More Time"
      maxDescription="Very Successful"
      className={cn(className, 'col-span-6')}
    />
  )
}
