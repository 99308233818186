import React from 'react'
import { Box, Link } from '@mui/joy'
import useTranslation from '../../shared/TranslationProvider'

const ContactUsLink = () => {
  const { t, locale } = useTranslation()
  const contactPath = `/${locale}/contact`
  return (
    <Box
      className="link-container subtext-bottom-container"
      sx={{
        '--textColor': '#000',
      }}
    >
      {t('having_problems')}{' '}
      <Link href={contactPath} target="_blank">
        {t('contact_us')}
      </Link>
    </Box>
  )
}

export default ContactUsLink
