import * as React from 'react'
import { CoachButton } from '../../coach_services/OutplacementBenefits/CoachButton.js'
import { cn } from '../../../lib/utils.js'

export function CoachSupportCard({
  coach_image,
  time_since_last_check_in,
  className,
}) {
  return (
    <div
      className={cn(
        'relative rounded-[20px] border border-[#B1B1B1] p-6 shadow-md',
        className,
      )}
    >
      <div className="flex h-full flex-col items-center justify-center text-cod-gray-800">
        <img
          className="mb-[16px] h-[75px] w-[75px] rounded-[50%]"
          src={coach_image}
          alt="Coach Image"
        />
        <h2 className="mb-[16px] text-2xl font-bold text-intoo-blue-medium">
          Coach Support
        </h2>
        <CoachButton
          className="open-chat-modal mb-[4px]"
          data-toggle="modal"
          data-target="#coach_chat_list"
          data-action="Upper Start a Coach Chat"
        >
          Chat With Your Coach
        </CoachButton>
        {time_since_last_check_in && <div>{time_since_last_check_in}</div>}
      </div>
    </div>
  )
}
