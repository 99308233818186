import React from 'react'

const InterestCard = ({ title, onClick, active, children }) => {
  const extraClasses = active ? 'text-[#fff] bg-primary' : ''

  return (
    <a
      onKeyPress={(key) =>
        ([32, 13].includes(key.charCode) && onClick()) || key.preventDefault()
      }
    >
      <div
        className={`shadow-[0_2px_4px_0px_rgba(0, 0, 0, 0.15)] mx-auto flex h-full w-24 cursor-pointer flex-col items-center justify-evenly rounded-[5px] border-2 border-solid border-primary p-2 text-center text-xs sm:w-40 sm:p-4 md:text-sm ${extraClasses}`}
        onClick={onClick}
      >
        {children}
        <div className="mt-1 sm:mt-2">{title}</div>
      </div>
    </a>
  )
}
export default InterestCard
