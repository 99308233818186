import React from 'react'
import { Button } from '../../lib/ui/button'

const LayoutFourOptions = ({ options, handleOptionClick, quizIndex }) => {
  return (
    options.length === 4 && (
      <div className="flex flex-col sm:flex-row">
        <div className="question-options flex flex-col gap-6">
          {options.map((option, index) => (
            <Button
              key={`response-${index}`}
              id={`btn-${quizIndex + 1}-${index}`}
              onClick={() => handleOptionClick(index)}
              className="rounded-[10px] "
            >
              {option}
            </Button>
          ))}
        </div>
      </div>
    )
  )
}
export default LayoutFourOptions
