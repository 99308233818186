import * as React from 'react'
import { CoachHeader } from '../CoachHeader'
import HeadShot from './HeadShot'
import Accolade from './Accolade'
import { TranslationProvider } from '../../shared/TranslationProvider'
import translations from '../locales.json'
import { useTranslation } from '../../../hooks/useTranslation'

const Index = ({ locale, ...props }) => {
  return (
    <TranslationProvider
      locale={locale}
      translations={translations}
      scope="javascript.components.coach_services.OutplacementBenefits.MeetYourCoach"
    >
      <MeetYourCoach {...props} />
    </TranslationProvider>
  )
}
export default Index

function MeetYourCoach({ coach, coach_photo, coach_bio, accolades }) {
  const { yearsExperience, numClientsSupported, numRatings } = accolades

  const numReviews = numRatings > 25 ? numRatings : 25

  const satisfactionRating =
    accolades.satisfactionRating >= 90 ? accolades.satisfactionRating : 90

  const { t } = useTranslation()

  return (
    <div className="m-12 flex flex-col gap-10 font-roboto">
      <div className="flex flex-col gap-6">
        <CoachHeader
          title="Meet Your Personal Coach"
          description="Learn about your coach and their history."
        />

        <div className="grid grid-cols-1 gap-6 xl:grid-cols-3 2xl:grid-cols-[340px_repeat(3,_minmax(0,_1fr))]">
          <div className="flex xl:col-span-3 2xl:col-span-1 2xl:row-span-2">
            <div className="flex flex-col items-center">
              <HeadShot
                className="h-[250px] w-[250px]"
                coachImage={coach_photo}
              />
              <h2 className="block bg-gradient-to-r from-intoo-green-coachcta to-intoo-green-bright bg-clip-text text-center font-ibm-plex text-5xl font-bold uppercase text-transparent">
                {coach.first_name + ' ' + coach.last_name}
              </h2>
              <p className="text-center text-2xl font-medium text-[#4a4a4a]">
                Your Dedicated Career Coach
              </p>
            </div>
          </div>
          <Accolade
            rating={`${yearsExperience}+`}
            title={t('accolades.yearsExperience.title')}
            description={t('accolades.yearsExperience.description', {
              first_name: coach.first_name,
              years: yearsExperience,
            })}
          />
          <Accolade
            rating={`${numClientsSupported}+`}
            title={t('accolades.clientsSupported.title')}
            description={t('accolades.clientsSupported.description', {
              first_name: coach.first_name,
            })}
          />
          <Accolade
            rating={`${satisfactionRating}%`}
            title={t('accolades.satisfactionRating.title')}
            description={t('accolades.satisfactionRating.description', {
              first_name: coach.first_name,
              numReviews: numReviews,
              satisfactionRating: satisfactionRating,
            })}
          />
          <div className="border-t border-cod-gray-200 pt-6 xl:col-span-3">
            <div className="rounded-3xl bg-gradient-to-r from-intoo-green-coachcta to-intoo-green-bright px-24 py-10 font-roboto-serif text-lg italic text-white">
              &ldquo;A dedicated person who sees hidden potential and maximizes
              performance by encouraging, developing and believing.&rdquo;
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-6">
          <h2 className="text-3.5xl font-bold text-intoo-green-coachcta">
            About {coach.first_name}:
          </h2>
          <p>{coach_bio}</p>
        </div>
      </div>
    </div>
  )
}
