import * as React from 'react'
import { JobsIcon, DoorIcon } from '../Icons'
import { cn } from '../../../lib/utils'
import { Button } from '../../../lib/ui/button'

export function MyJobsCard({ applied_job_count, className }) {
  return (
    <div
      className={cn(
        'relative rounded-[20px] border border-[#B1B1B1] p-6 shadow-md',
        className,
      )}
    >
      <div className="flex h-full flex-col gap-[12px] text-cod-gray-800">
        <div className="flex">
          <div className="flex-grow">
            <h2 className="mb-[16px] text-2xl font-bold text-primary">
              Explore My Jobs
            </h2>
            <div className="mb-[16px]">
              View all of your jobs and track your activities.
            </div>
            <div className="mb-[16px] font-bold">
              Number of Applied Jobs This Week:
            </div>
            <div className="mb-[16px] font-bold">{applied_job_count}</div>
          </div>
          <div className="mt-[10px]">
            <JobsIcon />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="absolute bottom-[16px] right-[24px]">
            <Button variant="link" asChild className="h-0 px-0 py-0">
              <a href="job-search/saved_jobs" className="ga-trackable">
                Go to My Jobs
                <DoorIcon className="ml-2 inline-flex" />
              </a>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
