import React from 'react'
import { MainBox, EvalForm, SubmitButton, ImprovementAreas } from './UiLib'
import { useProgramEvaluation } from './ProgramEvaluationProvider'
import { useProgramEvalForm } from './useProgramEvalForm'
import { ThankYouPage, EvalCompletedPage } from './ThankYouPage'
import {
  CoachProgramExperience,
  ProgramRating,
  ProgramRatingDetails,
  AdditionalComments,
  Employee,
  Company,
} from './QuestionBank'
import { TextareaField } from './FormFields'
import { cn } from '../../../../lib/utils'

const MidProgramEvaluation = () => {
  const { thankYouPage, completed } = useProgramEvaluation()
  const boxClasses = thankYouPage ? 'flex flex-col items-center gap-12' : ''

  const showForm = !thankYouPage && !completed

  return (
    <MainBox className={cn('bg-white p-12', boxClasses)}>
      {thankYouPage && <ThankYouPage />}
      {completed && <EvalCompletedPage />}
      {showForm && <MidProgramEvalQuestions />}
    </MainBox>
  )
}

export default MidProgramEvaluation

const MidProgramEvalQuestions = () => {
  const { peakPerformance, postProgramImprovementAreas } =
    useProgramEvaluation()
  const programName = peakPerformance ? 'Peak Performance' : 'career'
  const { form, onSubmit, submitted } = useProgramEvalForm()
  const formProps = { form, onSubmit }
  return (
    <EvalForm {...formProps}>
      <Employee
        name="name"
        form={form}
        label={'Name'}
        className={'required-text col-span-6 md:col-span-3'}
      />
      <Company
        className={'required-text col-span-6 md:col-span-3'}
        form={form}
      />
      <CoachProgramExperience
        label={`How valuable has your ${programName} coaching program experience been so far?`}
        form={form}
      />
      {!peakPerformance && (
        <>
          <div className="col-span-6">
            Please indicate the areas in which you have experienced individual
            development and growth. Please rate the level of growth you have
            experienced so far:
          </div>
          <ImprovementAreas
            form={form}
            improvementAreas={postProgramImprovementAreas}
            minDescription={'No Improvement'}
            maxDescription={'Huge Improvement'}
            keyName={'mid-program'}
          />
          <ProgramRating
            form={form}
            label={'The coaching program is currently:'}
            minDescription={'Not meeting my expectations'}
            maxDescription={'Exceeding my expectations'}
          />
          <ProgramRatingDetails
            form={form}
            label={'Please let us know why you rated the program this way.'}
          />
        </>
      )}
      {peakPerformance && (
        <TextareaField
          name="most_valuable"
          control={form.control}
          label="What has been the most valuable part of the program so far?"
          className="col-span-6"
        />
      )}
      <AdditionalComments
        form={form}
        label={
          peakPerformance
            ? 'Any feedback you would like to share to make the program better?'
            : 'Please provide any other comments you may have related to INTOO’s coaching program.'
        }
      />
      <SubmitButton disabled={submitted}>Continue</SubmitButton>
    </EvalForm>
  )
}
