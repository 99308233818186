import React from 'react'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useTranslation } from '../../../hooks/useTranslation'
import UpcomingTileHeader from './UpcomingTileHeader'

const CollapsedUpcomingTile = ({ tileInfo, expanded, setExpanded }) => {
  // const [selected, setSelected] = useState(0)
  const sectionName = Object.keys(tileInfo)[0]
  const collapsed = !expanded
  const isDesktop = useIsDesktop()
  const alignItems = isDesktop ? '' : ' items-center'
  const { t } = useTranslation()
  // const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0 })

  // console.log(scrollPosition)

  return (
    collapsed && (
      <div
        className={`mb-[24px] flex flex-col justify-between border-b border-solid border-cod-gray-200 pb-[24px]`}
      >
        <UpcomingTileHeader
          sectionName={sectionName}
          setExpanded={setExpanded}
          expanded={expanded}
          tileInfo={tileInfo}
          t={t}
        />
      </div>
    )
  )
}
export default CollapsedUpcomingTile
