import * as React from 'react'

import { ResumeReviewCard } from './cards/ResumeReviewCard'
import { CoachHeader } from '../CoachHeader'
import { CareerConsultationCallCard } from './cards/CareerConsultationCallCard'
import { OrientationCallCard } from './cards/OrientationCallCard'
import { SocialMediaReviewCard } from './cards/SocialMediaReviewCard'
import { TargetCompanyResearchCard } from './cards/TargetCompanyResearchCard'
import { InterviewReviewCard } from './cards/InterviewReviewCard'

export default function CandidatePremiumBenefits({ benefit_status }) {
  const {
    orientation_call,
    career_consultation_call,
    resume_review,
    social_media_review,
    target_company_research,
    interview_review,
  } = benefit_status

  return (
    <div className="m-4 flex flex-col gap-10 font-roboto md:m-12">
      <div className="flex flex-col gap-6">
        <CoachHeader
          title="Your Outplacement Benefits"
          description="You have access to all of the following benefits:"
        />

        <div className="grid gap-10 min-[1200px]:w-[840px] min-[1200px]:grid-cols-2 min-[1640px]:w-[1280px] min-[1640px]:grid-cols-3">
          <OrientationCallCard status={orientation_call} />
          <CareerConsultationCallCard status={career_consultation_call} />
          <ResumeReviewCard status={resume_review} />
          <SocialMediaReviewCard status={social_media_review} />
          <TargetCompanyResearchCard status={target_company_research} />
          <InterviewReviewCard status={interview_review} />
        </div>
      </div>
    </div>
  )
}
