import React from 'react'
import { Box, Checkbox } from '@mui/joy'

const FeedbackOption = ({ children, ...props }) => {
  return (
    <>
      <Box
        sx={{
          '.MuiCheckbox-checkbox.Mui-checked': {
            backgroundColor: 'var(--joy-palette-primary-solidBg, #123F1C)',
          },
          '.MuiCheckbox-checkbox': {
            borderColor: 'var(--joy-palette-neutral-outlinedBorder, #000)',
          },
          marginBottom: '1rem',
          label: {
            fontSize: '16px',
            color: '#000',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '112%',
          },
        }}
      >
        <Checkbox {...props} />
      </Box>
      {children}
    </>
  )
}
export default FeedbackOption
