import React, { useEffect, useState } from 'react'
import { useResources } from '../ResourcesProvider'
import { CheckMarkIcon } from '../icons'

const SkillCard = () => {
  const {
    skillsImagePath,
    saveSkillRank,
    selectedSkill,
    disableSaveRankButton,
    setDisableSaveRankButton,
  } = useResources()

  const icons = {
    relationship: 'el-icon-group',
    communication: 'el-icon-bullhorn',
    leadership: 'el-icon-compass-alt',
    analytical: 'el-icon-cogs',
    creative: 'el-icon-idea',
    physical: 'el-icon-wrench',
    technical: 'el-icon-laptop',
  }

  const icon = icons[selectedSkill[0]]

  const titleizedCategory =
    selectedSkill[0].charAt(0).toUpperCase() + selectedSkill[0].slice(1)

  const [ability, setAbility] = useState(selectedSkill[2] || 50)
  const [enjoyment, setEnjoyment] = useState(selectedSkill[3] || 50)

  useEffect(() => {
    setAbility(selectedSkill[2] || 50)
    setEnjoyment(selectedSkill[3] || 50)
  }, [selectedSkill])

  const handleSaveRank = async () => {
    const res = await saveSkillRank(selectedSkill, ability, enjoyment)
    if (res) {
      setDisableSaveRankButton(true)
    }
  }

  const handleEnjoymentChange = (e) => {
    setEnjoyment(e.target.value)
    setDisableSaveRankButton(false)
  }

  const handleAbilityChange = (e) => {
    setAbility(e.target.value)
    setDisableSaveRankButton(false)
  }

  return (
    <div id="animated-rate-box" className="rate-box animate__animated">
      <div
        id="rate-box-header"
        className={`rate-box-header ${selectedSkill[0]}`}
        role="region"
      >
        <div className="row">
          <div
            id="rate-box-header-icon"
            className="col-md-4 rate-box-header-icon"
          >
            <i className={icon}></i>
          </div>

          <div
            id="rate-box-header-category"
            className="col-md-8 rate-box-header-category"
          >
            {titleizedCategory}
          </div>
        </div>
        <div className="row">
          <div
            id="rate-box-header-skill"
            className={`col-md-12 rate-box-header-skill skill-padding-${selectedSkill[1].length}`}
          >
            {selectedSkill[1]}
          </div>
        </div>
      </div>

      <br />

      <div className="row input-range-row">
        <div className="col-md-12 px-[40px] pb-[10px] pt-0">
          <p className="text-center">Rank your Ability</p>
          <div className="h-[24px] w-full text-center text-xs">{ability}</div>
          <input
            type="range"
            id="ability-range"
            name="ability-range"
            min="0"
            max="100"
            value={ability}
            onChange={handleAbilityChange}
          />

          <div className="row mt-0">
            <div className="col-md-6">
              <span>No ability</span>
            </div>
            <div className="col-md-6">
              <span className="float-right">Expert ability</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row input-range-row">
        <div className="col-md-12 px-[40px] pb-[10px] pt-0">
          <p className="text-center">Rank your Enjoyment</p>
          <div
            className="h-[24px] w-full text-center text-xs"
            aria-hidden="true"
          >
            {enjoyment}
          </div>
          <input
            type="range"
            id="like-range"
            name="like-range"
            min="0"
            max="100"
            value={enjoyment}
            step="1"
            aria-label="Rank your Enjoyment"
            onChange={handleEnjoymentChange}
          ></input>
          <div className="row mt-[10px]">
            <div className="float-left w-1/5">
              <div className="flex w-full justify-center">
                <img
                  src={`${skillsImagePath}sentiment_dissatisfied_24px.png`}
                  alt="Sad Face"
                />
              </div>
              <span className="block text-center">Dislike</span>
            </div>
            <div className="float-right w-1/5">
              <div className="flex w-full justify-center">
                <img
                  src={`${skillsImagePath}sentiment_satisfied_24px.png`}
                  alt="Smiley Face"
                />
              </div>
              <span className="block text-center">Love it</span>
            </div>
          </div>

          <div className="row mb-[30px]">
            <div className="col-md-12 view-next-div" onClick={handleSaveRank}>
              <a
                id="view-next-link"
                className={`view-next btn btn-rank ${selectedSkill[0]}`}
                disabled={disableSaveRankButton}
              >
                <div className="flex items-center justify-center gap-2 pl-4">
                  Save Rank
                  <CheckMarkIcon
                    className={
                      disableSaveRankButton ? 'text-white' : 'invisible'
                    }
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SkillCard
