import React, { useState } from 'react'
import { MainBox, EvalButton } from './UiLib'
import { useProgramEvaluation } from './ProgramEvaluationProvider'
import {
  Collapsible,
  CollapsibleTrigger,
  CollapsibleContent,
} from '../../../../lib/ui/collapsible'
import { useTranslation } from '../../../../hooks/useTranslation'
import { CheckMarkIcon } from '../../../Resources/icons'
import { cn } from '../../../../lib/utils'
import { PlusCircle, MinusCircle } from 'lucide-react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../../../lib/ui/accordion'
import { Form } from '../../../../lib/ui/form'
import { useProgramEvalForm } from './useProgramEvalForm'
import { useIsMobile } from '../../../../hooks/useMediaQuery'

const TopicSelection = () => {
  const {
    topicSelectionThankYouPage,
    selectedTopics,
    candidate,
    totalTopicsAllowed,
    topicSelectionCompleted,
    type,
  } = useProgramEvaluation()
  const pluralize = totalTopicsAllowed > 1 ? 's' : ''
  const candidateFullName = candidate?.first_name + ' ' + candidate?.last_name
  const descriptionText =
    type === 'ManagerTopicSelection'
      ? `Select the top ${totalTopicsAllowed} topic${pluralize} you would like ${candidateFullName} to focus on in their coaching program.`
      : `Select the top ${totalTopicsAllowed} topic${pluralize} you would like to focus on in your coaching program.`

  const showThankYouPage = topicSelectionThankYouPage || topicSelectionCompleted

  const positionClass = showThankYouPage ? '' : 'sticky -top-4'

  return (
    <MainBox className="flex flex-col border border-[#E5E5E5] md:grid md:grid-cols-12">
      <div className="order-last col-span-8 flex flex-col gap-8 rounded-[10px] rounded-r-none bg-white p-12 md:order-first">
        {showThankYouPage ? (
          <TopicSelectionThankYouPage />
        ) : (
          <TopicSelectionForm />
        )}
      </div>
      <div
        className={cn(
          positionClass,
          'col-span-4 rounded-t-[10px] bg-[#E0F2F9] p-8 md:static md:rounded-l-none md:rounded-r-[10px] md:p-12',
        )}
      >
        <div className="text-2xl text-intoo-blue-medium">{descriptionText}</div>
        {selectedTopics.length > 0 && <ListSelectedTopics />}
      </div>
    </MainBox>
  )
}

export default TopicSelection

const TopicSelectionForm = () => {
  const { allTopicsSelected } = useProgramEvaluation()
  const { form, onSubmit, submitted } = useProgramEvalForm()
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <TopicSelectionInner />
        <div className="mt-4 flex flex-col items-start gap-4">
          <div>Please select all topics before proceeding.</div>
          <EvalButton
            className="ga-trackable"
            disabled={submitted || !allTopicsSelected}
            type="submit"
          >
            Continue
          </EvalButton>
        </div>
      </form>
    </Form>
  )
}

const TopicSelectionInner = () => {
  const { sessionTopicCategories } = useProgramEvaluation()
  const sessionTopicCategoriesArr = Object.keys(sessionTopicCategories)

  return (
    <Accordion type="single" collapsible className="w-full">
      {sessionTopicCategoriesArr.map((category, idx) => (
        <AccordionItem value={`item-${idx + 1}`} key={`${category}-${idx}`}>
          <AccordionTrigger>
            <div className="mb-4 text-xl font-bold">{category}</div>
          </AccordionTrigger>
          <AccordionContent>
            <TopicSelectionSection category={category} />
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

const ListSelectedTopics = () => {
  const { t } = useTranslation('topics')
  const { selectedTopics } = useProgramEvaluation()
  return (
    <div>
      <ol className="mt-8 flex list-decimal flex-col gap-4 pl-8 text-intoo-blue-medium">
        {selectedTopics.map((topicSlug, idx) => (
          <li key={`selected-topic-${idx}`}>
            {t(`${topicSlug}.description_short`)}
          </li>
        ))}
      </ol>
    </div>
  )
}

const TopicSelectionSection = ({ category }) => {
  const { sessionTopicCategories } = useProgramEvaluation()
  const topicSlugs = sessionTopicCategories[category]
  return (
    <div>
      <div className="flex flex-col gap-4 divide-y divide-[#e5e5e5]">
        {topicSlugs.map((topicSlug, idx) => (
          <TopicsListItem key={`${topicSlug}-${idx}`} topicSlug={topicSlug} />
        ))}
      </div>
    </div>
  )
}

const TopicsListItem = ({ topicSlug }) => {
  const { selectedTopics, setSelectedTopics, topicsSelectedByManager } =
    useProgramEvaluation()
  const [selected, setSelected] = useState(selectedTopics.includes(topicSlug))

  const toggleSelected = () => {
    setSelected(!selected)
    setSelectedTopics((prev) =>
      selected
        ? prev.filter((topic) => topic !== topicSlug)
        : [...prev, topicSlug],
    )
  }

  const selectedByManager = topicsSelectedByManager.includes(topicSlug)

  const { t } = useTranslation('topics')
  const topic = t(`${topicSlug}.description_short`)
  const [expanded, setExpanded] = useState(false)
  return (
    <div className="flex flex-col gap-4 pt-6">
      <div className="grid grid-cols-6 items-center gap-4">
        <div className="col-span-6 text-lg md:col-span-4">{topic}</div>
        <div className="col-span-6 md:col-span-2">
          {selected && (
            <SelectedButton
              className={'bg-primary-button-cd-darker-75'}
              onClick={toggleSelected}
            >
              Selected
            </SelectedButton>
          )}
          {selectedByManager && (
            <SelectedForYouButton disabled className={'flex-col md:flex-row'}>
              Selected For You
            </SelectedForYouButton>
          )}
          {!selected && !selectedByManager && (
            <SelectTopicButton onClick={toggleSelected} />
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 items-center">
        <Collapsible
          open={expanded}
          onOpenChange={setExpanded}
          className="w-full"
        >
          <CollapsibleTrigger>
            <div className="flex items-center gap-2">
              Details{' '}
              {expanded ? (
                <MinusCircle className="h-4" />
              ) : (
                <PlusCircle className="h-4" />
              )}
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent>
            {' '}
            <DescriptionComponent topicSlug={topicSlug} />{' '}
          </CollapsibleContent>
        </Collapsible>
      </div>
    </div>
  )
}

const DescriptionComponent = ({ topicSlug }) => {
  const { t } = useTranslation('topics')
  const description = t(`${topicSlug}.description`)
  return (
    <div>
      <ul className="list-disc pl-[40px]">
        {description.map((desc, idx) => (
          <li className="list-disc" key={`topic-desc-${idx}`}>
            {desc}
          </li>
        ))}
      </ul>
    </div>
  )
}

const SelectTopicButton = ({ className, ...props }) => {
  const { allTopicsSelected } = useProgramEvaluation()
  return (
    <EvalButton
      disabled={allTopicsSelected}
      className={cn(className, '')}
      {...props}
    >
      Select Topic
    </EvalButton>
  )
}

const SelectedForYouButton = ({ className, children, ...props }) => {
  const isMobile = useIsMobile()
  return (
    <EvalButton className={cn(className, '')} {...props}>
      {children}
      {!isMobile && <CheckMarkIcon className="ml-2" />}
    </EvalButton>
  )
}

const SelectedButton = ({ className, children, ...props }) => {
  return (
    <EvalButton className={cn(className, '')} {...props}>
      {children}
      <CheckMarkIcon className="ml-2" />
    </EvalButton>
  )
}

const TopicSelectionThankYouPage = () => {
  const {
    topicSelectionContinueLink,
    topicSelectionThankYouPage,
    completedTopicSelectionPath,
    inplatform,
    setPreProgramEvaluation,
    setTopicSelection,
    hrSelectsTopics,
    type,
  } = useProgramEvaluation()

  const handleButtonClick = () => {
    if (inplatform) {
      setPreProgramEvaluation(true)
      setTopicSelection(false)
    } else {
      window.location.href =
        topicSelectionContinueLink || completedTopicSelectionPath
    }
  }

  const showContinueButton = !(
    hrSelectsTopics && type === 'ManagerTopicSelection'
  )

  return (
    <>
      <div className="self-center text-xl">
        {topicSelectionThankYouPage ? 'Thank you! ' : ''}Your topic selections
        have been recorded.
      </div>
      {showContinueButton && (
        <EvalButton className="self-center" onClick={handleButtonClick}>
          Continue
        </EvalButton>
      )}
    </>
  )
}
