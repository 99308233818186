import React, { useEffect, useState } from 'react'
import { TranslationProvider } from '../shared/TranslationProvider'
import translations from './locales.json'
import ActionPlanInfo from './ActionPlanInfo'
import CurrentTile from './TrackTileComponents/CurrentTile'
import UpcomingTile from './TrackTileComponents/UpcomingTile'
import { ActionPlanProvider, useActionPlan } from './ActionPlanProvider'

const Index = ({ ...props }) => {
  return (
    <ActionPlanProvider {...props}>
      <ActionPlan {...props} />
    </ActionPlanProvider>
  )
}
export default Index

const ActionPlan = ({ current_user }) => {
  const [goal, setGoal] = useState(current_user.goal)
  const [timeline, setTimeline] = useState('')
  const [activeTab, setActiveTab] = useState('current')
  const { progressPercent, setTrackerSectionInfo } = useActionPlan()

  const currentActive = activeTab === 'current'
  const completedActive = activeTab === 'completed'

  const getUserTrackerSections = async () => {
    const res = await fetch('/tracker/get_tracker_sections')
    if (res.ok) {
      const data = await res.json()
      setTrackerSectionInfo(data.trackerSections)
      setGoal(data.trackerGoal)

      const timeLineText =
        data.trackerTimeline == 0 || data.trackerTimeline == null
          ? 'Set Date'
          : data.trackerTimeline +
            (data.trackerTimeline == 1 ? ' month' : ' months')

      setTimeline(timeLineText)
      return res
    }
    throw new Error('res not ok')
  }

  useEffect(() => {
    getUserTrackerSections()
  }, [])

  return (
    <TranslationProvider
      locale={current_user.locale}
      translations={translations}
      scope={'javascript.components.ActionPlan'}
    >
      <div className="m-4 md:m-12">
        <ActionPlanInfo
          goal={goal}
          timeline={timeline}
          setGoal={setGoal}
          setTimeline={setTimeline}
          progressPercent={progressPercent}
          getUserTrackerSections={getUserTrackerSections}
        />
        <div className="relative mb-8 w-full overflow-x-scroll border-b border-solid border-[#979797] sm:static sm:overflow-auto">
          <div className="flex cursor-pointer flex-row justify-between gap-5 min-[530px]:justify-start min-[530px]:gap-10">
            <div
              onClick={() => setActiveTab('current')}
              className={
                currentActive
                  ? 'boder-solid border-b-4 border-[#08467C] font-medium'
                  : ''
              }
            >
              Current Steps
            </div>
            <div
              onClick={() => setActiveTab('completed')}
              className={
                completedActive
                  ? 'boder-solid border-b-4 border-[#08467C] font-medium'
                  : ''
              }
            >
              Completed Steps
            </div>
          </div>
        </div>

        {currentActive && <CurrentSteps />}
        {completedActive && <CompletedSteps />}
      </div>
    </TranslationProvider>
  )
}

const CurrentSteps = () => {
  const { trackerSectionInfo } = useActionPlan()
  return (
    <>
      {trackerSectionInfo.incomplete.length > 0 && (
        <CurrentTile
          tileInfo={trackerSectionInfo.incomplete[0]}
          key={`sectionTile-${0}`}
          idx={0}
        />
      )}
      <div className="mb-[16px] font-roboto-slab text-xl font-medium">
        {trackerSectionInfo.incomplete.length > 1 && 'Up next for you:'}
      </div>
      {trackerSectionInfo.incomplete.slice(1).map((sectionTile, idx) => {
        return (
          <UpcomingTile
            tileInfo={sectionTile}
            key={`sectionTile-${idx + 1}`}
            idx={idx}
          />
        )
      })}
    </>
  )
}

const CompletedSteps = () => {
  const { trackerSectionInfo } = useActionPlan()
  return trackerSectionInfo.complete.map((sectionTile, idx) => {
    return <UpcomingTile tileInfo={sectionTile} key={`sectionTile-${idx}`} />
  })
}
