import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import axios from 'axios'
import { useProgramEvaluation } from './ProgramEvaluationProvider'
import { useTranslation } from '../../../../hooks/useTranslation'
import { useEffect, useState } from 'react'
export const useProgramEvalForm = (setShowButton) => {
  const {
    programId,
    programName,
    type,
    candidate,
    secret,
    company,
    managerEval,
    setThankYouPage,
    topicSelection,
    selectedTopics,
    setTopicSelectionThankYouPage,
    setTopicSelectionContinueLink,
    peakPerformance,
    annualBenefit,
    postProgramEvaluation,
    postProgramImprovementAreas,
    postProgramImprovementObjectives,
    postProgramManagerImprovementAreas,
    postProgramManagerImprovementObjectives,
    managerPostEval,
    midProgramEval,
    inplatform,
    peakPerformancePostEval,
    postSessionEval,
    cliftonStrengths,
    coachFit,
    longTermGoalsBlob,
    coachingGoalsBlob,
    executiveEval,
    setDescription,
  } = useProgramEvaluation()

  const [submitted, setSubmitted] = useState(false)

  const candidateProfile = candidate.candidate_profile

  const { t } = useTranslation()

  const allTopics = Object.keys(t('topics'))

  const convertTopicsToData = () => {
    const newTopicData = {}
    allTopics.forEach((topicSlug) => {
      newTopicData[topicSlug] = selectedTopics.includes(topicSlug)
        ? selectedTopics.indexOf(topicSlug) + 1
        : 0
    })
    return newTopicData
  }

  const convertImprovementAreasForSchema = () => {
    if (managerPostEval) return {}
    const newImprovementAreas = {}
    postProgramImprovementAreas.forEach((area) => {
      newImprovementAreas['improvement_areas_' + area.id] = z
        .union([z.number(), z.string()])
        .optional()
      newImprovementAreas['improvement_areas_' + area.id + '_checkbox'] = z
        .boolean()
        .optional()
    })
    if (!midProgramEval) {
      postProgramImprovementObjectives.forEach((objective) => {
        newImprovementAreas['improvement_objectives_' + objective.id] = z
          .union([z.number(), z.string()])
          .optional()
        newImprovementAreas[
          'improvement_objectives_' + objective.id + '_checkbox'
        ] = z.boolean().optional()
      })
    }
    return newImprovementAreas
  }

  const convertImprovementAreasForDefaultValues = () => {
    if (managerPostEval) return {}
    const newImprovementAreas = {}
    postProgramImprovementAreas.forEach((area) => {
      newImprovementAreas['improvement_areas_' + area.id] = '3'
      newImprovementAreas['improvement_areas_' + area.id + '_checkbox'] = false
    })
    if (!midProgramEval) {
      postProgramImprovementObjectives.forEach((objective) => {
        newImprovementAreas['improvement_objectives_' + objective.id] = '3'
        newImprovementAreas[
          'improvement_objectives_' + objective.id + '_checkbox'
        ] = false
      })
    }
    return newImprovementAreas
  }

  const convertManagerImprovementAreasForValues = () => {
    if (!managerPostEval) return {}
    const newImprovementAreas = {}
    postProgramManagerImprovementAreas.forEach((area) => {
      newImprovementAreas['improvement_areas_' + area.id] = '3'
      newImprovementAreas['improvement_areas_' + area.id + '_checkbox'] = false
    })
    if (!midProgramEval) {
      postProgramManagerImprovementObjectives.forEach((objective) => {
        newImprovementAreas['improvement_objectives_' + objective.id] = '3'
        newImprovementAreas[
          'improvement_objectives_' + objective.id + '_checkbox'
        ] = false
      })
    }
    return newImprovementAreas
  }

  const convertManagerImprovementAreasForSchema = () => {
    if (!managerPostEval) return {}
    const newImprovementAreas = {}
    postProgramManagerImprovementAreas.forEach((area) => {
      newImprovementAreas['improvement_areas_' + area.id] = z
        .union([z.number(), z.string()])
        .optional()
      newImprovementAreas['improvement_areas_' + area.id + '_checkbox'] = z
        .boolean()
        .optional()
    })
    if (!midProgramEval) {
      postProgramManagerImprovementObjectives.forEach((objective) => {
        newImprovementAreas['improvement_objectives_' + objective.id] = z
          .union([z.number(), z.string()])
          .optional()
        newImprovementAreas[
          'improvement_objectives_' + objective.id + '_checkbox'
        ] = z.boolean().optional()
      })
    }
    return newImprovementAreas
  }

  const topicSelectionSchema = {
    topics: z.array(z.string().optional()),
  }

  const managerPostSchema = managerPostEval
    ? {
        manager: z.string(),
      }
    : {}

  const midProgramEvalSchema = midProgramEval
    ? {
        name: z.string(),
        most_valuable: z.string().optional(),
      }
    : {
        employee: z.string(),
      }

  const postProgramEvalSchema = {
    company: z.string(),
    personal_goals_rating: z.number().optional(),
    improvement_areas_other: z.string().max(255).optional(),
    improvement_objectives_other: z.string().max(255).optional(),
    growth_expectation: z.string().optional(),
    coach_program_experience: z.number().optional(),
    program_rating: z.number().optional(),
    recommend_program: z.number().optional(),
    additional_comments: z.string().optional(),
    program_rating_details: z.string().optional(),
    suggested_improvements: z.string().optional(),
    ...convertImprovementAreasForSchema(),
    ...convertManagerImprovementAreasForSchema(),
    ...managerPostSchema,
    ...midProgramEvalSchema,
  }

  const dynamicPeakPerformanceSchema = peakPerformancePostEval
    ? {
        employee: z.string(),
        company: z.string(),
        coach_program_experience: z.number().optional(),
        goals_completed: z.string().optional(),
        favorite_aspects: z.string().optional(),
        suggested_improvements: z.string().optional(),
      }
    : {
        improvement_areas: z.string(),
        known_barriers: z.string(),
        name: z.string(),
      }

  const peakPerformanceSchema = {
    email: z.string().email(),
    clear_goals: z.union([z.string(), z.number()]),
    self_conscious: z.enum(['0', '1', '2', '3', '4', '5', '6']),
    in_control: z.enum(['0', '1', '2', '3', '4', '5', '6']),
    time_management: z.enum(['0', '1', '2', '3', '4', '5', '6']),
    self_awareness: z.enum(['0', '1', '2', '3', '4', '5', '6']),
    concentration: z.enum(['0', '1', '2', '3', '4', '5', '6']),
    work_life_balance: z.enum(['0', '1', '2', '3', '4', '5', '6']),
    involved: z.enum(['0', '1', '2', '3', '4', '5', '6']),
    ...dynamicPeakPerformanceSchema,
  }

  const annualBenefitSchema = {
    first_name: z.string(),
    last_name: z.string(),
    email: z.string().email().optional(),
    linkedin_url: z.string().optional(),
    goals: z.string().optional(),
    definition_of_success: z.string().optional(),
    current_challenges: z.string().optional(),
    other_topics: z.string().optional(),
  }

  const dynamicSchema = managerEval
    ? {
        name: z.string(),
        employee: z.string(),
      }
    : {
        first_name: z.string(),
        last_name: z.string(),
        years_in_role: z.union([z.string(), z.number()]).optional(),
      }

  const defaultSchema = {
    long_term_goals: z.string().optional(),
    organizational_culture: z.enum(['yes', 'no', '']).optional(),
    stress_and_strategies: z.enum(['yes', 'no', '']).optional(),
    manager: z.string().optional(),
    coaching_goals: z.string().optional(),
    title: z.string().min(1, { message: 'Required' }),
    company: z.string(),
    improvement_areas_other: z.string().max(255).optional(),
    improvement_areas: z.array(z.string().optional()),
    previously_worked_with_coach: z.enum(['yes', 'no', '']).optional(),
    is_coach_helpful: z.enum(['5', '4', '3', '2', '1', '']).optional(),
    growth_expectation: z.string().optional(),
    coach_notes: z.string().optional(),
    meeting_times: z.string().optional(),
    primary_role: z.string().optional(),
    primary_role_other: z.string().optional(),
    approximate_start_date: z
      .union([z.date(), z.string(), z.null()])
      .optional(),
    length_of_leave: z
      .enum(['1-3 months', '4-6 months', '7-12 months', '12+ months', ''])
      .optional(),
    maintaining_visibility: z.string().optional(),
    reentry_plan: z.string().optional(),
    flexible_work: z.string().optional(),
    work_life_balance: z.string().optional(),
    focus_area: z.string().optional(),
    specific_concerns: z.string().optional(),
    recording: z.instanceof(File).optional(),
    ...dynamicSchema,
  }

  const dynamicPostSessionEvalSchema = cliftonStrengths
    ? {
        recommendation_rating: z.union([z.string(), z.number()]).optional(),
        overall_rating: z.union([z.string(), z.number()]).optional(),
        general_feedback: z.string().optional(),
      }
    : {
        coach_relationship: z.string(),
        feedback: z.string(),
      }

  const postSessionEvalSchema = {
    candidate: z.string(),
    company: z.string(),
    ...dynamicPostSessionEvalSchema,
  }

  const getSchema = () => {
    if (postSessionEval) return postSessionEvalSchema
    if (peakPerformancePostEval) return peakPerformanceSchema
    if (postProgramEvaluation || managerPostEval || midProgramEval)
      return postProgramEvalSchema
    if (topicSelection) return topicSelectionSchema
    if (peakPerformance) return peakPerformanceSchema
    if (annualBenefit) return annualBenefitSchema
    return defaultSchema
  }

  const schema = getSchema()

  const managerName =
    (candidateProfile.manager_first_name || '') +
    ' ' +
    (candidateProfile.manager_last_name || '')

  const employeeName =
    (candidate.first_name || '') + ' ' + (candidate.last_name || '')

  const topicSelectionDefaultValues = { topics: selectedTopics }

  const dynamicPostSessionDefaultValues = cliftonStrengths
    ? {
        reccomendation_rating: 3,
        overall_rating: 3,
        general_feedback: '',
      }
    : {
        coach_relationship: coachFit || '',
        feedback: '',
      }

  const postSessionEvalDefaultValues = {
    candidate: employeeName || '',
    company: company.name || '',
    ...dynamicPostSessionDefaultValues,
  }

  const managerPostDefaultValues = managerPostEval
    ? { manager: managerName || '' }
    : {}

  const dynamicMidProgramDefaultValues = midProgramEval
    ? {
        name: employeeName || '',
        most_valuable: '',
      }
    : { employee: employeeName || '' }

  const postProgramEvalDefaultValues = {
    company: company.name || '',
    personal_goals_rating: 3,
    ...convertImprovementAreasForDefaultValues(),
    ...convertManagerImprovementAreasForValues(),
    improvement_areas_other: '',
    improvement_objectives_other: '',
    growth_expectation: '',
    coach_program_experience: 3,
    program_rating: 2,
    recommend_program: 3,
    additional_comments: '',
    program_rating_details: '',
    suggested_improvements: '',
    ...managerPostDefaultValues,
    ...dynamicMidProgramDefaultValues,
  }

  const peakPerformanceDefaultValues = {
    name: employeeName || '',
    employee: employeeName || '',
    email: candidate.email || '',
    company: company.name || '',
    coach_program_experience: 3,
  }

  const annualBenefitDefaultValues = {
    first_name: candidate.first_name || '',
    last_name: candidate.last_name || '',
    email: candidate.email || '',
    linkedin_url: '',
    goals: '',
    definition_of_success: '',
    current_challenges: '',
    other_topics: '',
  }

  const defaultDefaultValues = {
    first_name: candidate.first_name || '',
    last_name: candidate.last_name || '',
    name: managerName || '',
    years_in_role: '',
    title: managerEval ? '' : candidateProfile.job_title || '',
    long_term_goals: '',
    organizational_culture: '',
    stress_and_strategies: '',
    manager: managerName || '',
    coaching_goals: '',
    coaching_program_name: programName || '',
    improvement_areas: [],
    company: company.name || '',
    employee: employeeName || '',
    improvement_areas_other: '',
    previously_worked_with_coach: '',
    is_coach_helpful: '',
    growth_expectation: '',
    coach_notes: '',
    meeting_times: '',
    primary_role: '',
    primary_role_other: '',
    approximate_start_date: new Date(),
    length_of_leave: '',
    maintaining_visibility: '',
    reentry_plan: '',
    flexible_work: '',
    work_life_balance: '',
    focus_area: '',
    specific_concerns: '',
  }

  const getDefaultValues = () => {
    if (postSessionEval) return postSessionEvalDefaultValues
    if (peakPerformancePostEval) return peakPerformanceDefaultValues
    if (postProgramEvaluation || managerPostEval || midProgramEval)
      return postProgramEvalDefaultValues
    if (topicSelection) return topicSelectionDefaultValues
    if (peakPerformance) return peakPerformanceDefaultValues
    if (annualBenefit) return annualBenefitDefaultValues
    return defaultDefaultValues
  }

  const defaultValues = getDefaultValues()

  const form = useForm({
    resolver: zodResolver(z.object(schema)),
    defaultValues,
  })

  const coachRelationship = form.watch('coach_relationship')

  useEffect(() => {
    setDescription(coachRelationship)
  }, [coachRelationship, setDescription])

  const anyNAValues = (values) => {
    const newValues = { ...values }
    const valuesKeys = Object.keys(values)
    valuesKeys.forEach((key) => {
      if (key.includes('checkbox') && values[key] === true) {
        const valueKey = key.replace('_checkbox', '')
        newValues[valueKey] = ''
      }
    })
    return newValues
  }

  const getValues = (values) => {
    if (postProgramEvaluation || managerPostEval || midProgramEval)
      return anyNAValues(values)
    return values
  }

  const getDataByType = (values) => {
    const longTermGoalsRecording = longTermGoalsBlob
      ? {
          long_term_goals_recording: new File(
            [longTermGoalsBlob],
            'long_term_goals_audio.webm',
            {
              type: 'audio/webm;codecs=opus',
            },
          ),
        }
      : {}

    const coachingGoalsRecording = coachingGoalsBlob
      ? {
          coaching_goals_recording: new File(
            [coachingGoalsBlob],
            'coaching_goals_audio.webm',
            { type: 'audio/webm;codecs=opus' },
          ),
        }
      : {}

    const response_attributes = {
      ...getValues(values),
      candidate_id: candidate.id,
      secret: secret,
      ...longTermGoalsRecording,
      ...coachingGoalsRecording,
    }

    const annualBenefitResponseAttributes = {
      type: type,
      candidate_id: candidate.id,
      response_attributes: {
        ...getValues(values),
        coaching_program: 'annual_benefit',
        candidate_id: candidate.id,
        secret: secret,
      },
    }

    const sharedAttributes = {
      type: type,
      response_attributes: response_attributes,
    }
    if (managerEval) {
      return {
        manager_pre_program_evaluation: sharedAttributes,
      }
    } else if (postProgramEvaluation) {
      return { post_program_evaluation: sharedAttributes }
    } else if (managerPostEval) {
      return {
        manager_post_program_evaluation: sharedAttributes,
      }
    } else if (midProgramEval) {
      return {
        mid_program_evaluation: sharedAttributes,
      }
    } else if (postSessionEval) {
      return {
        post_session_evaluation: sharedAttributes,
      }
    } else if (annualBenefit) {
      return {
        pre_program_evaluation: annualBenefitResponseAttributes,
      }
    } else
      return {
        pre_program_evaluation: sharedAttributes,
      }
  }

  async function onSubmit(values) {
    setSubmitted(true)
    const token = document.querySelector('meta[name="csrf-token"]').content
    const baseUrl = topicSelection
      ? `/career_development_topic_selection`
      : `/career_development_program_evaluation`

    const url = inplatform ? '/coaching' + baseUrl : baseUrl

    const data = topicSelection
      ? {
          request_format: 'json',
          career_development_candidate_coaching_program: {
            coaching_program_id: programId,
            type: type,
          },
          topics: convertTopicsToData(),
        }
      : {
          request_format: 'json',
          program_id: programId,
          ...getDataByType(values),
        }

    const config = {
      headers: { 'content-type': 'multipart/form-data', 'X-CSRF-TOKEN': token },
    }

    const response = await axios.patch(url, data, config)

    if (response.status === 200 || response.status === 201) {
      if (response.data.redirect_url === 'thank_you') {
        setThankYouPage(true)
        setShowButton && setShowButton(false)
      } else if (response.data.redirect_url === 'topic_selection_thank_you') {
        setTopicSelectionThankYouPage(true)
        setTopicSelectionContinueLink(response.data.continue_link)
      } else {
        window.location.href = response.data.redirect_url
      }
      return response
    } else {
      console.log(response)
      console.log(response.status)
      console.log(response.error)
      throw new Error('res not ok')
    }
  }

  return { form, onSubmit, submitted }
}
