import React from 'react'
import { Input } from '../../../lib/ui/input'
import { Label } from '../Label'
import { useData } from '../DataProvider'
import useTranslation from '../../shared/TranslationProvider'

const DesiredLocation = () => {
  const { t } = useTranslation()
  const { desiredLocation, setDesiredLocation } = useData()
  return (
    <div>
      <Label htmlFor="desired-location">{t('step3.desired_location')}:</Label>
      <Input
        id="desired-location"
        type="text"
        className="mt-2"
        name="desired-location"
        value={desiredLocation}
        onChange={(e) => setDesiredLocation(e.target.value)}
      />
    </div>
  )
}
export default DesiredLocation
