import { useEffect, useState, useMemo } from 'react'

const usePersistState = (initialValue, id) => {
  const _initialValue = useMemo(() => {
    const localStorageValueStr = localStorage.getItem('state:' + id)
    if (localStorageValueStr) {
      return JSON.parse(localStorageValueStr)
    }
    return initialValue
  }, [id, initialValue])

  const [state, setState] = useState(_initialValue)

  useEffect(() => {
    const stateStr = JSON.stringify(state)
    localStorage.setItem('state:' + id, stateStr)
  }, [state, id])

  return [state, setState]
}
export default usePersistState

export const usePersistSkillsInventoryState = (initialValue, skillsState) => {
  const id = 'skills_inventory'
  const _initialValue = useMemo(() => {
    const localStorageValueStr = localStorage.getItem('state:' + id)
    if (localStorageValueStr && !localStorageValueStr === null) {
      return JSON.parse(localStorageValueStr)
    } else if (skillsState === 'empty') {
      return null
    }
    return initialValue
  }, [id, initialValue, skillsState])

  const [state, setState] = useState(_initialValue)

  useEffect(() => {
    const stateStr = JSON.stringify(state)
    localStorage.setItem('state:' + id, stateStr)
  }, [state, id])

  return [state, setState]
}

export const usePersistRefreshState = (initialValue, id) => {
  const [reload, setReload] = useState(false)

  const setReloadTrue = () => {
    setReload(true)
  }
  const path = window.location.pathname
  const basePath = path.split('/').slice(0, -1).join('/')
  const _initialValue = useMemo(() => {
    if (basePath === '/coaching/resources/article') {
      return 'guidesMiniPlatform'
    } else {
      const stateId = `state:${id}`
      const localStorageValueStr = localStorage.getItem(stateId)

      if (localStorageValueStr) {
        try {
          return JSON.parse(localStorageValueStr)
        } catch (e) {
          console.error('Not valid JSON', e)
          console.log({
            localStorageValueStr,
            type: typeof localStorageValueStr,
          })
        }
      }
    }
    return initialValue
  }, [initialValue, id])

  const [state, setState] = useState(_initialValue)

  useEffect(() => {
    window.addEventListener('beforeunload', setReloadTrue)
    return () => {
      window.removeEventListener('beforeunload', setReloadTrue)
    }
  }, [])

  useEffect(() => {
    if (reload) {
      const stateStr = JSON.stringify(state)
      localStorage.setItem('state:' + id, stateStr)
    } else {
      localStorage.removeItem('state:' + id)
    }
  }, [reload, id, state])

  return [state, setState]
}
