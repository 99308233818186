import * as React from 'react'
import { useDashboard } from '../DashboardProvider'
import SelectCoachCard from '../cards/SelectCoachCard'
import CoachingOverviewCard from '../CoachingOverviewCard'
import { ActionPlanCard } from '../cards/ActionPlanCard'
import { Calendar } from '../../coach_services/OutplacementBenefits/Calendar'

export function Select() {
  const { trackerProgress, trackerTimePeriod, calendarEvents } = useDashboard()
  return (
    <>
      <SelectCoachCard />
      <div className="flex flex-col gap-8 xl:grid xl:grid-cols-3">
        <CoachingOverviewCard className="xl:col-span-2" />
        <ActionPlanCard
          tracker_progress={trackerProgress}
          tracker_time_period={trackerTimePeriod}
        />
      </div>
      <div className="relative flex flex-col gap-[16px]">
        <h2 className="text-2xl font-bold text-primary">
          Upcoming Live Webinars
        </h2>
        <div className="inline-block">
          {`Don't miss out! Check out our live webinar schedule now.`}
        </div>
        <Calendar calendar_events={calendarEvents} homePageDisplay={true} />
      </div>
    </>
  )
}
