import React, { useState } from 'react'
import { useProgramEvalForm } from './useProgramEvalForm'
import { useProgramEvaluation } from './ProgramEvaluationProvider'
import { MainBox, EvalForm, SubmitButton } from './UiLib'
import { cn } from '../../../../lib/utils'
import { ThankYouPage, EvalCompletedPage } from './ThankYouPage'
import { Company, Employee } from './QuestionBank'
import { Radios, SliderField, TextareaField } from './FormFields'

const PostSessionEval = () => {
  const { thankYouPage, completed, cliftonStrengths } = useProgramEvaluation()
  const { form, onSubmit, submitted } = useProgramEvalForm()
  const boxClasses = thankYouPage ? 'flex flex-col items-center gap-12' : ''
  const formProps = { form, onSubmit }
  const showForm = !thankYouPage && !completed

  return (
    <MainBox className={cn('bg-white p-12', boxClasses)}>
      {thankYouPage && <ThankYouPage />}
      {completed && <EvalCompletedPage />}
      {showForm && (
        <EvalForm {...formProps}>
          {cliftonStrengths && <CliftonStrengthsForm form={form} />}
          {!cliftonStrengths && <NotCliftonStrengthsForm form={form} />}
          <SubmitButton disabled={submitted}>Submit Feedback</SubmitButton>
        </EvalForm>
      )}
    </MainBox>
  )
}

export default PostSessionEval

const NotCliftonStrengthsForm = ({ form }) => {
  const { description } = useProgramEvaluation()
  const coachRelationshipChoices = [
    {
      value: 'Great Fit!',
      label: 'Great Fit!',
    },
    {
      value: 'Good So Far',
      label: 'Good So Far',
    },
    {
      value: 'Not Working For Me',
      label: 'Not Working For Me',
    },
  ]

  const descriptions = {
    'Great Fit!':
      'That’s great! Is there anything else you’d like to share with us about your program so far?',
    'Good So Far':
      'Sounds like your program is off to a great start. Is there any feedback you’d like us to share with your coach before your next session?',
    'Not Working For Me':
      'Oh no! We’re sorry to hear your coaching relationship hasn’t lived up to expectations. We want to get that fixed for you right away. Can you please tell us a little bit more about what is not working for you at this time?',
  }
  return (
    <>
      <Employee
        name="candidate"
        className="required-text col-span-3"
        form={form}
      />
      <Company className="required-text col-span-3" form={form} />
      <Radios
        name="coach_relationship"
        choices={coachRelationshipChoices}
        control={form.control}
        label="How do you feel about your coaching relationship?"
        className={'col-span-6'}
      />
      <div className="col-span-6">{descriptions[description]}</div>
      <TextareaField
        name="feedback"
        label="Please provide some feedback."
        className={'required-text col-span-6'}
        control={form.control}
      />
    </>
  )
}

const CliftonStrengthsForm = ({ form }) => {
  const { groupProgram } = useProgramEvaluation()
  return (
    <>
      <SliderField
        name={groupProgram ? 'recommendation_rating' : 'overall_rating'}
        min={1}
        max={5}
        minDescription={groupProgram ? 'Not Unlikely' : 'Not Satisfied'}
        maxDescription={
          groupProgram ? 'Extremely Likely' : 'Extremely Satisfied'
        }
        label={
          groupProgram
            ? 'On a scale of 1 to 5, how likely are you to recommend the CliftonStrengths Team Blend session to other teams?'
            : 'On a scale of 1 to 5, how satisfied are you with the coaching session overall?'
        }
        className={'col-span-6'}
        control={form.control}
      />
      <TextareaField
        name="general_feedback"
        label={
          groupProgram
            ? "Please feel free to share any additional feedback, suggestions, or insights from the CliftonStrengths Team Blend session. Your input helps us tailor our sessions to better align with your team's unique dynamics."
            : 'Please share any additional thoughts, suggestions, or insights you gained from the CliftonStrengths(™) coaching session. Your feedback will assist us in refining our coaching approach to better serve your needs.'
        }
        className={'col-span-6'}
        control={form.control}
      />
    </>
  )
}
