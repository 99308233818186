import React from 'react'
import { useProgramEvaluation } from './ProgramEvaluationProvider'
import {
  MainBoxSplit,
  LeftBox,
  RightBox,
  H3,
  EvalForm,
  SubmitButton,
} from './UiLib'
import { useProgramEvalForm } from './useProgramEvalForm'
import { FeedbackPageIcon } from './icons'
import { ThankYouPage, EvalCompletedPage } from './ThankYouPage'
import ProfessionalPostProgramEvaluation from './PostProgramEvaluations/Professional'
import ManagerPostProgramEvaluation from './PostProgramEvaluations/Manager'
import PeakPerformancePostProgramEval from './PostProgramEvaluations/PeakPerformance'

const PostProgramEvaluation = () => {
  const { peakPerformance, thankYouPage, managerPostEval, completed } =
    useProgramEvaluation()
  const { form, onSubmit, submitted } = useProgramEvalForm()

  const showProfessionalEval = !managerPostEval && !peakPerformance

  const formProps = { form, onSubmit }

  const showForm = !thankYouPage && !completed

  return (
    <MainBoxSplit>
      <LeftBox className={thankYouPage ? 'items-center' : ''}>
        {thankYouPage && <ThankYouPage />}
        {completed && <EvalCompletedPage />}
        {showForm && (
          <EvalForm {...formProps}>
            {showProfessionalEval && (
              <ProfessionalPostProgramEvaluation form={form} />
            )}
            {managerPostEval && <ManagerPostProgramEvaluation form={form} />}
            {peakPerformance && <PeakPerformancePostProgramEval form={form} />}
            <SubmitButton disabled={submitted}>{'Continue'}</SubmitButton>
          </EvalForm>
        )}
      </LeftBox>
      <RightBox className={'space-y-8'}>
        <H3 className={'font-roboto-slab text-intoo-blue-medium'}>
          Your feedback matters to us!
        </H3>
        <FeedbackPageIcon />
      </RightBox>
    </MainBoxSplit>
  )
}

export default PostProgramEvaluation
