import * as React from 'react'

import { useSearchParams } from 'react-router-dom'

export function useJobSearchParams() {
  // urlSearchParams is a URLSearchParams object
  // https://developer.mozilla.org/en-US/docs/Web/API/URL/searchParams
  const [urlSearchParams, setUrlSearchParams] = useSearchParams(
    window.location.search,
  )

  urlSearchParams.sort()

  const [paramString, setParamString] = React.useState(
    urlSearchParams.toString(),
  )

  const lockParamString = React.useCallback(() => {
    // Set paramString to be used for new query
    setParamString(urlSearchParams.toString())
    // Set the params to not yet add to this history stack
    // until we build a custom history stack
    setUrlSearchParams(urlSearchParams, { replace: true })
  }, [setUrlSearchParams, urlSearchParams])

  return {
    urlSearchParams,
    setUrlSearchParams,
    paramString,
    lockParamString,
  }
}
