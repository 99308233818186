import * as React from 'react'
import AccessibleFormLabel from './AccessibleFormLabel'
import { Input, FormControl, IconButton } from '@mui/joy'
import { Visibility } from '@mui/icons-material'
import { useState } from 'react'
import Errors from './Errors'

const PasswordInput = ({
  password,
  setPassword,
  required = true,
  size = 'lg',
  placeholder = '@#*%',
  labelValue = 'Password',
  name = 'password',
  errors,
  className,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const hasError = errors && errors[name] && errors[name].length > 0

  return (
    <FormControl required={required} error={hasError} className={className}>
      <AccessibleFormLabel>{labelValue}</AccessibleFormLabel>
      <Input
        name={name}
        size={size}
        placeholder={placeholder}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        type={showPassword ? 'text' : 'password'}
        endDecorator={
          <IconButton
            sx={{ '--joy-palette-success-plainColor': '#0088FF' }}
            color={showPassword ? 'success' : 'neutral'}
            aria-label="toggle password visibility"
            onClick={() => {
              setShowPassword(!showPassword)
            }}
            onMouseDown={(e) => e.preventDefault()}
          >
            <Visibility />
          </IconButton>
        }
        {...props}
      />
      <Errors errors={errors} field={name} />
    </FormControl>
  )
}

export default PasswordInput
