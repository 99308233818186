import React from 'react'
import {
  ClearGoals,
  SelfConscious,
  InControl,
  TimeManagement,
  SelfAwareness,
  Concentration,
  WorkLifeBalance,
  Involved,
} from '../QuestionBank'
import { TextareaField } from '../FormFields'

const PeakPerformance = ({ form }) => {
  const props = {
    form,
    className: 'col-span-3 required-text',
  }
  return (
    <>
      <ClearGoals {...props} />
      <SelfConscious {...props} />
      <InControl {...props} />
      <TimeManagement {...props} />
      <SelfAwareness {...props} />
      <Concentration {...props} />
      <WorkLifeBalance {...props} />
      <Involved {...props} />
      <TextareaField
        name="improvement_areas"
        label="What are three things that you want help with?"
        control={form.control}
        className="required-text col-span-6"
      />
      <TextareaField
        name="known_barriers"
        label="What blockers are in the way of your success?"
        control={form.control}
        className="required-text col-span-6"
      />
    </>
  )
}
export default PeakPerformance
