import React from 'react'

const NotebookIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M7.78888 5.7145H11.8176M7.78888 8.57164H11.8176M7.78888 11.4288H11.8176M6.98314 2.14307H3.3573C2.69014 2.14307 2.14868 2.62307 2.14868 3.21449V13.9288C2.14868 14.5202 2.69014 15.0002 3.3573 15.0002H13.832C14.4991 15.0002 15.0406 14.5202 15.0406 13.9288V3.21449C15.0406 2.62307 14.4991 2.14307 13.832 2.14307H6.98314ZM5.37165 5.7145H5.38025V5.72211H5.37165V5.7145ZM5.37165 8.57164H5.38025V8.57926H5.37165V8.57164ZM5.37165 11.4288H5.38025V11.4364H5.37165V11.4288Z"
        stroke="#08467C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default NotebookIcon
