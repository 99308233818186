import React, { useState } from 'react'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import TileIntooIcon from './TileIntooIcon'
import { useTranslation } from '../../../hooks/useTranslation'
import { Button } from '../../../lib/ui/button'

const CollapsedCurrentTile = ({ tileInfo, expanded, setExpanded }) => {
  // const [selected, setSelected] = useState(0)
  const sectionName = Object.keys(tileInfo)[0]
  const collapsed = !expanded
  const isDesktop = useIsDesktop()
  const alignItems = isDesktop ? '' : ' items-center'
  const { t } = useTranslation()
  // const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0 })

  // console.log(scrollPosition)

  const handleViewClick = () => {
    setExpanded(true)
  }

  return (
    collapsed && (
      <div
        className={`flex flex-col ${alignItems} mb-[24px] justify-between rounded-[16px] border-[1px] border-cod-gray-200 md:flex-row`}
      >
        <div className="rounded-[16px] bg-white md:w-9/12">
          <div className={`flex justify-between p-8`}>
            <div className="marker-container">
              <h2
                className="font-roboto-slab text-2xl font-bold text-primary"
                style={{ marginTop: '0px', display: 'inline' }}
              >
                {t(`${sectionName.split('_')[0]}_tasks`, {
                  number: t(sectionName.split('_')[1]),
                })}
              </h2>
              <div className="pt-[16px]">
                {t(`sections.descriptions.${tileInfo[sectionName]['focus']}`)}
              </div>
            </div>
          </div>
        </div>
        <div className="gradient-action-plan-collapsed md:relative md:flex md:w-4/12 md:items-center md:justify-center md:rounded-[12px]">
          <TileIntooIcon className="absolute left-[10px] top-[40px]" />
          <Button
            variant={isDesktop ? 'outline' : 'secondary'}
            onClick={handleViewClick}
            title={
              'View ' +
              t(`${sectionName.split('_')[0]}_tasks`, {
                number: t(sectionName.split('_')[1]),
              })
            }
            className="mb-[32px] sm:mb-0 md:ml-[32%]"
          >
            {t('view')}
            <i className="el-icon-chevron-down ml-5"></i>
          </Button>
        </div>
      </div>
    )
  )
}
export default CollapsedCurrentTile
