import * as React from 'react'
import { Label as LabelLibUi } from '../../lib/ui/label'

import { cn } from '../../lib/utils'

const Label = React.forwardRef(({ className, ...props }, ref) => (
  <LabelLibUi
    ref={ref}
    className={cn('text-base font-bold', className)}
    {...props}
  />
))
Label.displayName = LabelLibUi.displayName

export { Label }
