import * as React from 'react'

import CandidateFlexBenefits from './CandidateFlexBenefits'
import CandidatePremiumBenefits from './CandidatePremiumBenefits'

export default function OutplacementBenefits({
  program_level,
  calendar_events,
  benefit_status,
}) {
  return {
    flex: (
      <CandidateFlexBenefits
        calendar_events={calendar_events}
        benefit_status={benefit_status}
      />
    ),
    premium: <CandidatePremiumBenefits benefit_status={benefit_status} />,
  }[program_level]
}
