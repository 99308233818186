import React from 'react'
import { cn } from '../../../../lib/utils'
import { SliderField, CheckboxField, TextField } from './FormFields'
import { Button } from '../../../../lib/ui/button'
import { Form } from '../../../../lib/ui/form'

export const MainBox = ({ className, children, ...props }) => {
  return (
    <div className={cn('rounded-[10px]', className)} {...props}>
      {children}
    </div>
  )
}

export const H1 = ({ className, children }) => {
  return (
    <h1 className={cn(className, 'font-roboto-slab text-3xl font-bold')}>
      {children}
    </h1>
  )
}

export const H2 = ({ className, children }) => {
  return <h2 className={cn(className, 'font-roboto text-xl')}>{children}</h2>
}

export const H3 = ({ className, children }) => {
  return <h3 className={cn('font-roboto text-xl', className)}>{children}</h3>
}

const MainBoxSplit = ({ className, children, ...props }) => {
  return (
    <MainBox
      className={cn(
        'flex flex-col border border-[#E5E5E5] md:grid md:grid-cols-12',
        className,
      )}
      {...props}
    >
      {children}
    </MainBox>
  )
}

const LeftBox = ({ children, className }) => {
  return (
    <div
      className={cn(
        'order-last col-span-8 flex flex-col gap-8 rounded-[10px] rounded-r-none bg-white p-12 md:order-first',
        className,
      )}
    >
      {children}
    </div>
  )
}

const RightBox = ({ children, className, positionClass }) => {
  return (
    <div
      className={cn(
        positionClass,
        'col-span-4 rounded-t-[10px] bg-[#E0F2F9] p-8 md:static md:rounded-l-none md:rounded-r-[10px] md:p-12',
        className,
      )}
    >
      {children}
    </div>
  )
}

MainBoxSplit.LeftBox = LeftBox
MainBoxSplit.RightBox = RightBox
LeftBox.displayName = 'LeftBox'
RightBox.displayName = 'RightBox'

export { MainBoxSplit, LeftBox, RightBox }

export const ImprovementAreas = ({
  form,
  improvementAreas,
  objectives = false,
  minDescription,
  maxDescription,
  keyName,
}) => {
  const improvementAreaName = (area, objective = objectives) => {
    return objective
      ? `improvement_objectives_${area}`
      : `improvement_areas_${area}`
  }

  const checkBoxChecked = (area) => {
    return form.watch(improvementAreaName(area) + '_checkbox')
  }
  return (
    <>
      {improvementAreas.map((area, index) => (
        <div
          className="col-span-6 grid grid-cols-3"
          key={`${keyName}-${area.id}-${index}`}
        >
          <SliderField
            name={improvementAreaName(area.id)}
            label={area.label}
            control={form.control}
            min={1}
            max={5}
            minDescription={minDescription}
            maxDescription={maxDescription}
            className={'col-span-3 md:col-span-2'}
            disabled={checkBoxChecked(area.id)}
          />
          <CheckboxField
            name={improvementAreaName(area.id) + '_checkbox'}
            control={form.control}
            label={'Not Applicable'}
            className={'col-span-3 md:col-span-1'}
          />
        </div>
      ))}
      <TextField
        name={
          objectives
            ? 'improvement_objectives_other'
            : 'improvement_areas_other'
        }
        className={'col-span-6'}
        label="Other"
        control={form.control}
      />
    </>
  )
}

export const EvalButton = ({ className, children, ...props }) => {
  return (
    <Button {...props} variant="cd" className={cn(className, '')}>
      {children}
    </Button>
  )
}

const EvalForm = ({ form, onSubmit, className, children }) => {
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className={cn(className, 'grid grid-cols-6 gap-4')}>
          {children}
        </div>
      </form>
    </Form>
  )
}

const SubmitButton = ({ className, disabled, children, props }) => {
  return (
    <EvalButton
      className={cn(className, 'ga-trackable col-span-6 md:col-span-1')}
      disabled={disabled}
      type="submit"
      {...props}
    >
      {children}
    </EvalButton>
  )
}

EvalForm.SubmitButton = SubmitButton
SubmitButton.displayName = 'SubmitButton'

export { EvalForm, SubmitButton }
