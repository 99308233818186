import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import axios from 'axios'
import { useMiniplatform } from './MiniplatformProvider'

export const useUploadAssessmentForm = ({ dateString }) => {
  const { coachingProgramId } = useMiniplatform()
  const schema = {
    name: z.string().min(2, {
      message: 'Please choose a name.',
    }),
    remoteContent: z.instanceof(File).refine((file) => file.size < 5000000, {
      message: 'File must be less than 5MB',
    }),
  }

  const defaultValues = {
    name: `Clifton Strengths Assessment ${dateString}`,
    coaching_program_id: coachingProgramId,
  }

  const form = useForm({
    resolver: zodResolver(z.object(schema)),
    defaultValues,
  })

  async function onSubmit(values) {
    const token = document.querySelector('meta[name="csrf-token"]').content

    const url = `/coaching/upload_create`
    const data = {
      request_format: 'json',
      program_id: coachingProgramId,
      clifton_strengths_assessment: {
        name: values.name,
        remote_content: values.remoteContent,
      },
    }
    const config = {
      headers: { 'content-type': 'multipart/form-data', 'X-CSRF-TOKEN': token },
    }

    const response = await axios.post(url, data, config)
    if (response.status === 200) {
      const resData = response.data
      return (window.location.href = resData.redirect_url)
    } else {
      console.log(response)
      console.log(response.status)
      console.log(response.error)
      throw new Error('res not ok')
    }
  }

  return { form, onSubmit }
}
