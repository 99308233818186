import React from 'react'
import { useData } from './DataProvider'
import { NavButton, ButtonContainer } from './Buttons'
import useTranslation from '../shared/TranslationProvider'
import InterestSelect from './InterestSelect'

const Step2 = () => {
  const { interest, setCurrentStep } = useData()
  const { t } = useTranslation()

  return (
    <>
      <div className="pt-10">
        <h1 className="text-2xl font-bold md:text-4xl">{t('looking_for')}</h1>
        <p className="py-4 text-base md:text-xl">{t('interested_in')}</p>
        <InterestSelect />
      </div>
      <ButtonContainer>
        <NavButton type={'back'} onClick={() => setCurrentStep(1)}>
          {t('back')}
        </NavButton>
        <NavButton
          type={'submit'}
          disabled={interest === null}
          onClick={() => setCurrentStep(3)}
        >
          {t('next')}
        </NavButton>
      </ButtonContainer>
    </>
  )
}

export default Step2
