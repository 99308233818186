import { startOfDay, startOfToday, startOfTomorrow } from 'date-fns'

/**
 * Get the date phase based on the given date.
 *
 * @param {Date|undefined} date - The date to evaluate.
 * @returns {'overdue' | 'soon' | 'future' | 'none'}
 */
export function getDatePhase(date) {
  if (!date) return 'none'
  date = startOfDay(date)

  const today = startOfToday()
  const tomorrow = startOfTomorrow()

  if (date < today) {
    return 'overdue'
  } else if (date >= today && date <= tomorrow) {
    return 'soon'
  } else {
    return 'future'
  }
}
