import React, { useState } from 'react'
import { Button } from '@mui/joy'
import SetPassword from './SetPassword/SetPassword'
import PasswordInput from './PasswordInput'
import useTranslation from '../../shared/TranslationProvider.js'
import { SpinnerWhite } from '../../shared/Spinner'
import { useAlert } from './AlertModal'
import { apiPut } from '../../../util/api'
import Errors from './Errors'

const ExpiredPasswordReset = () => {
  const [currentPassword, setCurrentPassword] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const { setAlertData, setAlertState } = useAlert()
  const { t } = useTranslation()

  const handleResetPassword = async () => {
    const user = {
      current_password: currentPassword,
      password: password,
      password_confirmation: passwordConfirmation,
    }
    setIsLoading(true)
    try {
      const response = await apiPut('/users/password_expired', {
        user: user,
      })
      if (response.data.errors) {
        if (typeof response.data.errors[0] === 'string') {
          setAlertData(response.data.errors[0])
          setAlertState('alert')
        } else {
          setErrors(response.data.errors)
        }
        setIsLoading(false)
      } else {
        window.location.href = response.data.location
      }
    } catch (error) {
      setAlertData(t('ResetPassword.error'))
      setAlertState('alert')
    }
  }

  return (
    <div>
      <h1>{t('ResetExpiredPassword.h1')}</h1>
      <h3 className="mb-8">{t('ResetExpiredPassword.h3')}</h3>
      <div className="mb-4">
        <PasswordInput
          name="currentPassword"
          value={currentPassword}
          autoComplete="current-password"
          labelValue={t('ResetExpiredPassword.current_pass')}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <Errors errors={errors} field={'current_password'} />
      </div>
      <SetPassword
        password={password}
        setPassword={setPassword}
        passwordConfirmation={passwordConfirmation}
        setPasswordConfirmation={setPasswordConfirmation}
        errors={errors}
      />
      <div className="button-container padding">
        <Button
          className="submit-button btn"
          size="lg"
          fullWidth
          onClick={handleResetPassword}
          disabled={isLoading}
        >
          {t('ResetPassword.reset_password')}
          <SpinnerWhite show={isLoading} className="pl-4" />
        </Button>
      </div>
    </div>
  )
}

export default ExpiredPasswordReset
