import React, { useState, useCallback, useEffect } from 'react'
import { Box } from '@mui/joy'
import PasswordCriterion from './PasswordCriteron'
import useTranslation from '../../../shared/TranslationProvider'

const PasswordCritera = ({ password }) => {
  const [length, setLength] = useState(false)
  const [capital, setCapital] = useState(false)
  const [lower, setLower] = useState(false)
  const [number, setNumber] = useState(false)
  const [special, setSpecial] = useState(false)
  const { t } = useTranslation()

  const checkLength = useCallback(() => {
    if (password.length >= 12 && password.length <= 22) {
      setLength(true)
    } else setLength(false)
  }, [password])

  const checkCapital = useCallback(() => {
    if (password.match(/[A-Z]/)) {
      setCapital(true)
    } else setCapital(false)
  }, [password])

  const checkLower = useCallback(() => {
    if (password.match(/[a-z]/)) {
      setLower(true)
    } else setLower(false)
  }, [password])

  const checkNumber = useCallback(() => {
    if (password.match(/[0-9]/)) {
      setNumber(true)
    } else setNumber(false)
  }, [password])

  const checkSpecial = useCallback(() => {
    if (password.match(/[^a-zA-Z0-9]/)) {
      setSpecial(true)
    } else setSpecial(false)
  }, [password])

  useEffect(() => {
    checkLength()
    checkCapital()
    checkLower()
    checkNumber()
    checkSpecial()
  }, [
    password,
    checkLength,
    checkCapital,
    checkLower,
    checkNumber,
    checkSpecial,
  ])

  return (
    <>
      <Box>
        <ul className="password-criteria">
          <PasswordCriterion criterion={length}>
            {t('SetPassword.criteria.length')}
          </PasswordCriterion>
          <PasswordCriterion criterion={number}>
            {t('SetPassword.criteria.number')}
          </PasswordCriterion>
          <PasswordCriterion criterion={special}>
            {t('SetPassword.criteria.special')}
          </PasswordCriterion>
          <PasswordCriterion criterion={capital}>
            {t('SetPassword.criteria.capital')}
          </PasswordCriterion>
          <PasswordCriterion criterion={lower}>
            {t('SetPassword.criteria.lower')}
          </PasswordCriterion>
        </ul>
      </Box>
    </>
  )
}

export default PasswordCritera
