import * as React from 'react'

import {
  Drawer,
  DrawerContent,
  DrawerClose,
  DrawerDescription,
  DrawerHeader,
} from '../../../lib/ui/drawer'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../lib/ui/dialog'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { Button } from '../../../lib/ui/button'
import useTranslation, { TranslationProvider } from '../TranslationProvider'
import translations from './locales.json'
import { useQuery, useMutation } from '@tanstack/react-query'
import { getUserConsent, setUserConsent } from '../../../util/api'
import { Checkbox } from '../../../lib/ui/checkbox'

import { Slot } from '@radix-ui/react-slot'

export default function LeaveSiteWarning({ children, url }) {
  const { locale } = useTranslation()

  return (
    <TranslationProvider
      locale={locale}
      translations={translations}
      scope="leave_site_consent"
    >
      <LeaveSiteWarningDialog url={url}>{children}</LeaveSiteWarningDialog>
    </TranslationProvider>
  )
}

function LeaveSiteWarningDialog({ children, url }) {
  const [open, setOpen] = React.useState(false)
  const isDesktop = useIsDesktop()
  const { t } = useTranslation()
  const { data } = useQuery({
    queryKey: ['user_consent'],
    queryFn: getUserConsent,
  })

  const handleOpenChange = (isOpen) => {
    setOpen(isOpen)
    const chatButton = document.getElementById('coach_chat_button')
    if (!chatButton) return

    if (isOpen) {
      chatButton.style.display = 'none'
    } else {
      chatButton.style.display = 'block'
    }
  }

  if (!data?.current_user) {
    return <>{children}</>
  }
  const { current_user } = data

  if (current_user.leave_site_consent_given) {
    return (
      <Slot
        onClick={(event) => {
          event.stopPropagation()
          window.open(url, '_blank', 'noreferrer')
        }}
      >
        {children}
      </Slot>
    )
  }

  const leadText = current_user.uc ? t('uc.sentence_1') : t('sentence_1')
  const firstParagraph = current_user.uc
    ? [t('uc.sentence_2'), t('uc.sentence_3')].join(' ')
    : [t('sentence_2'), t('sentence_3')].join(' ')
  const secondParagraph = current_user.uc
    ? [t('uc.sentence_4'), t('uc.sentence_5'), t('uc.sentence_6')].join(' ')
    : [t('sentence_4'), t('sentence_5')].join(' ')

  if (isDesktop) {
    return (
      <Dialog
        open={open}
        onOpenChange={handleOpenChange}
        className="rounded-2xl"
      >
        <DialogTrigger asChild>
          <div role="button" aria-expanded={open} className="cursor-pointer">
            {children}
          </div>
        </DialogTrigger>
        <DialogContent className="">
          <DialogHeader>
            <DialogTitle className="!mb-0 !mt-0 text-left">
              {t('title')}
            </DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-4 p-4">
            <DialogDescription className="text-intoo-blue-dark">
              {leadText}
            </DialogDescription>
            <DialogDescription className="font-roboto text-xs">
              {firstParagraph}
            </DialogDescription>
            <DialogDescription className="font-roboto text-xs">
              {secondParagraph}
            </DialogDescription>
            <LeaveSiteForm url={url} />
          </div>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Drawer open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <div role="button" aria-expanded={open} className="cursor-pointer">
          {children}
        </div>
      </DialogTrigger>
      <DrawerContent>
        <DrawerHeader>
          <DialogTitle>{t('title')}</DialogTitle>
          <SeparatorWithIcon />
          <DrawerDescription className="text-left">
            {leadText}
          </DrawerDescription>
          <DrawerDescription className="text-left font-roboto text-xs">
            {firstParagraph}
          </DrawerDescription>
          <DrawerDescription className="text-left font-roboto text-xs">
            {secondParagraph}
          </DrawerDescription>
        </DrawerHeader>
        <LeaveSiteForm url={url} />
      </DrawerContent>
    </Drawer>
  )
}

function LeaveSiteForm({ url }) {
  const { t } = useTranslation()
  const [checked, setChecked] = React.useState(false)
  const mutation = useMutation({ mutationFn: setUserConsent })

  const handleChange = async (value) => {
    setChecked(value)
    mutation.mutate({ consentGiven: value })
  }

  return (
    <div className="font-robot flex flex-col">
      <div className="mb-6 ml-8 mr-6 flex flex-row gap-4 sm:mx-6">
        <div className="h-12 pt-2">
          <Checkbox
            id="leave-site-consent"
            checked={checked}
            onCheckedChange={handleChange}
            className="onCheckedChange h-6 w-6 rounded data-[state=checked]:bg-intoo-blue-medium"
          />
        </div>
        <div className="h-12">
          <label htmlFor="leave-site-consent" className="m-0 text-xs">
            {t('consent')}
          </label>
        </div>
      </div>
      <div className="text-center text-lg font-bold">
        {t('question_continue')}
      </div>
      <div className="m-4 flex flex-col gap-4 sm:flex-row sm:justify-around">
        <YesButton url={url} />
        <NoButton />
      </div>
    </div>
  )
}

function YesButton({ disabled, url }) {
  const { t } = useTranslation()
  const handleClick = (event) => {
    event.stopPropagation()
    window.open(url, '_blank', 'noreferrer')
  }

  return (
    <DrawerClose asChild>
      <Button
        disabled={disabled}
        variant="default"
        className="h-12 min-w-32 rounded-[20px] bg-intoo-blue-medium text-lg hover:bg-intoo-blue-medium/90 sm:h-10 sm:rounded-2xl sm:text-base"
        onClick={handleClick}
      >
        {t('answer_yes')}
      </Button>
    </DrawerClose>
  )
}

function NoButton() {
  const { t } = useTranslation()

  return (
    <DrawerClose asChild>
      <Button
        variant="default"
        className="h-12 min-w-32 rounded-[20px] bg-[#5A5A5A] text-lg hover:bg-[#5A5A5A]/90 sm:h-10 sm:rounded-2xl sm:text-base"
      >
        {t('answer_no')}
      </Button>
    </DrawerClose>
  )
}

function SeparatorWithIcon() {
  return (
    <div className="flex flex-row items-center gap-4 pb-4 font-[Elusive-Icons] text-4xl text-intoo-blue-medium">
      <div className="flex-grow border-b border-intoo-blue-medium" />
      {String.fromCharCode(0xe61f)}
      <div className="flex-grow border-b border-intoo-blue-medium" />
    </div>
  )
}
