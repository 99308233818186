import React from 'react'

const Clock = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M8.59463 4.28592V8.57164H11.8176M15.0406 8.57164C15.0406 9.41585 14.8738 10.2518 14.5499 11.0317C14.226 11.8117 13.7512 12.5204 13.1526 13.1173C12.554 13.7143 11.8434 14.1878 11.0614 14.5109C10.2793 14.8339 9.44112 15.0002 8.59463 15.0002C7.74813 15.0002 6.90993 14.8339 6.12787 14.5109C5.34581 14.1878 4.63522 13.7143 4.03666 13.1173C3.43809 12.5204 2.96329 11.8117 2.63935 11.0317C2.31541 10.2518 2.14868 9.41585 2.14868 8.57164C2.14868 6.86667 2.82781 5.23154 4.03666 4.02595C5.2455 2.82036 6.88506 2.14307 8.59463 2.14307C10.3042 2.14307 11.9438 2.82036 13.1526 4.02595C14.3614 5.23154 15.0406 6.86667 15.0406 8.57164Z"
        stroke="#08467C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default Clock
