import * as React from 'react'
import { Check, Cancel } from '../icons'

const PasswordCriterion = ({ criterion, children }) => {
  return (
    <li className="text-4 mb-4">
      <span className="mr-2 align-text-bottom">
        {criterion ? <Check /> : <Cancel />}
      </span>
      {children}
    </li>
  )
}

export default PasswordCriterion
