import * as React from 'react'

import { Button } from '../../../lib/ui/button'
import { Calendar } from '../../../lib/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../../lib/ui/popover'
import { CircleX } from 'lucide-react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import { useSavedJobs } from './SavedJobsProvider'
import { getDatePhase } from './getDatePhase'
import { useTranslation } from '../../../hooks/useTranslation'

export function FollowupButton({ savedJob, rowIndex, variant }) {
  const { t } = useTranslation('FollowupButton')
  const { patchSavedJob } = useSavedJobs()
  const [calendarOpen, setCalendarOpen] = React.useState(false)
  const { followup_on } = savedJob
  const queryClient = useQueryClient()

  const updateData = (rowIndex, columnId, value) => {
    queryClient.setQueryData(['get-saved-jobs'], (old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          console.log(old[rowIndex])
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      }),
    )
  }

  const mutation = useMutation({
    mutationFn: (updates) => patchSavedJob(savedJob, updates),
    onSettled: () => {
      queryClient.invalidateQueries(['get-saved-jobs'])
    },
  })

  const handleSelectDate = (date) => {
    updateData(rowIndex, 'followup_on', date || undefined)
    updateData(rowIndex, 'updated_at', new Date())
    mutation.mutate({
      followup_on: date ? format(date, 'yyyy-MM-dd') : null,
    })
    setCalendarOpen(false)
  }

  const handleSetNoDate = () => {
    updateData(rowIndex, 'followup_on', undefined)
    updateData(rowIndex, 'updated_at', new Date())
    mutation.mutate({ followup_on: null })
  }

  const datePhase = getDatePhase(followup_on)

  return (
    <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
      <PopoverTrigger>
        <div
          className="h-6 justify-start rounded-lg px-2 py-0 text-left text-base font-normal text-black data-[phase=future]:bg-white data-[phase=none]:bg-white data-[phase=overdue]:bg-[#FC7C5E] data-[phase=soon]:bg-[#FFE769] data-[phase=none]:text-muted-foreground"
          data-phase={datePhase}
        >
          {followup_on ? (
            format(followup_on, 'P')
          ) : (
            <EnterDate variant={variant} />
          )}
        </div>
      </PopoverTrigger>
      {followup_on && (
        <Button
          variant="ghost"
          className="ml-1 h-7 p-1 text-muted-foreground hover:text-black"
          onClick={handleSetNoDate}
        >
          <span className="sr-only">{t('clear_date')}</span>
          <CircleX size={14} />
        </Button>
      )}
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={new Date(followup_on)}
          onSelect={handleSelectDate}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}

function EnterDate({ variant }) {
  const { t } = useTranslation('FollowupButton')

  if (variant !== 'outline') {
    return <>{t('enter_date')}</>
  }

  return (
    <div className="ml-[-0.5rem] mt-[-0.5rem] flex h-9 items-center justify-center rounded-xl border border-input px-6 text-sm font-normal text-black  hover:bg-accent hover:text-accent-foreground">
      {t('enter_date')}
    </div>
  )
}
