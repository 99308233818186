import * as React from 'react'

export function CoachHeader({ title, description }) {
  return (
    <div className="flex flex-col gap-4">
      <h1 className="m-0 p-0 font-roboto-slab text-3.5xl font-bold leading-tight text-intoo-green-coachcta">
        {title}
      </h1>

      <p className="font-medium">{description}</p>
    </div>
  )
}
