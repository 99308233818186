import React from 'react'
// import { TrackItemMenu } from './TrackItemMenu'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useTranslation } from '../../../hooks/useTranslation'
import { Button } from '../../../lib/ui/button'
import { Transition } from 'react-transition-group'
import { TimeIcon, CompletedIcon } from './icons'
import { itemTypeMap } from './ExpandedCurrentTile'
import UpcomingTileHeader from './UpcomingTileHeader'
import { CoachButton } from '../../coach_services/OutplacementBenefits/CoachButton'

const ExpandedUpcomingTile = ({ tileInfo, expanded, setExpanded }) => {
  // const [selected, setSelected] = useState(0)
  const sectionName = Object.keys(tileInfo)[0]
  const isDesktop = useIsDesktop()
  const alignItems = isDesktop ? '' : 'items-center'
  const { t } = useTranslation()

  return (
    expanded && (
      <div className="section-tile mb-[24px]">
        <UpcomingTileHeader
          sectionName={sectionName}
          setExpanded={setExpanded}
          expanded={expanded}
          tileInfo={tileInfo}
          t={t}
        />

        <div className="relative my-6 flex flex-row rounded-[16px]">
          <div className="absolute z-50 flex h-full w-[16px] flex-col rounded-bl-[16px] rounded-tl-[16px] bg-primary"></div>
          <div className="flex w-full flex-col flex-wrap gap-[24px] rounded-[16px]">
            {tileInfo[sectionName]['subsections'].map((tile, idx) => {
              return <TrackItem key={`expanded-${idx}`} tile={tile} t={t} />
            })}
          </div>
        </div>
      </div>
    )
  )
}
export default ExpandedUpcomingTile

const TrackItem = ({ tile, t }) => {
  const [isSkipped, setIsSkipped] = React.useState(tile.skipped)
  // const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const nodeRef = React.useRef(null)
  const duration = 500

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  }

  const icon = itemTypeMap[tile.type].icon

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  }

  return (
    <Transition
      nodeRef={nodeRef}
      in={!isSkipped}
      timeout={duration}
      unmountOnExit={true}
    >
      {(state) => (
        <div
          className={`track-item relative flex flex-col rounded-[16px] border border-solid border-cod-gray-200 bg-white p-[16px] md:grid md:grid-cols-12 ${nodeRef}`}
          ref={nodeRef}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          <Tile tile={tile} icon={icon} t={t} />
        </div>
      )}
    </Transition>
  )
}

const Tile = ({ tile, icon, t }) => {
  const isCoachService = tile['coach_service']
  return (
    <>
      <div className="col-span-1">
        <div className="flex h-full flex-col items-center justify-center">
          {React.cloneElement(icon, {
            variant: isCoachService ? 'coach' : 'primary',
            height: '40',
          })}
        </div>
      </div>
      <div className="col-span-8 flex grid grid-cols-12 items-center gap-4 md:flex-row">
        <div className="col-span-9 flex flex-col ">
          <h3 className="text-xl font-medium">{tile.name}</h3>
          {tile.description}
        </div>
        <div className="col-span-3 grid grid-cols-12">
          <div className="col-span-6 flex flex-col items-center justify-center gap-2 border-r border-cod-gray-200 pr-[6px]">
            <TimeIcon variant={isCoachService ? 'coach' : 'dark'} />
            {tile.length}
          </div>
          <div className="col-span-6 flex flex-col items-center justify-center gap-2 pl-[6px]">
            {React.cloneElement(icon, {
              variant: isCoachService ? 'coach' : 'dark',
              height: '24',
            })}
            <div className="capitalize">{tile.type.replace('_', ' ')}</div>
          </div>
        </div>
      </div>
      <div className="col-span-3 flex h-full flex-col items-center justify-center">
        <a href={tile.url}>
          {isCoachService ? (
            <CoachButton
              title={t('view') + ' ' + tile.name}
              href={tile.url}
              className="text-[14px]"
            >
              {itemTypeMap[tile.type].text}
              <span className="ml-[8px]">
                {React.cloneElement(icon, {
                  variant: 'light',
                  height: '24',
                })}
              </span>
            </CoachButton>
          ) : (
            <Button title={t('view') + ' ' + tile.name} href={tile.url}>
              {itemTypeMap[tile.type].text}
              <span className="ml-[8px]">
                {React.cloneElement(icon, {
                  variant: 'light',
                  height: '24',
                })}
              </span>
            </Button>
          )}
        </a>
      </div>
    </>
  )
}
