import * as React from 'react'

import translations from '../locales.json'
import { TranslationProvider } from '../../shared/TranslationProvider'
import SiteInfo from '../SiteInfo'

export default function QuickStartPage({ current_user }) {
  return (
    <TranslationProvider
      locale={current_user.locale}
      translations={translations}
      scope="javascript.components.orientation.quick_start"
    >
      <div
        className="flex flex-col pt-0 font-roboto"
        style={{
          paddingTop: '0px',
        }}
      >
        <SiteInfo currentUser={current_user} />
      </div>
    </TranslationProvider>
  )
}
