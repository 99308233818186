import React, { useState } from 'react'

const JobSearchQuiz = ({ quizId, quizPartId, content, bucketPath }) => {
  const [resultsCopy, setResultsCopy] = useState('')
  const [resultsHeader, setResultsHeader] = useState('')
  const [tasks, setTasks] = useState({})
  const [slugs, setSlugs] = useState(new Set())

  const startPageAvatars = [
    'intro_avatars/undraw_join_re_w1lh+10.svg',
    'intro_avatars/undraw_join_re_w1lh+11.svg',
    'intro_avatars/undraw_join_re_w1lh+12.svg',
    'intro_avatars/undraw_join_re_w1lh+13.svg',
    'intro_avatars/undraw_join_re_w1lh+2.svg',
    'intro_avatars/undraw_join_re_w1lh+3.svg',
    'intro_avatars/undraw_join_re_w1lh+4.svg',
    'intro_avatars/undraw_join_re_w1lh+5.svg',
    'intro_avatars/undraw_join_re_w1lh+6.svg',
    'intro_avatars/undraw_join_re_w1lh+7.svg',
    'intro_avatars/undraw_join_re_w1lh+8.svg',
    'intro_avatars/undraw_join_re_w1lh+9.svg',
  ]

  const resultsPageAvatars = [
    'results_avatars/undraw_chore_list_re_2lq8+2.svg',
    'results_avatars/undraw_chore_list_re_2lq8+3.svg',
    'results_avatars/undraw_chore_list_re_2lq8+4.svg',
    'results_avatars/undraw_chore_list_re_2lq8+5.svg',
    'results_avatars/undraw_chore_list_re_2lq8+6.svg',
    'results_avatars/undraw_chore_list_re_2lq8+7.svg',
    'results_avatars/undraw_chore_list_re_2lq8+8.svg',
    'results_avatars/undraw_chore_list_re_2lq8+9.svg',
    'results_avatars/undraw_chore_list_re_2lq8+10.svg',
  ]

  const startPageAvatar =
    startPageAvatars[Math.floor(Math.random() * startPageAvatars.length)]

  const resultsPageAvatar =
    resultsPageAvatars[Math.floor(Math.random() * resultsPageAvatars.length)]

  const updateUserTracker = async () => {
    const reqBody = {
      tracker_sections_slugs: Array.from(slugs),
    }
    const token = document.querySelector('meta[name="csrf-token"]').content
    const res = await fetch(`/trackers/${tracker.id}`, {
      method: 'PATCH',
      headers: {
        'X-CSRF-TOKEN': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqBody),
    })
    if (res.ok) return res
    throw new Error('res not ok')
  }

  const actions = (questionNumber, answerType) => {
    const prevState = slugs
    switch (parseInt(questionNumber)) {
      case 1:
        if (answerType === 0) {
          prevState.delete('resume-builder')
          // setSlugs(new Set([...prevState, 'resume-review']));
          setSlugs(new Set([...prevState]))
        } else setSlugs((prev) => new Set([...prev, 'resume-webinar']))
        break
      case 2:
        prevState.add('linked-in-guide')
        setSlugs(new Set([...prevState]))
        break
      // setSlugs(prev => new Set([...prev, 'linked-in-guide']))
      case 3:
        prevState.add('cover-letter-builder')
        setSlugs(new Set([...prevState]))
        break
      case 4:
        break
    }
  }

  // const calcScore = (obj) => {
  //   const numAs = Object.values(obj).filter((val) => val === 'a').length
  //   const score = Math.round((numAs / Object.keys(obj).length) * 100)
  //   if (numAs <= 8) {
  //     setResultsCopy(results[1].copy)
  //     setResultsHeader(results[1].header)
  //   } else if (numAs <= 14) {
  //     setResultsCopy(results[2].copy.split('[numAs]').join(numAs))
  //     setResultsHeader(results[2].header)
  //   } else {
  //     setResultsCopy(results[3].copy.split('[score]').join(score))
  //     setResultsHeader(results[3].header)
  //   }
  // }

  return <></>
}

export default JobSearchQuiz
