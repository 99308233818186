import React, { useEffect } from 'react'
import { useNav } from './NavProvider'
import { SubNavLink } from './SubNav'

const PopupSubNav = ({ popupRef, slug, items }) => {
  const { popupSubNav, setPopupSubNav, currentTimer } = useNav()
  const active = slug === popupSubNav
  useEffect(() => {
    const handleClick = (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        setPopupSubNav(null)
      }
    }
    if (active) document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [popupRef, setPopupSubNav, active])

  const setHovering = (e) => {
    clearTimeout(currentTimer.current)
    setPopupSubNav(slug)
    e.stopPropagation()
  }
  const setNotHovering = (e) => {
    clearTimeout(currentTimer.current)
    const timerId = setTimeout(() => {
      setPopupSubNav(null)
    }, 1000)
    currentTimer.current = timerId
    e.stopPropagation()
  }

  const popupClasses = slug == popupSubNav ? 'visible' : 'invisible'

  if (!items) {
    return null
  }
  return (
    <div
      ref={popupRef}
      className={popupClasses}
      onMouseOver={setHovering}
      onMouseOut={setNotHovering}
    >
      <div className="fixed left-24 flex flex-col gap-2 rounded-xl border border-solid border-[#0000001a] bg-white p-2 shadow-xl">
        {items.map((child, childIndex) => (
          <SubNavLink key={childIndex} item={child} />
        ))}
      </div>
    </div>
  )
}
export default PopupSubNav
