import * as React from 'react'

import { useCookies } from 'react-cookie'

const statusSortByList = [
  'saved',
  'applied',
  'interview',
  'offer',
  'closed',
  'archive',
]
const dateSortByList = ['created_at', 'updated_at']

export const useSortBy = () => {
  const [sortByValue, setSortByValue] = useCookieState('sortByValue', 'saved')
  const [sortByStatus, setSortByStatus] = useCookieState(
    'sortByStatus',
    'status',
  )
  const [pinFollowupJobs, setPinFollowupJobs] = useCookieState(
    'pinFollowupJobs',
    true,
  )
  const [statusSortByValue, setStatusSortByValue] = useCookieState(
    'statusSortByValue',
    'saved',
  )
  const [dateSortByValue, setDateSortByValue] = useCookieState(
    'dateSortByValue',
    'last_saved',
  )

  const [columnFilters, setColumnFilters] = useCookieState('columnFilters', [
    {
      id: 'status',
      value: ['archive'],
    },
  ])

  const [sorting, setSorting] = useCookieState('sorting', [
    { id: 'followup_on', desc: false },
    { id: 'status', desc: false },
  ])

  React.useEffect(() => {
    if (statusSortByList.includes(sortByValue)) {
      setSortByStatus(true)
      setStatusSortByValue(sortByValue)
    } else if (dateSortByList.includes(sortByValue)) {
      setSortByStatus(false)
      setDateSortByValue(sortByValue)
    }
  }, [setDateSortByValue, setSortByStatus, setStatusSortByValue, sortByValue])

  const [statusSortingOrder, setStatusSortingOrder] = React.useState([
    'saved',
    'applied',
    'interview',
    'offer',
    'closed',
    'archive',
  ])

  React.useEffect(() => {
    const cycleStatusSortingOrder = (topStatus) => {
      if (topStatus == 'archive') {
        setColumnFilters([
          {
            id: 'status',
            value: ['saved', 'applied', 'interview', 'offer', 'closed'],
          },
        ])
      } else {
        setColumnFilters([
          {
            id: 'status',
            value: ['archive'],
          },
        ])
      }
      setStatusSortingOrder((prevOrder) => {
        const newOrder = [...prevOrder]
        while (newOrder[0] !== topStatus) {
          newOrder.push(newOrder.shift())
        }

        return newOrder
      })
    }

    cycleStatusSortingOrder(statusSortByValue)
  }, [setColumnFilters, statusSortByValue])

  React.useEffect(() => {
    const newSorting = []

    if (pinFollowupJobs) newSorting.push({ id: 'followup_on', desc: false })

    if (sortByStatus) {
      newSorting.push({ id: 'status', desc: false })
      newSorting.push({ id: 'updated_at', desc: true })
    } else {
      newSorting.push({ id: dateSortByValue, desc: true })
    }

    setSorting(newSorting)
  }, [dateSortByValue, pinFollowupJobs, setSorting, sortByStatus])

  return {
    sorting,
    setSorting,
    columnFilters,
    setColumnFilters,
    statusSortingOrder,
    pinFollowupJobs,
    setPinFollowupJobs,
    sortByValue,
    setSortByValue,
  }
}

const useCookieState = (cookieName, defaultValue) => {
  const [cookies, setCookie] = useCookies([cookieName])
  const cookieValue =
    cookies[cookieName] === undefined ? defaultValue : cookies[cookieName]

  const setCookieValue = React.useCallback(
    (value) => {
      setCookie(cookieName, value, { path: '/job-search/saved_jobs' })
    },
    [cookieName, setCookie],
  )

  return [cookieValue, setCookieValue]
}
