import React, { useEffect, useState, useCallback } from 'react'
import parse from 'html-react-parser'
import CancelIcon from '@mui/icons-material/Cancel'
import { useData } from './DataProvider'

const FlashMessage = ({ isAlert = false, text = null }) => {
  const [visible, setVisible] = useState(true)
  const closeFlash = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  const timeoutSecs = 6000

  useEffect(() => {
    if (visible) {
      const timeout = setTimeout(() => closeFlash(), timeoutSecs)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [closeFlash, visible, timeoutSecs])

  const classes = isAlert
    ? 'mt-px relative w-auto border-2 border-[#ebccd1] bg-[#f2dede] px-4 py-2 text-[#a94442]'
    : 'mt-px relative w-auto border-2 border-[#d6e9c6] bg-[#dff0d8] px-4 py-2 text-[#3c763d]'

  if (!text) return null

  return (
    <div className={classes} style={{ display: visible ? 'block' : 'none' }}>
      <CancelIcon
        className="absolute right-1 top-1 cursor-pointer"
        onClick={closeFlash}
      />
      {text && parse(text)}
    </div>
  )
}

const FlashContainer = () => {
  const { flashAlert, flashNotice } = useData()
  if (!flashAlert && !flashNotice) return null

  return (
    <div className="fixed left-[15%] z-40 mt-[-1px] flex w-[70%] flex-col text-center">
      {flashAlert && <FlashMessage isAlert text={flashAlert} />}
      {flashNotice && <FlashMessage text={flashNotice} />}
    </div>
  )
}

export default FlashContainer
