import React from 'react'
import { Button } from '../../../lib/ui/button'
import { itemTypeMap } from './ExpandedCurrentTile'

const getSectionTitle = (sectionName, t) => {
  const parts = sectionName.split('_')
  if (parts.length === 3) {
    return `${t('months')} ${t(parts[1])} - ${t(parts[2])} ${t('tasks')}`
  } else {
    return t(`${parts[0]}_tasks`, {
      number: t(parts[1]),
    })
  }
}

const UpcomingTileHeader = ({
  sectionName,
  expanded,
  setExpanded,
  tileInfo,
  t,
}) => {
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-7">
        <div className={`flex flex-col items-start justify-between`}>
          <h2
            className="font-roboto-slab text-xl font-bold text-primary"
            style={{ marginTop: '0px', display: 'inline' }}
          >
            {getSectionTitle(sectionName, t)}
          </h2>
          <div className="py-[16px]">
            {t(`sections.descriptions.${tileInfo[sectionName]['focus']}`)}
          </div>
          <div>
            <Button
              onClick={() => (expanded ? setExpanded(null) : setExpanded(true))}
              title={
                'View ' +
                t(`${sectionName.split('_')[0]}_tasks`, {
                  number: t(sectionName.split('_')[1]),
                })
              }
              className="mb-[32px] sm:mb-0"
            >
              {t('view')}
              {expanded ? (
                <i className="el-icon-chevron-up ml-5"></i>
              ) : (
                <i className="el-icon-chevron-down ml-5"></i>
              )}
            </Button>
          </div>
        </div>
      </div>
      <div className="col-span-5 flex items-center justify-end">
        {Object.entries(tileInfo[sectionName]['type_count']).map(
          ([type, count], idx) => {
            return (
              <div key={`${type}-${idx}`}>
                <div
                  className={`flex flex-col items-center justify-center gap-2 pr-6 ${
                    idx !=
                      Object.entries(tileInfo[sectionName]['type_count'])
                        .length -
                        1 && 'border-r border-cod-gray-200'
                  } pl-6`}
                >
                  {React.cloneElement(itemTypeMap[type].icon, {
                    variant: 'dark',
                    height: '24',
                  })}
                  <div className="text-center capitalize">{count}</div>
                </div>
              </div>
            )
          },
        )}
      </div>
    </div>
  )
}

export default UpcomingTileHeader
