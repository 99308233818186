import React, { useRef } from 'react'
import { useNav } from './NavProvider'

const Link = ({ popupRef, onClick, slug, title, className, children }) => {
  const { setPopupSubNav, currentTimer, isExpanded } = useNav()
  const linkRef = useRef(null)
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onClick()
    }
  }
  const setNotHovering = () => {
    if (isExpanded) return
    clearTimeout(currentTimer.current)
    const timerId = setTimeout(() => {
      setPopupSubNav(null)
    }, 1000)
    currentTimer.current = timerId
  }

  const setHovering = (popupRef) => () => {
    if (isExpanded) return

    if (popupRef?.current) {
      var top = linkRef.current.getBoundingClientRect().top
      var popupHeight =
        popupRef.current.children[0].getBoundingClientRect().height
      if (popupHeight > window.innerHeight - top) {
        popupRef.current.children[0].style.top = `${
          window.innerHeight - popupHeight
        }px`
      } else {
        popupRef.current.children[0].style.top = `${top}px`
      }
      setPopupSubNav(slug)
      clearTimeout(currentTimer.current)
    }
  }

  return (
    <a
      ref={linkRef}
      className={className}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      title={title}
      onMouseOver={setHovering(popupRef)}
      onMouseOut={setNotHovering}
      role="link"
      tabIndex="0"
    >
      {children}
    </a>
  )
}
export default Link
