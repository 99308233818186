import * as React from 'react'

import { CoachCard } from '../CoachCard'
import { CoachButton } from '../CoachButton'
import { PhoneIcon } from '@heroicons/react/24/outline'

export function CoachChatCard() {
  return (
    <CoachCard
      icon={PhoneIcon}
      title="On-Demand Coach Chat"
      description="One-on-one coaching to help you navigate your job search."
    >
      <CoachButton
        className="open-chat-modal"
        data-toggle="modal"
        data-target="#coach_chat_list"
        data-action="Upper Start a Coach Chat"
      >
        Start a Coach Chat
      </CoachButton>

      <CoachButton variant="outline" asChild>
        <a href="/coach_chat">Learn More</a>
      </CoachButton>
    </CoachCard>
  )
}
