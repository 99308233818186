import React from 'react'
import { useProgramEvaluation } from '../ProgramEvaluationProvider'
import { TextareaField } from '../FormFields'
import { ImprovementAreas } from '../UiLib'
import {
  Employee,
  Company,
  CoachProgramExperience,
  ProgramRating,
  ProgramRatingDetails,
  RecommendProgram,
  SuggestedImprovements,
  AdditionalComments,
  PersonalGoals,
} from '../QuestionBank'

const ProfessionalPostProgramEvaluation = ({ form }) => {
  const {
    postProgramImprovementAreas,
    postProgramImprovementObjectives,
    annualBenefit,
  } = useProgramEvaluation()
  return (
    <>
      <Employee
        className={'required-text col-span-6 md:col-span-3'}
        form={form}
      />
      <Company
        className={'required-text col-span-6 md:col-span-3'}
        form={form}
      />
      <PersonalGoals form={form} />
      {!annualBenefit && (
        <>
          <div className="col-span-6">
            Please indicate the areas in which you have experienced personal
            development and growth. Please rate the level of growth you have
            experienced.
          </div>
          <ImprovementAreas
            form={form}
            improvementAreas={postProgramImprovementAreas}
            minDescription={'No Improvement'}
            maxDescription={'Huge Improvement'}
            keyName={'development'}
          />
        </>
      )}
      <div className="col-span-6">
        To what degree have you benefited in the following areas as a result of
        your participation in INTOO’s coaching program:
      </div>
      <ImprovementAreas
        form={form}
        improvementAreas={postProgramImprovementObjectives}
        minDescription={'No Improvement'}
        maxDescription={'Huge Improvement'}
        keyName={'benefit'}
        objectives={true}
      />
      <TextareaField
        name="growth_expectation"
        className={'col-span-6'}
        label="How have you grown within your current role at your company since you began working with your coach?"
        control={form.control}
      />
      <CoachProgramExperience form={form} />
      <ProgramRating form={form} />
      <ProgramRatingDetails form={form} />
      <RecommendProgram form={form} />
      <SuggestedImprovements form={form} />
      <AdditionalComments form={form} />
    </>
  )
}

export default ProfessionalPostProgramEvaluation
