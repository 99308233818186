import * as React from 'react'

import { Popover, PopoverTrigger, PopoverContent } from '../../lib/ui/popover'

export function DebugInfo({ obj, debug, children }) {
  if (!debug) return children

  const keys = Object.keys(obj)

  return (
    <Popover>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent className="m-16 w-[1024px] rounded-2xl border-2 border-intoo-blue-medium shadow-2xl">
        <div className="grid gap-4">
          <div className="space-y-2">
            <h4 className="font-medium leading-none">Debug Info</h4>
          </div>
          <div className="grid gap-1 font-roboto text-xs">
            {keys.map((key) => {
              let value = obj[key]
              if (!value) return null
              value = JSON.stringify(value)

              return (
                <div key={key} className="grid grid-cols-5 gap-2">
                  <div>{key}:</div>
                  <div className="col-span-4 line-clamp-1 overflow-hidden break-words hover:line-clamp-none">
                    {`${value}`}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
