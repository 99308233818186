import React from 'react'
import { useResources } from '../ResourcesProvider'
import GraphView from './GraphView'
import { cn } from '../../../lib/utils'
import { ChevronUpIcon } from 'lucide-react'
import SkillsList from './SkillsList'
import SkillCard from './SkillCard'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from '../../../lib/ui/dialog'

const SkillsInventory = () => {
  const { graphViewActive, cardViewActive, selectedSkill, isMobile } =
    useResources()

  const selectedSkillClasses = isMobile
    ? 'cursor-pointer rounded-[10px] px-5 py-[10px] text-[#fff] selected'
    : ''
  return (
    <>
      {!isMobile && <SkillsList />}
      {isMobile && (
        <div
          className={cn(
            `col-span-10 checkbox-${selectedSkill[0]}`,
            selectedSkillClasses,
          )}
        >
          <div className="grid grid-cols-5">
            <div className="col-span-4">{selectedSkill[1]}</div>
            {<ChevronUpIcon className="h-4 w-4" />}
          </div>
        </div>
      )}

      {!isMobile && <div className="col-span-1"></div>}
      {graphViewActive && <GraphView />}
      <ChooseSkillsDialog />
      {cardViewActive && <CardView />}
      {isMobile && <SkillsList />}
    </>
  )
}
export default SkillsInventory

const CardView = () => {
  return (
    <div className="col-span-10 mt-[0px] py-4 md:col-span-4 md:py-0">
      <SkillCard />
    </div>
  )
}

export const ChooseSkillsDialog = ({ children }) => {
  const {
    skillsDialogOpen,
    skillsForSkillsDialog,
    setSelectedSkill,
    openCardViewTab,
    toggleSkillsDialog,
  } = useResources()
  const handleSkillClick = (skill) => {
    setSelectedSkill(skill)
    toggleSkillsDialog()
    openCardViewTab()
  }
  return (
    <Dialog open={skillsDialogOpen} onOpenChange={toggleSkillsDialog}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Choose Your Skill</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col items-center justify-center gap-8">
          {skillsForSkillsDialog.map((skill, idx) => (
            <div
              onClick={() => handleSkillClick(skill)}
              className="btn btn-primary"
              key={`choose-skill-${idx}`}
            >
              {skill[1]}
            </div>
          ))}
        </div>
        <DialogClose />
      </DialogContent>
    </Dialog>
  )
}
