import React from 'react'
import {
  ClearGoals,
  SelfConscious,
  InControl,
  TimeManagement,
  SelfAwareness,
  Concentration,
  WorkLifeBalance,
  Involved,
  Employee,
  Company,
  CoachProgramExperience,
  SuggestedImprovements,
} from '../QuestionBank'
import { TextareaField } from '../FormFields'

const PeakPerformancePostProgramEval = ({ form }) => {
  const props = {
    form,
    className: 'col-span-3 required-text',
  }
  return (
    <>
      <Employee {...props} />
      <Company {...props} />
      <ClearGoals {...props} />
      <SelfConscious {...props} />
      <InControl {...props} />
      <TimeManagement {...props} />
      <SelfAwareness {...props} />
      <Concentration {...props} />
      <WorkLifeBalance {...props} />
      <Involved {...props} />
      <CoachProgramExperience
        form={form}
        label={'Overall, how valuable was your coaching experience?'}
      />
      <TextareaField
        name="goals_completed"
        label="What goals did you complete because of this program?"
        control={form.control}
        className="col-span-3"
      />
      <TextareaField
        name="favorite_aspects"
        label="What were some of your favorite aspects of this program?"
        control={form.control}
        className="col-span-3"
      />
      <SuggestedImprovements
        label="What could we do to improve the program?"
        form={form}
      />
    </>
  )
}
export default PeakPerformancePostProgramEval
