import React, { useCallback, createContext, useContext } from 'react'
import { I18n } from 'i18n-js'

export const TranslationContext = createContext()

const useTranslation = (hookScope) => {
  const { i18n, providerScope, providerLocale } = useContext(TranslationContext)

  const newScope = [providerScope, hookScope].filter((s) => s).join('.')

  const t = useCallback(
    (tScope, options) => {
      return i18n.t(tScope, { scope: newScope, ...options })
    },
    [i18n, newScope],
  )

  const locale = providerLocale

  return {
    t,
    locale,
    providerLocale,
    providerScope,
    i18n,
  }
}

export const TranslationProvider = ({
  translations,
  locale = 'en',
  scope,
  children,
}) => {
  const i18nOptions = {
    locale,
    // If true, any missing languages default to english
    // by keeping this false, we can see if we are missing translations
    enableFallback: false,
  }
  const i18n = new I18n(translations, i18nOptions)

  const allowFallback = (locale, nonDistinctLocales) => {
    nonDistinctLocales.forEach((nonDistinctLocale) => {
      // Registering a locale forces it to the more general locale
      // only do this for locales that don't exist
      if (!Object.keys(translations).includes(nonDistinctLocale)) {
        i18n.locales.register(nonDistinctLocale, locale)
      }
    })
  }

  const gbEnglishLocales = [
    'en_ae',
    'en_au',
    'en_bb',
    'en_be',
    'en_ca',
    'en_ch',
    'en_cz',
    'en_eu',
    'en_gb',
    'en_gr',
    'en_hk',
    'en_in',
    'en_it',
    'en_jm',
    'en_ke',
    'en_ph',
    'en_pl',
    'en_ro',
    'en_rs',
    'en_tt',
    'en_za',
  ]
  allowFallback('en_gb', gbEnglishLocales)

  const germanLocales = ['de_be', 'de_ch', 'de_de']
  allowFallback('de', germanLocales)

  const spanishLocales = [
    'es_us',
    'es_mx',
    'es_es',
    'es_ar',
    'es_co',
    'es_cl',
    'es_pa',
  ]
  allowFallback('es', spanishLocales)

  const frenchLocales = ['fr_ca', 'fr_fr', 'fr_ch', 'fr_be']
  allowFallback('fr', frenchLocales)

  const italianLocales = ['it_it', 'it_ch']
  allowFallback('it', italianLocales)

  const japaneseLocales = ['ja_jp']
  allowFallback('ja', japaneseLocales)

  const dutchLocales = ['nl_be', 'nl_nl']
  allowFallback('nl', dutchLocales)

  const portugueseLocales = ['pt_br', 'pt_pt']
  allowFallback('pt', portugueseLocales)

  const value = { i18n, providerScope: scope, providerLocale: locale }

  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  )
}

export default useTranslation
