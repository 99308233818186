import React, { useState, createContext, useContext } from 'react'
import { useCalendlyEventListener } from 'react-calendly'
import { api } from '../../util/api'

const BenefitsContext = createContext()

export const useBenefits = () => {
  return useContext(BenefitsContext)
}

const BenefitsProvider = ({
  coach_calendly_url,
  candidate_name,
  candidate_email,
  candidate_id,
  coach_id,
  calendly_events_path,
  completed_calendly_event,
  pending_event,
  pending_career_consultation_event,
  completed_career_consultation_event,
  children,
}) => {
  const defaultCalendlyEvent = () => {
    if (completed_calendly_event) {
      return { type: 'completed', ...completed_calendly_event }
    } else if (pending_event) {
      return { type: 'pending', ...pending_event }
    } else {
      return null
    }
  }
  const [calendlyEvent, setCalendlyEvent] = useState(defaultCalendlyEvent())

  const defaultCareerConsultationEvent = () => {
    if (completed_career_consultation_event) {
      return { type: 'completed', ...completed_career_consultation_event }
    } else if (pending_career_consultation_event) {
      return { type: 'pending', ...pending_career_consultation_event }
    } else {
      return null
    }
  }
  const [careerConsultationEvent, setCareerConsultationEvent] = useState(
    defaultCareerConsultationEvent(),
  )

  const [callType, setCallType] = useState()

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      const payload = e.data.payload
      createCalendlyEvent(
        payload.event.uri.split('/').pop(),
        payload.invitee.uri.split('/').pop(),
        callType,
      )
    },
  })

  const createCalendlyEvent = async (calendlyId, calendlyUserId, callType) => {
    const data = {
      request_format: 'json',
      calendly_event: {
        candidate_id: candidate_id,
        coach_id: coach_id,
        calendly_id: calendlyId,
        calendly_user_id: calendlyUserId,
        meeting_name: `60 minute ${callType} call`,
      },
    }

    const res = await api.post(calendly_events_path, data)

    if (res.status === 200) {
      setTimeout(() => {
        const event = { type: 'pending', ...res.data.calendlyEvent }
        if (callType === 'orientation') {
          setCalendlyEvent(event)
        } else {
          setCareerConsultationEvent(event)
        }
      }, 2000)
      return res
    } else {
      throw new Error('Calendly event creation failed')
    }
  }

  const values = {
    coach_calendly_url,
    candidate_name,
    candidate_email,
    candidate_id,
    coach_id,
    calendly_events_path,
    calendlyEvent,
    setCalendlyEvent,
    careerConsultationEvent,
    setCareerConsultationEvent,
    callType,
    setCallType,
  }
  return (
    <BenefitsContext.Provider value={values}>
      {children}
    </BenefitsContext.Provider>
  )
}
export default BenefitsProvider
