import React, { useEffect, useState } from 'react'
import { useCalendlyEventListener, InlineWidget } from 'react-calendly'
import { api } from '../../util/api'
import { useMiniplatform } from './MiniplatformProvider'

const Calendly = ({ setShowMeetingConfirmed }) => {
  const {
    candidateInfo,
    coachCalendlyUrl,
    calendlyEventsPath,
    coachId,
    coachingProgramId,
    setShowCalendly,
    setIndividualEvent,
    isMobile,
  } = useMiniplatform()
  const { candidateId, candidateName, candidateEmail } = candidateInfo
  const [styles, setStyles] = useState({
    height: '42rem',
    width: '63rem',
    backgroundColor: 'white',
    marginTop: '.2rem',
    borderRadius: '10px',
  })

  useEffect(() => {
    if (isMobile) {
      setStyles({
        height: '42rem',
        backgroundColor: 'white',
        marginTop: '.2rem',
        borderRadius: '10px',
      })
    } else {
      setStyles({
        height: '42rem',
        width: '63rem',
        backgroundColor: 'white',
        marginTop: '.2rem',
        borderRadius: '10px',
      })
    }
  }, [isMobile])

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      const payload = e.data.payload
      createCalendlyEvent(
        payload.event.uri.split('/').pop(),
        payload.invitee.uri.split('/').pop(),
      )
    },
  })

  const createCalendlyEvent = async (calendlyId, calendlyUserId) => {
    const url = calendlyEventsPath
    const data = {
      request_format: 'json',
      calendly_event: {
        candidate_id: candidateId,
        coach_id: coachId,
        calendly_id: calendlyId,
        calendly_user_id: calendlyUserId,
        career_development_candidate_coaching_program_id: coachingProgramId,
      },
    }

    const res = await api.post(url, data)

    if (res.status === 200) {
      setTimeout(() => {
        setShowCalendly(false)
        const event = { type: 'pending', ...res.data.calendlyEvent }
        setShowMeetingConfirmed(true)
        setIndividualEvent(event)
      }, 2000)
    } else {
      throw new Error('res not ok')
    }
  }

  const pageSettings = {
    pageBackgroundColor: '#f5f5f5',
  }

  const prefill = {
    name: candidateName,
    email: candidateEmail,
  }

  return (
    <div className="flex h-[42.4rem] justify-center rounded-[10px] bg-gradient-calendly md:m-auto md:w-[63.4rem]">
      <div>
        <InlineWidget
          url={coachCalendlyUrl}
          prefill={prefill}
          styles={styles}
          pageSettings={pageSettings}
        />
      </div>
    </div>
  )
}
export default Calendly
