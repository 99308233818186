import * as React from 'react'
import useTranslation from '../../shared/TranslationProvider'

const Errors = ({ errors, field }) => {
  const { t } = useTranslation()
  const translateField = (field) => {
    if (field === 'password_confirmation') return t('password')
    if (field === 'current_password') return t('password')
    return t(field)
  }

  if (field == 'password')
    return (
      errors &&
      errors.password && (
        <ul className="form-errors-list">
          <li className="form-error" key={`password-error-0`}>
            {t('ensure_password_guidelines')}
          </li>
        </ul>
      )
    )
  else
    return (
      errors &&
      errors[field] && (
        <ul className="form-errors-list">
          {errors[field].map((error, index) => (
            <li className="form-error" key={`${error}-${index}`}>
              {translateField(field)} {error}
            </li>
          ))}
        </ul>
      )
    )
}

export default Errors
