import React, { useState } from 'react'
import { useData } from '../DataProvider'
import { PaperClip, RedX, GreenCheck } from '../Icons'
import { SpinnerWhite } from '../../shared/Spinner'
import { Label } from '../Label'
import { Button } from '../../../lib/ui/button'
import { Input } from '../../../lib/ui/input'
import { Textarea } from '../../../lib/ui/textarea'
import DesiredLocation from './DesiredLocation'
import useTranslation from '../../shared/TranslationProvider'
import CharCounter from '../CharCounter'

const Step3Default = () => {
  const {
    desiredJobTitle,
    desiredIndustry,
    linkedInProfile,
    resumeName,
    setDesiredJobTitle,
    setLinkedInProfile,
    setDesiredIndustry,
    setResumeName,
    aboutYourself,
    setAboutYourself,
    resumeFileAcceptList,
    getApi,
  } = useData()

  const [resumeIsUploading, setResumeIsUploading] = useState(false)
  const [resumeUploadStatus, setResumeUploadStatus] = useState('')

  const maxLength = 500

  const { t } = useTranslation()

  const resumeChangeEvent = async (event) => {
    setResumeIsUploading(true)
    const config = { headers: { 'content-type': 'multipart/form-data' } }
    const formData = new FormData()
    formData.append('file', event.target.files[0])
    try {
      const {
        data: { name },
      } = await getApi().post('/onboarding.json', formData, config)
      setResumeIsUploading(false)
      setResumeName(name)
      setResumeUploadStatus('success')
    } catch (error) {
      setResumeIsUploading(false)
      setResumeUploadStatus('failure')
    }
  }

  return (
    <>
      <div className="flex flex-col gap-8 border-r pr-4">
        <div>
          <Label htmlFor="desired-job-title">{t('step3.desired_title')}:</Label>
          <Input
            id="desired-job-title"
            type="text"
            className="mt-2"
            name="desired-job-title"
            value={desiredJobTitle}
            onChange={(e) => setDesiredJobTitle(e.target.value)}
          />
        </div>
        <DesiredLocation />
        <div>
          <Label htmlFor="desired-industry">
            {t('step3.desired_industry')}:
          </Label>
          <Input
            id="desired-industry"
            type="text"
            className="mt-2"
            name="desired-industry"
            value={desiredIndustry}
            onChange={(e) => setDesiredIndustry(e.target.value)}
          />
        </div>
        <div>
          <Label htmlFor="resume">{t('step3.resume.description')}:</Label>
          <p className="pb-4 text-sm">{t('step3.resume.subtext')}</p>
          <div className="flex flex-row">
            <Button
              onClick={() => {
                document.getElementById('resume-uploader').click()
              }}
              disabled={resumeName || resumeIsUploading}
              className="bg-primary hover:bg-primary-darker-75 disabled:opacity-100 disabled:brightness-75"
            >
              {resumeName ? resumeName : t('step3.resume.upload')}
              {resumeIsUploading ? (
                <SpinnerWhite className="ml-2" show />
              ) : (
                <PaperClip />
              )}
            </Button>
            {resumeUploadStatus == 'success' && (
              <div className="flex flex-row items-center">
                <span className="ml-4 mr-2">{t('step3.resume.success')}</span>{' '}
                <GreenCheck />
              </div>
            )}
            {resumeUploadStatus == 'failure' && (
              <div className="flex flex-row items-center">
                <span className="ml-4 mr-2">{t('step3.resume.failure')}</span>{' '}
                <RedX />
              </div>
            )}
          </div>
          <input
            type="file"
            id="resume-uploader"
            accept={resumeFileAcceptList}
            hidden
            onChange={resumeChangeEvent}
          />
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col">
          <Label htmlFor="about_yourself" className="pb-2">
            {t('step3.about_yourself')}:
          </Label>
          <Textarea
            id="about_yourself"
            onChange={(e) => setAboutYourself(e.target.value)}
            value={aboutYourself}
            rows={10}
            maxLength={maxLength}
          />
          <CharCounter value={aboutYourself} maxLength={maxLength} />
        </div>
        <div className="hidden sm:block">
          <Label htmlFor="linked-in-url">{t('step3.provide_linkedin')}:</Label>
          <p className="pb-2 text-sm">{t('step3.linkedin_description')}</p>
          <Input
            id="linked-in-url"
            type="text"
            value={linkedInProfile}
            onChange={(e) => setLinkedInProfile(e.target.value)}
          />
        </div>
      </div>
    </>
  )
}

export default Step3Default
