import React, { useState } from 'react'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from '../../../lib/ui/dropdown-menu'
import { useTranslation } from '../../../hooks/useTranslation'
import { TrashIcon, FavoriteIcon } from './icons'
import { apiPatch } from '../../../util/api'

export function TrackItemMenu(props) {
  const { t } = useTranslation()
  const { slug, favorite, setIsSkipped, setIsMenuOpen, tile } = props
  const [isFavorite, setFavorite] = useState(favorite)

  const handleMenuChange = (open) => {
    setIsMenuOpen(open)
  }

  const removeItem = async () => {
    const res = await apiPatch('/trackers/skip_item', {
      slug: slug,
    })
    if (res.status === 200) {
      setIsSkipped(true)
      tile.skipped = true
      return res
    } else {
      throw new Error('res not ok')
    }
  }

  const favoriteItem = async () => {
    const res = await apiPatch('/trackers/favorite_item', {
      slug: slug,
    })
    if (res.status === 200) {
      setFavorite(!isFavorite)
    } else {
      throw new Error('res not ok')
    }
  }

  return (
    <DropdownMenu onOpenChange={handleMenuChange}>
      <DropdownMenuTrigger aria-label="subsection menu">
        <div className="track-dropdown-trigger h-[38px] w-[52px] cursor-pointer rounded-[6px] pl-[16px] pt-[16px] hover:bg-accent">
          <svg
            width="20"
            height="6"
            viewBox="0 0 20 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse cx="2" cy="2.8437" rx="2" ry="2.21675" fill="#08467C" />
            <ellipse cx="10" cy="2.8437" rx="2" ry="2.21675" fill="#08467C" />
            <ellipse cx="18" cy="2.8437" rx="2" ry="2.21675" fill="#08467C" />
          </svg>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        <DropdownMenuRadioGroup>
          <DropdownMenuRadioItem
            value="remove"
            className="cursor-pointer text-base"
            onClick={removeItem}
          >
            {t('remove')} <TrashIcon className="ml-[30px] inline-block" />
          </DropdownMenuRadioItem>
          <DropdownMenuRadioItem
            value="favorite"
            className="cursor-pointer text-base"
            onClick={favoriteItem}
          >
            {t('favorite')}{' '}
            <FavoriteIcon
              className="ml-[30px] inline-block"
              favorite={isFavorite}
            />
          </DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
