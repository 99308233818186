import * as React from 'react'

import { useQuery } from '@tanstack/react-query'
import { api } from '../../../util/api'
import { format, parse } from 'date-fns'
import { useTranslation } from '../../../hooks/useTranslation'
import { cn } from '../../../lib/utils'

export function Activity({ job }) {
  const { t } = useTranslation('Activity')
  const { data } = useQuery({
    queryKey: ['get-activity', job.id],
    queryFn: () => getSavedJobActivity(job.id),
  })

  const activityLength = data?.length || 0

  return (
    <div className="m-4 flex w-56 flex-col gap-2 rounded-xl border-2 border-primary-lighter-30 p-4">
      <div className="text-lg font-bold">{t('activity')}</div>
      <div className="flex flex-col">
        {data?.reverse().map((activity, i) => (
          <ActivityItem
            key={activity.id}
            activity={activity}
            isLastItem={i === activityLength - 1}
          />
        ))}
      </div>
    </div>
  )
}

const getSavedJobActivity = async (jobId) => {
  const response = await api.get(`/job-search/saved_jobs/${jobId}/activity`)

  return response.data
}

function ActivityItem({ activity, isLastItem }) {
  return (
    <div key={activity.id} className="flex flex-col">
      <div className="flex flex-row">
        <Dot />
        <ActivityText activity={activity} />
      </div>
      <div className="flex flex-row">
        <Bar visible={!isLastItem} />
        <div>{format(activity.created_at, 'P')}</div>
      </div>
    </div>
  )
}

function Dot() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8 8"
      height="12"
      width="12"
      className="my-2 ml-1 mr-2 text-primary-lighter-30"
    >
      <circle cx="4" cy="4" r="4" fill="currentColor" />
    </svg>
  )
}

function Bar({ visible }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2 20"
      className={cn(
        'ml-2 mr-3 h-10 text-cod-gray-400',
        visible ? 'visible' : 'invisible',
      )}
    >
      <rect width="2" height="20" rx="1" fill="currentColor" />
    </svg>
  )
}

function ActivityText({ activity }) {
  const { t } = useTranslation('Activity')

  let text
  if (activity.action === 'saved_job_posting') {
    text = t('saved')
  } else if (activity.action === 'saved_outside_job') {
    text = t('saved')
  } else if (activity.action === 'updated_status') {
    text = t(activity.new_value)
  } else if (activity.action === 'updated_followup_on') {
    if (activity.new_value) {
      const date = parse(activity.new_value, 'yyyy-MM-dd', new Date())
      text = `${t('followup_date_set')} (${format(date, 'P')})`
    } else {
      text = t('followup_date_cleared')
    }
  } else {
    text = `Unknown action: ${activity.action}`
  }

  return <div className="content-center font-semibold leading-none">{text}</div>
}
