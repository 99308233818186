import * as React from 'react'

import translations from './locales.json'
import { TranslationProvider } from '../shared/TranslationProvider'
import OutplacementBenefits from './OutplacementBenefits'
import CoachFeedback from './CoachFeedback'
import MeetYourCoach from './MeetYourCoach'
import BenefitsProvider from './BenefitsProvider'

export default function Page({
  action = 'outplacement_benefits',
  feedback,
  program_level,
  calendar_events,
  benefit_status,
  locale,
  ...props
}) {
  return (
    <TranslationProvider
      locale={locale}
      translations={translations}
      scope="javascript.components.coach_services.OutplacementBenefits"
    >
      <BenefitsProvider {...props}>
        {
          {
            meet_your_coach: <MeetYourCoach />,
            outplacement_benefits: (
              <OutplacementBenefits
                program_level={program_level}
                calendar_events={calendar_events}
                benefit_status={benefit_status}
              />
            ),
            coach_feedback: <CoachFeedback feedback={feedback} />,
          }[action]
        }
      </BenefitsProvider>
    </TranslationProvider>
  )
}

const n = {
  action: 'outplacement_benefits',
  program_level: 'premium',
  coach: {
    id: 2572,
    first_name: 'Coach',
    last_name: 'Kenya',
    status: 'active',
    email: 'katilaw+kenyacoach@gmail.com',
    created_at: '2022-01-13T20:45:09.000Z',
    updated_at: '2022-01-13T20:47:55.000Z',
    company_id: 764,
    expires_at: null,
    address: '',
    city: '',
    state: '',
    postal_code: '',
    country: 'KE',
    phone: '508-000-0000',
    program_level: null,
    coach_id: null,
    allow_marketing_email: null,
    invitation_id: null,
    candidate_page_views_count: 0,
    activated_at: null,
    division_id: null,
    product: 'OUTPLACEMENT',
    password_changed_at: '2022-01-13T20:47:55.000Z',
    seniority_level: null,
    last_noted_at: null,
    resume_followup_sent_at: null,
    i18n_reviewer: null,
    locale: 'en',
    terms_accepted: null,
    allow_careerarc_marketing_email: null,
    quebec: null,
    external_user_id: null,
    registration_ip: null,
    registration_country: null,
    is_eu: null,
    test: null,
    ca_terms_accepted_at: null,
    surveyed_at: null,
    mobile: null,
    expiration_reason: null,
    personalized_dashboard: false,
    activity_points: 0,
    source: null,
    demo: false,
    outplacement_settings_id: null,
    partner_company_id: null,
    phone_type: null,
    encrypted_otp_secret: null,
    encrypted_otp_secret_iv: null,
    encrypted_otp_secret_salt: null,
    consumed_timestep: null,
    otp_required_for_login: null,
    skip_mfa: null,
    minutes_in_system: null,
    non_platform: null,
    select_queue_released: false,
    tiles: null,
    session_token: null,
    skip_policy_acceptance: null,
    pre_platform: false,
    career_development_queue_released: null,
    coach_services: null,
    service_time: null,
    first_sign_in_at: null,
    premium_queue_released: false,
    current_login_token: null,
    until_landing_support: false,
    ayla_support: false,
    demoing: false,
    employee_id: null,
    resume_review_phone_number: null,
    accessibility_settings: false,
    subprocessor_notifications: false,
    new_platform: null,
    good_habitz_search: false,
    show_talentguide: false,
    email_confirmed: null,
    show_elearnings: false,
    alternate_email: null,
    next_contact_date: null,
    otp_secret: null,
  },
  pending_event: null,
  locale: 'en',
  calendar_events: [
    {
      id: 3,
      coach_id: 20,
      link: 'www.google.com',
      start_at: '2023-03-03T18:00:00.000Z',
      created_at: '2023-03-03T20:43:17.959Z',
      updated_at: '2024-01-25T14:31:43.818Z',
      email_text: 'This is the opening text that I want to send',
      webinar_type: 'Skylar Test Webinar',
      tile_text: 'to learn more about this webinar!',
      zoom_id: null,
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 1,
      number_attendees: null,
    },
    {
      id: 4,
      coach_id: 20,
      link: 'www.google.com',
      start_at: '2023-03-07T17:43:00.000Z',
      created_at: '2023-03-03T20:43:57.570Z',
      updated_at: '2024-01-25T14:31:43.848Z',
      email_text: 'This is the first line of text. ',
      webinar_type: 'Skylar Test Webinar',
      tile_text: 'to learn more!',
      zoom_id: null,
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 1,
      number_attendees: null,
    },
    {
      id: 5,
      coach_id: 20,
      link: 'www.google.com',
      start_at: '2023-03-08T17:44:00.000Z',
      created_at: '2023-03-03T20:44:56.193Z',
      updated_at: '2024-01-25T14:31:43.877Z',
      email_text: 'comes second.',
      webinar_type: 'Skylar Test Webinar2',
      tile_text: 'Line of text here',
      zoom_id: null,
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 2,
      number_attendees: null,
    },
    {
      id: 6,
      coach_id: 2634,
      link: 'https://intoo.zoom.us/webinar/register/WN_mJkraefXSnG-W-UXxhPMLQ',
      start_at: '2023-04-26T14:30:00.000Z',
      created_at: '2023-04-25T13:51:21.697Z',
      updated_at: '2024-01-25T14:31:43.906Z',
      email_text: 'Welcome to the webinar!',
      webinar_type: 'Resume Development Webinar',
      tile_text: 'Join Coach Lisa for a Resume Webinar tomorrow at 10:30am.',
      zoom_id: '123455666',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 3,
      number_attendees: null,
    },
    {
      id: 7,
      coach_id: 3822,
      link: 'www.webinar.com',
      start_at: '2023-04-28T21:39:00.000Z',
      created_at: '2023-04-28T21:40:01.368Z',
      updated_at: '2024-01-25T14:31:43.933Z',
      email_text: 'new webinar!',
      webinar_type: 'Live events',
      tile_text: 'new webinar',
      zoom_id: '12345',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 4,
      number_attendees: null,
    },
    {
      id: 8,
      coach_id: 3822,
      link: 'www.webinar.com',
      start_at: '2023-05-03T12:41:00.000Z',
      created_at: '2023-04-28T21:41:29.588Z',
      updated_at: '2024-01-25T14:31:43.954Z',
      email_text: 'Join us for this next webinar',
      webinar_type: 'Live events',
      tile_text: 'Upcoming webinar!',
      zoom_id: '12345',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url:
        'https://intoo.zoom.us/rec/share/uZJqkCRQAca2xoEgtOXWKfJ0eGQdIIEgMpRRPRE2v-bIDkCPIS_R97tprTKdr3g3.IMcShE_8oLoXkxE3',
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 4,
      number_attendees: null,
    },
    {
      id: 9,
      coach_id: 3237,
      link: 'link',
      start_at: '2023-05-04T15:00:00.000Z',
      created_at: '2023-05-04T14:10:18.871Z',
      updated_at: '2024-01-25T14:31:43.982Z',
      email_text: 'sorry for the emails!  just a test',
      webinar_type: 'test webinar',
      tile_text: 'test',
      zoom_id: 'zoom',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url:
        'https://intoo.zoom.us/rec/share/3oRdwdghjsDDW8Rhlg0WBs0CLBS57YlBDKShxYYMcKRLXHLgXHWjbjThMkGwTxMc.4LezUoi1ZC-uJVxV',
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 5,
      number_attendees: null,
    },
    {
      id: 10,
      coach_id: 20,
      link: 'www.google.com',
      start_at: '2023-05-09T07:00:00.000Z',
      created_at: '2023-05-04T16:20:10.725Z',
      updated_at: '2024-01-25T14:31:44.010Z',
      email_text: 'Testing',
      webinar_type: 'Jump Start Webinar',
      tile_text: 'Testing',
      zoom_id: '810 3418 0166',
      duration_minutes: 66,
      service_level: 'All',
      status: 'completed',
      recording_url:
        'https://intoo.zoom.us/rec/share/2ENjFw71J6hzqlegnCklYMAriJn77gLiD77n2rNoFwwGd4B-KTFVE_T1rEu2t-5B.fEc3AZcaErFmx9V3',
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 6,
      number_attendees: null,
    },
    {
      id: 11,
      coach_id: 3755,
      link: 'https://www.google.com/',
      start_at: '2023-06-28T19:07:00.000Z',
      created_at: '2023-06-27T19:08:16.830Z',
      updated_at: '2024-01-25T14:31:44.037Z',
      email_text: 'test',
      webinar_type: 'webinar test',
      tile_text: 'test',
      zoom_id: '1234567890',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url:
        'https://intoo.zoom.us/rec/share/Lx-_WYuYzMe-YBsEkkVO_GsvraJnZJkNnwRed83KWGSGlNuOQm69ixckavKyMokq.2UEQLp6rHZJvljze',
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 7,
      number_attendees: null,
    },
    {
      id: 12,
      coach_id: 3822,
      link: 'https://www.google.com',
      start_at: '2023-06-29T00:00:00.000Z',
      created_at: '2023-06-28T19:13:39.110Z',
      updated_at: '2024-01-25T14:31:44.064Z',
      email_text:
        'this webinar will cover all the ins and outs of job hunting! don&apos;t miss it',
      webinar_type: 'upcoming webinar',
      tile_text:
        'for an incredible webinar covering all the ins and outs of job hunting, resume building, cover letter writing, and interviewing. don&apos;t miss it!',
      zoom_id: '5',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url:
        'https://intoo.zoom.us/rec/share/15y8vgHnwNvBPUI9aKnDAx2cTbm5FkL9fOdJ5ZleibN8vsXo8GMBJe7Ah-J71Gy8.1egJ9OKfkmYr5Si3',
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 8,
      number_attendees: null,
    },
    {
      id: 13,
      coach_id: 3822,
      link: 'https://www.google.com',
      start_at: '2023-06-30T00:00:00.000Z',
      created_at: '2023-06-28T19:14:57.688Z',
      updated_at: '2024-01-25T14:31:44.085Z',
      email_text:
        'this is another incredible webinar opportunity! don&apos;t miss it',
      webinar_type: 'upcoming webinar',
      tile_text:
        'for a wonderful webinar that will cover networking and preparing for new jobs.',
      zoom_id: '10',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url:
        'https://intoo.zoom.us/rec/share/IUgwEe3zbmn5z5dlAwHkf8_LP_LeUuBng9CGfOQ1ZJ42yyNpZiLe97PpFw4pfkbx.U2xPT2HgjeXsJxsh',
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 8,
      number_attendees: null,
    },
    {
      id: 14,
      coach_id: 3755,
      link: 'https://www.google.com/',
      start_at: '2023-06-30T02:00:00.000Z',
      created_at: '2023-06-28T19:54:18.629Z',
      updated_at: '2024-01-25T14:31:44.113Z',
      email_text: 'text',
      webinar_type: 'webinar test service',
      tile_text: 'text',
      zoom_id: '1',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url:
        'https://intoo.zoom.us/rec/share/nyNS2IgUGFp4h8xF7u5IOP69LVwq6Sba7lpOyDH0fArqrmsbxD5tmo-gNYonE8xz.mIDiGqBi1GHD4OL5',
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 9,
      number_attendees: null,
    },
    {
      id: 15,
      coach_id: 3755,
      link: 'https://www.google.com/',
      start_at: '2023-07-01T03:00:00.000Z',
      created_at: '2023-06-28T19:56:05.313Z',
      updated_at: '2024-01-25T14:31:44.134Z',
      email_text: 'text1',
      webinar_type: 'webinar test service',
      tile_text: 'text1',
      zoom_id: '2',
      duration_minutes: null,
      service_level: 'Flex',
      status: 'completed',
      recording_url:
        'https://intoo.zoom.us/rec/share/LQpucXeQ7fifWxqZp4iu-HvJgVUWKmkFzfZ4AqXIXKgnnUuBhgQDJuK_96cBjb9u.PzL03neUQ3CYLnk-',
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 9,
      number_attendees: null,
    },
    {
      id: 16,
      coach_id: 20,
      link: 'www.google.com',
      start_at: '2023-07-05T19:02:00.000Z',
      created_at: '2023-06-30T22:03:40.143Z',
      updated_at: '2024-01-25T14:31:44.156Z',
      email_text:
        'This will focus on creating a great resume. This is a key step in your job search process. Our professional career coaches are here to help you get started.',
      webinar_type: 'Resume Development Webinar',
      tile_text:
        'for a live session discussing strategies for improving your resume. Attend this session prior to your resume review to get tips on how to get the most out of your coach interactions.',
      zoom_id: '55555555',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url:
        'https://intoo.zoom.us/rec/share/ceZIs-tcVI944w64PTGpwFO-YKVmoqACmsTLwnk8toRILYioRT2oZlOcIAFak-Ba.o6Ja7CxM4RM6EZlT',
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 3,
      number_attendees: null,
    },
    {
      id: 17,
      coach_id: 3822,
      link: 'www.yahoo.com',
      start_at: '2023-07-07T21:10:00.000Z',
      created_at: '2023-06-30T22:04:40.326Z',
      updated_at: '2024-01-25T14:31:44.176Z',
      email_text:
        'This is our Jump Start webinar. This is an interactive webinar with live Q\u0026A to learn about INTOO’s outplacement career coaching and job seeker tools. You’ll gain insight into how we’ll partner with you to assess your priorities and needs during your career transition.',
      webinar_type: 'Jump Start Webinar',
      tile_text:
        'to learn about INTOO’s outplacement career coaching and job seeker tools. You’ll gain insight into how we’ll partner with you to assess your priorities and needs during your career transition.',
      zoom_id: '5555555',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url:
        'https://intoo.zoom.us/rec/share/vskks7v2Ea0uQm3BmHVNOjHA3j2PPx8zFCRqvr8lHZUqUGcd9DOE36-5QSoYIvaT.IUYcG1Y9XL3GsZVL',
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 6,
      number_attendees: null,
    },
    {
      id: 18,
      coach_id: 465,
      link: 'www.google.com/awesome',
      start_at: '2023-07-10T11:05:00.000Z',
      created_at: '2023-06-30T22:06:07.644Z',
      updated_at: '2024-01-25T14:31:44.204Z',
      email_text:
        'This is our LinkedIn 101 \u0026 Personal Branding. Join to "Unlock Your Potential: Mastering LinkedIn Branding and Visibility for Job Seekers". You&apos;ll learn how to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      webinar_type: 'Linkedin 101/Personal Branding Webinar',
      tile_text:
        'to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      zoom_id: '810 3418 0166',
      duration_minutes: 66,
      service_level: 'All',
      status: 'completed',
      recording_url:
        'https://intoo.zoom.us/rec/share/HqiIJ5SJTD6QWGPcz88JzvobblIs0p53sq3QDn-GLWDNf75huN0yB_sfzp4E9YAB.cVQgBrxy_6AgXThM',
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 10,
      number_attendees: null,
    },
    {
      id: 19,
      coach_id: 603,
      link: 'www.nyt.com',
      start_at: '2023-07-12T21:00:00.000Z',
      created_at: '2023-06-30T22:07:35.651Z',
      updated_at: '2024-01-25T14:31:44.233Z',
      email_text:
        'is our Ace the Interview Webinar. Join us to hear all the tips and tricks you need to make a great impression in your next interview.',
      webinar_type: 'Ace the Interview Webinar',
      tile_text:
        'to hear all the tips and tricks you need to make a great impression in your next interview.',
      zoom_id: '810 3418 0166',
      duration_minutes: 66,
      service_level: 'All',
      status: 'completed',
      recording_url:
        'https://intoo.zoom.us/rec/share/xvfGEzjRzYI5gHjZ-DBpINNrAwzEM5MUMINP-zah_ko6SEtnZF3cjesHZZYydBBS.M0jXn8GbJBIL6NW7',
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 11,
      number_attendees: null,
    },
    {
      id: 20,
      coach_id: 782,
      link: 'www.tesla.com',
      start_at: '2023-07-17T22:30:00.000Z',
      created_at: '2023-07-05T16:36:32.798Z',
      updated_at: '2024-01-25T14:31:44.254Z',
      email_text:
        '"At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. ',
      webinar_type: 'Ace the Interview Webinar',
      tile_text:
        '"At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. ',
      zoom_id: '810 3418 0166',
      duration_minutes: 66,
      service_level: 'All',
      status: 'completed',
      recording_url:
        'https://intoo.zoom.us/rec/share/gWQm0yW7jMx5bpgaxv3Dz2D2Sw71_72qff8V_COx8dD2NjEGJYX79Y2aGdGLIdB6.4QpGNP5aJixp742I',
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 11,
      number_attendees: null,
    },
    {
      id: 61,
      coach_id: 3822,
      link: 'https://intoo.zoom.us/u/kossz1Dd',
      start_at: '2023-07-20T21:15:00.000Z',
      created_at: '2023-07-20T21:12:58.586Z',
      updated_at: '2024-01-25T14:31:44.596Z',
      email_text: 'test',
      webinar_type: 'new adina test webinar',
      tile_text: 'test',
      zoom_id: '851 5435 2577',
      duration_minutes: 60,
      service_level: 'All',
      status: 'completed',
      recording_url:
        'https://intoo.zoom.us/rec/share/Ah9h2yqS2rM_KFzeRv1HdF53FJrhWVP8ybP6xjYezZKDdpfcsLMugs0v4q87YMGN.HcKwcxQfKPDEupRh',
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 17,
      number_attendees: null,
    },
    {
      id: 86,
      coach_id: 2528,
      link: 'zoom.com',
      start_at: '2023-07-26T15:00:00.000Z',
      created_at: '2023-07-26T14:04:49.408Z',
      updated_at: '2024-01-25T14:31:45.149Z',
      email_text: 'Leigh&apos;s email text test 7.25.23',
      webinar_type: '7.26.23 test webinar',
      tile_text: 'Leigh&apos;s tile text 7.25.23',
      zoom_id: 'ID ',
      duration_minutes: null,
      service_level: 'Flex',
      status: 'confirmed',
      recording_url:
        'https://intoo.zoom.us/rec/share/7NwiPU8nn86saiR2uHATwV1pU6JH1Ldf-s7TbSaIbSQ9aXPiA_hP--hraWjcjmqb.j5Z--cAPqp0yNj-M',
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 31,
      number_attendees: null,
    },
    {
      id: 70,
      coach_id: 3822,
      link: 'https://intoo.zoom.us/u/kLQhbOb13',
      start_at: '2023-07-26T21:05:00.000Z',
      created_at: '2023-07-24T18:05:26.048Z',
      updated_at: '2024-01-25T14:31:44.744Z',
      email_text: 'testing',
      webinar_type: 'New test webinar request coaches test email 123',
      tile_text: 'testing',
      zoom_id: '810 3418 0166',
      duration_minutes: 66,
      service_level: 'All',
      status: 'completed',
      recording_url:
        'https://intoo.zoom.us/rec/share/yVMkWYxj6d1w4X7gNig8OQSYlCvtfMq3jYaouorO0X-30Ec_ELyrO97Ge0OSauhy.diTl3u9JyXTij2bE',
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 20,
      number_attendees: null,
    },
    {
      id: 88,
      coach_id: 2528,
      link: 'https://intoo.zoom.us/webinar/register/WN_EACY_zzbRWmm1wfg-jX4kA',
      start_at: '2023-07-27T19:00:00.000Z',
      created_at: '2023-07-26T19:09:04.742Z',
      updated_at: '2024-01-25T14:31:45.205Z',
      email_text:
        'Join us for our Negotiating for Success Webinar that will cover a variety of topics including the importance of preparation and research,&nbsp;effective communication strategies, and the ability to create win-win situations. The webinar also emphasizes the need to understand your own value and worth, as well as the importance of maintaining a positive and professional attitude throughout the negotiation process.',
      webinar_type: 'Negotiating for Success Webinar',
      tile_text:
        'to review the importance of preparation and research, effective communication strategies, and the ability to create win-win situations.',
      zoom_id: '871 9159 7039',
      duration_minutes: 102,
      service_level: 'All',
      status: 'completed',
      recording_url:
        'https://intoo.zoom.us/rec/share/tU8IMMF-SWw5-HxLVMIkwcYkW6tHJHORRTG0US2t4qewZBXi16OcXiR2IXsPbvpM.4EB8fD0QiCvnU5Ju',
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 33,
      number_attendees: null,
    },
    {
      id: 23,
      coach_id: 20,
      link: 'www.google.com',
      start_at: '2023-08-02T01:00:00.000Z',
      created_at: '2023-07-12T04:01:08.695Z',
      updated_at: '2024-01-25T14:31:44.324Z',
      email_text:
        'will focus on creating a great resume. This is a key step in your job search process. Our professional career coaches are here to help you get started.',
      webinar_type: 'Resume Development Webinar',
      tile_text:
        'for a live session discussing strategies for improving your resume. Attend this session prior to your resume review to get tips on how to get the most out of your coach interactions.',
      zoom_id: '810 3418 0166',
      duration_minutes: 66,
      service_level: 'Flex',
      status: 'confirmed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 3,
      number_attendees: null,
    },
    {
      id: 92,
      coach_id: 9,
      link: 'https://intoo.zoom.us/j/92210574962',
      start_at: '2023-08-02T15:30:00.000Z',
      created_at: '2023-08-01T15:24:16.866Z',
      updated_at: '2024-01-25T14:31:45.276Z',
      email_text:
        'will focus on creating a great resume. This is a key step in your job search process. Our professional career coaches are here to help you get started.',
      webinar_type: 'Resume Development Webinar',
      tile_text:
        'for a live session discussing strategies for improving your resume. Attend this session prior to your resume review to get tips on how to get the most out of your coach interactions.',
      zoom_id: '92210574962',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url:
        'https://intoo.zoom.us/rec/share/8hp-Vj6cU8ciAvFsibpbQFMPq0xSpPvMaDY_1c3Ji1fRvKi_xkgOQbjJRrk_cjHZ.25cAQxetojhpL8JX',
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 3,
      number_attendees: null,
    },
    {
      id: 24,
      coach_id: 20,
      link: 'www.google.com',
      start_at: '2023-08-03T01:04:00.000Z',
      created_at: '2023-07-12T04:04:47.575Z',
      updated_at: '2024-01-25T14:31:44.346Z',
      email_text:
        'is our Jump Start webinar. This is an interactive webinar with live Q\u0026A to learn about INTOO’s outplacement career coaching and job seeker tools. You’ll gain insight into how we’ll partner with you to assess your priorities and needs during your career transition.',
      webinar_type: 'Jump Start Webinar',
      tile_text:
        'to learn about INTOO’s outplacement career coaching and job seeker tools. You’ll gain insight into how we’ll partner with you to assess your priorities and needs during your career transition.',
      zoom_id: '856 9878 9590',
      duration_minutes: 70,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 6,
      number_attendees: null,
    },
    {
      id: 25,
      coach_id: 20,
      link: 'www.google.com',
      start_at: '2023-08-04T01:18:00.000Z',
      created_at: '2023-07-12T04:18:28.850Z',
      updated_at: '2024-01-25T14:31:44.367Z',
      email_text:
        'By adding a confirmed webinar to the calendar, you are creating a tile that will display for OP candidates. Only one webinar tile at a time will display.',
      webinar_type: 'Ace the Interview Webinar',
      tile_text:
        'By adding a confirmed webinar to the calendar, you are creating a tile that will display for OP candidates. Only one webinar tile at a time will display.',
      zoom_id: '810 3418 0166',
      duration_minutes: 66,
      service_level: 'All',
      status: 'confirmed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 11,
      number_attendees: null,
    },
    {
      id: 94,
      coach_id: 3822,
      link: 'https://intoo.zoom.us/webinar/register/WN_tWUcCXZPSDCXwoSi_vfrKA',
      start_at: '2023-08-04T21:15:00.000Z',
      created_at: '2023-08-01T21:15:16.671Z',
      updated_at: '2024-01-25T14:31:45.334Z',
      email_text:
        'is our Ace the Interview Webinar. Join us to hear all the tips and tricks you need to make a great impression in your next interview.',
      webinar_type: 'Try a test webinar for flow',
      tile_text:
        'to hear all the tips and tricks you need to make a great impression in your next interview.',
      zoom_id: '33243423',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 36,
      number_attendees: null,
    },
    {
      id: 91,
      coach_id: 3822,
      link: 'https://www.webinar.com',
      start_at: '2023-08-20T15:01:00.000Z',
      created_at: '2023-08-01T15:01:50.203Z',
      updated_at: '2024-01-25T14:31:45.254Z',
      email_text:
        'is our Jump Start webinar. This is an interactive webinar with live Q\u0026A to learn about INTOO’s outplacement career coaching and job seeker tools. You’ll gain insight into how we’ll partner with you to assess your priorities and needs during your career transition.',
      webinar_type: 'Jump Start Webinar',
      tile_text:
        'to learn about INTOO’s outplacement career coaching and job seeker tools. You’ll gain insight into how we’ll partner with you to assess your priorities and needs during your career transition.',
      zoom_id: '810 3418 0166',
      duration_minutes: 66,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 6,
      number_attendees: null,
    },
    {
      id: 95,
      coach_id: 3822,
      link: 'www.webinar.com',
      start_at: '2023-09-05T16:57:36.000Z',
      created_at: '2023-09-05T13:57:36.448Z',
      updated_at: '2024-01-25T14:31:45.362Z',
      email_text: 'new webinar',
      webinar_type: 'LinkedIn',
      tile_text: 'new webinar',
      zoom_id: '12345',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 37,
      number_attendees: null,
    },
    {
      id: 96,
      coach_id: 3822,
      link: 'www.webinar.com',
      start_at: '2023-09-06T14:16:15.000Z',
      created_at: '2023-09-05T14:16:15.241Z',
      updated_at: '2024-01-25T14:31:45.383Z',
      email_text: 'new webinar',
      webinar_type: 'LinkedIn',
      tile_text: 'new webinar',
      zoom_id: '12345',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 37,
      number_attendees: null,
    },
    {
      id: 97,
      coach_id: 3822,
      link: 'www.webinar.com',
      start_at: '2023-09-07T14:16:45.000Z',
      created_at: '2023-09-05T14:16:45.715Z',
      updated_at: '2024-01-25T14:31:45.405Z',
      email_text: 'new webinar',
      webinar_type: 'LinkedIn',
      tile_text: 'new webinar',
      zoom_id: '12345',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 37,
      number_attendees: null,
    },
    {
      id: 98,
      coach_id: 3822,
      link: 'www.webinar.com',
      start_at: '2023-09-09T14:16:57.000Z',
      created_at: '2023-09-05T14:16:57.784Z',
      updated_at: '2024-01-25T14:31:45.424Z',
      email_text: 'new webinar',
      webinar_type: 'LinkedIn',
      tile_text: 'new webinar',
      zoom_id: '12345',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 37,
      number_attendees: null,
    },
    {
      id: 99,
      coach_id: 3822,
      link: 'www.webinar.com',
      start_at: '2023-09-10T14:17:09.000Z',
      created_at: '2023-09-05T14:17:10.104Z',
      updated_at: '2024-01-25T14:31:45.445Z',
      email_text: 'new webinar',
      webinar_type: 'LinkedIn',
      tile_text: 'new webinar',
      zoom_id: '12345',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 37,
      number_attendees: null,
    },
    {
      id: 102,
      coach_id: 2528,
      link: 'link',
      start_at: '2023-09-14T14:00:00.000Z',
      created_at: '2023-09-12T13:43:15.756Z',
      updated_at: '2024-01-25T14:31:45.530Z',
      email_text:
        'is our Ace the Interview Webinar. Join us to hear all the tips and tricks you need to make a great impression in your next interview.',
      webinar_type: '9-14',
      tile_text:
        'to hear all the tips and tricks you need to make a great impression in your next interview.',
      zoom_id: 'id',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 40,
      number_attendees: null,
    },
    {
      id: 101,
      coach_id: 2528,
      link: 'link',
      start_at: '2023-09-15T13:08:00.000Z',
      created_at: '2023-09-12T13:09:22.539Z',
      updated_at: '2024-01-25T14:31:45.501Z',
      email_text:
        'is our Ace the Interview Webinar. Join us to hear all the tips and tricks you need to make a great impression in your next interview.',
      webinar_type: '9-15 web',
      tile_text:
        'to hear all the tips and tricks you need to make a great impression in your next interview.',
      zoom_id: 'id',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 39,
      number_attendees: null,
    },
    {
      id: 100,
      coach_id: 2528,
      link: 'link.com',
      start_at: '2023-09-20T20:10:00.000Z',
      created_at: '2023-09-11T20:10:30.826Z',
      updated_at: '2024-01-25T14:31:45.474Z',
      email_text:
        'is our Ace the Interview Webinar. Join us to hear all the tips and tricks you need to make a great impression in your next interview.',
      webinar_type: 'Test 9/11',
      tile_text:
        'to hear all the tips and tricks you need to make a great impression in your next interview.',
      zoom_id: 'zoom.com',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 38,
      number_attendees: null,
    },
    {
      id: 108,
      coach_id: 3755,
      link: 'link',
      start_at: '2023-10-06T19:00:00.000Z',
      created_at: '2023-10-05T14:59:40.563Z',
      updated_at: '2024-01-25T14:31:45.615Z',
      email_text:
        'is our LinkedIn 101 \u0026 Personal Branding Webinar. Join to &apos;Unlock Your Potential: Mastering LinkedIn Branding and Visibility for Job Seekers&apos;. You&apos;ll learn how to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      webinar_type: 'Linkedin 101/Personal Branding Webinar',
      tile_text:
        'to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      zoom_id: '1234567890',
      duration_minutes: null,
      service_level: 'All',
      status: 'confirmed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 10,
      number_attendees: null,
    },
    {
      id: 104,
      coach_id: 3755,
      link: 'link',
      start_at: '2023-10-09T21:00:00.000Z',
      created_at: '2023-10-02T21:04:15.343Z',
      updated_at: '2024-01-25T14:31:45.573Z',
      email_text:
        'is our LinkedIn 101 \u0026 Personal Branding Webinar. Join to &apos;Unlock Your Potential: Mastering LinkedIn Branding and Visibility for Job Seekers&apos;. You&apos;ll learn how to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      webinar_type: 'Linkedin 101/Personal Branding Webinar',
      tile_text:
        'to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      zoom_id: '1234567890',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 10,
      number_attendees: null,
    },
    {
      id: 107,
      coach_id: 3755,
      link: 'link',
      start_at: '2023-10-10T22:00:00.000Z',
      created_at: '2023-10-03T14:36:46.574Z',
      updated_at: '2024-01-25T14:31:45.594Z',
      email_text:
        'is our LinkedIn 101 \u0026 Personal Branding Webinar. Join to &apos;Unlock Your Potential: Mastering LinkedIn Branding and Visibility for Job Seekers&apos;. You&apos;ll learn how to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      webinar_type: 'Linkedin 101/Personal Branding Webinar',
      tile_text:
        'to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      zoom_id: '1234567890',
      duration_minutes: null,
      service_level: 'All',
      status: 'confirmed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 10,
      number_attendees: null,
    },
    {
      id: 110,
      coach_id: 3755,
      link: 'link',
      start_at: '2023-10-11T21:00:00.000Z',
      created_at: '2023-10-05T18:47:10.841Z',
      updated_at: '2024-01-25T14:31:45.636Z',
      email_text:
        'is our LinkedIn 101 \u0026 Personal Branding Webinar. Join to &apos;Unlock Your Potential: Mastering LinkedIn Branding and Visibility for Job Seekers&apos;. You&apos;ll learn how to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      webinar_type: 'Linkedin 101/Personal Branding Webinar',
      tile_text:
        'to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      zoom_id: '1234567890',
      duration_minutes: null,
      service_level: 'All',
      status: 'confirmed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 10,
      number_attendees: null,
    },
    {
      id: 111,
      coach_id: 3755,
      link: 'link',
      start_at: '2023-10-12T21:00:00.000Z',
      created_at: '2023-10-05T18:48:43.419Z',
      updated_at: '2024-01-25T14:31:45.658Z',
      email_text:
        'is our LinkedIn 101 \u0026 Personal Branding Webinar. Join to &apos;Unlock Your Potential: Mastering LinkedIn Branding and Visibility for Job Seekers&apos;. You&apos;ll learn how to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      webinar_type: 'Linkedin 101/Personal Branding Webinar',
      tile_text:
        'to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      zoom_id: '1234567890',
      duration_minutes: null,
      service_level: 'All',
      status: 'confirmed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 10,
      number_attendees: null,
    },
    {
      id: 117,
      coach_id: 20,
      link: 'https://intoo.zoom.us/webinar/register/WN_K8m-WWpRSvKJ2Lpf3cb1dA',
      start_at: '2023-10-13T14:00:00.000Z',
      created_at: '2023-10-10T17:42:28.000Z',
      updated_at: '2024-01-25T14:31:45.743Z',
      email_text:
        'will focus on creating a great resume. This is a key step in your job search process. Our professional career coaches are here to help you get started.',
      webinar_type: 'Resume Development Webinar',
      tile_text:
        'for a live session discussing strategies for improving your resume. Attend this session prior to your resume review to get tips on how to get the most out of your coach interactions.',
      zoom_id: '879 1695 8759',
      duration_minutes: 82,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 3,
      number_attendees: null,
    },
    {
      id: 114,
      coach_id: 3755,
      link: 'link',
      start_at: '2023-10-13T21:00:00.000Z',
      created_at: '2023-10-06T14:24:27.368Z',
      updated_at: '2024-01-25T14:31:45.701Z',
      email_text:
        'is our LinkedIn 101 \u0026 Personal Branding Webinar. Join to &apos;Unlock Your Potential: Mastering LinkedIn Branding and Visibility for Job Seekers&apos;. You&apos;ll learn how to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      webinar_type: 'Linkedin 101/Personal Branding Webinar',
      tile_text:
        'to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      zoom_id: '1234567890',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 10,
      number_attendees: null,
    },
    {
      id: 118,
      coach_id: 20,
      link: 'https://intoo.zoom.us/webinar/register/WN_M1jNz_BGTJqipPc6sT1aTA',
      start_at: '2023-10-16T17:00:00.000Z',
      created_at: '2023-10-10T17:45:15.856Z',
      updated_at: '2024-01-25T14:31:45.765Z',
      email_text:
        'is our Ace the Interview Webinar. Join us to hear all the tips and tricks you need to make a great impression in your next interview.',
      webinar_type: 'Ace the Interview Webinar',
      tile_text:
        'to hear all the tips and tricks you need to make a great impression in your next interview.',
      zoom_id: '836 6400 0758',
      duration_minutes: 60,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 11,
      number_attendees: null,
    },
    {
      id: 120,
      coach_id: 3822,
      link: 'https://intoo.zoom.us/webinar/register/WN_WkJD5pmMQ7Ga1R6_LaJs7w',
      start_at: '2023-10-27T14:50:00.000Z',
      created_at: '2023-10-10T17:50:12.702Z',
      updated_at: '2024-01-25T14:31:45.809Z',
      email_text:
        'will focus on creating a great resume. This is a key step in your job search process. Our professional career coaches are here to help you get started.',
      webinar_type: 'Resume Development Webinar',
      tile_text:
        'for a live session discussing strategies for improving your resume. Attend this session prior to your resume review to get tips on how to get the most out of your coach interactions.',
      zoom_id: '879 1695 8759',
      duration_minutes: 82,
      service_level: 'Flex',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 3,
      number_attendees: null,
    },
    {
      id: 116,
      coach_id: 9,
      link: 'link',
      start_at: '2023-10-28T14:00:00.000Z',
      created_at: '2023-10-10T13:05:07.516Z',
      updated_at: '2024-01-25T14:31:45.722Z',
      email_text:
        'is our Jump Start webinar. This is an interactive webinar with live Q\u0026A to learn about INTOO’s outplacement career coaching and job seeker tools. You’ll gain insight into how we’ll partner with you to assess your priorities and needs during your career transition.',
      webinar_type: 'Jump Start Webinar',
      tile_text:
        'to learn about INTOO’s outplacement career coaching and job seeker tools. You’ll gain insight into how we’ll partner with you to assess your priorities and needs during your career transition.',
      zoom_id: 'id',
      duration_minutes: null,
      service_level: 'Flex',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 6,
      number_attendees: null,
    },
    {
      id: 121,
      coach_id: 3237,
      link: 'link',
      start_at: '2023-10-30T19:30:00.000Z',
      created_at: '2023-10-30T18:36:17.753Z',
      updated_at: '2024-01-25T14:31:45.829Z',
      email_text:
        'will focus on creating a great resume. This is a key step in your job search process. Our professional career coaches are here to help you get started.',
      webinar_type: 'Resume Development Webinar',
      tile_text:
        'for a live session discussing strategies for improving your resume. Attend this session prior to your resume review to get tips on how to get the most out of your coach interactions.',
      zoom_id: 'id',
      duration_minutes: null,
      service_level: 'Flex',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 3,
      number_attendees: null,
    },
    {
      id: 122,
      coach_id: 3237,
      link: 'https://intoo.zoom.us/j/92210574962',
      start_at: '2023-11-01T16:00:00.000Z',
      created_at: '2023-11-01T15:52:17.579Z',
      updated_at: '2024-01-25T14:31:45.850Z',
      email_text:
        'is our Jump Start webinar. This is an interactive webinar with live Q\u0026A to learn about INTOO’s outplacement career coaching and job seeker tools. You’ll gain insight into how we’ll partner with you to assess your priorities and needs during your career transition.',
      webinar_type: 'Jump Start Webinar',
      tile_text:
        'to learn about INTOO’s outplacement career coaching and job seeker tools. You’ll gain insight into how we’ll partner with you to assess your priorities and needs during your career transition.',
      zoom_id: '92210574962',
      duration_minutes: null,
      service_level: 'Flex',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 6,
      number_attendees: null,
    },
    {
      id: 123,
      coach_id: 3237,
      link: 'https://intoo.zoom.us/j/92210574962',
      start_at: '2023-11-02T19:00:00.000Z',
      created_at: '2023-11-02T18:39:54.315Z',
      updated_at: '2024-01-25T14:31:45.872Z',
      email_text:
        'will focus on creating a great resume. This is a key step in your job search process. Our professional career coaches are here to help you get started.',
      webinar_type: 'Resume Development Webinar',
      tile_text:
        'for a live session discussing strategies for improving your resume. Attend this session prior to your resume review to get tips on how to get the most out of your coach interactions.',
      zoom_id: '92210574962',
      duration_minutes: null,
      service_level: 'Flex',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 3,
      number_attendees: null,
    },
    {
      id: 124,
      coach_id: 3755,
      link: 'https://intoo.zoom.us/webinar/register/WN_WkJD5pmMQ7Ga1R6_LaJs7w',
      start_at: '2023-11-07T19:38:00.000Z',
      created_at: '2023-11-06T22:38:06.699Z',
      updated_at: '2024-01-25T14:31:45.893Z',
      email_text:
        'will focus on creating a great resume. This is a key step in your job search process. Our professional career coaches are here to help you get started.',
      webinar_type: 'Resume Development Webinar',
      tile_text:
        'for a live session discussing strategies for improving your resume. Attend this session prior to your resume review to get tips on how to get the most out of your coach interactions.',
      zoom_id: '810 3418 0166',
      duration_minutes: 66,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 3,
      number_attendees: null,
    },
    {
      id: 126,
      coach_id: 2528,
      link: 'zoom.com',
      start_at: '2023-12-18T15:19:00.000Z',
      created_at: '2023-12-18T15:32:30.148Z',
      updated_at: '2024-01-25T14:31:45.940Z',
      email_text:
        'is our Ace the Interview Webinar. Join us to hear all the tips and tricks you need to make a great impression in your next interview.',
      webinar_type: 'Ace the Interview Webinar',
      tile_text:
        'to hear all the tips and tricks you need to make a great impression in your next interview.',
      zoom_id: '123456',
      duration_minutes: null,
      service_level: 'All',
      status: 'confirmed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 11,
      number_attendees: null,
    },
    {
      id: 127,
      coach_id: 3822,
      link: 'zoom.com',
      start_at: '2023-12-20T18:21:00.000Z',
      created_at: '2023-12-18T18:21:39.453Z',
      updated_at: '2024-01-25T14:31:45.961Z',
      email_text:
        'is our Jump Start webinar. This is an interactive webinar with live Q\u0026A to learn about INTOO’s outplacement career coaching and job seeker tools. You’ll gain insight into how we’ll partner with you to assess your priorities and needs during your career transition.',
      webinar_type: 'Jump Start Webinar',
      tile_text:
        'to learn about INTOO’s outplacement career coaching and job seeker tools. You’ll gain insight into how we’ll partner with you to assess your priorities and needs during your career transition.',
      zoom_id: '123456',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 6,
      number_attendees: null,
    },
    {
      id: 134,
      coach_id: 2528,
      link: 'url',
      start_at: '2024-03-29T15:30:00.000Z',
      created_at: '2024-01-31T15:28:51.195Z',
      updated_at: '2024-03-30T07:00:54.962Z',
      email_text: 'new webinar',
      webinar_type: 'LinkedIn',
      tile_text: 'new webinar',
      zoom_id: 'id',
      duration_minutes: null,
      service_level: 'Flex',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: 197,
      supported_coach_webinar_id: 37,
      number_attendees: null,
    },
    {
      id: 135,
      coach_id: 3237,
      link: 'url',
      start_at: '2024-03-30T00:00:00.000Z',
      created_at: '2024-01-31T18:42:10.850Z',
      updated_at: '2024-03-30T07:00:55.144Z',
      email_text:
        'This is our LinkedIn 101 \u0026 Personal Branding. Join to "Unlock Your Potential: Mastering LinkedIn Branding and Visibility for Job Seekers". You&apos;ll learn how to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      webinar_type: 'Linkedin 101/Personal Branding Webinar',
      tile_text:
        'to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      zoom_id: 'id',
      duration_minutes: null,
      service_level: 'Flex',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 10,
      number_attendees: null,
    },
    {
      id: 133,
      coach_id: 3755,
      link: '',
      start_at: '2024-04-01T17:00:00.000Z',
      created_at: '2024-01-30T17:08:56.379Z',
      updated_at: '2024-04-02T07:00:56.396Z',
      email_text:
        'Join us for our Negotiating for Success Webinar that will cover a variety of topics including the importance of preparation and research,&nbsp;effective communication strategies, and the ability to create win-win situations. The webinar also emphasizes the need to understand your own value and worth, as well as the importance of maintaining a positive and professional attitude throughout the negotiation process.',
      webinar_type: 'Negotiating for Success Webinar',
      tile_text:
        'to review the importance of preparation and research, effective communication strategies, and the ability to create win-win situations.',
      zoom_id: '',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 33,
      number_attendees: null,
    },
    {
      id: 132,
      coach_id: 3755,
      link: '',
      start_at: '2024-04-03T17:00:00.000Z',
      created_at: '2024-01-30T17:08:26.228Z',
      updated_at: '2024-04-04T07:00:55.654Z',
      email_text:
        'is our LinkedIn 101 \u0026 Personal Branding Webinar. Join to &apos;Unlock Your Potential: Mastering LinkedIn Branding and Visibility for Job Seekers&apos;. You&apos;ll learn how to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      webinar_type: 'Personal Branding \u0026 LinkedIn Webinar',
      tile_text:
        'to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      zoom_id: '',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 41,
      number_attendees: null,
    },
    {
      id: 136,
      coach_id: 3237,
      link: 'url',
      start_at: '2024-05-09T14:30:00.000Z',
      created_at: '2024-05-07T14:42:11.787Z',
      updated_at: '2024-05-10T07:01:07.208Z',
      email_text: 'Testing Premium Webinar email',
      webinar_type: 'Leigh&apos;s test',
      tile_text: 'Testing for Premium ',
      zoom_id: '326457',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 12,
      number_attendees: null,
    },
    {
      id: 138,
      coach_id: 3755,
      link: 'https://www.google.com/',
      start_at: '2024-07-05T20:00:00.000Z',
      created_at: '2024-07-03T19:44:14.761Z',
      updated_at: '2024-07-06T07:01:01.854Z',
      email_text:
        'is our Ace the Interview Webinar. Join us to hear all the tips and tricks you need to make a great impression in your next interview.',
      webinar_type: 'Ace the Interview Webinar',
      tile_text:
        'to hear all the tips and tricks you need to make a great impression in your next interview.',
      zoom_id: '1',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 11,
      number_attendees: null,
    },
    {
      id: 141,
      coach_id: 3237,
      link: 'sadgdryh',
      start_at: '2024-07-22T18:30:00.000Z',
      created_at: '2024-07-17T18:39:48.605Z',
      updated_at: '2024-07-23T07:01:07.493Z',
      email_text:
        'is our Jump Start webinar. This is an interactive webinar with live Q\u0026A to learn about INTOO’s outplacement career coaching and job seeker tools. You’ll gain insight into how we’ll partner with you to assess your priorities and needs during your career transition.',
      webinar_type: 'Jump Start Webinar',
      tile_text:
        'to learn about INTOO’s outplacement career coaching and job seeker tools. You’ll gain insight into how we’ll partner with you to assess your priorities and needs during your career transition.',
      zoom_id: 'rtfhdhr',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 6,
      number_attendees: null,
    },
    {
      id: 149,
      coach_id: 2528,
      link: 'url',
      start_at: '2024-08-05T15:00:00.000Z',
      created_at: '2024-08-05T13:54:20.435Z',
      updated_at: '2024-08-06T07:01:08.576Z',
      email_text:
        'is our Jump Start webinar. This is an interactive webinar with live Q\u0026A to learn about INTOO’s outplacement career coaching and job seeker tools. You’ll gain insight into how we’ll partner with you to assess your priorities and needs during your career transition.',
      webinar_type: 'Jump Start Webinar',
      tile_text:
        'to learn about INTOO’s outplacement career coaching and job seeker tools. You’ll gain insight into how we’ll partner with you to assess your priorities and needs during your career transition.',
      zoom_id: '6464',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: 244,
      supported_coach_webinar_id: 6,
      number_attendees: null,
    },
    {
      id: 151,
      coach_id: 4286,
      link: 'intoo.com',
      start_at: '2024-08-08T18:00:00.000Z',
      created_at: '2024-08-05T13:56:14.544Z',
      updated_at: '2024-08-09T07:01:07.833Z',
      email_text:
        'This is our LinkedIn 101 \u0026 Personal Branding. Join to "Unlock Your Potential: Mastering LinkedIn Branding and Visibility for Job Seekers". You&apos;ll learn how to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      webinar_type: 'Linkedin 101/Personal Branding Webinar',
      tile_text:
        'to optimize your profile, increase your visibility to recruiters and hiring managers, and build a strong personal brand that will make you stand out from the crowd.',
      zoom_id: '1698',
      duration_minutes: null,
      service_level: 'All',
      status: 'completed',
      recording_url: null,
      invoice_minute_item_id: null,
      supported_coach_webinar_id: 10,
      number_attendees: null,
    },
  ],
  benefit_status: {
    orientation_call: 'in_progress',
    career_consultation_call: 'unsubmitted',
    resume_review: 'in_progress',
    socal_media_review: 'in_progress',
    target_company_research: 'unsubmitted',
    interview_review: 'unsubmitted',
  },
}
