import React from 'react'
import Divider from '@mui/material/Divider'
import { styled } from '@mui/material/styles'
import { Box, Button } from '@mui/joy'
import GoogleSso from './GoogleSso'
import AppleSso from './AppleSso'
import useTranslation from '../../../shared/TranslationProvider'
import { useData } from '../DataProvider'

export const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  '& > :not(style) ~ :not(style)': {
    marginTop: theme.spacing(2),
  },
}))

const SsoButtons = ({
  showGoogle = true,
  showApple = true,
  showOr = true,
  goBackFormState = null,
}) => {
  const { t } = useTranslation()
  const { setFormState } = useData()
  return (
    <Root>
      {showOr && <Divider className="uppercase">{t('or')}</Divider>}
      <Box className="sso-container">
        {showGoogle && <GoogleButton />}

        {showApple && <AppleButton />}

        {goBackFormState && (
          <Button
            className="back-button btn"
            size="lg"
            fullWidth
            onClick={() => setFormState(goBackFormState)}
          >
            {t('go_back')}
          </Button>
        )}
      </Box>
    </Root>
  )
}

export const AppleButton = () => {
  const navigateAppleSso = () => {
    window.location.href = '/auth/apple'
  }
  return (
    <Button
      size="lg"
      fullWidth
      className="sso-button btn"
      onClick={navigateAppleSso}
    >
      <AppleSso />
    </Button>
  )
}

export const GoogleButton = () => {
  const navigateGoogleSso = () => {
    window.location.href = '/auth/google_oauth2'
  }
  return (
    <Button
      className="gsi-material-button sso-button btn"
      size="lg"
      fullWidth
      onClick={navigateGoogleSso}
    >
      <GoogleSso />
    </Button>
  )
}

export default SsoButtons
