import React from 'react'
import ArticleCard from './ArticleCard'
import AudioCard from './AudioCard'
import QuizCard from './QuizCard'
import WorkshopCard from './WorkshopCard'
import ExerciseCard from './ExerciseCard'
import VideoCard from './VideoCard'
import NavHeader from './NavHeader'
import SkillsInventory from './SkillsInventoryComponents/SkillsInventory'
import SkillsAssessments from './SkillsInventoryComponents/SkillsAssessments'
import ResourcesProvider, { useResources } from './ResourcesProvider'
import { useTranslation } from './../../hooks/useTranslation'
import { TranslationProvider } from './../shared/TranslationProvider'
import translations from './locales.json'
import { cn } from '../../lib/utils'

const ArticleCards = () => {
  const { articles, favoritesActive, checkFavorite } = useResources()
  const displayArticles = favoritesActive
    ? articles.filter(checkFavorite)
    : articles
  return (
    <>
      {displayArticles.map((article) => (
        <ArticleCard key={article.id} article={article} />
      ))}
    </>
  )
}

const QuizCards = () => {
  const { quizzes, favoritesActive, checkResourceFavorite } = useResources()
  const displayQuizzes = favoritesActive
    ? quizzes.filter(checkResourceFavorite)
    : quizzes
  return (
    <>
      {displayQuizzes.map((quiz, index) => (
        <QuizCard key={index} quiz={quiz} />
      ))}
    </>
  )
}

const ExerciseCards = () => {
  const { exercises, favoritesActive, checkResourceFavorite } = useResources()
  const displayExercises = favoritesActive
    ? exercises.filter(checkResourceFavorite)
    : exercises
  return (
    <>
      {displayExercises.map((exercise, index) => (
        <ExerciseCard key={index} exercise={exercise} />
      ))}
    </>
  )
}

const AssessmentCards = () => {
  return
}

const AudioCards = () => {
  const { audioFiles, favoritesActive, checkResourceFavorite } = useResources()
  const displayAudioFiles = favoritesActive
    ? audioFiles.filter(checkResourceFavorite)
    : audioFiles
  return (
    <>
      {displayAudioFiles.map((audioFile, index) => (
        <AudioCard key={index} audioFile={audioFile} />
      ))}
    </>
  )
}

const WorkshopCards = () => {
  const { workshops, favoritesActive, checkResourceFavorite } = useResources()
  const displayWorkshops = favoritesActive
    ? workshops.filter(checkResourceFavorite)
    : workshops
  return (
    <>
      {displayWorkshops.map((workshop, index) => (
        <WorkshopCard key={index} workshop={workshop} />
      ))}
    </>
  )
}

const VideoCards = () => {
  const { videos, favoritesActive, checkResourceFavorite } = useResources()
  const displayVideos = favoritesActive
    ? videos.filter(checkResourceFavorite)
    : videos
  return (
    <>
      {displayVideos.map((video, index) => (
        <VideoCard key={index} video={video} />
      ))}
    </>
  )
}

const NoFavoritesMessage = () => {
  return (
    <div className="col-span-1 mt-5 text-center md:col-span-2">
      <img
        src="https://www-yns-com.s3.us-east-2.amazonaws.com/images/no_favorites.png"
        alt="No Favorites"
        className="mx-auto mb-6"
      />
      <h2 className="mb-4">No Favorites</h2>
      <p>
        Explore other sections and mark your preferred resources as favorites to
        see them displayed here.
      </p>
    </div>
  )
}

const EmptySkillStateButton = () => {
  const { skillsState, skillsInventory } = useResources()
  const { t } = useTranslation('index')

  return (
    skillsState === 'empty' &&
    skillsInventory && (
      <div>
        <a
          href={'/skills/workshops/skills-gap'}
          className={
            'flex w-full items-center justify-center rounded-3xl border bg-primary px-4 py-3 text-base font-medium text-white hover:!bg-primary-lighter-30 hover:text-white focus:text-white active:text-white sm:py-2 md:w-1/3'
          }
        >
          {t(`skills_inventory_intro.start_assessment`)}
        </a>
      </div>
    )
  )
}

const ResourcesInner = () => {
  const {
    articlesActive,
    audioActive,
    exercisesActive,
    quizzesActive,
    assessmentsActive,
    workshopsActive,
    favoritesActive,
    hasFavorites,
    module,
    skillsInventory,
    skillsState,
    graphViewActive,
    cardViewActive,
  } = useResources()
  const { t } = useTranslation('index')

  const hasFavoritesValue = hasFavorites()

  const intro = skillsInventory
    ? t(`skills_inventory_intro.${skillsState}`)
    : t(`${module}_intro`)

  const contentClasses = skillsInventory
    ? 'grid grid-cols-10'
    : 'grid grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-2 lg:grid-cols-3'

  return (
    <div className="tw-enable min-h-[70vh] font-[Roboto] lg:flex lg:flex-row">
      <div className="mx-2 flex-grow sm:mx-8">
        <h1 className="mb-4 mt-10 !font-roboto-slab text-2xl text-[32px] font-medium leading-normal text-black">
          {skillsInventory ? 'Skills Inventory' : 'Resource Center'}
        </h1>
        <div className="mb-6">{intro}</div>
        <NavHeader />
        <EmptySkillStateButton />
        <div className={cn('', contentClasses)}>
          {articlesActive && <ArticleCards />}
          {audioActive && (
            <>
              <AudioCards />
              <VideoCards />
            </>
          )}
          {exercisesActive && <ExerciseCards />}
          {quizzesActive && <QuizCards />}
          {assessmentsActive && <AssessmentCards />}
          {workshopsActive && <WorkshopCards />}
          {favoritesActive && (
            <>
              {hasFavoritesValue ? (
                <>
                  <ArticleCards />
                  <AudioCards />
                  <VideoCards />
                  <ExerciseCards />
                  <QuizCards />
                  <WorkshopCards />
                  <AssessmentCards />
                </>
              ) : (
                <NoFavoritesMessage />
              )}
            </>
          )}
          {(graphViewActive || cardViewActive) && <SkillsInventory />}
          <div className="min-w-[472px] overflow-hidden"></div>
        </div>
        {skillsInventory && <SkillsAssessments />}
      </div>
    </div>
  )
}

const Resources = ({ locale, ...props }) => {
  return (
    <TranslationProvider
      locale={locale}
      translations={translations}
      scope="javascript.components.Resources"
    >
      <ResourcesProvider {...props}>
        <ResourcesInner />
      </ResourcesProvider>
    </TranslationProvider>
  )
}

export default Resources
