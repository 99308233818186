import * as React from 'react'
import { cn } from '../../../lib/utils.js'
import HeadShot from '../../coach_services/MeetYourCoach/HeadShot'
import { useDashboard } from '../DashboardProvider'
import Accolade from '../../coach_services/MeetYourCoach/Accolade.js'
import { QuoteCard } from './QuoteCard.js'

const SelectCoachCard = ({ className }) => {
  const { coach } = useDashboard()
  return (
    <div
      className={cn(
        'relative overflow-hidden lg:border-b lg:border-[#D0D0D0] lg:pb-8',
        className,
      )}
    >
      {coach.liaison ? (
        <LiaisonCard coach={coach} />
      ) : (
        <DedicatedCoachCard coach={coach} />
      )}
    </div>
  )
}

function LiaisonCard({ coach }) {
  return (
    <CoachCard coach={coach}>
      <div className="flex h-full flex-col justify-evenly">
        <div>
          Meet your coach liaison {coach.name}. We are working on your match and
          will assign your dedicated coach soon!
        </div>
        <div>
          In the meantime, tune in to some of our upcoming live webinars and
          check out your action plan.
        </div>
      </div>
    </CoachCard>
  )
}

function DedicatedCoachCard({ coach }) {
  return (
    <CoachCard coach={coach}>
      <div className="flex flex-col lg:grid lg:grid-cols-2 lg:gap-8">
        <Accolade
          rating="16+"
          title="Years Experience"
          description={`${coach.name} brings over 16 years of career development and human resources experience to Intoo USA.`}
        />
        <Accolade
          rating="100+"
          title="Clients Supported"
          description={`${
            coach.name.split(' ')[0]
          } is a member of the coaching team at Intoo USA that recently won the Stevie Gold Award, a national award received for providing exceptional coaching.`}
        />
      </div>
    </CoachCard>
  )
}

const CoachCard = ({ coach, children }) => {
  const { quote, isMobile } = useDashboard()
  const coachText = coach.liaison
    ? 'Your Coach Liaison'
    : 'Your Dedicated Career Coach'
  return (
    <div
      className={cn(
        'flex flex-col justify-between xl:grid xl:grid-cols-10 xl:gap-4',
      )}
    >
      <div className="flex items-center justify-around lg:col-span-3 lg:flex-col lg:justify-between">
        <HeadShot
          className={'h-[87px] w-[87px] lg:h-48 lg:w-48'}
          coachImage={coach.image}
        />
        <div className="flex flex-col">
          <h2 className="mb-[16px] block bg-gradient-to-r from-intoo-green-coachcta to-intoo-green-bright bg-clip-text font-ibm-plex text-2xl font-bold uppercase text-transparent md:pr-[24px] md:text-4xl">
            {coach.name}
          </h2>
          <h3 className="font-bold text-[#4A4A4A] md:text-2xl">{coachText}</h3>
        </div>
      </div>
      <div className="flex flex-col lg:col-span-7 lg:justify-between lg:divide-y lg:divide-[#D0D0D0]">
        {children}
        <div className="pt-4">
          {!isMobile && <QuoteCard quote={quote} padding={'md:pr-0'} />}
        </div>
      </div>
    </div>
  )
}

export default SelectCoachCard
