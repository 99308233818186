import * as React from 'react'
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../lib/ui/form'
import { Slider } from '../../lib/ui/slider'

export function SliderField({
  name,
  label,
  description,
  control,
  className,
  min,
  max,
  step,
}) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <FormLabel>{label}</FormLabel>
          <div>{field.value}</div>
          <FormControl>
            <Slider
              min={min}
              max={max}
              step={step || 1}
              defaultValue={[field.value]}
              onValueChange={(vals) => {
                field.onChange(vals[0])
              }}
            />
          </FormControl>
          <FormDescription>{description}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
