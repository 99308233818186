import React from 'react'
import Step from '@mui/joy/Step'
import StepIndicator from '@mui/joy/StepIndicator'
import Typography from '@mui/joy/Typography'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import LensIcon from '@mui/icons-material/Lens'
// import CircleIcon from '@mui/icons-material/Circle'

const CustomStep = ({ activeStep, stepNumber, ...props }) => {
  const completed = activeStep > stepNumber
  const current = activeStep === stepNumber
  const disabled = activeStep < stepNumber

  const indicatorIcon = () => {
    if (completed) {
      return <CheckRoundedIcon sx={{ color: '#fff', fontSize: '1.5625rem' }} />
    } else if (current) {
      return (
        <LensIcon
          fontSize="large"
          sx={{ color: '#148335', width: '.7em', height: '.7em' }}
        />
      )
    } else {
      return ''
    }
  }

  return (
    <Step
      {...props}
      completed={completed}
      active={current}
      disabled={disabled}
      indicator={
        <StepIndicator
          sx={{
            '--joy-palette-neutral-outlinedBorder': '#6D6D6D',
            fontSize: '1.5625rem',
          }}
          variant={completed ? 'solid' : 'outlined'}
          color={completed ? 'success' : 'neutral'}
        >
          {indicatorIcon()}
        </StepIndicator>
      }
    >
      <Typography
        sx={{
          fontWeight: 'lg',
          fontSize: '0.9375rem',
          letterSpacing: '0.5px',
        }}
      >
        {`Step ${stepNumber}`}
      </Typography>
    </Step>
  )
}

export default CustomStep
