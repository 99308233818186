import React from 'react'
import useTranslation from '../shared/TranslationProvider'

function Counter({ value, maxLength }) {
  const remainingChars = value ? maxLength - value.length : maxLength
  const { t } = useTranslation()

  return (
    <p className="self-end text-sm">
      {t('char_remaining', { count: remainingChars })}
    </p>
  )
}

export default Counter
