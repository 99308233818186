import React from 'react'
import {
  BulletList,
  WebinarTile,
  Testimonial,
  PanelHeading,
  PanelContent,
} from './components'
import { useData } from '../DataProvider'
import PanelNav, { PanelEnabled } from './PanelNav'
import useTranslation from '../../../shared/TranslationProvider'

export { WebinarPanel, AylaPanel }

const WebinarPanel = () => {
  const { webinars } = useData()
  const { t } = useTranslation()
  return (
    <PanelEnabled thisPanelNumber={1}>
      <PanelNav />
      <PanelContent>
        <PanelHeading>{t('RightPanel.webinar_title')}</PanelHeading>
        {webinars.map((webinar, key) => (
          <WebinarTile key={key} {...webinar} />
        ))}
      </PanelContent>
      <Testimonial />
    </PanelEnabled>
  )
}

const AylaPanel = ({ thisPanelNumber = 2 }) => {
  const { t } = useTranslation()
  const title = t('RightPanel.ayla.title')
  const subtitle = t('RightPanel.ayla.subtitle')
  const items = ['RightPanel.ayla.item0', 'RightPanel.ayla.item1']
  return (
    <PanelEnabled thisPanelNumber={thisPanelNumber}>
      <PanelNav />
      <BulletList items={items} title={title} subtitle={subtitle} />
      <Testimonial />
    </PanelEnabled>
  )
}
