import React from 'react'
import { Subheading } from './styles'
import { cn } from '../../lib/utils'
import { useMiniplatform } from './MiniplatformProvider'
import DashboardArticleCard from './DashboardArticleCard'

const CoachResources = () => {
  const { className, page, coachingResources, maxWidth } = useMiniplatform()
  return (
    page == 'home' && (
      <div className={maxWidth}>
        <div className={cn('flex flex-col gap-8', className)}>
          <Subheading>CliftonStrengths Coaching Resources</Subheading>
          <div className="flex grid-cols-6 flex-col lg:grid lg:gap-20">
            {coachingResources.map((resource, index) => (
              <DashboardArticleCard
                className={'max-w-[370px]'}
                key={index}
                article={resource}
              />
            ))}
          </div>
        </div>
      </div>
    )
  )
}
export default CoachResources
