import * as React from 'react'

export default function TextField({
  label,
  name,
  value,
  setValue,
  required,
  ...props
}) {
  const requiredClass = required ? 'required' : ''
  const onChange = (event) => setValue(event.target.value)

  return (
    <fieldset>
      <label
        style={{ fontFamily: 'gotham_boldregular' }}
        htmlFor={name}
        className={`control-label ${requiredClass}-text`}
      >
        {label}
      </label>
      <div className={`form-group string ${requiredClass}`}>
        <TextInput
          className={`form-control ${requiredClass} form-control`}
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{ position: 'relative', verticalAlign: 'top' }}
          onChange={onChange}
          value={value}
          {...props}
        />
      </div>
    </fieldset>
  )
}

function TextInput({ multiline, ...props }) {
  if (multiline) {
    return <textarea {...props} />
  } else {
    return <input type="text" {...props} />
  }
}
