import * as React from 'react'

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../lib/ui/form'
// import { Checkbox } from '../../lib/ui/checkbox'

export function Checkbox({ name, label, choices, control, marketing_consent }) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="mt-6">
          <FormControl>
            <input
              type="checkbox"
              {...field}
              id={name}
              onChange={(e) => {
                field.onChange(e)
                marketing_consent(e.target.checked)
              }}
            />
          </FormControl>
          <FormLabel className="ml-2">{label}</FormLabel>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
