import * as React from 'react'

export default function Cursor({ show }) {
  if (!show) return null

  return (
    <>
      <style>
        {`
          @keyframes blink-animation {
            to {
              opacity: 0;
            }
          }
        `}
      </style>
      <span
        style={{
          display: 'inline-block',
          marginLeft: '2px',
          opacity: '1',
          animation: 'blink-animation 1s steps(2, start) infinite',
        }}
      >
        |
      </span>
    </>
  )
}
