import React, { useEffect, useState } from 'react'
import Option from './Option'
import { useQuiz } from './QuizProvider'
import { cn } from '../../lib/utils'

const LayoutThreeOptions = ({ updateResponse, questionInfo, children }) => {
  const { quizIndex, trackContent, setTrackContent, tasks, setTasks } =
    useQuiz()
  const [focus, setFocus] = useState()
  const options = Object.values(questionInfo.options)
  useEffect(() => {
    if (trackContent[quizIndex + 1] === 'a') {
      setFocus(0)
    } else if (trackContent[quizIndex + 1] === 'b') {
      setFocus(1)
    } else if (trackContent[quizIndex + 1] === 'c') {
      setFocus(2)
    } else if (trackContent[quizIndex + 1] === 'd') {
      setFocus(3)
    }
  }, [focus, trackContent, quizIndex])

  const handleOptionClick = (choice, feedback_key) => {
    const key = feedback_key === 'yes_response' ? 0 : 1
    const show = () => {
      updateResponse(key)
    }

    if (choice === 0) {
      trackContent[quizIndex + 1] = 'a'
    } else if (choice === 1) {
      trackContent[quizIndex + 1] = 'b'
    } else if (choice === 2) {
      trackContent[quizIndex + 1] = 'c'
    } else if (choice === 3) {
      trackContent[quizIndex + 1] = 'd'
    }

    setTrackContent({
      ...trackContent,
    })

    let newTask
    const prevTasks = tasks

    if (choice === 0 && questionInfo.yes_action_results_copy) {
      newTask = questionInfo.yes_action_results_copy
    } else if (choice === 1 && questionInfo.no_action_results_copy) {
      newTask = questionInfo.no_action_results_copy
    } else if (questionInfo.action_results_copy) {
      newTask = questionInfo.action_results_copy
    }

    if (newTask) {
      prevTasks[quizIndex + 1] = newTask
      setTasks({ ...prevTasks })
    }

    show()

    // setTimeout(show, 500)
  }

  const alphabet = ['A', 'B', 'C', 'D']

  return (
    <div className="z-10 justify-between sm:flex">
      <div className="question-options flex flex-col gap-6 sm:gap-8 md:w-2/5">
        {options.map((option, index) => (
          <Option
            key={`response-${index}`}
            id={`btn-${quizIndex + 1}-${index}`}
            className={cn(
              'grid h-fit grid-cols-6 gap-4 md:min-h-[60px] md:justify-start md:px-4 xl:grid-cols-10',
              focus === index ? 'bg-primary text-white' : '',
            )}
            onClick={() => {
              handleOptionClick(index, option['feedback_key'])
              setFocus(index)
            }}
          >
            <div
              className={
                'flex h-8 w-8 items-center justify-center rounded-full bg-[#E5F6FF] !text-[#2F2F2F]'
              }
            >
              {alphabet[index]}
            </div>
            <div className="col-span-5 text-left xl:col-span-9">
              {option['option']}
            </div>
          </Option>
        ))}
      </div>
      {children}
    </div>
  )
}
export default LayoutThreeOptions
