import React from 'react'
import { useBenefits } from '../../BenefitsProvider'
import { CoachCard } from '../CoachCard'
import { PhoneIcon } from '@heroicons/react/24/outline'
import CalendlyCallPopup, {
  CalendlyCallScheduled,
} from '../../CalendlyCallPopup'

export function OrientationCallCard({ status }) {
  // TODO: Implement in_progress and completed (See ResumeReviewCard.js)
  const { calendlyEvent } = useBenefits()
  return (
    <>
      <CoachCard
        icon={PhoneIcon}
        title="Orientation Call"
        description="Use this call to meet your coach and learn about the system and the services available to you."
      >
        {!calendlyEvent && <CalendlyCallPopup eventType={'orientation'} />}
        {calendlyEvent && (
          <CalendlyCallScheduled
            eventType="orientation"
            calendlyEvent={calendlyEvent}
          />
        )}
      </CoachCard>
    </>
  )
}
