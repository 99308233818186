import React from 'react'
import { cn } from '../../../../lib/utils'

export const FeedbackPageIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="349"
      height="350"
      viewBox="0 0 349 350"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M180.748 171.245C182.125 169.892 183.819 168.895 185.694 168.338L199.328 153.454L206.517 160.355L191.906 174.715C191.279 176.554 190.219 178.198 188.803 179.513C185.306 182.86 180.671 183.721 178.447 181.435C176.216 179.157 177.244 174.592 180.735 171.251H180.748V171.245Z"
        fill="#F3A3A6"
      />
      <path
        d="M186.431 164.544L193.671 173.66L231.096 145.29L231.128 145.225L252.998 104.353C256.347 98.092 254.492 90.3034 248.68 86.2376C245.124 83.7449 240.702 83.0522 236.552 84.3276C232.402 85.6031 229.137 88.6654 227.585 92.7248L213.395 129.92L186.431 164.531L186.431 164.544Z"
        fill="#75B21D"
      />
      <path
        d="M248.059 70.0065H232.802V94.2463L249.624 88.7107L248.059 70.0065Z"
        fill="#F3A3A6"
      />
      <path
        d="M218.127 59.9326C218.127 69.2038 225.627 76.7139 234.884 76.7139C244.142 76.7139 251.641 69.2038 251.641 59.9326C251.641 50.6614 244.142 43.1512 234.884 43.1512C225.627 43.1512 218.127 50.6614 218.127 59.9326Z"
        fill="#F3A3A6"
      />
      <path
        d="M246.74 70.6605L248.376 73.7681L255.591 60.0361C259.935 46.7121 244.762 39.0595 244.762 39.0595C239.81 33.2197 229.861 40.05 229.861 40.05C215.509 36.8582 222.439 48.9716 222.439 48.9716C222.439 48.9716 227.333 49.0817 227.333 53.0439C227.333 57.0126 232.667 56.7343 232.667 56.7343L236.843 64.0567L239.151 61.2468C247.73 57.6083 246.74 70.6605 246.74 70.6605Z"
        fill="#2F2E43"
      />
      <path d="M254.24 318.38H240.702V337.615H254.24V318.38Z" fill="#F3A3A6" />
      <path
        d="M227.521 349.255C233.462 349.255 242.138 348.634 242.202 348.628C244.523 348.835 256.108 349.748 256.683 347.093C257.213 344.652 256.431 342.101 256.321 341.752C255.203 330.661 254.789 330.532 254.543 330.461C254.149 330.344 252.998 330.894 251.13 332.099L251.014 332.176L250.988 332.312C250.955 332.481 250.128 336.553 246.204 335.931C243.515 335.504 242.642 334.909 242.364 334.617C242.59 334.514 242.875 334.332 243.075 334.022C243.366 333.568 243.418 332.986 243.224 332.286C242.719 330.448 241.207 327.722 241.142 327.606L240.967 327.295L225.581 337.693L216.078 340.412C215.36 340.612 214.785 341.13 214.494 341.817C214.093 342.775 214.339 343.888 215.115 344.588C216.841 346.141 220.274 348.802 223.868 349.133C224.825 349.223 226.092 349.256 227.514 349.256L227.521 349.255Z"
        fill="#2F2E43"
      />
      <path
        d="M282.901 289.546L272.471 298.187L284.714 313.008L295.144 304.367L282.901 289.546Z"
        fill="#F3A3A6"
      />
      <path
        d="M281.98 330.37C286.557 326.576 292.847 320.561 292.892 320.516C294.812 319.202 304.322 312.507 303.074 310.093C301.93 307.872 299.706 306.402 299.396 306.208C291.477 298.368 291.082 298.536 290.843 298.64C290.462 298.801 289.925 299.954 289.253 302.077L289.214 302.207L289.279 302.33C289.369 302.485 291.321 306.156 287.902 308.176C285.555 309.562 284.508 309.659 284.107 309.613C284.217 309.387 284.32 309.063 284.275 308.694C284.21 308.163 283.88 307.678 283.286 307.263C281.721 306.169 278.825 305.03 278.702 304.984L278.372 304.855L273.136 322.685L267.544 330.849C267.124 331.464 267.007 332.228 267.221 332.947C267.525 333.944 268.423 334.643 269.464 334.688C271.778 334.785 276.116 334.643 279.097 332.604C279.885 332.06 280.881 331.276 281.98 330.37Z"
        fill="#2F2E43"
      />
      <path
        d="M218.321 170.047L238.957 323.766H254.434L264.751 170.047H218.315L218.321 170.047Z"
        fill="#2F2E43"
      />
      <path
        d="M258.299 254.012L289.117 294.056L275.929 304.965L223.041 254.588L218.315 170.047L227.773 178.516L258.299 254.012Z"
        fill="#2F2E43"
      />
      <path
        d="M227.773 79.6209L218.315 170.047H264.751L268.352 140.699C270.57 122.571 266.878 104.21 257.828 88.3611L250.703 75.8917L227.773 79.6209Z"
        fill="#75B21D"
      />
      <path
        d="M223.61 195.744C224.301 193.937 225.433 192.325 226.907 191.043L233.145 171.828L242.551 175.123L235.214 194.255C235.414 196.184 235.123 198.12 234.38 199.913C232.589 204.406 228.723 207.119 225.756 205.967C222.789 204.814 221.832 200.237 223.623 195.744L223.61 195.744Z"
        fill="#F3A3A6"
      />
      <path
        d="M226.021 187.243L236.384 192.526L258.681 151.162V151.091L261.648 104.819C262.101 97.7359 257.187 91.417 250.212 90.1351C245.939 89.3517 241.627 90.556 238.388 93.4435C235.136 96.331 233.436 100.481 233.708 104.819L236.209 144.552L226.021 187.249L226.021 187.243Z"
        fill="#75B21D"
      />
      <path
        d="M166.386 184H87.6142C77.3504 184 69 177.61 69 169.756V14.2443C69 6.38991 77.3504 0 87.6142 0H166.386C176.65 0 185 6.38991 185 14.2443V169.756C185 177.61 176.65 184 166.386 184Z"
        fill="#2F2E41"
      />
      <path
        d="M166.015 179H86.9845C79.2734 179 73 174.264 73 168.444V14.5563C73 8.7356 79.2734 4 86.9845 4H166.015C173.726 4 180 8.7356 180 14.5563V168.444C180 174.264 173.726 179 166.015 179Z"
        fill="white"
      />
      <path
        d="M139.082 17.1352H113.987C111.094 17.1352 108.74 14.778 108.74 11.8804C108.74 8.98281 111.094 6.62561 113.987 6.62561H139.082C141.975 6.62561 144.329 8.98281 144.329 11.8804C144.329 14.778 141.975 17.1352 139.082 17.1352Z"
        fill="#2F2E41"
      />
      <path d="M91 69H87V98H91V69Z" fill="#75B21D" />
      <path d="M100 85H95V98H100V85Z" fill="#75B21D" />
      <path d="M109 81H104V98H109V81Z" fill="#75B21D" />
      <path d="M117 80H113V98H117V80Z" fill="#75B21D" />
      <path d="M125 80H121V98H125V80Z" fill="#75B21D" />
      <path d="M133 65H129V98H133V65Z" fill="#75B21D" />
      <path d="M142 81H137V98H142V81Z" fill="#75B21D" />
      <path d="M150 81H146V98H150V81Z" fill="#75B21D" />
      <path d="M166 81H162V98H166V81Z" fill="#75B21D" />
      <path d="M158 87H154V98H158V87Z" fill="#75B21D" />
    </svg>
  )
}

export const ThankYouImage = ({ className }) => {
  return (
    <svg
      className={className}
      width="297"
      height="255"
      viewBox="0 0 297 255"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_707_1177)">
        <path
          d="M22.302 234.011C24.0134 234.613 25.8509 234.764 27.6374 234.449C29.424 234.134 31.0994 233.364 32.5022 232.213C36.0748 229.213 37.1951 224.271 38.1063 219.695L40.802 206.157L35.1583 210.045C31.0993 212.841 26.9492 215.727 24.1391 219.777C21.329 223.827 20.103 229.357 22.3602 233.739"
          fill="#1C6F29"
        />
        <path
          d="M28.2866 243.035C29.0205 244.151 30.0294 245.06 31.216 245.673C32.4027 246.286 33.7272 246.582 35.0618 246.535C38.4925 246.372 41.3514 243.977 43.9247 241.703L51.5377 234.977L46.4994 234.736C42.876 234.562 39.1591 234.399 35.7079 235.519C32.2568 236.638 29.074 239.328 28.443 242.902"
          fill="#1C6F29"
        />
        <path
          d="M294.808 255H106.192C105.611 254.999 105.054 254.671 104.643 254.087C104.232 253.503 104.001 252.712 104 251.886V3.11355C104.001 2.28812 104.232 1.4968 104.643 0.913128C105.054 0.32946 105.611 0.00107905 106.192 0H294.808C295.389 0.00107905 295.946 0.32946 296.357 0.913128C296.768 1.4968 296.999 2.28812 297 3.11355V251.886C296.999 252.711 296.768 253.503 296.357 254.086C295.946 254.67 295.389 254.999 294.808 255Z"
          fill="#F0F7FF"
        />
        <path
          d="M294.808 255H106.192C105.611 254.999 105.054 254.671 104.643 254.087C104.232 253.503 104.001 252.712 104 251.886V3.11355C104.001 2.28812 104.232 1.4968 104.643 0.913128C105.054 0.32946 105.611 0.00107905 106.192 0H294.808C295.389 0.00107905 295.946 0.32946 296.357 0.913128C296.768 1.4968 296.999 2.28812 297 3.11355V251.886C296.999 252.711 296.768 253.503 296.357 254.086C295.946 254.67 295.389 254.999 294.808 255ZM106.192 1.24297C105.844 1.24404 105.51 1.44104 105.264 1.79084C105.018 2.14064 104.879 2.61477 104.878 3.10946V251.886C104.879 252.381 105.018 252.855 105.264 253.204C105.51 253.554 105.844 253.751 106.192 253.752H294.808C295.156 253.751 295.49 253.554 295.736 253.205C295.983 252.855 296.121 252.381 296.122 251.886V3.11355C296.121 2.61885 295.982 2.14473 295.736 1.79493C295.49 1.44512 295.156 1.24813 294.808 1.24705L106.192 1.24297Z"
          fill="#CACACA"
        />
        <path
          d="M195.355 45.5244C194.961 45.5251 194.583 45.6822 194.305 45.9613C194.026 46.2404 193.87 46.6186 193.87 47.013C193.87 47.4074 194.026 47.7856 194.305 48.0647C194.583 48.3438 194.961 48.5009 195.355 48.5016H276.992C277.386 48.5009 277.764 48.3438 278.042 48.0647C278.321 47.7856 278.477 47.4074 278.477 47.013C278.477 46.6186 278.321 46.2404 278.042 45.9613C277.764 45.6822 277.386 45.5251 276.992 45.5244H195.355Z"
          fill="#08467C"
        />
        <path
          d="M195.355 54.4568C194.961 54.4578 194.584 54.6151 194.306 54.8941C194.027 55.1732 193.871 55.5513 193.871 55.9455C193.871 56.3396 194.027 56.7177 194.306 56.9968C194.584 57.2758 194.961 57.4331 195.355 57.434H237.032C237.426 57.4331 237.804 57.2758 238.082 56.9968C238.36 56.7177 238.517 56.3396 238.517 55.9455C238.517 55.5513 238.36 55.1732 238.082 54.8941C237.804 54.6151 237.426 54.4578 237.032 54.4568H195.355Z"
          fill="#08467C"
        />
        <path
          d="M195.355 36.457C194.961 36.458 194.584 36.6152 194.306 36.8943C194.027 37.1734 193.871 37.5515 193.871 37.9456C193.871 38.3398 194.027 38.7179 194.306 38.997C194.584 39.276 194.961 39.4333 195.355 39.4342H237.032C237.426 39.4333 237.804 39.276 238.082 38.997C238.36 38.7179 238.517 38.3398 238.517 37.9456C238.517 37.5515 238.36 37.1734 238.082 36.8943C237.804 36.6152 237.426 36.458 237.032 36.457H195.355Z"
          fill="#08467C"
        />
        <path
          d="M124.002 102.492C123.608 102.493 123.231 102.65 122.952 102.93C122.674 103.209 122.518 103.587 122.518 103.981C122.518 104.375 122.674 104.753 122.952 105.032C123.231 105.311 123.608 105.468 124.002 105.469H277.071C277.465 105.468 277.843 105.311 278.121 105.032C278.399 104.753 278.555 104.375 278.555 103.981C278.555 103.587 278.399 103.209 278.121 102.93C277.843 102.65 277.465 102.493 277.071 102.492H124.002Z"
          fill="#08467C"
        />
        <path
          d="M124.002 111.425C123.608 111.426 123.231 111.583 122.952 111.862C122.674 112.141 122.518 112.519 122.518 112.913C122.518 113.307 122.674 113.686 122.952 113.965C123.231 114.244 123.608 114.401 124.002 114.402H237.112C237.506 114.401 237.883 114.244 238.161 113.965C238.44 113.686 238.596 113.307 238.596 112.913C238.596 112.519 238.44 112.141 238.161 111.862C237.883 111.583 237.506 111.426 237.112 111.425H124.002Z"
          fill="#08467C"
        />
        <path
          d="M124.002 120.155C123.608 120.156 123.231 120.313 122.952 120.592C122.674 120.871 122.518 121.249 122.518 121.644C122.518 122.038 122.674 122.416 122.952 122.695C123.231 122.974 123.608 123.131 124.002 123.132H277.071C277.465 123.131 277.843 122.974 278.121 122.695C278.399 122.416 278.555 122.038 278.555 121.644C278.555 121.249 278.399 120.871 278.121 120.592C277.843 120.313 277.465 120.156 277.071 120.155H124.002Z"
          fill="#08467C"
        />
        <path
          d="M124.002 129.087C123.608 129.088 123.231 129.246 122.952 129.525C122.674 129.804 122.518 130.182 122.518 130.576C122.518 130.97 122.674 131.348 122.952 131.627C123.231 131.906 123.608 132.064 124.002 132.065H237.112C237.506 132.064 237.883 131.906 238.161 131.627C238.44 131.348 238.596 130.97 238.596 130.576C238.596 130.182 238.44 129.804 238.161 129.525C237.883 129.246 237.506 129.088 237.112 129.087H124.002Z"
          fill="#08467C"
        />
        <path
          d="M124.002 138.05C123.608 138.051 123.231 138.209 122.952 138.488C122.674 138.767 122.518 139.145 122.518 139.539C122.518 139.933 122.674 140.311 122.952 140.59C123.231 140.869 123.608 141.027 124.002 141.027H277.071C277.465 141.027 277.843 140.869 278.121 140.59C278.399 140.311 278.555 139.933 278.555 139.539C278.555 139.145 278.399 138.767 278.121 138.488C277.843 138.209 277.465 138.051 277.071 138.05H124.002Z"
          fill="#08467C"
        />
        <path
          d="M124.002 188.051C123.608 188.052 123.231 188.209 122.953 188.488C122.674 188.767 122.518 189.145 122.518 189.539C122.518 189.934 122.674 190.312 122.953 190.591C123.231 190.87 123.608 191.027 124.002 191.028H277.071C277.465 191.027 277.843 190.87 278.121 190.591C278.399 190.312 278.556 189.934 278.556 189.539C278.556 189.145 278.399 188.767 278.121 188.488C277.843 188.209 277.465 188.052 277.071 188.051H124.002Z"
          fill="#08467C"
        />
        <path
          d="M124.002 218.051C123.608 218.052 123.231 218.209 122.953 218.488C122.674 218.767 122.518 219.145 122.518 219.539C122.518 219.934 122.674 220.312 122.953 220.591C123.231 220.87 123.608 221.027 124.002 221.028H277.071C277.465 221.027 277.843 220.87 278.121 220.591C278.399 220.312 278.556 219.934 278.556 219.539C278.556 219.145 278.399 218.767 278.121 218.488C277.843 218.209 277.465 218.052 277.071 218.051H124.002Z"
          fill="#08467C"
        />
        <path
          d="M124.002 146.983C123.608 146.984 123.231 147.141 122.952 147.42C122.674 147.699 122.518 148.077 122.518 148.471C122.518 148.865 122.674 149.244 122.952 149.523C123.231 149.802 123.608 149.959 124.002 149.96H237.112C237.506 149.959 237.883 149.802 238.161 149.523C238.44 149.244 238.596 148.865 238.596 148.471C238.596 148.077 238.44 147.699 238.161 147.42C237.883 147.141 237.506 146.984 237.112 146.983H124.002Z"
          fill="#08467C"
        />
        <path
          d="M124.002 196.982C123.608 196.983 123.231 197.141 122.953 197.42C122.674 197.699 122.518 198.077 122.518 198.471C122.518 198.865 122.674 199.243 122.953 199.522C123.231 199.801 123.608 199.959 124.002 199.96H237.112C237.506 199.959 237.883 199.801 238.162 199.522C238.44 199.243 238.596 198.865 238.596 198.471C238.596 198.077 238.44 197.699 238.162 197.42C237.883 197.141 237.506 196.983 237.112 196.982H124.002Z"
          fill="#08467C"
        />
        <path
          d="M124.002 226.982C123.608 226.983 123.231 227.141 122.953 227.42C122.674 227.699 122.518 228.077 122.518 228.471C122.518 228.865 122.674 229.243 122.953 229.522C123.231 229.801 123.608 229.959 124.002 229.96H237.112C237.506 229.959 237.883 229.801 238.162 229.522C238.44 229.243 238.596 228.865 238.596 228.471C238.596 228.077 238.44 227.699 238.162 227.42C237.883 227.141 237.506 226.983 237.112 226.982H124.002Z"
          fill="#08467C"
        />
        <path
          d="M148.247 72.8662C144.019 72.8663 139.887 71.612 136.371 69.262C132.856 66.9119 130.116 63.5717 128.499 59.6638C126.881 55.7558 126.457 51.4556 127.282 47.3069C128.107 43.1582 130.143 39.3474 133.132 36.3563C136.121 33.3652 139.93 31.3283 144.076 30.5031C148.223 29.6778 152.521 30.1014 156.426 31.7201C160.332 33.3388 163.671 36.08 166.019 39.5971C168.368 43.1142 169.622 47.2492 169.622 51.4792C169.615 57.1494 167.361 62.5855 163.354 66.5949C159.347 70.6044 153.914 72.8597 148.247 72.8662Z"
          fill="#75B21D"
        />
        <path
          d="M147.012 59.5571C146.627 59.5575 146.256 59.4116 145.974 59.1487L138.925 52.5985C138.629 52.3226 138.454 51.9404 138.44 51.5358C138.425 51.1312 138.571 50.7373 138.847 50.4406C139.122 50.1439 139.504 49.9688 139.908 49.9536C140.313 49.9385 140.707 50.0846 141.003 50.3598L146.973 55.9082L158.785 44.0903C158.927 43.9475 159.095 43.8342 159.28 43.7567C159.466 43.6792 159.665 43.6391 159.866 43.6387C160.067 43.6384 160.266 43.6777 160.452 43.7545C160.637 43.8313 160.806 43.9441 160.948 44.0863C161.09 44.2285 161.203 44.3974 161.28 44.5833C161.357 44.7692 161.396 44.9684 161.395 45.1695C161.395 45.3706 161.355 45.5697 161.277 45.7552C161.2 45.9408 161.087 46.1092 160.944 46.2509L148.092 59.1097C147.951 59.2517 147.782 59.3643 147.597 59.4411C147.412 59.5179 147.213 59.5573 147.012 59.5571Z"
          fill="white"
        />
        <path
          d="M35.3296 207.759L28.1665 201.635C28.4436 197.203 28.9724 192.79 29.7504 188.417L33.6924 190.044L30.0249 186.908C31.041 181.511 32.0582 178 32.0582 178C32.0582 178 46.512 190.579 54.6524 205.983L51.1955 217.524L56.5378 209.873C57.3508 211.707 58.0467 213.59 58.6215 215.51C64.3515 234.892 62.6177 252.461 54.749 254.752C46.8803 257.043 35.8564 243.188 30.1264 223.806C28.35 217.798 27.8614 211.113 28.0318 204.747L35.3296 207.759Z"
          fill="#123F1C"
        />
        <path
          d="M16.9406 222.648L7.80269 221.09C5.77216 217.179 3.96255 213.155 2.38249 209.038L6.52112 208.413L1.8425 207.615C-0.0610511 202.505 -1 199 -1 199C-1 199 17.5524 202.333 32.2492 211.288L35.2411 222.868L35.8179 213.637C37.4365 214.782 38.9819 216.029 40.4448 217.37C55.1501 230.937 62.6663 246.768 57.2328 252.728C51.7994 258.687 35.4737 252.52 20.7685 238.953C16.2097 234.747 12.3861 229.309 9.27896 223.807L16.9406 222.648Z"
          fill="#123F1C"
        />
        <path
          d="M100 99.9982C100.001 110.463 95.9007 120.511 88.5785 127.987C81.2562 135.464 71.2954 139.772 60.8328 139.989C60.5567 139.996 60.277 140 60.0008 140C59.7246 140 59.4449 139.996 59.1687 139.989C51.2946 139.826 43.644 137.341 37.1761 132.847C30.7083 128.354 25.711 122.05 22.8108 114.728C19.9106 107.406 19.2366 99.3906 20.8732 91.687C22.5099 83.9833 26.3844 76.9341 32.0109 71.4233C37.6375 65.9125 44.7657 62.1854 52.5019 60.7091C60.2381 59.2327 68.238 60.073 75.4986 63.1246C82.7592 66.1761 88.9574 71.3031 93.316 77.8627C97.6747 84.4223 99.9998 92.1226 100 99.9982Z"
          fill="#75B21D"
        />
        <path
          d="M63 254.984C62.5626 254.995 60.4374 255 60 255C59.5626 255 57.4374 254.995 57 254.984L60 99L63 254.984Z"
          fill="#979797"
        />
        <path
          d="M50.108 256.631C50.6807 251.454 51.27 246.211 50.8673 240.979C50.5104 236.332 49.3676 231.794 47.0411 228.072C45.8062 226.101 44.3036 224.411 42.6008 223.08C42.1568 222.733 41.7482 223.605 42.1902 223.952C45.1366 226.262 47.4156 229.673 48.6986 233.693C50.1156 238.164 50.3431 243.038 50.099 247.787C49.9515 250.659 49.638 253.512 49.3232 256.362C49.2983 256.491 49.3138 256.626 49.3666 256.742C49.4193 256.857 49.5054 256.943 49.6074 256.983C49.7114 257.017 49.8222 257 49.9159 256.934C50.0096 256.868 50.0785 256.759 50.1077 256.63L50.108 256.631Z"
          fill="#D3D3D3"
        />
        <path
          d="M47.2188 230.767C40.3327 229.801 32.6189 228.573 28.0225 222.765C26.7465 221.149 25.7914 219.304 25.2085 217.33C25.0239 216.708 26.0105 216.493 26.195 217.114C27.1878 220.417 29.2891 223.278 32.1451 225.213C34.8842 227.079 38.079 228.115 41.2993 228.788C43.2722 229.2 45.2692 229.479 47.264 229.758C47.9016 229.848 47.8637 230.857 47.2188 230.767Z"
          fill="#D3D3D3"
        />
      </g>
      <defs>
        <clipPath id="clip0_707_1177">
          <rect width="297" height="255" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const TrashIcon = ({ className, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={cn(className, 'size-6')}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
      />
    </svg>
  )
}
