import React from 'react'
import { Heading, Link, Paragraph } from './styles'
import { cn } from '../../lib/utils'
import { useMiniplatform } from './MiniplatformProvider'
import Moment from 'moment'
import { Button } from '../../lib/ui/button'
import { Form } from '../../lib/ui/form'
import { FileField, InputField } from './FormFields'
import { useUploadAssessmentForm } from './useUploadAssessmentForm'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from '../../lib/ui/dialog'

const UploadAssessment = () => {
  const { assessmentMissing, uploadUrl, className, setShowAssessmentDialog } =
    useMiniplatform()
  return (
    assessmentMissing && (
      <div
        className={cn(
          'flex flex-col items-center gap-6 md:flex-row',
          className,
        )}
      >
        <UploadAssessmentDialog />
        <div className="flex flex-col items-start gap-4 md:w-6/12">
          <div className="flex flex-col items-center gap-4">
            <img className="h-24" src={uploadUrl} alt="upload icon" />
            <Link
              onClick={() => setShowAssessmentDialog(true)}
              className="font-bold"
            >
              Upload your assessment here
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <Heading className="text-[26px] leading-8">
            To get started, upload your assessment
          </Heading>
          <Paragraph>
            {`Once your assessment is uploaded, you will be able to schedule your
          first session with your CliftonStrengths Career Coach. Please allow
          the coach a few days to review your results. In this session, you'll
          discover how your strengths align with those of your team, enhancing
          collaboration and synergy.`}
          </Paragraph>
        </div>
      </div>
    )
  )
}
export default UploadAssessment

const UploadAssessmentDialog = ({ children }) => {
  const { showAssessmentDialog, toggleAssessmentDialog } = useMiniplatform()
  return (
    <Dialog open={showAssessmentDialog} onOpenChange={toggleAssessmentDialog}>
      <DialogTrigger asChild className="cursor-pointer">
        {children}
      </DialogTrigger>
      <DialogContent className="max-w-xl font-roboto">
        <DialogHeader>
          <DialogTitle className="!mb-[0px] !font-roboto !text-lg">
            Upload Assessment Results
          </DialogTitle>
        </DialogHeader>
        <UploadAssessmentForm />
        <DialogClose />
      </DialogContent>
    </Dialog>
  )
}

const UploadAssessmentForm = () => {
  const acceptList = ['.pdf', '.rtf', '.doc', '.docx', '.txt']
  const dateString = Moment().format('YYYY_MM_DD_hh:mm:ss')
  const { form, onSubmit } = useUploadAssessmentForm({
    dateString,
  })

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="">
        <div className="well">
          <InputField
            name="name"
            id="clifton_strengths_assessment_name"
            className="w-full"
            label="Name"
            control={form.control}
            placeholder={`Clifton Strengths Assessment ${dateString}`}
          />
          <FileField
            accept={acceptList.join(',')}
            name="remoteContent"
            id="clifton_strengths_assessment_remote_content"
            control={form.control}
          />
          <div className="hint">
            Please choose a Microsoft Word, PDF, Text, or HTML file up to 5MB
          </div>
        </div>
        <div className="flex justify-center">
          <Button type="submit">Upload Assessment</Button>
        </div>
      </form>
    </Form>
  )
}
