import React from 'react'
import { Button } from '../../lib/ui/button'
import { cn } from '../../lib/utils'

const buttonClasses =
  'rounded-full flex w-[120px] h-12 justify-center items-center gap-2 sm:w-[172px] sm:h-10 py-0 px-6'

export const PrimaryQuizButton = ({ className, children, ...props }) => {
  return (
    <Button
      {...props}
      className={cn(
        buttonClasses,
        className,
        'bg-primary hover:bg-primary-darker-75',
      )}
    >
      {children}
    </Button>
  )
}

export const BackQuizButton = ({ className, children, ...props }) => {
  return (
    <Button
      {...props}
      variant="outline"
      className={cn(
        buttonClasses,
        className,
        'border-[#5A5A5A] bg-[#fff] hover:bg-[#F0F0F0]',
      )}
    >
      {children}
    </Button>
  )
}
