import React, { useState } from 'react'
import { Subheading, Paragraph, Link } from './styles'
import { cn } from '../../lib/utils'
import ScheduleCoaching from './scheduleCoaching'
import Calendly from './calendly'
import MeetingConfirmed from './meetingConfirmed'
import { useMiniplatform } from './MiniplatformProvider'

const AboutProgramParagraph = ({ className, children, ...props }) => {
  return (
    <div
      {...props}
      className={cn('duration-1500 overflow-hidden transition-all', className)}
      style={{
        transitionDuration: '2s',
        transitionTimingFunction: 'ease-in-out',
      }}
    >
      {children}
    </div>
  )
}

const AboutProgram = () => {
  const {
    className,
    page,
    coachName,
    coachPhoto,
    imgUrl,
    aboutProgram,
    event,
    showCalendly,
    maxWidth,
    programType,
  } = useMiniplatform()

  const { groupEvent } = useMiniplatform()
  const [divHeight, setDivHeight] = useState('max-h-40 md:max-h-20')
  const [showMeetingConfirmed, setShowMeetingConfirmed] = useState(false)
  const [toggleText, setToggleText] = useState('Learn More')

  const tileHeight = programType === 'group' ? 'h-[165px]' : 'h-[144px]'
  const shadow = programType === 'group' ? 'shadow-miniplatform-event-tile' : ''

  const toggleAbout = () => {
    if (toggleText === 'Learn More') {
      setDivHeight('max-h-screen')
      setToggleText('Collapse')
    } else {
      setDivHeight('max-h-40 md:max-h-20')
      setTimeout(() => {
        setToggleText('Learn More')
      }, 850)
    }
  }

  return (
    page === 'home' && (
      <div className={cn('bg-[#E5F6FF]')}>
        <div
          className={cn('flex flex-col gap-4 md:gap-8', className, maxWidth)}
        >
          <div
            className={cn(
              'flex flex-col gap-2 lg:grid lg:grid-cols-6 lg:gap-20',
            )}
          >
            <div className="order-2 flex flex-col gap-4 lg:order-1 lg:col-span-4">
              <Subheading>CliftonStrengths Program</Subheading>
              <Paragraph className="font-bold">About Your Program</Paragraph>
              <AboutProgramParagraph className={divHeight}>
                <Paragraph>{aboutProgram[0]}</Paragraph>
                <br />
                <Paragraph>{aboutProgram[1]}</Paragraph>
              </AboutProgramParagraph>
              {groupEvent && (
                <Paragraph
                  className={'cursor-pointer underline'}
                  onClick={toggleAbout}
                >
                  {toggleText}
                </Paragraph>
              )}
            </div>
            <div className="flex flex-col items-center gap-4 lg:order-2 lg:col-span-2">
              <img
                src={imgUrl}
                alt=""
                className={cn('w-[255px] rounded-[10px]', tileHeight, shadow)}
              />
              <Link
                target="_blank"
                href="https://www.gallup.com/cliftonstrengths/en/252137/home.aspx"
              >
                Go to Gallup CliftonStrengths
              </Link>
            </div>
          </div>
          {!showMeetingConfirmed && <ScheduleCoaching />}
          {showCalendly && (
            <Calendly setShowMeetingConfirmed={setShowMeetingConfirmed} />
          )}
          {showMeetingConfirmed && (
            <MeetingConfirmed
              event={event}
              coachName={coachName}
              coachPhoto={coachPhoto}
            />
          )}
        </div>
      </div>
    )
  )
}
export default AboutProgram
