import React from 'react'
import PanelNav, { PanelNavProvider, PanelEnabled } from './PanelNav'
import { BenefitsBulletList, Testimonial } from './components'
import { AylaPanel, WebinarPanel } from './commonPanels'
import useTranslation from '../../../shared/TranslationProvider'

const FlexBenefitsPanel = () => {
  const items = [...Array(6).keys()].map((key) => `RightPanel.flex.item${key}`)
  return (
    <>
      <PanelEnabled thisPanelNumber={0}>
        <PanelNav />
        <BenefitsBulletList items={items} />
        <Testimonial />
      </PanelEnabled>
    </>
  )
}

const RightPanelFlex = () => {
  const { locale } = useTranslation()
  if (locale.slice(0, 2) === 'en') {
    return (
      <PanelNavProvider panelCount={3}>
        <FlexBenefitsPanel />
        <WebinarPanel />
        <AylaPanel />
      </PanelNavProvider>
    )
  } else {
    return (
      <PanelNavProvider panelCount={2}>
        <FlexBenefitsPanel />
        <AylaPanel thisPanelNumber={1} />
      </PanelNavProvider>
    )
  }
}

export default RightPanelFlex
