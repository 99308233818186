import * as React from 'react'

import translations from '../locales.json'
import { TranslationProvider } from '../../shared/TranslationProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { SavedJobsProvider } from './SavedJobsProvider'
import { Toaster } from '../../../lib/ui/toast'

import Page from './Page'

const queryClient = new QueryClient()

export default function SavedJobsPage({ saved_jobs, locale, applied_jobs }) {
  return (
    <CookiesProvider defaultSetOptions={{ path: '/job-search' }}>
      <QueryClientProvider client={queryClient}>
        <TranslationProvider
          locale={locale}
          translations={translations}
          scope="javascript.components.job_search.SavedJobsPage"
        >
          <BrowserRouter>
            <SavedJobsProvider
              saved_jobs={saved_jobs}
              applied_jobs={applied_jobs}
            >
              <style>
                {`
          // TODO: fix and remove this

          .fix-legacy-hack p {
            margin: 0 !important;
          }

          .fix-legacy-hack ul {
            margin: 0 !important;
            padding-inline-start: 0 !important;
          }

          .fix-legacy-hack h1,
          .fix-legacy-hack h2,
          .fix-legacy-hack h3 {
            margin: 0 !important;
          }

        `}
              </style>

              <Toaster />
              <div className="fix-legacy-hack flex flex-col border-l border-alto-200 font-roboto">
                <div className="my-6 flex flex-col gap-8 md:mx-4 md:mr-4">
                  <Page />
                </div>
              </div>
            </SavedJobsProvider>
          </BrowserRouter>
        </TranslationProvider>
      </QueryClientProvider>
    </CookiesProvider>
  )
}
