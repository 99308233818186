import React from 'react'
import { useData } from './DataProvider'
import { Box } from '@mui/joy'
import { Label } from './Label'
import { RadioGroup, RadioGroupItem } from '../../lib/ui/radio-group'
import { NavButton, ButtonContainer } from './Buttons'
import useTranslation from '../shared/TranslationProvider'

const Step4 = () => {
  const {
    setCurrentStep,
    lastRoleType,
    seniorityLevel,
    seniorityLevels,
    seekingEducation,
    setLastRoleType,
    setSeniorityLevel,
    setSeekingEducation,
    lastRoleTypeValues,
    seekingEducationValues,
  } = useData()
  const { t } = useTranslation()
  return (
    <>
      <Box className="pt-10">
        <h1 className="text-2xl font-bold md:text-4xl">{t('step4.title')}</h1>
        <p className="py-5 text-base md:text-xl">{t('step4.subtitle')}</p>
        <Box className="pt-2">
          <RadioGroup>
            <Label htmlFor="last_role_type">
              {t('step4.last_role_type.description')}
            </Label>
            {lastRoleTypeValues.map(([id, value]) => (
              <div
                key={`role-type-${id}`}
                className="flex items-center space-x-2"
              >
                <RadioGroupItem
                  value={value}
                  id={`role-type-option-${id}`}
                  onClick={() => setLastRoleType(id)}
                  checked={lastRoleType === id}
                />
                <Label
                  className="font-normal"
                  htmlFor={`role-type-option-${id}`}
                >
                  {t(`step4.last_role_type.${id}`)}
                </Label>
              </div>
            ))}
          </RadioGroup>
        </Box>
        <Box className="pt-5">
          <RadioGroup>
            <Label htmlFor="seniority_level">
              {t('step4.seniority.description')}
            </Label>
            {seniorityLevels.map(([id, value]) => (
              <div
                key={`seniority-level-${id}`}
                className="flex items-center space-x-2"
              >
                <RadioGroupItem
                  value={value}
                  id={`seniority-level-option-${id}`}
                  onClick={() => setSeniorityLevel(value)}
                  checked={seniorityLevel === value}
                />
                <Label
                  className="font-normal"
                  htmlFor={`seniority-level-option-${id}`}
                >
                  {t(`step4.seniority.${id}`)}
                </Label>
              </div>
            ))}
          </RadioGroup>
        </Box>
        <Box className="pt-5">
          <RadioGroup>
            <Label htmlFor="seeking_education">
              {t('step4.seeking_education')}
            </Label>
            {seekingEducationValues.map((value) => (
              <div
                key={`seeking-education-${value}`}
                className="flex items-center space-x-2"
              >
                <RadioGroupItem
                  value={value}
                  id={`seeking-education-option-${value}`}
                  onClick={() => setSeekingEducation(value)}
                  checked={seekingEducation === value}
                />
                <Label
                  className="font-normal"
                  htmlFor={`seeking-education-option-${value}`}
                >
                  {t(`step4.education_${value}`)}
                </Label>
              </div>
            ))}
          </RadioGroup>
        </Box>
      </Box>
      <ButtonContainer>
        <NavButton type="back" onClick={() => setCurrentStep(3)}>
          {t('back')}
        </NavButton>
        <NavButton type="submit" onClick={() => setCurrentStep(5)}>
          {t('next')}
        </NavButton>
      </ButtonContainer>
    </>
  )
}
export default Step4
