import React from 'react'
import { useData } from './DataProvider'
import { Stepper, Step, StepIndicator, Sheet } from '@mui/joy'
import { Check, Circle } from '@mui/icons-material'
import useTranslation from '../shared/TranslationProvider'
import useTheme from '../shared/ThemeProvider'

const StepperDisplay = () => {
  const { step } = useData()
  const { t } = useTranslation()
  const { secondaryColor } = useTheme()
  const steps = [1, 2, 3, 4, 5].map((stepNum) => (
    <Step
      key={stepNum}
      orientation="vertical"
      completed={stepNum < step}
      active={stepNum == step}
      indicator={
        <StepIndicator
          variant={stepNum < step ? 'solid' : 'outlined'}
          color={stepNum < step ? 'success' : 'neutral'}
        >
          {stepNum < step && <Check />}
          {stepNum == step && <Circle color="success" />}
        </StepIndicator>
      }
    >
      {t('step')} {stepNum}
    </Step>
  ))

  return (
    <Sheet variant="outlined" className="stepper-container">
      <Stepper
        className="!mx-auto max-w-3xl"
        size="lg"
        sx={{
          '--joy-palette-success-solidBg': secondaryColor,
          '.MuiSvgIcon-colorSuccess': { color: secondaryColor },
        }}
      >
        {steps}
      </Stepper>
    </Sheet>
  )
}
export default StepperDisplay
