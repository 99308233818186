import React from 'react'
import { useHighlightComment } from './HighlightCommentProvider'
import { CommentBadge, HighlightBadge } from './icons'

const CommentBox = () => {
  const {
    textAreaRef,
    comment,
    setComment,
    hasComment,
    editingComment,
    locator,
  } = useHighlightComment()
  let containerClass =
    'mt-2 mb-8 rounded-2xl bg-intoo-haze p-2 ring-2 font-normal'
  editingComment
    ? (containerClass += ' ring-primary')
    : (containerClass += ' ring-alto-300')
  return (
    <div className="">
      <CommentHighlightBadges />
      {hasComment && (
        <div className={containerClass}>
          <textarea
            ref={textAreaRef}
            className="h-24 w-full resize-none rounded-md border-none bg-intoo-haze p-2 text-[#565656] focus:ring-transparent"
            placeholder="Type your comment here"
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            disabled={!editingComment}
            aria-label={`Notes for the above article part: ${locator}`}
          ></textarea>
          <hr className="border-alto-300" />
          <div className="mt-4 flex justify-end">
            {editingComment ? (
              <EditingCommentButtons />
            ) : (
              <ViewingCommentButtons />
            )}
          </div>
        </div>
      )}
    </div>
  )
}
export default CommentBox

const EditingCommentButtons = () => {
  const { saveComment, cancelComment } = useHighlightComment()
  return (
    <>
      <button
        className="mr-2 rounded-3xl px-6 py-2 hover:bg-[#F3F3F3]"
        onClick={cancelComment}
      >
        Cancel
      </button>
      <button
        onClick={saveComment}
        className="rounded-3xl bg-primary px-6 py-2 text-white hover:bg-primary-darker-75"
      >
        Done
      </button>
    </>
  )
}

const ViewingCommentButtons = () => {
  const { editComment, deleteComment } = useHighlightComment()
  return (
    <>
      <button
        className="mr-2 rounded-3xl px-6 py-2 hover:bg-[#F3F3F3]"
        onClick={deleteComment}
      >
        Delete
      </button>
      <button
        onClick={editComment}
        className="rounded-3xl bg-primary px-6 py-2 text-white hover:bg-primary-darker-75"
      >
        Edit
      </button>
    </>
  )
}

const CommentHighlightBadges = () => {
  const {
    hasHighlight,
    hasComment,
    editingComment,
    setEditingComment,
    removeHighlight,
  } = useHighlightComment()

  const toggleEditingComment = () => {
    setEditingComment(!editingComment)
  }

  const handleCommentKeyDown = (e) => {
    if ([13, 12].includes(e.keyCode)) {
      toggleEditingComment()
    }
  }

  const handleHighlightKeyDown = (e) => {
    if ([13, 12].includes(e.keyCode)) {
      removeHighlight()
    }
  }

  return (
    <div className="items-left mt-2 flex">
      {hasComment && (
        <button
          onKeyDown={handleCommentKeyDown}
          onClick={toggleEditingComment}
          title="Toggle editing comment"
        >
          <CommentBadge
            title="This text has a comment, click to toggle editing comment"
            className="mr-4"
            active={editingComment}
          />
        </button>
      )}
      {hasHighlight && (
        <button
          onKeyDown={handleHighlightKeyDown}
          onClick={removeHighlight}
          title="Remove highlight"
        >
          <HighlightBadge
            active
            title="This text is highlighted, click to remove highlight"
          />
        </button>
      )}
    </div>
  )
}
