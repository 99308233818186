import React from 'react'

const Progress = ({ completed, current, total }) => {
  const percentComplete = completed ? 100 : Math.floor((current / total) * 100)
  const text = completed ? 'Completed!' : `${percentComplete}% completed`
  return (
    <div className="container">
      <div className="flex flex-col items-center gap-2">
        <ProgressBar percentComplete={percentComplete} />
        <div className="text-sm font-medium text-[#08467C]">{text}</div>
      </div>
    </div>
  )
}

const ProgressBar = ({ percentComplete }) => {
  return (
    <div className="prog-bar w-11/12 rounded-[100px] bg-[#BEE1FF] sm:w-4/6">
      <div
        className="prog-bar__filler h-4 rounded-[100px] bg-gradient-to-r from-[#08467C] to-[#4CACE8] transition-all duration-500"
        style={{ width: `${percentComplete}%` }}
      ></div>
    </div>
  )
}

export default Progress
