import React from 'react'
import LeaveSiteWarning from '../shared/LeaveSiteWarning'
import { PopupButton } from 'react-calendly'
import { useBenefits } from './BenefitsProvider'
import moment from 'moment'

export const CalendlyCallScheduled = ({ eventType, calendlyEvent }) => {
  const { type, start_time, join_url } = calendlyEvent

  const eventName =
    eventType === 'orientation'
      ? 'Orientation Call'
      : 'Career Consultation Call'
  return (
    <>
      {type === 'completed' && (
        <div className="font-bold">{eventName} Completed</div>
      )}
      {type === 'pending' && (
        <div>
          <div className="font-bold">{eventName} Scheduled</div>
          <div>{moment(start_time).format('MMMM Do YYYY, h:mm:ss a')}</div>
          <div className="pt-4">
            <a
              href={join_url}
              className="inline-flex h-10 items-center justify-center whitespace-nowrap rounded-full border border-cod-gray-700 bg-white px-8 py-2 text-base font-medium text-cod-gray-800 ring-offset-background transition-colors hover:bg-cod-gray-75 hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
            >
              Join Call
            </a>
          </div>
        </div>
      )}
    </>
  )
}

const CalendlyCallPopup = ({ eventType, buttonText }) => {
  const { coach_calendly_url, candidate_name, candidate_email, setCallType } =
    useBenefits()

  return (
    <div onClick={() => setCallType(eventType)}>
      <PopupButton
        url={`${coach_calendly_url}/30min`}
        prefill={{
          name: candidate_name,
          email: candidate_email,
        }}
        className="h-10 rounded-full bg-intoo-green-coachcta px-8 py-2 font-medium text-white hover:bg-intoo-green-coachcta-hover"
        rootElement={document.getElementById('popupContainer')}
        text={buttonText || 'Schedule A Call'}
      />
    </div>
  )
}
export default CalendlyCallPopup
