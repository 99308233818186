import React from 'react'
import {
  PreviouslyWorkedWithCoach,
  IsCoachHelpful,
  CoachingGoals,
  GrowthExpectation,
  CoachNotes,
  MeetingTimes,
} from '../QuestionBank'

const Professional = ({ form }) => {
  return (
    <>
      <PreviouslyWorkedWithCoach form={form} />
      <IsCoachHelpful form={form} />
      <CoachingGoals
        form={form}
        label="What are your goals for working with your INTOO career development coach? Please share them so that we can best support you."
      />
      <GrowthExpectation form={form} />
      <CoachNotes form={form} />
      <MeetingTimes form={form} />
    </>
  )
}
export default Professional
