import React, { useState, useEffect } from 'react'
import AccessibleFormLabel from './AccessibleFormLabel'
import { Box, Input, FormControl, Button } from '@mui/joy'
import SetPassword from './SetPassword/SetPassword'
import Errors from './Errors'
import EmailInput from './EmailInput'
import SsoButtons from './Sso/SsoButtons'
import LegalAgreement from './LegalAgreement'
import { SpinnerWhite } from '../../shared/Spinner'
import { useData } from './DataProvider'
import { useAlert } from './AlertModal'
import useTranslation from '../../shared/TranslationProvider'

export default function RegistrationForm() {
  const {
    handleFormResponse,
    invitation,
    isLoading,
    setIsLoading,
    getApi,
    oauthEnabled,
    appleAuthEnabled,
    googleAuthEnabled,
    newLegalNotice,
  } = useData()
  const { setAlertState, setAlertData } = useAlert()
  const { t, locale } = useTranslation()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const serverError = t('RegistrationForm.server_error')

  useEffect(() => {
    if (invitation?.first_name) {
      setFirstName(invitation.first_name)
    }
    if (invitation?.last_name) {
      setLastName(invitation.last_name)
    }
    if (invitation?.email) {
      setEmail(invitation.email)
    }
  }, [invitation])

  const handleRegistration = async () => {
    const candidate = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      password_confirmation: passwordConfirmation,
      ca_terms_accepted_at: '1',
      quebec: false,
    }
    setIsLoading(true)
    try {
      const response = await getApi().post('/users', {
        candidate: candidate,
        invitation: invitation.code,
        locale: locale,
      })
      setIsLoading(false)
      if (response.data.errors) {
        setErrors(response.data.errors)
      } else if (response.data.alert) {
        setAlertState('alert')
        setAlertData(response.data.alert)
      } else {
        handleFormResponse(response)
      }
    } catch (error) {
      setIsLoading(false)
      setAlertData(serverError)
      setAlertState('alert')
    }
  }

  return (
    <>
      <Box
        sx={{
          h1: {
            textTransform: 'uppercase',
            fontSize: '2.5rem',
          },
        }}
      >
        <h1>
          {t('RegistrationForm.title1')}
          <br />
          {t('RegistrationForm.title2')}
        </h1>
      </Box>
      <Box className="subtext-container">{t('RegistrationForm.subtitle')}</Box>

      <Box className="control-container flex flex-col justify-between gap-[1.875rem] sm:flex-row">
        <FormControl
          required
          error={!!errors.first_name?.length}
          className="flex-grow"
        >
          <AccessibleFormLabel>
            {t('RegistrationForm.first_name')}
          </AccessibleFormLabel>
          <Input
            name="first_name"
            autoComplete="given-name"
            size="lg"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <Errors errors={errors} field={'first_name'} />
        </FormControl>
        <FormControl
          required
          error={!!errors.last_name?.length}
          className="flex-grow"
        >
          <AccessibleFormLabel>
            {t('RegistrationForm.last_name')}
          </AccessibleFormLabel>
          <Input
            name="last_name"
            autoComplete="family-name"
            size="lg"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <Errors errors={errors} field={'last_name'} />
        </FormControl>
      </Box>

      <Box className="control-container">
        <EmailInput email={email} setEmail={setEmail} errors={errors} />
      </Box>
      <SetPassword
        password={password}
        setPassword={setPassword}
        passwordConfirmation={passwordConfirmation}
        setPasswordConfirmation={setPasswordConfirmation}
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleRegistration()
        }}
        errors={errors}
      />
      <Box className="button-container">
        <Button
          className="submit-button btn"
          size="lg"
          fullWidth
          onClick={handleRegistration}
          disabled={isLoading}
        >
          {t('RegistrationForm.create_button')}
          <SpinnerWhite show={isLoading} className="pl-4" />
        </Button>
      </Box>
      {oauthEnabled && (
        <SsoButtons
          showGoogle={googleAuthEnabled}
          showApple={appleAuthEnabled}
        />
      )}
      <LegalAgreement
        actionText={t('RegistrationForm.create_button')}
        htmlText={newLegalNotice}
      />
    </>
  )
}
