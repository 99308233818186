'use client'
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'
import React, { forwardRef } from 'react'
import { cn } from '../utils'

const Collapsible = CollapsiblePrimitive.Root

const CollapsibleTrigger = CollapsiblePrimitive.Trigger

const CollapsibleContent = CollapsiblePrimitive.CollapsibleContent

export { Collapsible, CollapsibleTrigger, CollapsibleContent }
