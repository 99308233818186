import React, { useState } from 'react'
import ProgressContainer from './ProgressContainer'
import { useIsDesktop } from '../../hooks/useMediaQuery'
import SetGoalDialog from './SetGoalDialog'
import SetTimelineDialog from './SetTimelineDialog'
import { Button } from '../../lib/ui/button'

const ActionPlanInfo = ({
  goal,
  timeline,
  setGoal,
  setTimeline,
  progressPercent,
  getUserTrackerSections,
}) => {
  const [showGoalDialog, setShowGoalDialog] = useState(false)
  const [showDateDialog, setShowDateDialog] = useState(false)
  const toggleGoalDialog = () => setShowGoalDialog((prev) => !prev)
  const toggleDateDialog = () => setShowDateDialog((prev) => !prev)
  const isDesktop = useIsDesktop()

  const actionPlanProgressClass = isDesktop
    ? 'text-[32px] font-bold text-primary mb-[6px]'
    : 'hidden'
  return (
    <div className="track-info mb-[16px] flex flex-col md:gap-2">
      <SetGoalDialog
        showDialog={showGoalDialog}
        toggleDialog={toggleGoalDialog}
        goal={goal}
        setGoal={setGoal}
        getUserTrackerSections={getUserTrackerSections}
      />
      <SetTimelineDialog
        showDialog={showDateDialog}
        toggleDialog={toggleDateDialog}
        timeline={timeline}
        setTimeline={setTimeline}
        å
        getUserTrackerSections={getUserTrackerSections}
      />
      <div className="flex justify-between">
        <div className="flex flex-col">
          <div className="flex flex-col">
            <h1 className="m-0 mb-[8px] p-0 font-roboto-slab text-[32px] font-[700] leading-tight text-primary">
              Action Plan
            </h1>
            <div className="mb-[8px] text-base">
              <strong>Goal: </strong>
              <Button
                variant="link"
                onClick={toggleGoalDialog}
                className="goal-link cursor-pointer p-0 text-base !underline"
              >
                {goal}
              </Button>
            </div>
            <div className="mb-[16px] text-base">
              <strong>Desired Timeline: </strong>
              <Button
                variant="link"
                onClick={toggleDateDialog}
                className="goal-link cursor-pointer p-0 text-base !underline"
              >
                {timeline}
              </Button>
            </div>
          </div>
          {isDesktop && (
            <>
              <div className="mb-[8px] font-roboto-slab text-xl font-medium">
                Your First Steps
              </div>
              <div>
                {`Welcome to Action Plan - your career transition plan for meeting your goal to ${goal}. We've created a plan for you based on the insights you've already shared with us. As you dive into quizzes and assessments, your Action Plan adapts, adding and taking away resources to help you achieve your goal.`}
              </div>
            </>
          )}
        </div>
        <h1 className="flex flex-col items-center gap-4 font-roboto-slab md:min-w-max">
          <div className={actionPlanProgressClass}>Action Plan Progress</div>
          <ProgressContainer progressPercent={progressPercent} />
        </h1>
      </div>
      {!isDesktop && (
        <>
          <div className="mb-[8px] text-base">
            <strong>Your First Steps</strong>
          </div>
          <div>
            {`Welcome to Action Plan - your career transition plan for meeting your goal to ${goal}. We've created a plan for you based on the insights you've already shared with us. As you dive into quizzes and assessments, your Action Plan adapts, adding and taking away resources to help you achieve your goal.`}
          </div>
        </>
      )}
    </div>
  )
}
export default ActionPlanInfo
