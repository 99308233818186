import React from 'react'
import { TranslationProvider } from '../../../shared/TranslationProvider'
import translations from '../../locales.json'
import { Box } from '@mui/joy'
import { AppleButton, GoogleButton, Root } from './SsoButtons'

const Sso = ({ locale, showGoogle = true, showApple = true }) => {
  return (
    <TranslationProvider
      locale={locale || 'en'}
      translations={translations}
      scope="javascript.components.visitor.LoggedOutExperience"
    >
      <Root>
        <Box className="sso-container">
          {showGoogle && <GoogleButton />}

          {showApple && <AppleButton />}
        </Box>
      </Root>
    </TranslationProvider>
  )
}

export default Sso
