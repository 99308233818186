import * as React from 'react'
import { api } from '../../../../util/api'
import TypingEffect from './TypingEffect'
import { useAiResponseStream } from './useAiResponseStream'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import NewCoverLetterForm from './NewCoverLetterForm'

export default function CreateAi({ id, t }) {
  const [formSubmitted, setFormSubmitted] = React.useState(false)
  const [targetJobTitle, setTargetJobTitle] = React.useState('')
  const [yearsExperience, setYearsExperience] = React.useState('')
  const [exampleDescription, setExampleDescription] = React.useState('')
  const [exampleLink, setExampleLink] = React.useState('')
  const [skillsExperiences, setSkillsExperiences] = React.useState('')
  const [showAiTyping, setShowAiTyping] = React.useState(false)
  const [typingComplete, setTypingComplete] = React.useState(false)
  const [parentCoverLetterId, setParentCoverLetterId] = React.useState()
  const [displayedText, setDisplayedText] = React.useState('')
  const [index, setIndex] = React.useState(0)
  const {
    aiResponse,
    coverLetterId,
    setCoverLetterId,
    aiStreamComplete,
    setServerSentEvents,
  } = useAiResponseStream()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => {
    setOpen(true)
    setFormSubmitted(false)
  }
  const handleClose = () => {
    setOpen(false)
    setFormSubmitted(true)
  }
  const [coverLetterTone, setCoverLetterTone] = React.useState(66)
  const [coverLetters, setCoverLetters] = React.useState()
  const [fetchCoverLetters, setFetchCoverLetters] = React.useState(true)
  const [selectedCoverLetter, setSelectedCoverLetter] = React.useState()
  const [typingEffect, setTypingEffect] = React.useState(false)
  const [copyButtonText, setCopyButtonText] = React.useState(t.copy_button_text)
  const [goBack, setGoBack] = React.useState(true)
  const [regenerateCoverLetter, setRegenerateCoverLetter] =
    React.useState(false)

  const showContinueButton = typingComplete && aiStreamComplete && coverLetters
  const formComplete = targetJobTitle && yearsExperience
  const submitButtonDisabled = formSubmitted || !formComplete

  React.useEffect(() => {
    if (aiStreamComplete) {
      fetchCoverLettersFromApi(coverLetterId)
      setSelectedCoverLetter(coverLetterId)
      setRegenerateCoverLetter(false)
    }
  }, [aiStreamComplete, coverLetterId, setSelectedCoverLetter])

  React.useEffect(() => {
    if (regenerateCoverLetter) {
      const copyButton = document.getElementById('copy-button')
      const downloadButton = document.getElementById(
        'download-button-container',
      )
      const aylaCoverLetterText = document.getElementById(
        'ayla-cover-letter-text',
      )
      const coverLetterButtons = document.getElementById('cover-letter-buttons')
      const continueButton = document.getElementById('continue-button')

      if (
        copyButton &&
        downloadButton &&
        aylaCoverLetterText &&
        coverLetterButtons &&
        continueButton
      ) {
        document.getElementById('copy-button').style.display = 'none'
        document.getElementById('download-button-container').style.display =
          'none'
        document.getElementById('ayla-cover-letter-text').style.display = 'none'
        document.getElementById('cover-letter-buttons').style.display = 'none'
        document.getElementById('continue-button').style.display = 'none'
      }
    }
  })

  const coverLetterTones = {
    0: 'in an informal tone',
    33: 'in a neutral tone',
    66: 'in a business formal tone',
    99: 'in a very formal tone',
  }

  const fetchCoverLettersFromApi = async (id) => {
    const {
      data: { cover_letters },
    } = await api.get(`/story/cover_letters/ai_versions/${id}`, {
      id: id,
    })
    setCoverLetters(cover_letters)
    cover_letters.forEach((element, idx) => {
      const documentElement = document.getElementById(`cover-letter-${idx + 1}`)
      if (documentElement) {
        documentElement.hidden = false
        documentElement.classList.add('not-selected')
      }

      if (element && element.id && element.id === parseInt(id)) {
        setTargetJobTitle(element.target_job_title)
        setYearsExperience(element.years_experience)
        setExampleDescription(element.example_description)
        setExampleLink(element.example_link)
        setSkillsExperiences(element.skills_experiences)
        setParentCoverLetterId(element.parent_cover_letter_id)
        setCoverLetterTone(
          Object.keys(coverLetterTones).find(
            (key) => coverLetterTones[key] === element.tone,
          ),
        )
        if (documentElement) {
          documentElement.classList.remove('not-selected')
        }
      }
    })
  }

  React.useEffect(() => {
    if (id && fetchCoverLetters && goBack) {
      fetchCoverLettersFromApi(parseInt(id))
      setFetchCoverLetters(false)
      setCoverLetterId(parseInt(id))
      setSelectedCoverLetter(parseInt(id))
      setShowAiTyping(true)
      setGoBack(false)
    }
  }, [
    id,
    coverLetters,
    fetchCoverLetters,
    goBack,
    setCoverLetterId,
    setShowAiTyping,
    setSelectedCoverLetter,
    setFetchCoverLetters,
    setGoBack,
  ])

  React.useEffect(() => {
    setIndex(0)
    setDisplayedText('')
  }, [coverLetterId])

  const handleSubmitButtonClick = async (event) => {
    event.preventDefault()
    setFormSubmitted(true)
    setShowAiTyping(true)
    setOpen(false)
    setTypingEffect(true)
    setTypingComplete(false)
    setDisplayedText('')
    setIndex(0)
    setRegenerateCoverLetter(true)

    const {
      data: { cover_letter_id, use_sse },
    } = await api.post('/story/cover_letters/create_ai', {
      ai_cover_letter: {
        target_job_title: targetJobTitle,
        years_experience: yearsExperience,
        example_description: exampleDescription,
        example_link: exampleLink,
        skills_experiences: skillsExperiences,
        parent_cover_letter_id: parentCoverLetterId,
        tone: coverLetterTones[coverLetterTone],
      },
    })
    setServerSentEvents(use_sse)
    setCoverLetterId(cover_letter_id)

    if (!parentCoverLetterId) {
      setParentCoverLetterId(cover_letter_id)
    }
    setFetchCoverLetters(true)
  }

  const handleCopyButtonClick = (e) => {
    e.preventDefault()

    document.addEventListener(
      'copy',
      function (e) {
        const coverLetter = coverLetters.find(
          (ele) => ele.id === parseInt(selectedCoverLetter),
        )
        const coverLetterText = coverLetter.content
        e.clipboardData.setData('text/plain', coverLetterText)
        e.preventDefault()
      },
      true,
    )
    setCopyButtonText(t.copied_text)

    document.execCommand('copy')
  }

  const handleContinueButtonClick = () => {
    fetchCoverLettersFromApi(selectedCoverLetter)
      .then(() => {
        window.location.href = `/story/document/cover_letters/${selectedCoverLetter}/edit`
        return true
      })
      .catch(() => {
        return false
      })
  }

  const handleSelectButtonClick = (index) => {
    const selected = coverLetters[index]
    setTypingEffect(false)
    setCopyButtonText(t.copy_button_text)

    for (let i = 0; i < coverLetters.length; i++) {
      document
        .getElementById(`cover-letter-${i + 1}`)
        .classList.add('not-selected')
      if (i === index) {
        document
          .getElementById(`cover-letter-${i + 1}`)
          .classList.remove('not-selected')
      }
    }
    const newSelectedId = selected.id
    setSelectedCoverLetter(newSelectedId)
  }

  if (showAiTyping) {
    return (
      <>
        {showContinueButton && (
          <div
            className="actions"
            id="download-button-container"
            style={{
              margin: '0 auto',
              padding: '1rem',
              paddingRight: '0',
              marginBottom: '1rem',
            }}
          >
            <div
              id="download-button"
              style={{ position: 'absolute', right: '4rem' }}
            >
              <a
                className="pull-right"
                href="#"
                id="download-dropdown"
                data-toggle="dropdown"
                aria-expanded="true"
              >
                <i className="el-icon-download-alt"></i>{' '}
                {t.download_button_text}
              </a>
              <ul
                className="dropdown-menu dropdown-menu-right"
                role="menu"
                aria-labelledby="download-dropdown"
              >
                <li role="presentation">
                  <a
                    href={`/story/document/cover_letters/${selectedCoverLetter}.pdf`}
                  >
                    Download - PDF
                  </a>
                </li>
                <li role="presentation">
                  <a
                    href={`/story/document/cover_letters/${selectedCoverLetter}.doc`}
                  >
                    Download - Word
                  </a>
                </li>
                <li role="presentation">
                  <a
                    href={`/story/document/cover_letters/${selectedCoverLetter}.txt`}
                  >
                    Download - Text
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
        <Paper>
          {showContinueButton && (
            <Box
              id="copy-button"
              sx={{
                flexDirection: 'row',
                justifyContent: 'right',
                padding: '0.5rem',
                display: 'flex',
              }}
            >
              <button
                onClick={handleCopyButtonClick}
                style={{
                  fontWeight: 'bold',
                }}
              >
                <i className="el-icon-share copy_text">
                  <span style={{ fontFamily: 'gotham_bookregular' }}>
                    &nbsp;{copyButtonText}
                  </span>
                </i>
              </button>
            </Box>
          )}

          {aiStreamComplete &&
            coverLetters &&
            !typingEffect &&
            coverLetters.map((coverLetter) => {
              if (
                coverLetter &&
                coverLetter.id === parseInt(selectedCoverLetter)
              ) {
                return coverLetter.content
                  .split('\n\n')
                  .map((paragraph, index) => (
                    <p key={index} className="m-4">
                      {paragraph}
                    </p>
                  ))
              }
            })}

          {((!aiStreamComplete && !coverLetters) || typingEffect) && (
            <TypingEffect
              message={aiResponse}
              baseSpeed={5}
              aiStreamComplete={aiStreamComplete}
              setTypingComplete={setTypingComplete}
              displayedText={displayedText}
              setDisplayedText={setDisplayedText}
              index={index}
              setIndex={setIndex}
            />
          )}
          <>
            <br />
            <Box
              id="ayla-cover-letter-text"
              sx={{ fontWeight: 'bold', display: 'none' }}
            >
              {t.ayla_cover_letters_text}
            </Box>
            <Box
              id="cover-letter-buttons"
              sx={{
                display: 'none',
                flexDirection: 'row',
                justifyContent: 'left',
                padding: '0.5rem',
                gap: '1rem',
                flexWrap: 'wrap',
              }}
            >
              <Button
                id="cover-letter-1"
                hidden={true}
                onClick={() => handleSelectButtonClick(0)}
              >
                1
              </Button>
              <Button
                id="cover-letter-2"
                hidden={true}
                onClick={() => handleSelectButtonClick(1)}
              >
                2
              </Button>
              <Button
                id="cover-letter-3"
                hidden={true}
                onClick={() => handleSelectButtonClick(2)}
              >
                3
              </Button>
              <Button
                id="cover-letter-4"
                hidden={true}
                onClick={() => handleSelectButtonClick(3)}
              >
                4
              </Button>
              <Button
                id="cover-letter-5"
                hidden={true}
                onClick={() => handleSelectButtonClick(4)}
              >
                5
              </Button>
            </Box>
          </>
        </Paper>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              backgroundColor: '#ffffff',
              m: 'auto',
              mt: '4rem',
              padding: '2rem',
              width: '90vw',
              maxHeight: '80vh',
              overflow: 'auto',
              borderRadius: 3,
            }}
          >
            <button
              onClick={handleClose}
              style={{
                float: 'right',
                fontWeight: 'bold',
                fontSize: '1.25rem',
              }}
            >
              <i className="el-icon-remove-sign" />
            </button>
            <NewCoverLetterForm
              t={t}
              targetJobTitle={targetJobTitle}
              setTargetJobTitle={setTargetJobTitle}
              yearsExperience={yearsExperience}
              setYearsExperience={setYearsExperience}
              exampleDescription={exampleDescription}
              setExampleDescription={setExampleDescription}
              exampleLink={exampleLink}
              setExampleLink={setExampleLink}
              skillsExperiences={skillsExperiences}
              setSkillsExperiences={setSkillsExperiences}
              handleSubmitButtonClick={handleSubmitButtonClick}
              submitButtonDisabled={submitButtonDisabled}
              tonePresent={true}
              coverLetterTone={coverLetterTone}
              setCoverLetterTone={setCoverLetterTone}
              handleClose={handleClose}
            />
          </Box>
        </Modal>
        {showContinueButton && (
          <div id="continue-button">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              sx={{ padding: '0.5rem' }}
            >
              {t.want_to_change_text}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              sx={{ padding: '0.5rem' }}
            >
              <Button
                id="regenerate_button"
                onClick={handleOpen}
                disabled={coverLetters && coverLetters.length > 4}
              >
                {t.regenerate_button_text}
              </Button>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              sx={{ padding: '0.5rem' }}
            >
              {t.looks_good_text}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              sx={{ padding: '0.5rem' }}
            >
              <Button onClick={handleContinueButtonClick}>
                {t.continue_button_text}
              </Button>
            </Box>
          </div>
        )}
      </>
    )
  }

  return (
    <NewCoverLetterForm
      t={t}
      targetJobTitle={targetJobTitle}
      setTargetJobTitle={setTargetJobTitle}
      yearsExperience={yearsExperience}
      setYearsExperience={setYearsExperience}
      exampleDescription={exampleDescription}
      setExampleDescription={setExampleDescription}
      exampleLink={exampleLink}
      setExampleLink={setExampleLink}
      skillsExperiences={skillsExperiences}
      setSkillsExperiences={setSkillsExperiences}
      handleSubmitButtonClick={handleSubmitButtonClick}
      submitButtonDisabled={submitButtonDisabled}
      coverLetterTone={coverLetterTone}
      setCoverLetterTone={setCoverLetterTone}
      tonePresent={false}
    />
  )
}

export function Button({ children, ...props }) {
  return (
    <button className="btn btn-primary" {...props}>
      {children}
    </button>
  )
}

function Paper({ children, ...props }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        color: '#000',
        fontSize: '0.85rem',
        lineHeight: '1.5',
        fontFamily: 'monospace',
        borderRadius: '0.3125rem',
        boxShadow: '0 0 0.625rem rgba(0, 0, 0, 0.5)',
      }}
      {...props}
      className="p-4 md:m-8 md:p-12"
    >
      {children}
    </Box>
  )
}
